import emitter from 'tiny-emitter/instance';

export let activeHotspot = 10;
export const setActiveHotspot = (number) => {
    activeHotspot = number;
    emitter.emit('caption', activeHotspot)
}
export const getActiveHotspot = () => {

    return activeHotspot;
}

export const hotspots = [
    {
        position: { x: -1702.473, y: 200.455, z: -1450.000 },

        scale: { x: 20.000, y: 20.000, z: 20.000 },
        info: {
            image: '/assets/chapter1-popup1.jpg',
            title: 'انتقال بني حنيفة إلى اليمامة ',
            description: 'كان أول مـن قـدم مـن بنـي حنيفـة إلى اليمامة "عبيـد بـن ثعلبة بـن ربـوع بن ثعلبة بـن الـ ّدؤل بـن حنيفـة" مرتحـلاً بأهلـه ومالـه مـن الحجـاز، حتـى نـزل بموضـع يقـال لـه "قـارات"، بالقـرب مـن (حجـر الريـاض)، فأ قـام بهـا أيّامـا. '
        }
    },
    {
        position: { x: -431.102, y: 179.918, z: -519.169 },
        scale: { x: 20.000, y: 20.000, z: 20.000 },
        info: {
            image: '/assets/chapter1-popup2.jpg',
            title: '',
            description: 'وتشمل اليمامـة عـدداً مـن الأقاليم من أشـهرها "العـارض" و"الوشم" و"سدير" و"الفرع" و"الأفـلاج" و"وادي الدواسـر"، وعشرات الأوديـة التـي تخترق سـهولها وجبالهـا. كـما أن فيهـا عـدداً مـن العيـون الشـهيرة، ومـن أشـهرها "الخـضراء" و"هيـت"، وقـد كانـت اليمامـة تسـمى "الخـضراء"؛ لخصوبـة تربتهـا وكثرة أشجارها. '
        }
    },
    {
        position: { x: 1120.142, y: 129.113, z: -1339.963 },
        scale: { x: 20.000, y: 20.000, z: 20.000 },
        info: {
            image: '',
            title: '',
            description: 'في زمــن "طســم" و"جديــس"، والفــترة الإســلامية المبكـرة تميـز وادي حنيفة بالخضرة والنضرة، وكثرة الميـاه الجاريـة مـن ميـاه الأمطـار والأنهار والعيـون. وفي ذلـك يقـول ابـن خلـدون بـأن "طسـماً وجديسـاً كانـوا مـن سـاكني اليمامـة، وهـي إذ ذاك مـن أخصـب البـلاد وأعمرهـا، وأكثرهـا خـيراً وثمـاراً حدائـق وقصوراً"، ويستطرد ابـن خلـدون في حديثه فيقول: "بقيـت اليمامة بعـد طسـم يبابـاً لا يأكل ثمرها إلا عوافي الطير والسباع حتـى نزلهـا بنـو حنيفـة".'
        }
    },
    {
        position: { x: -121.150, y: 69.113, z: 286.077 },
        scale: { x: 20.000, y: 20.000, z: 20.000 },
        info: {
            image: '/assets/chapter1-popup4.jpg',
            title: 'الاستقرار',
            description: 'أسـس بنـو حنيفـة عـدداً مـن مراكـز الاسـتقرار التـي امتـدت عـلى ضفـاف "وادي العـرض" الـذي أصبـح "وادي حنيفـة"، حيـث تحولت اليمامة إلى إقليم تحت نفوذ بني حنيفـة. وعندمـا ظهـر الإسـلام كان ملـك اليمامة هـو "ثمامة بن أثال الحنفي" صاحب القصة الشهيرة مـع النبـي صلى الله عليه وسلم، وهـذا يبيـن مـدى الاستقرار والقوة في هذا الإقليم. '
        }
    }
]