export const palms = [
    {
        "position": {
            "x": 405.3097640888444,
            "y": 264.95140343904495,
            "z": 905.8716030233926
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 405.3097640888444,
            "y": 272.2577374577522,
            "z": 905.8716030233926
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 405.3097640888444,
            "y": 272.2577374577522,
            "z": 905.8716030233926
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 415.701606016554,
            "y": 264.95140343904495,
            "z": 910.1048588657586
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 415.701606016554,
            "y": 273.260350048542,
            "z": 910.1048588657586
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 415.701606016554,
            "y": 273.260350048542,
            "z": 910.1048588657586
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 420.2908035224111,
            "y": 264.95140343904495,
            "z": 962.7624626268911
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 420.2908035224111,
            "y": 272.0807874798775,
            "z": 962.7624626268911
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 420.2908035224111,
            "y": 272.0807874798775,
            "z": 962.7624626268911
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 417.96740584498673,
            "y": 264.95140343904495,
            "z": 953.5281711841966
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 417.96740584498673,
            "y": 273.2285516858101,
            "z": 953.5281711841966
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 417.96740584498673,
            "y": 273.2285516858101,
            "z": 953.5281711841966
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 438.0538242131141,
            "y": 264.95140343904495,
            "z": 1068.0502847175803
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 438.0538242131141,
            "y": 274.4909523129463,
            "z": 1068.0502847175803
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 438.0538242131141,
            "y": 274.4909523129463,
            "z": 1068.0502847175803
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 427.76470090824006,
            "y": 264.95140343904495,
            "z": 1016.3559770301351
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 427.7647009082401,
            "y": 274.4909523129463,
            "z": 1016.3559770301353
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 427.7647009082401,
            "y": 274.4909523129463,
            "z": 1016.3559770301353
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 323.58110682626733,
            "y": 264.95140343904495,
            "z": 1124.2755538275535
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 323.5811068262673,
            "y": 274.4909523129463,
            "z": 1124.2755538275535
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 323.5811068262673,
            "y": 274.4909523129463,
            "z": 1124.2755538275535
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 341.7178191110151,
            "y": 264.95140343904495,
            "z": 1102.9195092563605
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 341.71781911101505,
            "y": 273.2285516858101,
            "z": 1102.9195092563605
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 341.71781911101505,
            "y": 273.2285516858101,
            "z": 1102.9195092563605
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 407.867807380657,
            "y": 264.95140343904495,
            "z": 1086.1030838914107
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 407.867807380657,
            "y": 274.4909523129463,
            "z": 1086.1030838914107
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 407.867807380657,
            "y": 274.4909523129463,
            "z": 1086.1030838914107
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 357.8998230861339,
            "y": 264.95140343904495,
            "z": 1078.4896415512394
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 357.8998230861339,
            "y": 274.4909523129463,
            "z": 1078.4896415512394
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 357.8998230861339,
            "y": 274.4909523129463,
            "z": 1078.4896415512394
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 152.97245491622965,
            "y": 264.95140343904495,
            "z": 845.9322093864677
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 152.9724549162296,
            "y": 272.7733386158943,
            "z": 845.9322093864677
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 152.9724549162296,
            "y": 272.7733386158943,
            "z": 845.9322093864677
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 161.47151827689675,
            "y": 264.95140343904495,
            "z": 849.4432207380949
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 161.47151827689675,
            "y": 277.1282747387886,
            "z": 849.4432207380949
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 161.47151827689675,
            "y": 277.1282747387886,
            "z": 849.4432207380949
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 159.7165516096661,
            "y": 264.95140343904495,
            "z": 840.4701906211575
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 159.7165516096661,
            "y": 274.7903154492378,
            "z": 840.4701906211575
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 159.7165516096661,
            "y": 274.7903154492378,
            "z": 840.4701906211575
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 234.671043007364,
            "y": 264.95140343904495,
            "z": 802.8126859841138
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 234.67104300736406,
            "y": 277.1282747387886,
            "z": 802.812685984114
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 234.67104300736406,
            "y": 277.1282747387886,
            "z": 802.812685984114
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 225.2382011070419,
            "y": 264.95140343904495,
            "z": 800.6268742113944
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 225.2382011070419,
            "y": 275.8042305111885,
            "z": 800.6268742113944
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 225.2382011070419,
            "y": 275.8042305111885,
            "z": 800.6268742113944
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 228.03398533236256,
            "y": 264.95140343904495,
            "z": 793.6181341914956
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 228.03398533236256,
            "y": 274.4909523129463,
            "z": 793.6181341914956
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 228.03398533236256,
            "y": 274.4909523129463,
            "z": 793.6181341914956
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 290.8496066483268,
            "y": 264.95140343904495,
            "z": 786.2396542585333
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 290.8496066483268,
            "y": 277.85387974977493,
            "z": 786.2396542585333
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 290.8496066483268,
            "y": 277.85387974977493,
            "z": 786.2396542585333
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 303.34679089237,
            "y": 264.95140343904495,
            "z": 792.8381354717548
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 303.34679089237,
            "y": 275.5387752652168,
            "z": 792.8381354717548
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 303.34679089237,
            "y": 275.5387752652168,
            "z": 792.8381354717548
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 306.99111257325836,
            "y": 264.95140343904495,
            "z": 785.7736814727633
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 306.99111257325836,
            "y": 272.7058261036873,
            "z": 785.7736814727633
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 306.99111257325836,
            "y": 272.7058261036873,
            "z": 785.7736814727633
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 255.09121964508427,
            "y": 264.95140343904495,
            "z": 810.4591472288757
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 255.09121964508427,
            "y": 272.7058261036873,
            "z": 810.4591472288757
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 255.09121964508427,
            "y": 272.7058261036873,
            "z": 810.4591472288757
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 160.3490847718877,
            "y": 266.76140332221985,
            "z": 952.4727890987315
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 160.3490847718877,
            "y": 273.1886284351349,
            "z": 952.4727890987315
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 160.3490847718877,
            "y": 273.1886284351349,
            "z": 952.4727890987315
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 166.94537625659393,
            "y": 266.7329763174057,
            "z": 958.8778018443484
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 166.94537625659393,
            "y": 273.9708422422409,
            "z": 958.8778018443484
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 166.94537625659393,
            "y": 273.9708422422409,
            "z": 958.8778018443484
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 168.45373462341644,
            "y": 264.95140343904495,
            "z": 954.2814753433239
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 168.45373462341644,
            "y": 274.0671041607857,
            "z": 954.2814753433239
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 168.45373462341644,
            "y": 274.0671041607857,
            "z": 954.2814753433239
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 219.75401540838536,
            "y": 264.95140343904495,
            "z": 732.9479683549241
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 219.75401540838536,
            "y": 274.0671041607857,
            "z": 732.9479683549241
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 219.75401540838536,
            "y": 274.0671041607857,
            "z": 732.9479683549241
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 230.462859399305,
            "y": 264.95140343904495,
            "z": 737.2063838822563
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 230.462859399305,
            "y": 272.144223511219,
            "z": 737.2063838822563
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 230.462859399305,
            "y": 272.144223511219,
            "z": 737.2063838822563
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 229.03404448573934,
            "y": 264.95140343904495,
            "z": 731.341842689093
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 229.03404448573934,
            "y": 274.4909523129463,
            "z": 731.341842689093
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 229.03404448573934,
            "y": 274.4909523129463,
            "z": 731.341842689093
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 178.1563451935442,
            "y": 264.95140343904495,
            "z": 885.5422512804291
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 178.15634519354427,
            "y": 272.42087012529373,
            "z": 885.5422512804291
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 178.15634519354427,
            "y": 272.42087012529373,
            "z": 885.5422512804291
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 169.7031014686529,
            "y": 264.95140343904495,
            "z": 884.8600911938682
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 169.70310146865285,
            "y": 274.4909523129463,
            "z": 884.8600911938682
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 169.70310146865285,
            "y": 274.4909523129463,
            "z": 884.8600911938682
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 357.6937801459082,
            "y": 264.95140343904495,
            "z": 1045.679432426327
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 357.69378014590825,
            "y": 274.4909523129463,
            "z": 1045.679432426327
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 357.69378014590825,
            "y": 274.4909523129463,
            "z": 1045.679432426327
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 395.41699721628163,
            "y": 264.95140343904495,
            "z": 947.9193842840763
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 395.4169972162816,
            "y": 271.3937786221504,
            "z": 947.9193842840762
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 395.4169972162816,
            "y": 271.3937786221504,
            "z": 947.9193842840762
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 347.8681942645378,
            "y": 264.95140343904495,
            "z": 992.8147373699945
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 347.8681942645378,
            "y": 273.2285516858101,
            "z": 992.8147373699945
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 347.8681942645378,
            "y": 273.2285516858101,
            "z": 992.8147373699945
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 327.1847224931874,
            "y": 264.95140343904495,
            "z": 1046.8003648556519
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 327.18472249318734,
            "y": 273.2285516858101,
            "z": 1046.8003648556519
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 327.18472249318734,
            "y": 273.2285516858101,
            "z": 1046.8003648556519
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 303.939203463255,
            "y": 264.95140343904495,
            "z": 925.8521773928384
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 303.939203463255,
            "y": 273.2285516858101,
            "z": 925.8521773928384
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 303.939203463255,
            "y": 273.2285516858101,
            "z": 925.8521773928384
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 261.15967490421434,
            "y": 264.95140343904495,
            "z": 901.105323559453
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 261.15967490421434,
            "y": 273.2285516858101,
            "z": 901.105323559453
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 261.15967490421434,
            "y": 273.2285516858101,
            "z": 901.105323559453
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 277.90764019140875,
            "y": 264.95140343904495,
            "z": 1021.2503223005505
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 277.90764019140875,
            "y": 273.3256328701973,
            "z": 1021.2503223005505
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 277.90764019140875,
            "y": 273.3256328701973,
            "z": 1021.2503223005505
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 265.53840492492805,
            "y": 264.95140343904495,
            "z": 859.3784200179955
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 265.53840492492805,
            "y": 272.1161444783211,
            "z": 859.3784200179955
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 265.53840492492805,
            "y": 272.1161444783211,
            "z": 859.3784200179955
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 243.16893075853244,
            "y": 264.95140343904495,
            "z": 877.8004268049328
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 243.16893075853244,
            "y": 272.3484151959419,
            "z": 877.8004268049328
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 243.16893075853244,
            "y": 272.3484151959419,
            "z": 877.8004268049328
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 307.1536583809094,
            "y": 267.0353317260742,
            "z": 856.4256978531455
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 307.15365838090946,
            "y": 275.3442783355713,
            "z": 856.4256978531456
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 307.15365838090946,
            "y": 275.3442783355713,
            "z": 856.4256978531456
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 289.132098782217,
            "y": 264.95140343904495,
            "z": 1072.509863145297
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 289.132098782217,
            "y": 274.4909523129463,
            "z": 1072.509863145297
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 289.132098782217,
            "y": 274.4909523129463,
            "z": 1072.509863145297
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 314.81260768269055,
            "y": 264.95140343904495,
            "z": 1020.9092800937001
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 314.81260768269055,
            "y": 274.4909523129463,
            "z": 1020.9092800937001
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 314.81260768269055,
            "y": 274.4909523129463,
            "z": 1020.9092800937001
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 332.5108633200299,
            "y": 264.95140343904495,
            "z": 953.2560579494531
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 332.5108633200299,
            "y": 274.4909523129463,
            "z": 953.2560579494531
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 332.5108633200299,
            "y": 274.4909523129463,
            "z": 953.2560579494531
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 300.0348961681381,
            "y": 264.95140343904495,
            "z": 969.800247521775
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 300.0348961681381,
            "y": 273.2285516858101,
            "z": 969.800247521775
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 300.0348961681381,
            "y": 273.2285516858101,
            "z": 969.800247521775
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 357.9491648814778,
            "y": 264.95140343904495,
            "z": 885.1380556264135
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 357.9491648814779,
            "y": 273.2285516858101,
            "z": 885.1380556264136
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 357.9491648814779,
            "y": 273.2285516858101,
            "z": 885.1380556264136
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 221.9356799301435,
            "y": 264.95140343904495,
            "z": 987.7241179949186
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 221.9356799301435,
            "y": 273.2285516858101,
            "z": 987.7241179949186
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 221.9356799301435,
            "y": 273.2285516858101,
            "z": 987.7241179949186
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 233.3040894982584,
            "y": 264.95140343904495,
            "z": 1033.080006959521
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 233.3040894982584,
            "y": 274.4909523129463,
            "z": 1033.080006959521
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 233.3040894982584,
            "y": 274.4909523129463,
            "z": 1033.080006959521
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 218.7098152275824,
            "y": 267.8185558319092,
            "z": 932.2779036424099
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 218.7098152275824,
            "y": 276.3214740753174,
            "z": 932.2779036424099
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 218.7098152275824,
            "y": 276.3214740753174,
            "z": 932.2779036424099
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 372.6069993449321,
            "y": 264.95140343904495,
            "z": 975.4228260997933
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 372.6069993449321,
            "y": 274.4909523129463,
            "z": 975.4228260997933
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 372.6069993449321,
            "y": 274.4909523129463,
            "z": 975.4228260997933
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 238.14744469361727,
            "y": 264.95140343904495,
            "z": 968.1501544642908
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 238.14744469361727,
            "y": 274.4909523129463,
            "z": 968.1501544642908
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 238.14744469361727,
            "y": 274.4909523129463,
            "z": 968.1501544642908
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 311.07276814753664,
            "y": 264.95140343904495,
            "z": 878.9995216941521
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 311.0727681475367,
            "y": 274.4909523129463,
            "z": 878.9995216941522
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 311.0727681475367,
            "y": 274.4909523129463,
            "z": 878.9995216941522
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 254.76188240655364,
            "y": 264.95140343904495,
            "z": 834.8161488222395
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 254.76188240655364,
            "y": 272.5830424427986,
            "z": 834.8161488222395
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 254.76188240655364,
            "y": 272.5830424427986,
            "z": 834.8161488222395
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 207.59994710651083,
            "y": 266.3207119703293,
            "z": 898.4642389979612
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 207.59994710651088,
            "y": 275.86026084423065,
            "z": 898.4642389979612
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 207.59994710651088,
            "y": 275.86026084423065,
            "z": 898.4642389979612
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 326.4206799700851,
            "y": 264.95140343904495,
            "z": 733.6025871730278
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 326.4206799700851,
            "y": 277.1282747387886,
            "z": 733.6025871730278
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 326.4206799700851,
            "y": 277.1282747387886,
            "z": 733.6025871730278
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 316.9878397061425,
            "y": 264.95140343904495,
            "z": 731.4167744203892
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 316.9878397061425,
            "y": 275.8042305111885,
            "z": 731.4167744203892
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 316.9878397061425,
            "y": 275.8042305111885,
            "z": 731.4167744203892
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 319.78362229508366,
            "y": 264.95140343904495,
            "z": 724.4080353804095
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 319.78362229508366,
            "y": 274.4909523129463,
            "z": 724.4080353804095
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 319.78362229508366,
            "y": 274.4909523129463,
            "z": 724.4080353804095
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 323.4016637063904,
            "y": 264.95140343904495,
            "z": 719.3735894020635
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 323.4016637063904,
            "y": 277.1282747387886,
            "z": 719.3735894020635
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 323.4016637063904,
            "y": 277.1282747387886,
            "z": 719.3735894020635
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 313.9688025120596,
            "y": 264.95140343904495,
            "z": 717.1877713977409
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 313.9688025120596,
            "y": 275.8042305111885,
            "z": 717.1877713977409
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 313.9688025120596,
            "y": 275.8042305111885,
            "z": 717.1877713977409
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 316.76459819203694,
            "y": 264.95140343904495,
            "z": 710.1790245184092
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 316.76459819203694,
            "y": 274.4909523129463,
            "z": 710.1790245184092
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 316.76459819203694,
            "y": 274.4909523129463,
            "z": 710.1790245184092
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 334.12630282252076,
            "y": 264.95140343904495,
            "z": 710.9887028439715
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 334.12630282252076,
            "y": 277.1282747387886,
            "z": 710.9887028439715
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 334.12630282252076,
            "y": 277.1282747387886,
            "z": 710.9887028439715
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 324.6934625585782,
            "y": 264.95140343904495,
            "z": 708.802890091333
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 324.69346255857823,
            "y": 275.8042305111885,
            "z": 708.8028900913331
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 324.69346255857823,
            "y": 275.8042305111885,
            "z": 708.8028900913331
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 327.48924514751934,
            "y": 264.95140343904495,
            "z": 701.7941510513533
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 327.48924514751934,
            "y": 274.4909523129463,
            "z": 701.7941510513533
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 327.48924514751934,
            "y": 274.4909523129463,
            "z": 701.7941510513533
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 381.91553385055863,
            "y": 264.95140343904495,
            "z": 877.7466201934053
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 381.91553385055863,
            "y": 277.1282747387886,
            "z": 877.7466201934053
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 381.91553385055863,
            "y": 277.1282747387886,
            "z": 877.7466201934053
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 372.48268465254534,
            "y": 264.95140343904495,
            "z": 875.5608073717677
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 372.48268465254534,
            "y": 275.8042305111885,
            "z": 875.5608073717677
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 372.48268465254534,
            "y": 275.8042305111885,
            "z": 875.5608073717677
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 375.2784732970451,
            "y": 264.95140343904495,
            "z": 868.5520635939222
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 375.27847329704514,
            "y": 274.4909523129463,
            "z": 868.5520635939223
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 375.27847329704514,
            "y": 274.4909523129463,
            "z": 868.5520635939223
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 406.4084470711679,
            "y": 264.95140343904495,
            "z": 887.7786769149745
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 406.4084470711679,
            "y": 277.1282747387886,
            "z": 887.7786769149745
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 406.4084470711679,
            "y": 277.1282747387886,
            "z": 887.7786769149745
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 396.9755912663917,
            "y": 264.95140343904495,
            "z": 885.5928679107392
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 396.9755912663917,
            "y": 275.8042305111885,
            "z": 885.5928679107392
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 396.9755912663917,
            "y": 275.8042305111885,
            "z": 885.5928679107392
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 399.7713864564095,
            "y": 264.95140343904495,
            "z": 878.5841202132177
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 399.7713864564095,
            "y": 274.4909523129463,
            "z": 878.5841202132177
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 399.7713864564095,
            "y": 274.4909523129463,
            "z": 878.5841202132177
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 396.7759133733708,
            "y": 264.95140343904495,
            "z": 901.2773439188147
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 396.7759133733708,
            "y": 277.1282747387886,
            "z": 901.2773439188147
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 396.7759133733708,
            "y": 277.1282747387886,
            "z": 901.2773439188147
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 390.13885275861236,
            "y": 264.95140343904495,
            "z": 892.082787217058
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 390.13885275861236,
            "y": 274.4909523129463,
            "z": 892.082787217058
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 390.13885275861236,
            "y": 274.4909523129463,
            "z": 892.082787217058
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 202.5243524832795,
            "y": 264.95140343904495,
            "z": 962.7401131097156
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 202.5243524832795,
            "y": 277.1282747387886,
            "z": 962.7401131097156
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 202.5243524832795,
            "y": 277.1282747387886,
            "z": 962.7401131097156
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 203.98335614893122,
            "y": 264.95140343904495,
            "z": 919.0438981789748
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 203.98335614893122,
            "y": 277.1282747387886,
            "z": 919.0438981789748
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 203.98335614893122,
            "y": 277.1282747387886,
            "z": 919.0438981789748
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 261.33403254619486,
            "y": 264.95140343904495,
            "z": 1063.416680153424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 261.3340325461948,
            "y": 277.1282747387886,
            "z": 1063.416680153424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 261.3340325461948,
            "y": 277.1282747387886,
            "z": 1063.416680153424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 251.90118836257625,
            "y": 264.95140343904495,
            "z": 1061.2308608552673
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 251.90118836257625,
            "y": 275.8042305111885,
            "z": 1061.2308608552673
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 251.90118836257625,
            "y": 275.8042305111885,
            "z": 1061.2308608552673
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 254.69697095151741,
            "y": 264.95140343904495,
            "z": 1054.2221218152877
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 254.69697095151741,
            "y": 274.4909523129463,
            "z": 1054.2221218152877
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 254.69697095151741,
            "y": 274.4909523129463,
            "z": 1054.2221218152877
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 272.6620273977512,
            "y": 264.95140343904495,
            "z": 1091.4420211544973
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 272.66202739775116,
            "y": 277.1282747387886,
            "z": 1091.4420211544973
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 272.66202739775116,
            "y": 277.1282747387886,
            "z": 1091.4420211544973
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 263.22917274893854,
            "y": 264.95140343904495,
            "z": 1089.2561992304986
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 263.2291727489386,
            "y": 275.8042305111885,
            "z": 1089.2561992304986
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 263.2291727489386,
            "y": 275.8042305111885,
            "z": 1089.2561992304986
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 266.02496188339774,
            "y": 264.95140343904495,
            "z": 1082.2474562708428
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 266.0249618833977,
            "y": 274.4909523129463,
            "z": 1082.2474562708428
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 266.0249618833977,
            "y": 274.4909523129463,
            "z": 1082.2474562708428
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 247.1652534502782,
            "y": 264.95140343904495,
            "z": 1073.75687572874
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 247.1652534502782,
            "y": 277.1282747387886,
            "z": 1073.75687572874
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 247.1652534502782,
            "y": 277.1282747387886,
            "z": 1073.75687572874
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 237.73240272114148,
            "y": 264.95140343904495,
            "z": 1071.5710603502594
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 237.73240272114154,
            "y": 275.8042305111885,
            "z": 1071.5710603502594
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 237.73240272114154,
            "y": 275.8042305111885,
            "z": 1071.5710603502594
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 240.52819185560074,
            "y": 264.95140343904495,
            "z": 1064.5623173906035
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 240.52819185560074,
            "y": 274.4909523129463,
            "z": 1064.5623173906035
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 240.52819185560074,
            "y": 274.4909523129463,
            "z": 1064.5623173906035
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 256.4000320148548,
            "y": 264.95140343904495,
            "z": 1103.4415167651978
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 256.4000320148548,
            "y": 277.1282747387886,
            "z": 1103.4415167651978
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 256.4000320148548,
            "y": 277.1282747387886,
            "z": 1103.4415167651978
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 246.9671852053941,
            "y": 264.95140343904495,
            "z": 1101.255707932235
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 246.9671852053941,
            "y": 275.8042305111885,
            "z": 1101.255707932235
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 246.9671852053941,
            "y": 275.8042305111885,
            "z": 1101.255707932235
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 249.76297433985334,
            "y": 264.95140343904495,
            "z": 1094.2469649725795
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 249.76297433985334,
            "y": 274.4909523129463,
            "z": 1094.2469649725795
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 249.76297433985334,
            "y": 274.4909523129463,
            "z": 1094.2469649725795
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 149.99214290610928,
            "y": 264.95140343904495,
            "z": 1036.6448866722508
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 149.99214290610928,
            "y": 277.1282747387886,
            "z": 1036.6448866722508
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 149.99214290610928,
            "y": 277.1282747387886,
            "z": 1036.6448866722508
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 140.55930068232868,
            "y": 264.95140343904495,
            "z": 1034.459070646853
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 140.55930068232868,
            "y": 275.8042305111885,
            "z": 1034.459070646853
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 140.55930068232868,
            "y": 275.8042305111885,
            "z": 1034.459070646853
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 143.35508278131033,
            "y": 264.95140343904495,
            "z": 1027.4503307886837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 143.35508278131027,
            "y": 274.4909523129463,
            "z": 1027.4503307886837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 143.35508278131027,
            "y": 274.4909523129463,
            "z": 1027.4503307886837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 131.02171214692717,
            "y": 264.95140343904495,
            "z": 1014.7810458434712
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 131.02171214692717,
            "y": 277.1282747387886,
            "z": 1014.7810458434712
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 131.02171214692717,
            "y": 277.1282747387886,
            "z": 1014.7810458434712
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 121.58886992314658,
            "y": 264.95140343904495,
            "z": 1012.5952298180737
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 121.58886992314658,
            "y": 275.8042305111885,
            "z": 1012.5952298180737
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 121.58886992314658,
            "y": 275.8042305111885,
            "z": 1012.5952298180737
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 124.38465153216873,
            "y": 264.95140343904495,
            "z": 1005.5864891417144
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 124.38465153216873,
            "y": 274.4909523129463,
            "z": 1005.5864891417144
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 124.38465153216873,
            "y": 274.4909523129463,
            "z": 1005.5864891417144
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 141.19078583658367,
            "y": 264.95140343904495,
            "z": 1002.4472451230697
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 141.19078583658367,
            "y": 277.1282747387886,
            "z": 1002.4472451230697
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 141.19078583658367,
            "y": 277.1282747387886,
            "z": 1002.4472451230697
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 131.75794312284359,
            "y": 264.95140343904495,
            "z": 1000.2614282794823
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 131.75794312284359,
            "y": 275.8042305111885,
            "z": 1000.2614282794823
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 131.75794312284359,
            "y": 275.8042305111885,
            "z": 1000.2614282794823
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 134.55372571178475,
            "y": 264.95140343904495,
            "z": 993.2526892395026
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 134.55372571178475,
            "y": 274.4909523129463,
            "z": 993.2526892395026
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 134.55372571178475,
            "y": 274.4909523129463,
            "z": 993.2526892395026
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 356.11497378889123,
            "y": 264.95140343904495,
            "z": 816.7377720754182
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 356.11497378889123,
            "y": 274.4909523129463,
            "z": 816.7377720754182
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 356.11497378889123,
            "y": 274.4909523129463,
            "z": 816.7377720754182
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 356.0047987195884,
            "y": 264.95140343904495,
            "z": 825.4572541759119
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 356.0047987195884,
            "y": 275.91552525758743,
            "z": 825.4572541759119
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 356.0047987195884,
            "y": 275.91552525758743,
            "z": 825.4572541759119
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 348.0897787151604,
            "y": 264.95140343904495,
            "z": 819.1310582255713
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 348.0897787151603,
            "y": 275.91552525758743,
            "z": 819.1310582255712
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 348.0897787151603,
            "y": 275.91552525758743,
            "z": 819.1310582255712
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 368.5531748059642,
            "y": 264.95140343904495,
            "z": 819.3265907022693
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 368.55317480596426,
            "y": 275.91552525758743,
            "z": 819.3265907022694
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 368.55317480596426,
            "y": 275.91552525758743,
            "z": 819.3265907022694
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 361.8133377938765,
            "y": 264.95140343904495,
            "z": 810.4409563025238
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 361.8133377938765,
            "y": 275.91552525758743,
            "z": 810.4409563025238
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 361.8133377938765,
            "y": 275.91552525758743,
            "z": 810.4409563025238
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 145.65379494842196,
            "y": 266.72425949573517,
            "z": 944.2022069985785
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 145.65379494842196,
            "y": 277.68838131427765,
            "z": 944.2022069985785
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 145.65379494842196,
            "y": 277.68838131427765,
            "z": 944.2022069985785
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 143.07323842705338,
            "y": 264.95140343904495,
            "z": 951.0268552392779
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 143.07323842705338,
            "y": 275.91552525758743,
            "z": 951.0268552392779
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 143.07323842705338,
            "y": 275.91552525758743,
            "z": 951.0268552392779
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 150.3092495025226,
            "y": 266.3366379737854,
            "z": 954.960662042589
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 150.3092495025226,
            "y": 279.09522104263306,
            "z": 954.960662042589
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 150.3092495025226,
            "y": 279.09522104263306,
            "z": 954.960662042589
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 144.8185427597661,
            "y": 266.2786872982979,
            "z": 963.050500763685
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 144.8185427597661,
            "y": 277.677718937397,
            "z": 963.050500763685
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 144.8185427597661,
            "y": 277.677718937397,
            "z": 963.050500763685
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 137.60508827818214,
            "y": 266.5903915166855,
            "z": 954.3815620058921
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 137.60508827818214,
            "y": 278.031974196434,
            "z": 954.3815620058921
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 137.60508827818214,
            "y": 278.031974196434,
            "z": 954.3815620058921
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 134.3834808299744,
            "y": 266.9447636604309,
            "z": 944.362089582371
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 134.3834808299744,
            "y": 275.98213243484497,
            "z": 944.362089582371
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 134.3834808299744,
            "y": 275.98213243484497,
            "z": 944.362089582371
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 138.62327593603743,
            "y": 266.99054861068726,
            "z": 937.8892891082937
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 138.62327593603743,
            "y": 277.95467042922974,
            "z": 937.8892891082937
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 138.62327593603743,
            "y": 277.95467042922974,
            "z": 937.8892891082937
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 178.38481646707993,
            "y": 264.95140343904495,
            "z": 728.2437539149975
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 178.38481646707993,
            "y": 274.4909523129463,
            "z": 728.2437539149975
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 178.38481646707993,
            "y": 274.4909523129463,
            "z": 728.2437539149975
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 177.8825333791661,
            "y": 264.95140343904495,
            "z": 735.6573164266506
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 177.8825333791661,
            "y": 275.8010500073433,
            "z": 735.6573164266506
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 177.8825333791661,
            "y": 275.8010500073433,
            "z": 735.6573164266506
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 168.19792563963722,
            "y": 264.95140343904495,
            "z": 725.772834194509
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 168.19792563963722,
            "y": 274.2133854031563,
            "z": 725.772834194509
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 168.19792563963722,
            "y": 274.2133854031563,
            "z": 725.772834194509
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 242.20027676394164,
            "y": 264.95140343904495,
            "z": 762.1317177436097
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 242.20027676394164,
            "y": 275.8042305111885,
            "z": 762.1317177436097
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 242.20027676394164,
            "y": 275.8042305111885,
            "z": 762.1317177436097
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 246.81896536805056,
            "y": 264.95140343904495,
            "z": 709.0931744886861
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 246.81896536805056,
            "y": 273.74942857027054,
            "z": 709.0931744886861
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 246.81896536805056,
            "y": 273.74942857027054,
            "z": 709.0931744886861
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 87.41526162818576,
            "y": 264.95140343904495,
            "z": 953.5815437770066
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 87.41526162818583,
            "y": 274.4909523129463,
            "z": 953.5815437770065
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 87.41526162818583,
            "y": 274.4909523129463,
            "z": 953.5815437770065
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 80.40556571614951,
            "y": 264.95140343904495,
            "z": 956.0467792229556
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 80.40556571614958,
            "y": 275.8010500073433,
            "z": 956.0467792229555
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 80.40556571614958,
            "y": 275.8010500073433,
            "z": 956.0467792229555
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 85.66390711423153,
            "y": 264.95140343904495,
            "z": 943.2466043804014
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 85.66390711423145,
            "y": 274.2133854031563,
            "z": 943.2466043804013
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 85.66390711423145,
            "y": 274.2133854031563,
            "z": 943.2466043804013
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 24.239848067434224,
            "y": 264.95140343904495,
            "z": 984.2969006297627
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 24.239848067434224,
            "y": 274.4909523129463,
            "z": 984.2969006297627
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 24.239848067434224,
            "y": 274.4909523129463,
            "z": 984.2969006297627
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 17.230152155397946,
            "y": 264.95140343904495,
            "z": 986.7621360757117
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 17.230152155397946,
            "y": 275.8010500073433,
            "z": 986.7621360757117
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 17.230152155397946,
            "y": 275.8010500073433,
            "z": 986.7621360757117
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 22.488489633803937,
            "y": 264.95140343904495,
            "z": 973.9619546876395
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 22.488489633803937,
            "y": 274.2133854031563,
            "z": 973.9619546876395
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 22.488489633803937,
            "y": 274.2133854031563,
            "z": 973.9619546876395
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 215.83108633280676,
            "y": 264.95140343904495,
            "z": 678.9610876954109
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5,
            "z": 0.5000000241576243
        },
        "rotation": {
            "x": 0,
            "y": 0.9653125238032387,
            "z": 0,
            "w": 0.2610971684806666
        }
    },
    {
        "position": {
            "x": 215.83108633280676,
            "y": 275.8042305111885,
            "z": 678.9610876954109
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5000000241576243,
            "z": 0.5
        },
        "rotation": {
            "x": 0.35210767766128614,
            "y": -0.6132048461419526,
            "z": 0.6132048461419527,
            "w": 0.35210767766128614
        }
    },
    {
        "position": {
            "x": 215.83108633280676,
            "y": 275.8042305111885,
            "z": 678.9610876954109
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.500000007043353,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.18462357522158773,
            "y": 0.6825790432274135,
            "z": -0.6825790198637276,
            "w": 0.18462358154098416
        }
    },
    {
        "position": {
            "x": 214.90259251517023,
            "y": 264.95140343904495,
            "z": 671.4726288436339
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5,
            "z": 0.5000000241576243
        },
        "rotation": {
            "x": 0,
            "y": 0.9653125238032387,
            "z": 0,
            "w": 0.2610971684806666
        }
    },
    {
        "position": {
            "x": 214.90259251517017,
            "y": 274.4909523129463,
            "z": 671.4726288436339
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5000000241576243,
            "z": 0.5
        },
        "rotation": {
            "x": 0.35210767766128614,
            "y": -0.6132048461419526,
            "z": 0.6132048461419527,
            "w": 0.35210767766128614
        }
    },
    {
        "position": {
            "x": 214.90259251517017,
            "y": 274.4909523129463,
            "z": 671.4726288436339
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.500000007043353,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.18462357522158773,
            "y": 0.6825790432274135,
            "z": -0.6825790198637276,
            "w": 0.18462358154098416
        }
    },
    {
        "position": {
            "x": 315.7555687843379,
            "y": 264.95140343904495,
            "z": 637.3413369004327
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 315.755568784338,
            "y": 275.8042305111885,
            "z": 637.3413369004327
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 315.755568784338,
            "y": 275.8042305111885,
            "z": 637.3413369004327
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 317.18198026792606,
            "y": 264.95140343904495,
            "z": 629.9315950953409
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 317.1819802679261,
            "y": 274.4909523129463,
            "z": 629.9315950953409
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 317.1819802679261,
            "y": 274.4909523129463,
            "z": 629.9315950953409
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 312.212732453071,
            "y": 260.6850423812866,
            "z": 634.1643024399693
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 312.212732453071,
            "y": 271.5378694534302,
            "z": 634.1643024399693
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 312.212732453071,
            "y": 271.5378694534302,
            "z": 634.1643024399693
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 245.23609341696363,
            "y": 264.95140343904495,
            "z": 616.9730156609769
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 245.23609341696363,
            "y": 273.74942857027054,
            "z": 616.9730156609769
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 245.23609341696363,
            "y": 273.74942857027054,
            "z": 616.9730156609769
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 230.48778865798073,
            "y": 262.937007188797,
            "z": 631.517855215994
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 230.48778865798073,
            "y": 271.7350323200226,
            "z": 631.517855215994
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 230.48778865798073,
            "y": 271.7350323200226,
            "z": 631.517855215994
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 142.31210877754214,
            "y": 264.95140343904495,
            "z": 737.2213123492135
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 142.31210877754214,
            "y": 274.4909523129463,
            "z": 737.2213123492135
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 142.31210877754214,
            "y": 274.4909523129463,
            "z": 737.2213123492135
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 160.2771573844239,
            "y": 264.95140343904495,
            "z": 774.4411985973868
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 160.27715738442396,
            "y": 277.1282747387886,
            "z": 774.4411985973868
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 160.27715738442396,
            "y": 277.1282747387886,
            "z": 774.4411985973868
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 150.84431384772228,
            "y": 264.95140343904495,
            "z": 772.2553878045862
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 150.84431384772228,
            "y": 275.8042305111885,
            "z": 772.2553878045862
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 150.84431384772228,
            "y": 275.8042305111885,
            "z": 772.2553878045862
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 153.64009807304296,
            "y": 264.95140343904495,
            "z": 765.2466477846876
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 153.64009807304296,
            "y": 274.4909523129463,
            "z": 765.2466477846876
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 153.64009807304296,
            "y": 274.4909523129463,
            "z": 765.2466477846876
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 134.78038735662687,
            "y": 264.95140343904495,
            "z": 756.7560597171475
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 134.78038735662682,
            "y": 277.1282747387886,
            "z": 756.7560597171474
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 134.78038735662682,
            "y": 277.1282747387886,
            "z": 756.7560597171474
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 125.34754381992525,
            "y": 264.95140343904495,
            "z": 754.570248924347
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 125.34754381992529,
            "y": 275.8042305111885,
            "z": 754.570248924347
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 125.34754381992529,
            "y": 275.8042305111885,
            "z": 754.570248924347
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 128.14332804524594,
            "y": 264.95140343904495,
            "z": 747.5615089044484
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 128.14332804524594,
            "y": 274.4909523129463,
            "z": 747.5615089044484
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 128.14332804524594,
            "y": 274.4909523129463,
            "z": 747.5615089044484
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 139.82900219322684,
            "y": 264.95140343904495,
            "z": 769.8329465291897
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 139.82900219322684,
            "y": 277.1282747387886,
            "z": 769.8329465291897
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 139.82900219322684,
            "y": 277.1282747387886,
            "z": 769.8329465291897
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 128.4347764342595,
            "y": 264.95140343904495,
            "z": 772.6806157397506
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 128.4347764342595,
            "y": 275.8042305111885,
            "z": 772.6806157397506
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 128.4347764342595,
            "y": 275.8042305111885,
            "z": 772.6806157397506
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 131.23056229595971,
            "y": 264.95140343904495,
            "z": 765.671874739933
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 131.23056229595971,
            "y": 274.4909523129463,
            "z": 765.671874739933
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 131.23056229595971,
            "y": 274.4909523129463,
            "z": 765.671874739933
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 393.7822117324716,
            "y": 264.95140343904495,
            "z": 728.7459813470159
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 393.7822117324716,
            "y": 274.4909523129463,
            "z": 728.7459813470159
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 393.7822117324716,
            "y": 274.4909523129463,
            "z": 728.7459813470159
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 411.7472577135113,
            "y": 264.95140343904495,
            "z": 765.9658780603834
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 411.74725771351126,
            "y": 277.1282747387886,
            "z": 765.9658780603833
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 411.74725771351126,
            "y": 277.1282747387886,
            "z": 765.9658780603833
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 402.31441744956874,
            "y": 264.95140343904495,
            "z": 763.7800653077448
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 402.31441744956874,
            "y": 275.8042305111885,
            "z": 763.7800653077448
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 402.31441744956874,
            "y": 275.8042305111885,
            "z": 763.7800653077448
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 405.1102000385099,
            "y": 264.95140343904495,
            "z": 756.7713262677652
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 405.1102000385099,
            "y": 274.4909523129463,
            "z": 756.7713262677652
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 405.1102000385099,
            "y": 274.4909523129463,
            "z": 756.7713262677652
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 386.25048768571423,
            "y": 264.95140343904495,
            "z": 748.2807391801441
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 386.25048768571423,
            "y": 277.1282747387886,
            "z": 748.2807391801441
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 386.25048768571423,
            "y": 277.1282747387886,
            "z": 748.2807391801441
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 376.8176474217717,
            "y": 264.95140343904495,
            "z": 746.0949264275056
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 376.8176474217717,
            "y": 275.8042305111885,
            "z": 746.0949264275056
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 376.8176474217717,
            "y": 275.8042305111885,
            "z": 746.0949264275056
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 379.6134300107129,
            "y": 264.95140343904495,
            "z": 739.0861873875259
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 379.6134300107129,
            "y": 274.4909523129463,
            "z": 739.0861873875259
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 379.6134300107129,
            "y": 274.4909523129463,
            "z": 739.0861873875259
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 395.4852701699669,
            "y": 264.95140343904495,
            "z": 777.96538676212
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 395.4852701699669,
            "y": 277.1282747387886,
            "z": 777.96538676212
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 395.4852701699669,
            "y": 277.1282747387886,
            "z": 777.96538676212
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 386.0524259863483,
            "y": 264.95140343904495,
            "z": 775.7795674639634
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 386.0524259863483,
            "y": 275.8042305111885,
            "z": 775.7795674639634
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 386.0524259863483,
            "y": 275.8042305111885,
            "z": 775.7795674639634
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 388.84820857528945,
            "y": 264.95140343904495,
            "z": 768.7708284239837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 388.84820857528945,
            "y": 274.4909523129463,
            "z": 768.7708284239837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 388.84820857528945,
            "y": 274.4909523129463,
            "z": 768.7708284239837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 335.7037598651247,
            "y": 264.95140343904495,
            "z": 629.1594940205059
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5,
            "z": 0.5000000241576243
        },
        "rotation": {
            "x": 0,
            "y": 0.9653125238032387,
            "z": 0,
            "w": 0.2610971684806666
        }
    },
    {
        "position": {
            "x": 335.70375986512465,
            "y": 275.8042305111885,
            "z": 629.1594940205059
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5000000241576243,
            "z": 0.5
        },
        "rotation": {
            "x": 0.35210767766128614,
            "y": -0.6132048461419526,
            "z": 0.6132048461419527,
            "w": 0.35210767766128614
        }
    },
    {
        "position": {
            "x": 335.70375986512465,
            "y": 275.8042305111885,
            "z": 629.1594940205059
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.500000007043353,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.18462357522158773,
            "y": 0.6825790432274135,
            "z": -0.6825790198637276,
            "w": 0.18462358154098416
        }
    },
    {
        "position": {
            "x": 334.77526734132215,
            "y": 264.95140343904495,
            "z": 621.6710521794412
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5,
            "z": 0.5000000241576243
        },
        "rotation": {
            "x": 0,
            "y": 0.9653125238032387,
            "z": 0,
            "w": 0.2610971684806666
        }
    },
    {
        "position": {
            "x": 334.77526734132215,
            "y": 274.4909523129463,
            "z": 621.6710521794412
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5000000241576243,
            "z": 0.5
        },
        "rotation": {
            "x": 0.35210767766128614,
            "y": -0.6132048461419526,
            "z": 0.6132048461419527,
            "w": 0.35210767766128614
        }
    },
    {
        "position": {
            "x": 334.77526734132215,
            "y": 274.4909523129463,
            "z": 621.6710521794412
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.500000007043353,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.18462357522158773,
            "y": 0.6825790432274135,
            "z": -0.6825790198637276,
            "w": 0.18462358154098416
        }
    },
    {
        "position": {
            "x": 330.1599105568925,
            "y": 262.7558481693268,
            "z": 627.4929222671134
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5,
            "z": 0.5000000241576243
        },
        "rotation": {
            "x": 0,
            "y": 0.9653125238032387,
            "z": 0,
            "w": 0.2610971684806666
        }
    },
    {
        "position": {
            "x": 330.1599105568925,
            "y": 272.29539704322815,
            "z": 627.4929222671134
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5000000241576243,
            "z": 0.5
        },
        "rotation": {
            "x": 0.35210767766128614,
            "y": -0.6132048461419526,
            "z": 0.6132048461419527,
            "w": 0.35210767766128614
        }
    },
    {
        "position": {
            "x": 330.1599105568925,
            "y": 272.29539704322815,
            "z": 627.4929222671134
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.500000007043353,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.18462357522158773,
            "y": 0.6825790432274135,
            "z": -0.6825790198637276,
            "w": 0.18462358154098416
        }
    },
    {
        "position": {
            "x": 324.60369685495186,
            "y": 262.7558479309082,
            "z": 600.5540804304787
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5,
            "z": 0.5000000241576243
        },
        "rotation": {
            "x": 0,
            "y": 0.9653125238032387,
            "z": 0,
            "w": 0.2610971684806666
        }
    },
    {
        "position": {
            "x": 324.60369685495186,
            "y": 272.29539680480957,
            "z": 600.554080430479
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5000000241576243,
            "z": 0.5
        },
        "rotation": {
            "x": 0.35210767766128614,
            "y": -0.6132048461419526,
            "z": 0.6132048461419527,
            "w": 0.35210767766128614
        }
    },
    {
        "position": {
            "x": 324.60369685495186,
            "y": 272.29539680480957,
            "z": 600.554080430479
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.500000007043353,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.18462357522158773,
            "y": 0.6825790432274135,
            "z": -0.6825790198637276,
            "w": 0.18462358154098416
        }
    },
    {
        "position": {
            "x": 247.57722890908565,
            "y": 262.7558479309082,
            "z": 732.5258144862958
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5,
            "z": 0.5000000241576243
        },
        "rotation": {
            "x": 0,
            "y": 0.9653125238032387,
            "z": 0,
            "w": 0.2610971684806666
        }
    },
    {
        "position": {
            "x": 247.57722890908565,
            "y": 272.29539680480957,
            "z": 732.5258144862959
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.5000000241576243,
            "z": 0.5
        },
        "rotation": {
            "x": 0.35210767766128614,
            "y": -0.6132048461419526,
            "z": 0.6132048461419527,
            "w": 0.35210767766128614
        }
    },
    {
        "position": {
            "x": 247.57722890908565,
            "y": 272.29539680480957,
            "z": 732.5258144862959
        },
        "scale": {
            "x": 0.5000000241576243,
            "y": 0.500000007043353,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.18462357522158773,
            "y": 0.6825790432274135,
            "z": -0.6825790198637276,
            "w": 0.18462358154098416
        }
    },
    {
        "position": {
            "x": 358.1614614214926,
            "y": 264.95140343904495,
            "z": 724.0139349227402
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 358.1614614214927,
            "y": 277.1282747387886,
            "z": 724.0139349227403
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 358.1614614214927,
            "y": 277.1282747387886,
            "z": 724.0139349227403
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 351.5244037464912,
            "y": 264.95140343904495,
            "z": 714.8193831301219
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 351.5244037464912,
            "y": 274.4909523129463,
            "z": 714.8193831301219
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 351.5244037464912,
            "y": 274.4909523129463,
            "z": 714.8193831301219
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 343.72191042932826,
            "y": 264.95140343904495,
            "z": 510.18946601785626
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 343.72191042932826,
            "y": 275.8042305111885,
            "z": 510.18946601785626
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 343.72191042932826,
            "y": 275.8042305111885,
            "z": 510.18946601785626
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 345.1483088218803,
            "y": 264.95140343904495,
            "z": 502.77973205211663
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 345.1483088218803,
            "y": 274.4909523129463,
            "z": 502.77973205211663
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 345.1483088218803,
            "y": 274.4909523129463,
            "z": 502.77973205211663
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 340.17906625870927,
            "y": 260.6850423812866,
            "z": 507.01241846635685
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 340.1790662587093,
            "y": 271.5378694534302,
            "z": 507.0124184663569
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 340.1790662587093,
            "y": 271.5378694534302,
            "z": 507.0124184663569
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 254.91317579435622,
            "y": 264.95140343904495,
            "z": 545.9291243305905
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 254.9131757943562,
            "y": 275.8042305111885,
            "z": 545.9291243305905
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 254.9131757943562,
            "y": 275.8042305111885,
            "z": 545.9291243305905
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 256.33958727794436,
            "y": 264.95140343904495,
            "z": 538.5193825254988
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 256.3395872779444,
            "y": 274.4909523129463,
            "z": 538.5193825254988
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 256.3395872779444,
            "y": 274.4909523129463,
            "z": 538.5193825254988
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 251.37033162373723,
            "y": 260.6850423812866,
            "z": 542.7520767790911
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 251.37033162373726,
            "y": 271.5378694534302,
            "z": 542.7520767790911
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 251.37033162373726,
            "y": 271.5378694534302,
            "z": 542.7520767790911
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 200.8581130560736,
            "y": 264.95140343904495,
            "z": 561.9121173629001
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 200.85811305607362,
            "y": 275.8042305111885,
            "z": 561.9121173629001
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 200.85811305607362,
            "y": 275.8042305111885,
            "z": 561.9121173629001
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 202.2845114486256,
            "y": 264.95140343904495,
            "z": 554.5023833971604
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 202.2845114486256,
            "y": 274.4909523129463,
            "z": 554.5023833971604
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 202.2845114486256,
            "y": 274.4909523129463,
            "z": 554.5023833971604
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 197.3152688854546,
            "y": 260.6850423812866,
            "z": 558.7350698114006
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 197.31526888545463,
            "y": 271.5378694534302,
            "z": 558.7350698114006
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 197.31526888545463,
            "y": 271.5378694534302,
            "z": 558.7350698114006
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 363.9810779611606,
            "y": 264.95140343904495,
            "z": 450.1504574371678
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 363.9810779611606,
            "y": 275.8042305111885,
            "z": 450.1504574371678
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 363.9810779611606,
            "y": 275.8042305111885,
            "z": 450.1504574371678
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 365.4074763537127,
            "y": 264.95140343904495,
            "z": 442.74072347142817
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 365.40747635371264,
            "y": 274.4909523129463,
            "z": 442.7407234714281
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 365.40747635371264,
            "y": 274.4909523129463,
            "z": 442.7407234714281
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 360.43823379054163,
            "y": 260.6850423812866,
            "z": 446.9734098856684
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 360.43823379054163,
            "y": 271.5378694534302,
            "z": 446.9734098856684
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 360.43823379054163,
            "y": 271.5378694534302,
            "z": 446.9734098856684
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 387.2081534308953,
            "y": 264.95140343904495,
            "z": 562.7738855679697
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 387.2081534308953,
            "y": 275.8042305111885,
            "z": 562.7738855679697
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 387.2081534308953,
            "y": 275.8042305111885,
            "z": 562.7738855679697
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 388.63457800551964,
            "y": 264.95140343904495,
            "z": 555.3641359235259
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 388.63457800551964,
            "y": 274.4909523129463,
            "z": 555.3641359235259
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 388.63457800551964,
            "y": 274.4909523129463,
            "z": 555.3641359235259
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 383.66532493898046,
            "y": 260.6850423812866,
            "z": 559.5968641985426
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 383.6653249389805,
            "y": 271.5378694534302,
            "z": 559.5968641985426
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 383.6653249389805,
            "y": 271.5378694534302,
            "z": 559.5968641985426
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 248.39700241381192,
            "y": 264.95140343904495,
            "z": 477.0834676543608
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 248.39700241381192,
            "y": 275.8042305111885,
            "z": 477.0834676543608
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 248.39700241381192,
            "y": 275.8042305111885,
            "z": 477.0834676543608
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 249.82340080636394,
            "y": 264.95140343904495,
            "z": 469.6737336886211
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 249.8234008063639,
            "y": 274.4909523129463,
            "z": 469.67373368862104
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 249.8234008063639,
            "y": 274.4909523129463,
            "z": 469.67373368862104
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 244.8541582431929,
            "y": 260.6850423812866,
            "z": 473.9064201028614
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 244.8541582431929,
            "y": 271.5378694534302,
            "z": 473.9064201028614
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 244.8541582431929,
            "y": 271.5378694534302,
            "z": 473.9064201028614
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 244.1927268825323,
            "y": 260.6850423812866,
            "z": 508.74634020751455
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 244.19272688253233,
            "y": 271.5378694534302,
            "z": 508.7463402075146
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 244.19272688253233,
            "y": 271.5378694534302,
            "z": 508.7463402075146
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 265.4579363652649,
            "y": 260.6850423812866,
            "z": 455.93804248605693
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 265.45793636526486,
            "y": 271.5378694534302,
            "z": 455.9380424860569
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 265.45793636526486,
            "y": 271.5378694534302,
            "z": 455.9380424860569
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 276.0984150815377,
            "y": 260.6850423812866,
            "z": 436.95830770601253
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 276.0984150815377,
            "y": 271.5378694534302,
            "z": 436.95830770601253
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 276.0984150815377,
            "y": 271.5378694534302,
            "z": 436.95830770601253
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 366.0960157790654,
            "y": 260.6850423812866,
            "z": 498.8273626914132
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 366.09601577906534,
            "y": 271.5378694534302,
            "z": 498.82736269141316
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 366.09601577906534,
            "y": 271.5378694534302,
            "z": 498.82736269141316
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 364.7117470850973,
            "y": 260.6850423812866,
            "z": 561.7172939355935
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 364.7117470850972,
            "y": 271.5378694534302,
            "z": 561.7172939355935
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 364.7117470850972,
            "y": 271.5378694534302,
            "z": 561.7172939355935
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 371.21883083492264,
            "y": 260.6850423812866,
            "z": 583.1677150313153
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 371.21883083492264,
            "y": 271.5378694534302,
            "z": 583.1677150313153
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 371.21883083492264,
            "y": 271.5378694534302,
            "z": 583.1677150313153
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 240.62505575464766,
            "y": 260.6850423812866,
            "z": 537.0267245336846
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 240.62505575464766,
            "y": 271.5378694534302,
            "z": 537.0267245336846
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 240.62505575464766,
            "y": 271.5378694534302,
            "z": 537.0267245336846
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 229.99909672775482,
            "y": 260.6850423812866,
            "z": 620.3741015096643
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 229.99909672775482,
            "y": 271.5378694534302,
            "z": 620.3741015096643
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 229.99909672775482,
            "y": 271.5378694534302,
            "z": 620.3741015096643
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 332.1317105027731,
            "y": 260.6850423812866,
            "z": 541.2019988385491
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 332.1317105027731,
            "y": 271.5378694534302,
            "z": 541.2019988385491
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 332.1317105027731,
            "y": 271.5378694534302,
            "z": 541.2019988385491
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 354.05150611909414,
            "y": 260.6850423812866,
            "z": 593.117666473541
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 354.0515061190942,
            "y": 271.5378694534302,
            "z": 593.117666473541
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 354.0515061190942,
            "y": 271.5378694534302,
            "z": 593.117666473541
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 257.7679339061144,
            "y": 260.6850423812866,
            "z": 509.92569830857246
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 257.7679339061144,
            "y": 271.5378694534302,
            "z": 509.92569830857246
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 257.7679339061144,
            "y": 271.5378694534302,
            "z": 509.92569830857246
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 323.12024294660273,
            "y": 264.95140343904495,
            "z": 546.359153198462
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 323.12024294660273,
            "y": 274.4909523129463,
            "z": 546.359153198462
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 323.12024294660273,
            "y": 274.4909523129463,
            "z": 546.359153198462
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 279.8102916322456,
            "y": 264.95140343904495,
            "z": 471.0900763805421
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 279.81029163224565,
            "y": 274.4909523129463,
            "z": 471.09007638054214
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 279.81029163224565,
            "y": 274.4909523129463,
            "z": 471.09007638054214
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 213.9552877153896,
            "y": 264.95140343904495,
            "z": 572.4717141970332
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 213.9552877153896,
            "y": 274.4909523129463,
            "z": 572.4717141970332
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 213.9552877153896,
            "y": 274.4909523129463,
            "z": 572.4717141970332
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 257.69549837833097,
            "y": 264.95140343904495,
            "z": 578.0452949160867
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 257.6954983783309,
            "y": 274.4909523129463,
            "z": 578.0452949160867
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 257.6954983783309,
            "y": 274.4909523129463,
            "z": 578.0452949160867
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 326.53175125377317,
            "y": 264.95140343904495,
            "z": 513.5001393752786
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 326.5317512537731,
            "y": 274.4909523129463,
            "z": 513.5001393752786
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 326.5317512537731,
            "y": 274.4909523129463,
            "z": 513.5001393752786
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 349.6789491193172,
            "y": 264.95140343904495,
            "z": 475.23049308857867
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 349.67894911931717,
            "y": 274.4909523129463,
            "z": 475.2304930885786
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 349.67894911931717,
            "y": 274.4909523129463,
            "z": 475.2304930885786
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 324.3551388369122,
            "y": 264.95140343904495,
            "z": 485.71338514566094
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 324.3551388369122,
            "y": 274.4909523129463,
            "z": 485.71338514566094
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 324.3551388369122,
            "y": 274.4909523129463,
            "z": 485.71338514566094
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    },
    {
        "position": {
            "x": 271.3779015184899,
            "y": 264.95140343904495,
            "z": 538.4650231323992
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5,
            "z": 0.5000000268250685
        },
        "rotation": {
            "x": 0,
            "y": 0.9127104247914438,
            "z": 0,
            "w": 0.4086070000342899
        }
    },
    {
        "position": {
            "x": 271.3779015184899,
            "y": 274.4909523129463,
            "z": 538.4650231323992
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000268250685,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2520517123785769,
            "y": -0.6606587124128669,
            "z": 0.6606587124128669,
            "w": 0.2520517123785769
        }
    },
    {
        "position": {
            "x": 271.3779015184899,
            "y": 274.4909523129463,
            "z": 538.4650231323992
        },
        "scale": {
            "x": 0.5000000268250685,
            "y": 0.5000000097107969,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.28892877561973274,
            "y": 0.6453837416749564,
            "z": -0.6453837195844119,
            "w": 0.2889287855093444
        }
    }
]
