import queryString from 'query-string';
import { getGPUTier } from "detect-gpu";
//import { isMobile } from "./device";

var queryParams = queryString.parse(window.location.href);

function addBooleanParameter(params, name) {
    if (queryParams[name] != undefined) {
        params[name] = queryParams[name] == 1;
    }
}
function addNumberParameter(params, name) {
    if (queryParams[name] != undefined) {
        params[name] = queryParams[name];
    }
}

const params = {
    lowquality: false,
    postprocessing: true,
    antialias: false,
    pixelratio: true,
    fog: true,
    envmaps: true,
    particles: true,
    blobanim: false,
    debug: false,
    reversepp: false,
    alltextures: true,
    vrbutton: false,
    blobcount: 6, // 3 or 6
    textures: 3, // 3=high 2k, 2=medium 2k+1k, 1=low 1k / 0 = very low 256
};

const tier = getGPUTier({});
const code = tier.tier;
const fps = tier.gpu;

switch (code) {
    case "GPU_MOBILE_TIER_3":
        params.postprocessing = true;
        params.antialias = false;
        params.pixelratio = true;
        params.fog = true;
        params.envmaps = true;
        params.blobanim = true;
        params.alltextures = true;
        params.blobcount = 6;
        params.textures = 1;
        break;
    case "GPU_MOBILE_TIER_2":
    case "GPU_MOBILE_TIER_1":
    case "GPU_MOBILE_TIER_0":
        params.postprocessing = true;
        params.antialias = false;
        params.pixelratio = false;
        params.fog = true;
        params.envmaps = true;
        params.blobanim = true;
        params.alltextures = false;
        params.blobcount = 6;
        params.textures = 1;
        break;
    case "GPU_DESKTOP_TIER_3":
        params.postprocessing = true;
        params.antialias = false;
        params.pixelratio = true;
        params.fog = true;
        params.envmaps = true;
        params.blobanim = true;
        params.alltextures = true;
        params.blobcount = 6;
        params.textures = 3;
        break;
    case "GPU_DESKTOP_TIER_2":
    case "GPU_DESKTOP_TIER_1":
    case "GPU_DESKTOP_TIER_0":
        params.postprocessing = true;
        params.antialias = false;
        params.pixelratio = true;
        params.fog = true;
        params.envmaps = true;
        params.blobanim = true;
        params.alltextures = true;
        params.blobcount = 6;
        params.textures = 2; ""
        break;
    default:
        // same a mobile low
        params.postprocessing = true;
        params.antialias = false;
        params.pixelratio = false;
        params.fog = true;
        params.envmaps = true;
        params.blobanim = true;
        params.alltextures = false;
        params.blobcount = 6;
        break;
}

addBooleanParameter(params, "vrbutton");
addBooleanParameter(params, "lowquality");
addBooleanParameter(params, "pixelratio");
addBooleanParameter(params, "postprocessing");
addBooleanParameter(params, "antialias");
addBooleanParameter(params, "envmaps");
addBooleanParameter(params, "debug");
addBooleanParameter(params, "fog");
addBooleanParameter(params, "reversepp");
addBooleanParameter(params, "alltextures");
addBooleanParameter(params, "blobanim");
addNumberParameter(params, "textures");
addNumberParameter(params, "blobcount");
if (params.lowquality) {
    // params.postprocessing = false;
    // params.antialias = false;
    // params.pixelratio = false;
    // params.fog = false;
    // //params.envmaps = false;
    // params.textures = 1;
    // params.blobcount = 3;
    //params.particles = false;
    //params.blobanim = false;
    //params.alltextures = false;
}

function getPerformanceParameters() {
    return params;
}

function getBlobTextures(texture) {
    var baseTexture, heightTexture, normalTexture, roughnessTexture;
    if (params.textures == 3) {
        baseTexture = texture.baseTextureHigh;
        heightTexture = texture.heightTextureHigh;
        normalTexture = texture.normalTextureHigh;
        roughnessTexture = texture.roughnessTextureHigh;
    } else if (params.textures == 2) {
        baseTexture = texture.baseTextureHigh;
        heightTexture = texture.heightTextureMid;
        normalTexture = texture.normalTextureMid;
        roughnessTexture = texture.roughnessTextureMid;
    } else if (params.textures == 1) {
        baseTexture = texture.baseTextureLow;
        heightTexture = texture.heightTextureLow;
        normalTexture = texture.normalTextureLow;
        roughnessTexture = texture.roughnessTextureLow;
    } else {
        baseTexture = texture.baseTextureLow;
        heightTexture = texture.heightTextureLow;
        normalTexture = texture.normalTextureLow;
        roughnessTexture = texture.roughnessTextureLow;
    }

    return [baseTexture, heightTexture, normalTexture, roughnessTexture];
}

export { getPerformanceParameters, getBlobTextures };
