export const palms = [
    {
        "position": {
            "x": -1511.6649315374823,
            "y": 135.5282081946688,
            "z": -5428.871119087851
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1511.6649316776043,
            "y": 145.20179504006674,
            "z": -5428.87111912168
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1511.6649316776043,
            "y": 145.20179504006674,
            "z": -5428.87111912168
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1498.0248947220084,
            "y": 135.52820837165422,
            "z": -5434.759072626113
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1498.0248948813585,
            "y": 146.5292545523793,
            "z": -5434.759072664586
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1498.0248948813585,
            "y": 146.5292545523793,
            "z": -5434.759072664586
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1444.0295654714569,
            "y": 135.52820930946888,
            "z": -5390.238104890618
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1444.0295656081848,
            "y": 144.96751366127552,
            "z": -5390.23810492363
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1444.0295656081848,
            "y": 144.96751366127552,
            "z": -5390.23810492363
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1454.9074153919505,
            "y": 135.5282091296164,
            "z": -5396.61112630391
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1454.9074155506905,
            "y": 146.48715345029512,
            "z": -5396.6111263422345
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1454.9074155506905,
            "y": 146.48715345029512,
            "z": -5396.6111263422345
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1728.862288814298,
            "y": 135.52820613895858,
            "z": -5117.068122488745
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1728.8622889972487,
            "y": 148.15856864963973,
            "z": -5117.0681225329145
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1728.8622889972487,
            "y": 148.15856864963973,
            "z": -5117.0681225329145
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1396.5863712106561,
            "y": 135.52821014169552,
            "z": -5348.771233006922
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1396.5863713936064,
            "y": 148.15857265237668,
            "z": -5348.771233051092
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1396.5863713936064,
            "y": 148.15857265237668,
            "z": -5348.771233051092
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1767.3271196190901,
            "y": 135.52820472066253,
            "z": -5363.313118747113
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1767.3271198526197,
            "y": 151.65038184468597,
            "z": -5363.313118803495
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1767.3271198526197,
            "y": 151.65038184468597,
            "z": -5363.313118803495
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1778.1434980002623,
            "y": 135.52820458805317,
            "z": -5356.4313647722465
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1778.1434982083995,
            "y": 149.8973471204018,
            "z": -5356.431364822497
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1778.1434982083995,
            "y": 149.8973471204018,
            "z": -5356.431364822497
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1782.1696480799428,
            "y": 135.52820449775922,
            "z": -5365.574814619638
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1782.1696482628934,
            "y": 148.15856700844037,
            "z": -5365.574814663809
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1782.1696482628934,
            "y": 148.15856700844037,
            "z": -5365.574814663809
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1730.858690435699,
            "y": 135.52820500957034,
            "z": -5431.752738671587
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1730.858690683144,
            "y": 152.61108502971194,
            "z": -5431.752738731329
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1730.858690683144,
            "y": 152.61108502971194,
            "z": -5431.752738731329
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1713.0312603363732,
            "y": 135.52820524793003,
            "z": -5437.434830773929
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1713.0312605394192,
            "y": 149.5458854160819,
            "z": -5437.434830822951
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1713.0312605394192,
            "y": 149.5458854160819,
            "z": -5437.434830822951
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1716.3227543941493,
            "y": 135.52820516529403,
            "z": -5447.431439428672
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1716.322754542864,
            "y": 145.7950604051622,
            "z": -5447.431439464578
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1716.322754542864,
            "y": 145.7950604051622,
            "z": -5447.431439464578
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1750.4931361146132,
            "y": 135.5282048554178,
            "z": -5394.506464539491
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1750.4931362633288,
            "y": 145.79506009528598,
            "z": -5394.506464575397
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1750.4931362633288,
            "y": 145.79506009528598,
            "z": -5394.506464575397
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1694.9222558346494,
            "y": 137.924646410459,
            "z": -5154.258214278521
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1694.922255957911,
            "y": 146.43429282616944,
            "z": -5154.258214308282
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1694.922255957911,
            "y": 146.43429282616944,
            "z": -5154.258214308282
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1682.7524027520674,
            "y": 137.88700923278898,
            "z": -5154.546146695999
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1682.752402890876,
            "y": 147.46994361236662,
            "z": -5154.546146729512
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1682.752402890876,
            "y": 147.46994361236662,
            "z": -5154.546146729512
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1685.6943822766145,
            "y": 135.52820661328744,
            "z": -5160.235328739033
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1685.6943824514362,
            "y": 147.59739377759408,
            "z": -5160.235328781242
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1685.6943824514362,
            "y": 147.59739377759408,
            "z": -5160.235328781242
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1847.157500022879,
            "y": 135.5282033869117,
            "z": -5414.043721460976
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1847.1575001977017,
            "y": 147.59739055121835,
            "z": -5414.043721503183
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1847.1575001977017,
            "y": 147.59739055121835,
            "z": -5414.043721503183
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1833.1995639157713,
            "y": 135.52820356753645,
            "z": -5420.207743525847
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1833.1995640537161,
            "y": 145.05149706271467,
            "z": -5420.207743559152
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1833.1995640537161,
            "y": 145.05149706271467,
            "z": -5420.207743559152
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1840.0645549055332,
            "y": 135.52820345378868,
            "z": -5424.299294410829
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1840.0645550884842,
            "y": 148.15856596446983,
            "z": -5424.299294455
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1840.0645550884842,
            "y": 148.15856596446983,
            "z": -5424.299294455
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1659.5482547459233,
            "y": 135.5282070834898,
            "z": -5134.07741209701
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1659.5482548891737,
            "y": 145.41778113439312,
            "z": -5134.077412131596
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1659.5482548891737,
            "y": 145.41778113439312,
            "z": -5134.077412131596
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1668.0354929420353,
            "y": 135.528206986261,
            "z": -5126.725850095851
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1668.0354931249858,
            "y": 148.15856949694216,
            "z": -5126.72585014002
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1668.0354931249858,
            "y": 148.15856949694216,
            "z": -5126.72585014002
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1423.788200600175,
            "y": 135.52821007846063,
            "z": -5254.182136272325
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1423.788200783125,
            "y": 148.15857258914178,
            "z": -5254.1821363164945
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1423.788200783125,
            "y": 148.15857258914178,
            "z": -5254.1821363164945
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1481.1274095234498,
            "y": 135.5282088061087,
            "z": -5380.51506772137
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1481.127409647002,
            "y": 144.05791385371612,
            "z": -5380.5150677512
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1481.127409647002,
            "y": 144.05791385371612,
            "z": -5380.5150677512
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1482.837452039279,
            "y": 135.52820908406449,
            "z": -5293.949230921208
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1482.8374521980184,
            "y": 146.4871534047432,
            "z": -5293.949230959534
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1482.8374521980184,
            "y": 146.4871534047432,
            "z": -5293.949230959534
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1451.035701615824,
            "y": 135.5282097881929,
            "z": -5224.324961897086
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1451.0357017745641,
            "y": 146.4871541088716,
            "z": -5224.324961935412
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1451.0357017745641,
            "y": 146.4871541088716,
            "z": -5224.324961935412
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1586.84387228765,
            "y": 135.52820750537026,
            "z": -5314.584112924383
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1586.8438724463897,
            "y": 146.48715182604897,
            "z": -5314.584112962708
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1586.8438724463897,
            "y": 146.48715182604897,
            "z": -5314.584112962708
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1649.9091874382882,
            "y": 135.52820665288274,
            "z": -5297.1370262871515
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1649.9091875970278,
            "y": 146.48715097356146,
            "z": -5297.137026325478
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1649.9091875970278,
            "y": 146.48715097356146,
            "z": -5297.137026325478
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1520.8880854794133,
            "y": 135.52820885625096,
            "z": -5201.485843389329
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1520.8880856400158,
            "y": 146.61568844762058,
            "z": -5201.485843428103
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1520.8880856400158,
            "y": 146.61568844762058,
            "z": -5201.485843428103
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1685.2595419604286,
            "y": 135.52820599098368,
            "z": -5339.987104719533
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1685.259542097835,
            "y": 145.01432335395975,
            "z": -5339.987104752708
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1685.259542097835,
            "y": 145.01432335395975,
            "z": -5339.987104752708
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1688.613501527931,
            "y": 135.52820607606225,
            "z": -5301.7662156844835
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1688.6135016697917,
            "y": 145.32185002747826,
            "z": -5301.766215718733
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1688.6135016697917,
            "y": 145.32185002747826,
            "z": -5301.766215718733
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1626.4340240287077,
            "y": 138.28732785005835,
            "z": -5389.341040793465
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1626.4340241880577,
            "y": 149.28837403078344,
            "z": -5389.341040831938
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1626.4340241880577,
            "y": 149.28837403078344,
            "z": -5389.341040831938
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1486.9698301749577,
            "y": 135.52820922454845,
            "z": -5236.660661242229
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1486.9698303579082,
            "y": 148.1585717352296,
            "z": -5236.660661286399
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1486.9698303579082,
            "y": 148.1585717352296,
            "z": -5236.660661286399
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1534.450919062383,
            "y": 135.52820825881994,
            "z": -5316.1460399737
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1534.4509192453334,
            "y": 148.1585707695011,
            "z": -5316.146040017869
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1534.4509192453334,
            "y": 148.1585707695011,
            "z": -5316.146040017869
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1548.9553563712234,
            "y": 135.5282082096742,
            "z": -5270.121350411604
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1548.9553565299636,
            "y": 146.48715253035292,
            "z": -5270.121350449929
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1548.9553565299636,
            "y": 146.48715253035292,
            "z": -5270.121350449929
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1575.1898114280646,
            "y": 135.52820736367778,
            "z": -5403.373467868952
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1575.1898115868046,
            "y": 146.4871516843565,
            "z": -5403.373467907278
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1575.1898115868046,
            "y": 146.4871516843565,
            "z": -5403.373467907278
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1604.4858160137258,
            "y": 135.52820772144253,
            "z": -5179.723383059143
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1604.4858161724658,
            "y": 146.48715204212124,
            "z": -5179.723383097467
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1604.4858161724658,
            "y": 146.48715204212124,
            "z": -5179.723383097467
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1551.0975427699525,
            "y": 135.5282086043805,
            "z": -5148.380211116054
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1551.0975429529035,
            "y": 148.15857111506165,
            "z": -5148.380211160222
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1551.0975429529035,
            "y": 148.15857111506165,
            "z": -5148.380211160222
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1659.8502125507139,
            "y": 139.32431642660694,
            "z": -5228.119342757794
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1659.8502127137838,
            "y": 150.5821794713457,
            "z": -5228.119342797163
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1659.8502127137838,
            "y": 150.5821794713457,
            "z": -5228.119342797163
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1476.3122626382087,
            "y": 135.52820904043716,
            "z": -5333.452344779275
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1476.3122628211593,
            "y": 148.1585715511183,
            "z": -5333.452344823445
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1476.3122628211593,
            "y": 148.1585715511183,
            "z": -5333.452344823445
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1607.9330488017574,
            "y": 135.5282075544508,
            "z": -5213.196821609458
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1607.933048984708,
            "y": 148.15857006513195,
            "z": -5213.196821653629
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1607.933048984708,
            "y": 148.15857006513195,
            "z": -5213.196821653629
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1624.479797317834,
            "y": 135.5282067846343,
            "z": -5364.791926130895
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1624.4797975007848,
            "y": 148.15856929531546,
            "z": -5364.791926175065
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1624.4797975007848,
            "y": 148.15856929531546,
            "z": -5364.791926175065
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1718.4581561349387,
            "y": 135.52820546600412,
            "z": -5352.597042513882
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1718.458156281299,
            "y": 145.63249547454905,
            "z": -5352.59704254922
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1718.458156281299,
            "y": 145.63249547454905,
            "z": -5352.59704254922
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1702.0964606642372,
            "y": 137.34117050468774,
            "z": -5248.997848602581
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1702.0964608471886,
            "y": 149.9715330153689,
            "z": -5248.99784864675
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1702.0964608471886,
            "y": 149.9715330153689,
            "z": -5248.99784864675
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1747.5739734593144,
            "y": 135.52820447916915,
            "z": -5514.18783625281
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1747.573973692845,
            "y": 151.65038160319259,
            "z": -5514.187836309191
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1747.573973692845,
            "y": 151.65038160319259,
            "z": -5514.187836309191
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1758.3903509452398,
            "y": 135.52820434655982,
            "z": -5507.306085986103
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1758.390351153377,
            "y": 149.89734687890845,
            "z": -5507.306086036355
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1758.390351153377,
            "y": 149.89734687890845,
            "z": -5507.306086036355
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1762.4165037106611,
            "y": 135.52820425626587,
            "z": -5516.449524709015
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1762.4165038936121,
            "y": 148.15856676694702,
            "z": -5516.449524753185
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1762.4165038936121,
            "y": 148.15856676694702,
            "z": -5516.449524753185
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1763.8149390753147,
            "y": 135.5282042077241,
            "z": -5524.537879102936
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1763.8149393088445,
            "y": 151.65038133174752,
            "z": -5524.537879159319
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1763.8149393088445,
            "y": 151.65038133174752,
            "z": -5524.537879159319
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1774.6313349748668,
            "y": 135.52820407511453,
            "z": -5517.656117584578
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1774.6313351830036,
            "y": 149.89734660746316,
            "z": -5517.6561176348305
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1774.6313351830036,
            "y": 149.89734660746316,
            "z": -5517.6561176348305
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1778.6574693266612,
            "y": 135.5282039848208,
            "z": -5526.799567559142
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1778.6574695096124,
            "y": 148.15856649550196,
            "z": -5526.799567603312
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1778.6574695096124,
            "y": 148.15856649550196,
            "z": -5526.799567603312
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1761.784534014599,
            "y": 135.52820417450434,
            "z": -5542.447265249442
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1761.7845342481287,
            "y": 151.65038129852778,
            "z": -5542.447265305826
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1761.7845342481287,
            "y": 151.65038129852778,
            "z": -5542.447265305826
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1772.600926333164,
            "y": 135.52820404189478,
            "z": -5535.565518563723
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1772.6009265413006,
            "y": 149.89734657424341,
            "z": -5535.565518613975
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1772.6009265413006,
            "y": 149.89734657424341,
            "z": -5535.565518613975
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1776.6270642659458,
            "y": 135.52820395160106,
            "z": -5544.708953705648
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1776.627064448897,
            "y": 148.1585664622822,
            "z": -5544.708953749818
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1776.627064448897,
            "y": 148.1585664622822,
            "z": -5544.708953749818
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1559.9353869703687,
            "y": 135.52820748149213,
            "z": -5432.86853886879
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1559.935387203899,
            "y": 151.65038460551557,
            "z": -5432.8685389251705
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1559.935387203899,
            "y": 151.65038460551557,
            "z": -5432.8685389251705
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1570.7517704820536,
            "y": 135.5282073488827,
            "z": -5425.986790056859
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1570.7517706901906,
            "y": 149.89734988123132,
            "z": -5425.98679010711
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1570.7517706901906,
            "y": 149.89734988123132,
            "z": -5425.98679010711
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1574.7779145677675,
            "y": 135.52820725858882,
            "z": -5435.130242381447
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1574.777914750718,
            "y": 148.15856976926997,
            "z": -5435.130242425617
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1574.777914750718,
            "y": 148.15856976926997,
            "z": -5435.130242425617
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1527.7351602533522,
            "y": 135.52820789955877,
            "z": -5446.695470446499
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1527.7351604868823,
            "y": 151.6503850235822,
            "z": -5446.695470502879
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1527.7351604868823,
            "y": 151.6503850235822,
            "z": -5446.695470502879
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1538.551545155597,
            "y": 135.52820776694932,
            "z": -5439.813721970286
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1538.551545363734,
            "y": 149.89735029929795,
            "z": -5439.813722020534
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1538.551545363734,
            "y": 149.89735029929795,
            "z": -5439.813722020534
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1542.5776923587787,
            "y": 135.52820767665546,
            "z": -5448.957159350328
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1542.5776925417294,
            "y": 148.1585701873366,
            "z": -5448.9571593944975
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1542.5776925417294,
            "y": 148.1585701873366,
            "z": -5448.9571593944975
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1523.9221155497914,
            "y": 135.52820803040552,
            "z": -5425.073063658305
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1523.922115783321,
            "y": 151.65038515442896,
            "z": -5425.073063714688
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1523.922115783321,
            "y": 151.65038515442896,
            "z": -5425.073063714688
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1538.7646422201506,
            "y": 135.52820780750224,
            "z": -5427.3347669471505
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1538.764642403101,
            "y": 148.1585703181834,
            "z": -5427.33476699132
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1538.764642403101,
            "y": 148.1585703181834,
            "z": -5427.33476699132
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1646.0941384537728,
            "y": 135.52820710180188,
            "z": -5184.568645438659
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1646.0941386873021,
            "y": 151.65038422582532,
            "z": -5184.568645495041
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1646.0941386873021,
            "y": 151.65038422582532,
            "z": -5184.568645495041
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1686.013518152562,
            "y": 135.52820637697516,
            "z": -5226.488036474521
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1686.0135183860918,
            "y": 151.6503835009986,
            "z": -5226.488036530903
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1686.0135183860918,
            "y": 151.6503835009986,
            "z": -5226.488036530903
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1522.338046341474,
            "y": 135.52820903371554,
            "z": -5144.73320611174
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1522.3380465750035,
            "y": 151.65038615773898,
            "z": -5144.733206168122
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1522.3380465750035,
            "y": 151.65038615773898,
            "z": -5144.733206168122
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1507.2534248837296,
            "y": 135.5282092693712,
            "z": -5139.827673751353
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1507.253425091866,
            "y": 149.89735180171982,
            "z": -5139.827673801606
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1507.253425091866,
            "y": 149.89735180171982,
            "z": -5139.827673801606
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1511.2795758586572,
            "y": 135.52820917907724,
            "z": -5148.971119890586
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1511.2795760416077,
            "y": 148.1585716897584,
            "z": -5148.971119934756
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1511.2795760416077,
            "y": 148.1585716897584,
            "z": -5148.971119934756
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1517.0251393659612,
            "y": 135.5282091583901,
            "z": -5131.088277188518
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1517.025139548912,
            "y": 148.15857166907125,
            "z": -5131.088277232688
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1517.025139548912,
            "y": 148.15857166907125,
            "z": -5131.088277232688
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1641.498717891711,
            "y": 135.52820618732267,
            "z": -5465.102693844981
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1641.4987180746612,
            "y": 148.15856869800382,
            "z": -5465.102693889153
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1641.4987180746612,
            "y": 148.15856869800382,
            "z": -5465.102693889153
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1633.3649955314322,
            "y": 135.52820633379451,
            "z": -5456.908693924997
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1633.364995741703,
            "y": 150.0447029921197,
            "z": -5456.908693975764
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1633.364995741703,
            "y": 150.0447029921197,
            "z": -5456.908693975764
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1646.6839174458703,
            "y": 135.52820614648851,
            "z": -5455.302040122412
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1646.6839176561416,
            "y": 150.0447028048137,
            "z": -5455.302040173179
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1646.6839176561416,
            "y": 150.0447028048137,
            "z": -5455.302040173179
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1627.5133123825242,
            "y": 135.52820635721582,
            "z": -5474.449241587615
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1627.5133125927955,
            "y": 150.04470301554102,
            "z": -5474.449241638382
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1627.5133125927955,
            "y": 150.04470301554102,
            "z": -5474.449241638382
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1642.1594040840869,
            "y": 135.52820613849974,
            "z": -5476.327239569135
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1642.1594042943589,
            "y": 150.04470279682494,
            "z": -5476.3272396199
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1642.1594042943589,
            "y": 150.04470279682494,
            "z": -5476.3272396199
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1798.7665066674608,
            "y": 135.5282041084009,
            "z": -5408.168574771913
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1798.7665068755982,
            "y": 149.89734664074953,
            "z": -5408.1685748221635
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1798.7665068755982,
            "y": 149.89734664074953,
            "z": -5408.1685748221635
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    },
    {
        "position": {
            "x": -1844.5785941621054,
            "y": 135.5282032574697,
            "z": -5461.740230952682
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.5,
            "z": 0.500000015287154
        },
        "rotation": {
            "x": -7.398377407264117e-9,
            "y": 0.9929934069194752,
            "z": -8.804333994923937e-10,
            "w": 0.11816976692222658
        }
    },
    {
        "position": {
            "x": -1844.5785943308342,
            "y": 147.17678857698874,
            "z": -5461.7402309934205
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.500000015287154,
            "z": 0.5
        },
        "rotation": {
            "x": 0.4374118232575964,
            "y": -0.5555815827814455,
            "z": 0.5555815910602563,
            "w": 0.43741181673965235
        }
    },
    {
        "position": {
            "x": -1844.5785943308342,
            "y": 147.17678857698874,
            "z": -5461.7402309934205
        },
        "scale": {
            "x": 0.500000015287154,
            "y": 0.4999999981728828,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.08355863686045203,
            "y": 0.7021523831005595,
            "z": -0.7021523603120278,
            "w": 0.08355865018342841
        }
    }
]
export const palmsV2 = [
    {
        "position": {
            "x": 405.30977171823895,
            "y": 264.9514179825783,
            "z": 905.8715419882363
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 405.30977171823895,
            "y": 272.25775200128555,
            "z": 905.8715419882363
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 405.30977171823895,
            "y": 272.25775200128555,
            "z": 905.8715419882363
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 415.70161364594856,
            "y": 264.9514179825783,
            "z": 910.1047978306024
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 415.70161364594856,
            "y": 273.26036459207535,
            "z": 910.1047978306024
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 415.70161364594856,
            "y": 273.26036459207535,
            "z": 910.1047978306024
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 420.29081115180566,
            "y": 264.9514179825783,
            "z": 962.7624015917348
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 420.29081115180566,
            "y": 272.0808020234108,
            "z": 962.7624015917348
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 420.29081115180566,
            "y": 272.0808020234108,
            "z": 962.7624015917348
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 417.96741347438126,
            "y": 264.9514179825783,
            "z": 953.5281101490403
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 417.9674134743812,
            "y": 273.2285662293434,
            "z": 953.5281101490402
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 417.9674134743812,
            "y": 273.2285662293434,
            "z": 953.5281101490402
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 438.05383184250866,
            "y": 264.9514179825783,
            "z": 1068.050223682424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 438.0538318425086,
            "y": 274.49096685647964,
            "z": 1068.050223682424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 438.0538318425086,
            "y": 274.49096685647964,
            "z": 1068.050223682424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 427.7647085376346,
            "y": 264.9514179825783,
            "z": 1016.3559159949789
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 427.76470853763465,
            "y": 274.49096685647964,
            "z": 1016.355915994979
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 427.76470853763465,
            "y": 274.49096685647964,
            "z": 1016.355915994979
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 323.58111445566186,
            "y": 264.9514179825783,
            "z": 1124.2754927923972
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 323.5811144556618,
            "y": 274.49096685647964,
            "z": 1124.2754927923972
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 323.5811144556618,
            "y": 274.49096685647964,
            "z": 1124.2754927923972
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 341.71782674040963,
            "y": 264.9514179825783,
            "z": 1102.9194482212042
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 341.7178267404096,
            "y": 273.2285662293434,
            "z": 1102.9194482212042
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 341.7178267404096,
            "y": 273.2285662293434,
            "z": 1102.9194482212042
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 407.8678150100515,
            "y": 264.9514179825783,
            "z": 1086.1030228562545
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 407.8678150100515,
            "y": 274.49096685647964,
            "z": 1086.1030228562545
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 407.8678150100515,
            "y": 274.49096685647964,
            "z": 1086.1030228562545
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 357.8998307155284,
            "y": 264.9514179825783,
            "z": 1078.4895805160832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 357.8998307155284,
            "y": 274.49096685647964,
            "z": 1078.4895805160832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 357.8998307155284,
            "y": 274.49096685647964,
            "z": 1078.4895805160832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 152.97246254562418,
            "y": 264.9514179825783,
            "z": 845.9321483513114
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 152.97246254562413,
            "y": 272.77335315942764,
            "z": 845.9321483513114
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 152.97246254562413,
            "y": 272.77335315942764,
            "z": 845.9321483513114
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 161.47152590629128,
            "y": 264.9514179825783,
            "z": 849.4431597029386
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 161.47152590629128,
            "y": 277.12828928232193,
            "z": 849.4431597029386
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 161.47152590629128,
            "y": 277.12828928232193,
            "z": 849.4431597029386
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 159.71655923906064,
            "y": 264.9514179825783,
            "z": 840.4701295860012
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 159.71655923906064,
            "y": 274.79032999277115,
            "z": 840.4701295860012
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 159.71655923906064,
            "y": 274.79032999277115,
            "z": 840.4701295860012
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 234.67105063675854,
            "y": 264.9514179825783,
            "z": 802.8126249489576
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 234.67105063675854,
            "y": 277.12828928232193,
            "z": 802.8126249489576
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 234.67105063675854,
            "y": 277.12828928232193,
            "z": 802.8126249489576
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 225.23820873643643,
            "y": 264.9514179825783,
            "z": 800.6268131762381
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 225.23820873643643,
            "y": 275.80424505472183,
            "z": 800.6268131762381
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 225.23820873643643,
            "y": 275.80424505472183,
            "z": 800.6268131762381
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 228.0339929617571,
            "y": 264.9514179825783,
            "z": 793.6180731563394
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 228.0339929617571,
            "y": 274.49096685647964,
            "z": 793.6180731563394
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 228.0339929617571,
            "y": 274.49096685647964,
            "z": 793.6180731563394
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 290.84961427772134,
            "y": 264.9514179825783,
            "z": 786.239593223377
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 290.84961427772134,
            "y": 277.85389429330826,
            "z": 786.239593223377
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 290.84961427772134,
            "y": 277.85389429330826,
            "z": 786.239593223377
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 303.3467985217645,
            "y": 264.9514179825783,
            "z": 792.8380744365985
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 303.3467985217646,
            "y": 275.53878980875015,
            "z": 792.8380744365986
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 303.3467985217646,
            "y": 275.53878980875015,
            "z": 792.8380744365986
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 306.9911202026529,
            "y": 264.9514179825783,
            "z": 785.7736204376071
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 306.9911202026529,
            "y": 272.7058406472206,
            "z": 785.7736204376071
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 306.9911202026529,
            "y": 272.7058406472206,
            "z": 785.7736204376071
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 255.0912272744788,
            "y": 264.9514179825783,
            "z": 810.4590861937195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 255.0912272744788,
            "y": 272.7058406472206,
            "z": 810.4590861937195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 255.0912272744788,
            "y": 272.7058406472206,
            "z": 810.4590861937195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 160.34909240128223,
            "y": 266.7614178657532,
            "z": 952.4727280635752
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 160.34909240128223,
            "y": 273.1886429786682,
            "z": 952.4727280635752
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 160.34909240128223,
            "y": 273.1886429786682,
            "z": 952.4727280635752
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 166.94538388598846,
            "y": 266.732990860939,
            "z": 958.8777408091921
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 166.94538388598846,
            "y": 273.97085678577423,
            "z": 958.8777408091921
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 166.94538388598846,
            "y": 273.97085678577423,
            "z": 958.8777408091921
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 168.45374225281097,
            "y": 264.9514179825783,
            "z": 954.2814143081677
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 168.45374225281086,
            "y": 274.067118704319,
            "z": 954.2814143081675
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 168.45374225281086,
            "y": 274.067118704319,
            "z": 954.2814143081675
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 219.7540230377799,
            "y": 264.9514179825783,
            "z": 732.9479073197679
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 219.7540230377799,
            "y": 274.067118704319,
            "z": 732.9479073197679
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 219.7540230377799,
            "y": 274.067118704319,
            "z": 732.9479073197679
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 230.46286702869952,
            "y": 264.9514179825783,
            "z": 737.2063228471001
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 230.46286702869952,
            "y": 272.14423805475235,
            "z": 737.2063228471001
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 230.46286702869952,
            "y": 272.14423805475235,
            "z": 737.2063228471001
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 229.03405211513387,
            "y": 264.9514179825783,
            "z": 731.3417816539368
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 229.03405211513387,
            "y": 274.49096685647964,
            "z": 731.3417816539368
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 229.03405211513387,
            "y": 274.49096685647964,
            "z": 731.3417816539368
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 178.15635282293874,
            "y": 264.9514179825783,
            "z": 885.5421902452729
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 178.1563528229388,
            "y": 272.42088466882706,
            "z": 885.5421902452729
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 178.1563528229388,
            "y": 272.42088466882706,
            "z": 885.5421902452729
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 169.70310909804743,
            "y": 264.9514179825783,
            "z": 884.860030158712
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 169.70310909804738,
            "y": 274.49096685647964,
            "z": 884.860030158712
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 169.70310909804738,
            "y": 274.49096685647964,
            "z": 884.860030158712
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 357.6937877753027,
            "y": 264.9514179825783,
            "z": 1045.6793713911707
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 357.6937877753028,
            "y": 274.49096685647964,
            "z": 1045.6793713911707
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 357.6937877753028,
            "y": 274.49096685647964,
            "z": 1045.6793713911707
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 395.41700484567616,
            "y": 264.9514179825783,
            "z": 947.91932324892
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 395.4170048456761,
            "y": 271.39379316568375,
            "z": 947.9193232489199
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 395.4170048456761,
            "y": 271.39379316568375,
            "z": 947.9193232489199
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 347.86820189393234,
            "y": 264.9514179825783,
            "z": 992.8146763348383
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 347.86820189393234,
            "y": 273.2285662293434,
            "z": 992.8146763348383
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 347.86820189393234,
            "y": 273.2285662293434,
            "z": 992.8146763348383
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 303.93921109264954,
            "y": 264.9514179825783,
            "z": 925.8521163576821
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 303.93921109264954,
            "y": 273.2285662293434,
            "z": 925.8521163576821
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 303.93921109264954,
            "y": 273.2285662293434,
            "z": 925.8521163576821
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 261.1596825336089,
            "y": 264.9514179825783,
            "z": 901.1052625242968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 261.1596825336089,
            "y": 273.2285662293434,
            "z": 901.1052625242968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 261.1596825336089,
            "y": 273.2285662293434,
            "z": 901.1052625242968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 277.9076478208033,
            "y": 264.9514179825783,
            "z": 1021.2502612653942
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 277.9076478208033,
            "y": 273.3256474137306,
            "z": 1021.2502612653942
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 277.9076478208033,
            "y": 273.3256474137306,
            "z": 1021.2502612653942
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 265.5384125543226,
            "y": 264.9514179825783,
            "z": 859.3783589828392
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 265.5384125543226,
            "y": 272.1161590218544,
            "z": 859.3783589828392
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 265.5384125543226,
            "y": 272.1161590218544,
            "z": 859.3783589828392
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 243.16893838792697,
            "y": 264.9514179825783,
            "z": 877.8003657697766
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 243.16893838792697,
            "y": 272.34842973947525,
            "z": 877.8003657697766
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 243.16893838792697,
            "y": 272.34842973947525,
            "z": 877.8003657697766
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 307.15366601030394,
            "y": 267.03534626960754,
            "z": 856.4256368179892
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 307.153666010304,
            "y": 275.3442928791046,
            "z": 856.4256368179894
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 307.153666010304,
            "y": 275.3442928791046,
            "z": 856.4256368179894
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 289.1321064116115,
            "y": 264.9514179825783,
            "z": 1072.5098021101408
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 289.1321064116115,
            "y": 274.49096685647964,
            "z": 1072.5098021101408
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 289.1321064116115,
            "y": 274.49096685647964,
            "z": 1072.5098021101408
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 314.8126153120851,
            "y": 264.9514179825783,
            "z": 1020.9092190585438
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 314.8126153120851,
            "y": 274.49096685647964,
            "z": 1020.9092190585438
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 314.8126153120851,
            "y": 274.49096685647964,
            "z": 1020.9092190585438
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 332.51087094942443,
            "y": 264.9514179825783,
            "z": 953.2559969142968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 332.51087094942443,
            "y": 274.49096685647964,
            "z": 953.2559969142968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 332.51087094942443,
            "y": 274.49096685647964,
            "z": 953.2559969142968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 300.03490379753265,
            "y": 264.9514179825783,
            "z": 969.8001864866187
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 300.03490379753265,
            "y": 273.2285662293434,
            "z": 969.8001864866187
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 300.03490379753265,
            "y": 273.2285662293434,
            "z": 969.8001864866187
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 357.94917251087236,
            "y": 264.9514179825783,
            "z": 885.1379945912572
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 357.9491725108724,
            "y": 273.2285662293434,
            "z": 885.1379945912573
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 357.9491725108724,
            "y": 273.2285662293434,
            "z": 885.1379945912573
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 221.93568755953802,
            "y": 264.9514179825783,
            "z": 987.7240569597624
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 221.93568755953802,
            "y": 273.2285662293434,
            "z": 987.7240569597624
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 221.93568755953802,
            "y": 273.2285662293434,
            "z": 987.7240569597624
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 233.30409712765294,
            "y": 264.9514179825783,
            "z": 1033.0799459243647
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 233.30409712765294,
            "y": 274.49096685647964,
            "z": 1033.0799459243647
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 233.30409712765294,
            "y": 274.49096685647964,
            "z": 1033.0799459243647
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 218.70982285697693,
            "y": 267.8185703754425,
            "z": 932.2778426072537
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 218.70982285697698,
            "y": 276.3214886188507,
            "z": 932.2778426072537
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 218.70982285697698,
            "y": 276.3214886188507,
            "z": 932.2778426072537
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 372.6070069743266,
            "y": 264.9514179825783,
            "z": 975.422765064637
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 372.6070069743266,
            "y": 274.49096685647964,
            "z": 975.422765064637
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 372.6070069743266,
            "y": 274.49096685647964,
            "z": 975.422765064637
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 238.1474523230118,
            "y": 264.9514179825783,
            "z": 968.1500934291346
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 238.1474523230118,
            "y": 274.49096685647964,
            "z": 968.1500934291346
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 238.1474523230118,
            "y": 274.49096685647964,
            "z": 968.1500934291346
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 311.07277577693117,
            "y": 264.9514179825783,
            "z": 878.9994606589959
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 311.0727757769312,
            "y": 274.49096685647964,
            "z": 878.999460658996
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 311.0727757769312,
            "y": 274.49096685647964,
            "z": 878.999460658996
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 254.76189003594817,
            "y": 264.9514179825783,
            "z": 834.8160877870832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 254.76189003594817,
            "y": 272.58305698633194,
            "z": 834.8160877870832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 254.76189003594817,
            "y": 272.58305698633194,
            "z": 834.8160877870832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 207.59995473590536,
            "y": 266.3207265138626,
            "z": 898.4641779628049
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 207.59995473590536,
            "y": 275.860275387764,
            "z": 898.4641779628049
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 207.59995473590536,
            "y": 275.860275387764,
            "z": 898.4641779628049
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 326.4206875994796,
            "y": 264.9514179825783,
            "z": 733.6025261378716
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 326.4206875994796,
            "y": 277.12828928232193,
            "z": 733.6025261378716
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 326.4206875994796,
            "y": 277.12828928232193,
            "z": 733.6025261378716
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 316.987847335537,
            "y": 264.9514179825783,
            "z": 731.4167133852329
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 316.987847335537,
            "y": 275.80424505472183,
            "z": 731.4167133852329
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 316.987847335537,
            "y": 275.80424505472183,
            "z": 731.4167133852329
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 319.7836299244782,
            "y": 264.9514179825783,
            "z": 724.4079743452533
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 319.7836299244782,
            "y": 274.49096685647964,
            "z": 724.4079743452533
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 319.7836299244782,
            "y": 274.49096685647964,
            "z": 724.4079743452533
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 323.40167133578495,
            "y": 264.9514179825783,
            "z": 719.3735283669073
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 323.40167133578495,
            "y": 277.12828928232193,
            "z": 719.3735283669073
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 323.40167133578495,
            "y": 277.12828928232193,
            "z": 719.3735283669073
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 313.9688101414541,
            "y": 264.9514179825783,
            "z": 717.1877103625847
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 313.9688101414541,
            "y": 275.80424505472183,
            "z": 717.1877103625847
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 313.9688101414541,
            "y": 275.80424505472183,
            "z": 717.1877103625847
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 316.7646058214315,
            "y": 264.9514179825783,
            "z": 710.1789634832529
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 316.7646058214315,
            "y": 274.49096685647964,
            "z": 710.1789634832529
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 316.7646058214315,
            "y": 274.49096685647964,
            "z": 710.1789634832529
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 334.1263104519153,
            "y": 264.9514179825783,
            "z": 710.9886418088153
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 334.1263104519153,
            "y": 277.12828928232193,
            "z": 710.9886418088153
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 334.1263104519153,
            "y": 277.12828928232193,
            "z": 710.9886418088153
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 324.6934701879727,
            "y": 264.9514179825783,
            "z": 708.8028290561767
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 324.69347018797276,
            "y": 275.80424505472183,
            "z": 708.8028290561768
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 324.69347018797276,
            "y": 275.80424505472183,
            "z": 708.8028290561768
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 327.4892527769139,
            "y": 264.9514179825783,
            "z": 701.7940900161971
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 327.4892527769139,
            "y": 274.49096685647964,
            "z": 701.7940900161971
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 327.4892527769139,
            "y": 274.49096685647964,
            "z": 701.7940900161971
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 381.91554147995316,
            "y": 264.9514179825783,
            "z": 877.746559158249
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 381.9155414799531,
            "y": 277.12828928232193,
            "z": 877.7465591582489
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 381.9155414799531,
            "y": 277.12828928232193,
            "z": 877.7465591582489
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 372.4826922819399,
            "y": 264.9514179825783,
            "z": 875.5607463366115
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 372.4826922819399,
            "y": 275.80424505472183,
            "z": 875.5607463366115
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 372.4826922819399,
            "y": 275.80424505472183,
            "z": 875.5607463366115
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 375.2784809264396,
            "y": 264.9514179825783,
            "z": 868.5520025587659
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 375.27848092643967,
            "y": 274.49096685647964,
            "z": 868.552002558766
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 375.27848092643967,
            "y": 274.49096685647964,
            "z": 868.552002558766
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 406.40845470056246,
            "y": 264.9514179825783,
            "z": 887.7786158798183
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 406.40845470056246,
            "y": 277.12828928232193,
            "z": 887.7786158798183
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 406.40845470056246,
            "y": 277.12828928232193,
            "z": 887.7786158798183
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 396.9755988957862,
            "y": 264.9514179825783,
            "z": 885.5928068755829
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 396.9755988957862,
            "y": 275.80424505472183,
            "z": 885.5928068755829
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 396.9755988957862,
            "y": 275.80424505472183,
            "z": 885.5928068755829
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 399.77139408580405,
            "y": 264.9514179825783,
            "z": 878.5840591780615
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 399.77139408580405,
            "y": 274.49096685647964,
            "z": 878.5840591780615
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 399.77139408580405,
            "y": 274.49096685647964,
            "z": 878.5840591780615
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 396.77592100276536,
            "y": 264.9514179825783,
            "z": 901.2772828836585
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 396.77592100276536,
            "y": 277.12828928232193,
            "z": 901.2772828836585
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 396.77592100276536,
            "y": 277.12828928232193,
            "z": 901.2772828836585
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 390.1388603880069,
            "y": 264.9514179825783,
            "z": 892.0827261819018
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 390.13886038800695,
            "y": 274.49096685647964,
            "z": 892.0827261819019
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 390.13886038800695,
            "y": 274.49096685647964,
            "z": 892.0827261819019
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 202.52436011267403,
            "y": 264.9514179825783,
            "z": 962.7400520745593
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 202.52436011267403,
            "y": 277.12828928232193,
            "z": 962.7400520745593
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 202.52436011267403,
            "y": 277.12828928232193,
            "z": 962.7400520745593
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 203.98336377832575,
            "y": 264.9514179825783,
            "z": 919.0438371438186
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 203.9833637783258,
            "y": 277.12828928232193,
            "z": 919.0438371438186
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 203.9833637783258,
            "y": 277.12828928232193,
            "z": 919.0438371438186
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 261.3340401755894,
            "y": 264.9514179825783,
            "z": 1063.4166191182678
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 261.33404017558934,
            "y": 277.12828928232193,
            "z": 1063.4166191182678
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 261.33404017558934,
            "y": 277.12828928232193,
            "z": 1063.4166191182678
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 251.90119599197078,
            "y": 264.9514179825783,
            "z": 1061.230799820111
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 251.90119599197078,
            "y": 275.80424505472183,
            "z": 1061.230799820111
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 251.90119599197078,
            "y": 275.80424505472183,
            "z": 1061.230799820111
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 254.69697858091195,
            "y": 264.9514179825783,
            "z": 1054.2220607801314
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 254.69697858091195,
            "y": 274.49096685647964,
            "z": 1054.2220607801314
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 254.69697858091195,
            "y": 274.49096685647964,
            "z": 1054.2220607801314
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 272.66203502714575,
            "y": 264.9514179825783,
            "z": 1091.441960119341
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 272.66203502714575,
            "y": 277.12828928232193,
            "z": 1091.441960119341
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 272.66203502714575,
            "y": 277.12828928232193,
            "z": 1091.441960119341
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 263.22918037833307,
            "y": 264.9514179825783,
            "z": 1089.2561381953424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 263.2291803783331,
            "y": 275.80424505472183,
            "z": 1089.2561381953424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 263.2291803783331,
            "y": 275.80424505472183,
            "z": 1089.2561381953424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 266.02496951279227,
            "y": 264.9514179825783,
            "z": 1082.2473952356866
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 266.0249695127922,
            "y": 274.49096685647964,
            "z": 1082.2473952356866
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 266.0249695127922,
            "y": 274.49096685647964,
            "z": 1082.2473952356866
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 247.16526107967272,
            "y": 264.9514179825783,
            "z": 1073.7568146935837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 247.16526107967272,
            "y": 277.12828928232193,
            "z": 1073.7568146935837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 247.16526107967272,
            "y": 277.12828928232193,
            "z": 1073.7568146935837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 237.73241035053601,
            "y": 264.9514179825783,
            "z": 1071.570999315103
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 237.73241035053607,
            "y": 275.80424505472183,
            "z": 1071.570999315103
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 237.73241035053607,
            "y": 275.80424505472183,
            "z": 1071.570999315103
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 240.52819948499527,
            "y": 264.9514179825783,
            "z": 1064.5622563554473
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 240.52819948499527,
            "y": 274.49096685647964,
            "z": 1064.5622563554473
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 240.52819948499527,
            "y": 274.49096685647964,
            "z": 1064.5622563554473
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 256.4000396442493,
            "y": 264.9514179825783,
            "z": 1103.4414557300415
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 256.4000396442493,
            "y": 277.12828928232193,
            "z": 1103.4414557300415
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 256.4000396442493,
            "y": 277.12828928232193,
            "z": 1103.4414557300415
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 246.96719283478862,
            "y": 264.9514179825783,
            "z": 1101.2556468970788
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 246.96719283478868,
            "y": 275.80424505472183,
            "z": 1101.2556468970788
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 246.96719283478868,
            "y": 275.80424505472183,
            "z": 1101.2556468970788
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 249.76298196924787,
            "y": 264.9514179825783,
            "z": 1094.2469039374232
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 249.76298196924787,
            "y": 274.49096685647964,
            "z": 1094.2469039374232
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 249.76298196924787,
            "y": 274.49096685647964,
            "z": 1094.2469039374232
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 149.9921505355038,
            "y": 264.9514179825783,
            "z": 1036.6448256370945
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 149.9921505355038,
            "y": 277.12828928232193,
            "z": 1036.6448256370945
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 149.9921505355038,
            "y": 277.12828928232193,
            "z": 1036.6448256370945
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 140.5593083117232,
            "y": 264.9514179825783,
            "z": 1034.4590096116967
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 140.5593083117232,
            "y": 275.80424505472183,
            "z": 1034.4590096116967
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 140.5593083117232,
            "y": 275.80424505472183,
            "z": 1034.4590096116967
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 143.35509041070486,
            "y": 264.9514179825783,
            "z": 1027.4502697535274
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 143.3550904107048,
            "y": 274.49096685647964,
            "z": 1027.4502697535274
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 143.3550904107048,
            "y": 274.49096685647964,
            "z": 1027.4502697535274
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 131.0217197763217,
            "y": 264.9514179825783,
            "z": 1014.780984808315
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 131.0217197763217,
            "y": 277.12828928232193,
            "z": 1014.780984808315
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 131.0217197763217,
            "y": 277.12828928232193,
            "z": 1014.780984808315
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 121.5888775525411,
            "y": 264.9514179825783,
            "z": 1012.5951687829174
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 121.5888775525411,
            "y": 275.80424505472183,
            "z": 1012.5951687829174
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 121.5888775525411,
            "y": 275.80424505472183,
            "z": 1012.5951687829174
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 124.38465916156326,
            "y": 264.9514179825783,
            "z": 1005.5864281065582
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 124.38465916156326,
            "y": 274.49096685647964,
            "z": 1005.5864281065582
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 124.38465916156326,
            "y": 274.49096685647964,
            "z": 1005.5864281065582
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 141.1907934659782,
            "y": 264.9514179825783,
            "z": 1002.4471840879135
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 141.1907934659782,
            "y": 277.12828928232193,
            "z": 1002.4471840879135
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 141.1907934659782,
            "y": 277.12828928232193,
            "z": 1002.4471840879135
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 131.75795075223812,
            "y": 264.9514179825783,
            "z": 1000.261367244326
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 131.75795075223812,
            "y": 275.80424505472183,
            "z": 1000.261367244326
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 131.75795075223812,
            "y": 275.80424505472183,
            "z": 1000.261367244326
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 134.55373334117928,
            "y": 264.9514179825783,
            "z": 993.2526282043464
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 134.55373334117928,
            "y": 274.49096685647964,
            "z": 993.2526282043464
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 134.55373334117928,
            "y": 274.49096685647964,
            "z": 993.2526282043464
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 356.11498141828577,
            "y": 264.9514179825783,
            "z": 816.737711040262
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 356.11498141828577,
            "y": 274.49096685647964,
            "z": 816.737711040262
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 356.11498141828577,
            "y": 274.49096685647964,
            "z": 816.737711040262
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 356.0048063489829,
            "y": 264.9514179825783,
            "z": 825.4571931407556
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 356.0048063489829,
            "y": 275.91553980112076,
            "z": 825.4571931407556
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 356.0048063489829,
            "y": 275.91553980112076,
            "z": 825.4571931407556
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 348.0897863445549,
            "y": 264.9514179825783,
            "z": 819.1309971904151
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 348.08978634455485,
            "y": 275.91553980112076,
            "z": 819.130997190415
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 348.08978634455485,
            "y": 275.91553980112076,
            "z": 819.130997190415
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 368.55318243535874,
            "y": 264.9514179825783,
            "z": 819.326529667113
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 368.55318243535874,
            "y": 275.91553980112076,
            "z": 819.326529667113
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 368.55318243535874,
            "y": 275.91553980112076,
            "z": 819.326529667113
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 361.813345423271,
            "y": 264.9514179825783,
            "z": 810.4408952673675
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 361.813345423271,
            "y": 275.91553980112076,
            "z": 810.4408952673675
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 361.813345423271,
            "y": 275.91553980112076,
            "z": 810.4408952673675
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 122.20163851083808,
            "y": 266.7242740392685,
            "z": 898.9348546627796
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 122.20163851083808,
            "y": 277.688395857811,
            "z": 898.9348546627796
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 122.20163851083808,
            "y": 277.688395857811,
            "z": 898.9348546627796
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 119.62108590914553,
            "y": 264.9514179825783,
            "z": 905.7595094489969
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 119.62108590914553,
            "y": 275.91553980112076,
            "z": 905.7595094489969
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 119.62108590914553,
            "y": 275.91553980112076,
            "z": 905.7595094489969
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 126.85708847925864,
            "y": 266.3366525173187,
            "z": 909.6933168992251
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 126.85708847925859,
            "y": 279.0952355861664,
            "z": 909.6933168992251
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 126.85708847925859,
            "y": 279.0952355861664,
            "z": 909.6933168992251
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 121.36638828202024,
            "y": 266.2787018418312,
            "z": 917.7831517006451
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 121.36638828202028,
            "y": 277.6777334809303,
            "z": 917.7831517006451
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 121.36638828202028,
            "y": 277.6777334809303,
            "z": 917.7831517006451
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 114.15292529508017,
            "y": 266.5904060602188,
            "z": 909.1142135897692
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 114.15292529508017,
            "y": 278.03198873996735,
            "z": 909.1142135897692
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 114.15292529508017,
            "y": 278.03198873996735,
            "z": 909.1142135897692
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 110.93133027190456,
            "y": 266.94477820396423,
            "z": 899.0947470648491
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 110.93133027190456,
            "y": 275.9821469783783,
            "z": 899.0947470648491
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 110.93133027190456,
            "y": 275.9821469783783,
            "z": 899.0947470648491
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 115.17111491277348,
            "y": 266.9905631542206,
            "z": 892.6219439649298
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 115.17111491277348,
            "y": 277.95468497276306,
            "z": 892.6219439649298
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 115.17111491277348,
            "y": 277.95468497276306,
            "z": 892.6219439649298
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 133.62831160924213,
            "y": 264.9514179825783,
            "z": 822.1149557843573
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 133.62831160924213,
            "y": 274.49096685647964,
            "z": 822.1149557843573
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 133.62831160924213,
            "y": 274.49096685647964,
            "z": 822.1149557843573
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 133.12602068197623,
            "y": 264.9514179825783,
            "z": 829.5285052049744
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 133.12602068197623,
            "y": 275.8010645508766,
            "z": 829.5285052049744
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 133.12602068197623,
            "y": 275.8010645508766,
            "z": 829.5285052049744
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 123.44141996360966,
            "y": 264.9514179825783,
            "z": 819.6440365538283
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 123.44141996360966,
            "y": 274.2133999466896,
            "z": 819.6440365538283
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 123.44141996360966,
            "y": 274.2133999466896,
            "z": 819.6440365538283
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 242.20028439333618,
            "y": 264.9514179825783,
            "z": 762.1316567084534
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 242.20028439333618,
            "y": 275.80424505472183,
            "z": 762.1316567084534
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 242.20028439333618,
            "y": 275.80424505472183,
            "z": 762.1316567084534
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 246.8189729974451,
            "y": 264.9514179825783,
            "z": 709.0931134535299
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 246.8189729974451,
            "y": 273.74944311380386,
            "z": 709.0931134535299
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 246.8189729974451,
            "y": 273.74944311380386,
            "z": 709.0931134535299
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 71.7947233026949,
            "y": 264.9514179825783,
            "z": 877.5496292089513
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 71.79472330269506,
            "y": 274.49096685647964,
            "z": 877.5496292089513
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 71.79472330269506,
            "y": 274.49096685647964,
            "z": 877.5496292089513
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 64.78502347098262,
            "y": 264.9514179825783,
            "z": 880.0148581093822
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 64.7850234709826,
            "y": 275.8010645508766,
            "z": 880.0148581093821
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 64.7850234709826,
            "y": 275.8010645508766,
            "z": 880.0148581093821
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 70.04336486906465,
            "y": 264.9514179825783,
            "z": 867.2146832668279
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 70.04336486906476,
            "y": 274.2133999466896,
            "z": 867.2146832668278
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 70.04336486906476,
            "y": 274.2133999466896,
            "z": 867.2146832668278
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 24.239855696828755,
            "y": 264.9514179825783,
            "z": 984.2968395946065
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 24.239855696828755,
            "y": 274.49096685647964,
            "z": 984.2968395946065
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 24.239855696828755,
            "y": 274.49096685647964,
            "z": 984.2968395946065
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 17.230159784792477,
            "y": 264.9514179825783,
            "z": 986.7620750405555
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 17.230159784792477,
            "y": 275.8010645508766,
            "z": 986.7620750405555
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 17.230159784792477,
            "y": 275.8010645508766,
            "z": 986.7620750405555
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 22.488497263198468,
            "y": 264.9514179825783,
            "z": 973.9618936524832
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 22.488497263198468,
            "y": 274.2133999466896,
            "z": 973.9618936524832
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 22.488497263198468,
            "y": 274.2133999466896,
            "z": 973.9618936524832
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    }
]
export const palmsV3 = [
    {
        "position": {
            "x": 208.90053421857615,
            "y": 252.2944305874345,
            "z": 1250.2064760236353
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 208.90053422525602,
            "y": 261.83397946133584,
            "z": 1250.2064759900047
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 208.90053422525602,
            "y": 261.83397946133584,
            "z": 1250.2064759900047
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 206.8305959475684,
            "y": 252.29443062562277,
            "z": 1260.6277094799602
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 206.8305959542483,
            "y": 261.83397949952416,
            "z": 1260.6277094463296
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 206.8305959542483,
            "y": 261.83397949952416,
            "z": 1260.6277094463296
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 204.92126444646834,
            "y": 252.29443066084798,
            "z": 1270.24035812614
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 204.9212644531482,
            "y": 261.83397953474935,
            "z": 1270.2403580925095
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 204.9212644531482,
            "y": 261.83397953474935,
            "z": 1270.2403580925095
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 202.4909276390148,
            "y": 252.2944307056853,
            "z": 1282.4760405146985
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 202.49092764569477,
            "y": 261.83397957958664,
            "z": 1282.476040481068
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 202.49092764569477,
            "y": 261.83397957958664,
            "z": 1282.476040481068
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 200.04208129085046,
            "y": 252.29443075086402,
            "z": 1294.804910341558
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 200.0420812975303,
            "y": 261.8339796247654,
            "z": 1294.8049103079277
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 200.0420812975303,
            "y": 261.8339796247654,
            "z": 1294.8049103079277
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 197.52053794724,
            "y": 252.29443079738402,
            "z": 1307.4997777306291
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 197.52053795391993,
            "y": 261.8339796712854,
            "z": 1307.4997776969985
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 197.52053795391993,
            "y": 261.8339796712854,
            "z": 1307.4997776969985
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 194.9993479873505,
            "y": 252.29443084389743,
            "z": 1320.1928659872947
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 194.99934799403042,
            "y": 261.8339797177988,
            "z": 1320.192865953664
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 194.99934799403042,
            "y": 261.8339797177988,
            "z": 1320.192865953664
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 192.49610219137747,
            "y": 252.29443089007987,
            "z": 1332.7956132304275
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 192.4961021980574,
            "y": 261.83397976398123,
            "z": 1332.795613196797
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 192.4961021980574,
            "y": 261.83397976398123,
            "z": 1332.795613196797
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 175.72853933967625,
            "y": 252.29443089007984,
            "z": 1329.4651226312367
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 175.72853934635629,
            "y": 261.83397976398123,
            "z": 1329.4651225976056
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 175.72853934635629,
            "y": 261.83397976398123,
            "z": 1329.4651225976056
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 178.23178513564923,
            "y": 252.29443084389746,
            "z": 1316.8623753881036
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 178.23178514232916,
            "y": 261.8339797177988,
            "z": 1316.862375354473
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 178.23178514232916,
            "y": 261.8339797177988,
            "z": 1316.862375354473
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 180.75297509553874,
            "y": 252.29443079738405,
            "z": 1304.1692871314383
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 180.7529751022186,
            "y": 261.8339796712854,
            "z": 1304.1692870978077
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 180.7529751022186,
            "y": 261.8339796712854,
            "z": 1304.1692870978077
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 183.2745184391492,
            "y": 252.29443075086405,
            "z": 1291.4744197423672
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 183.2745184458291,
            "y": 261.83397962476545,
            "z": 1291.4744197087366
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 183.2745184458291,
            "y": 261.83397962476545,
            "z": 1291.4744197087366
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 185.72336478731356,
            "y": 252.29443070568527,
            "z": 1279.1455499155074
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 185.7233647939935,
            "y": 261.83397957958664,
            "z": 1279.1455498818766
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 185.7233647939935,
            "y": 261.83397957958664,
            "z": 1279.1455498818766
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 188.15370159476709,
            "y": 252.294430660848,
            "z": 1266.9098675269493
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 188.15370160144704,
            "y": 261.83397953474935,
            "z": 1266.9098674933184
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 188.15370160144704,
            "y": 261.83397953474935,
            "z": 1266.9098674933184
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 190.06303309586715,
            "y": 252.29443062562274,
            "z": 1257.2972188807692
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 190.0630331025471,
            "y": 261.8339794995241,
            "z": 1257.2972188471385
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 190.0630331025471,
            "y": 261.8339794995241,
            "z": 1257.2972188471385
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 192.1329713668749,
            "y": 252.29443058743448,
            "z": 1246.8759854244443
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 192.13297137355474,
            "y": 261.83397946133584,
            "z": 1246.8759853908139
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 192.13297137355474,
            "y": 261.83397946133584,
            "z": 1246.8759853908139
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 177.1677013288113,
            "y": 252.2944305874345,
            "z": 1243.9034788284087
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 177.16770133549116,
            "y": 261.83397946133584,
            "z": 1243.903478794778
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 177.16770133549116,
            "y": 261.83397946133584,
            "z": 1243.903478794778
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 175.09776305780355,
            "y": 252.29443062562277,
            "z": 1254.3247122847336
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 175.09776306448353,
            "y": 261.83397949952416,
            "z": 1254.3247122511027
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 175.09776306448353,
            "y": 261.83397949952416,
            "z": 1254.3247122511027
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 173.18843155670348,
            "y": 252.29443066084798,
            "z": 1263.9373609309134
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 173.18843156338335,
            "y": 261.83397953474935,
            "z": 1263.9373608972828
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 173.18843156338335,
            "y": 261.83397953474935,
            "z": 1263.9373608972828
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 170.75809474924995,
            "y": 252.2944307056853,
            "z": 1276.1730433194718
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 170.75809475592985,
            "y": 261.83397957958664,
            "z": 1276.173043285841
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 170.75809475592985,
            "y": 261.83397957958664,
            "z": 1276.173043285841
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 168.3092484010856,
            "y": 252.29443075086402,
            "z": 1288.5019131463314
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 168.30924840776555,
            "y": 261.8339796247654,
            "z": 1288.5019131127005
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 168.30924840776555,
            "y": 261.8339796247654,
            "z": 1288.5019131127005
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 165.78770505747514,
            "y": 252.294430797384,
            "z": 1301.1967805354025
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 165.7877050641551,
            "y": 261.83397967128536,
            "z": 1301.1967805017716
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 165.7877050641551,
            "y": 261.83397967128536,
            "z": 1301.1967805017716
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 163.26651509758562,
            "y": 252.29443084389743,
            "z": 1313.889868792068
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 163.26651510426558,
            "y": 261.8339797177988,
            "z": 1313.8898687584372
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 163.26651510426558,
            "y": 261.8339797177988,
            "z": 1313.8898687584372
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 160.7632693016126,
            "y": 252.29443089007987,
            "z": 1326.4926160352009
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 160.76326930829248,
            "y": 261.83397976398123,
            "z": 1326.4926160015702
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 160.76326930829248,
            "y": 261.83397976398123,
            "z": 1326.4926160015702
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 308.2394624412324,
            "y": 252.29443011059735,
            "z": 1152.668256571731
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 308.23946244791233,
            "y": 261.8339789844987,
            "z": 1152.6682565381004
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 308.23946244791233,
            "y": 261.8339789844987,
            "z": 1152.6682565381004
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 306.16952417022463,
            "y": 252.2944301487856,
            "z": 1163.089490028056
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 306.16952417690464,
            "y": 261.833979022687,
            "z": 1163.0894899944253
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 306.16952417690464,
            "y": 261.833979022687,
            "z": 1163.0894899944253
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 304.2601926691246,
            "y": 252.29443018401082,
            "z": 1172.7021386742358
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 304.2601926758045,
            "y": 261.8339790579122,
            "z": 1172.7021386406052
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 304.2601926758045,
            "y": 261.8339790579122,
            "z": 1172.7021386406052
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 301.8298558616711,
            "y": 252.29443022884814,
            "z": 1184.9378210627942
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 301.82985586835105,
            "y": 261.8339791027495,
            "z": 1184.9378210291636
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 301.82985586835105,
            "y": 261.8339791027495,
            "z": 1184.9378210291636
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 299.3810095135067,
            "y": 252.29443027402687,
            "z": 1197.2666908896538
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 299.3810095201866,
            "y": 261.83397914792823,
            "z": 1197.266690856023
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 299.3810095201866,
            "y": 261.83397914792823,
            "z": 1197.266690856023
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 296.8594661698962,
            "y": 252.29443032054687,
            "z": 1209.9615582787249
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 296.8594661765761,
            "y": 261.83397919444826,
            "z": 1209.9615582450942
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 296.8594661765761,
            "y": 261.83397919444826,
            "z": 1209.9615582450942
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 294.3382762100067,
            "y": 252.29443036706027,
            "z": 1222.6546465353904
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 294.3382762166867,
            "y": 261.83397924096164,
            "z": 1222.6546465017598
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 294.3382762166867,
            "y": 261.83397924096164,
            "z": 1222.6546465017598
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 291.8350304140337,
            "y": 252.2944304132427,
            "z": 1235.2573937785232
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 291.8350304207137,
            "y": 261.8339792871441,
            "z": 1235.2573937448926
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 291.8350304207137,
            "y": 261.8339792871441,
            "z": 1235.2573937448926
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 275.06746756233247,
            "y": 252.29443041324268,
            "z": 1231.9269031793324
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 275.0674675690124,
            "y": 261.8339792871441,
            "z": 1231.9269031457018
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 275.0674675690124,
            "y": 261.8339792871441,
            "z": 1231.9269031457018
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 277.5707133583055,
            "y": 252.2944303670603,
            "z": 1219.3241559361993
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 277.5707133649854,
            "y": 261.83397924096164,
            "z": 1219.3241559025687
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 277.5707133649854,
            "y": 261.83397924096164,
            "z": 1219.3241559025687
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 280.091903318195,
            "y": 252.2944303205469,
            "z": 1206.631067679534
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 280.091903324875,
            "y": 261.83397919444826,
            "z": 1206.6310676459034
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 280.091903324875,
            "y": 261.83397919444826,
            "z": 1206.6310676459034
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 282.6134466618054,
            "y": 252.2944302740269,
            "z": 1193.936200290463
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 282.61344666848527,
            "y": 261.8339791479283,
            "z": 1193.9362002568325
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 282.61344666848527,
            "y": 261.8339791479283,
            "z": 1193.9362002568325
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 285.06229300996984,
            "y": 252.2944302288481,
            "z": 1181.6073304636031
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 285.0622930166498,
            "y": 261.8339791027495,
            "z": 1181.6073304299725
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 285.0622930166498,
            "y": 261.8339791027495,
            "z": 1181.6073304299725
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 287.49262981742334,
            "y": 252.29443018401085,
            "z": 1169.371648075045
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 287.49262982410323,
            "y": 261.8339790579122,
            "z": 1169.3716480414143
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 287.49262982410323,
            "y": 261.8339790579122,
            "z": 1169.3716480414143
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 289.4019613185234,
            "y": 252.29443014878558,
            "z": 1159.7589994288649
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 289.40196132520333,
            "y": 261.83397902268695,
            "z": 1159.7589993952342
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 289.40196132520333,
            "y": 261.83397902268695,
            "z": 1159.7589993952342
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 291.4718995895312,
            "y": 252.29443011059732,
            "z": 1149.33776597254
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 291.4718995962111,
            "y": 261.8339789844987,
            "z": 1149.3377659389093
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 291.4718995962111,
            "y": 261.8339789844987,
            "z": 1149.3377659389093
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 276.50662955146754,
            "y": 252.29443011059735,
            "z": 1146.3652593765044
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 276.5066295581475,
            "y": 261.8339789844987,
            "z": 1146.3652593428737
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 276.5066295581475,
            "y": 261.8339789844987,
            "z": 1146.3652593428737
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 274.4366912804598,
            "y": 252.2944301487856,
            "z": 1156.7864928328293
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 274.4366912871397,
            "y": 261.833979022687,
            "z": 1156.7864927991986
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 274.4366912871397,
            "y": 261.833979022687,
            "z": 1156.7864927991986
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 272.5273597793597,
            "y": 252.29443018401082,
            "z": 1166.3991414790091
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 272.5273597860396,
            "y": 261.8339790579122,
            "z": 1166.3991414453785
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 272.5273597860396,
            "y": 261.8339790579122,
            "z": 1166.3991414453785
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 270.0970229719062,
            "y": 252.29443022884814,
            "z": 1178.6348238675675
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 270.0970229785862,
            "y": 261.8339791027495,
            "z": 1178.634823833937
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 270.0970229785862,
            "y": 261.8339791027495,
            "z": 1178.634823833937
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 267.64817662374185,
            "y": 252.29443027402687,
            "z": 1190.963693694427
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 267.6481766304217,
            "y": 261.83397914792823,
            "z": 1190.9636936607967
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 267.6481766304217,
            "y": 261.83397914792823,
            "z": 1190.9636936607967
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 265.1266332801314,
            "y": 252.29443032054684,
            "z": 1203.6585610834982
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 265.12663328681145,
            "y": 261.8339791944482,
            "z": 1203.6585610498673
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 265.12663328681145,
            "y": 261.8339791944482,
            "z": 1203.6585610498673
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 262.6054433202419,
            "y": 252.29443036706027,
            "z": 1216.3516493401637
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 262.60544332692183,
            "y": 261.83397924096164,
            "z": 1216.3516493065329
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 262.60544332692183,
            "y": 261.83397924096164,
            "z": 1216.3516493065329
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 260.1021975242689,
            "y": 252.2944304132427,
            "z": 1228.9543965832966
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 260.10219753094884,
            "y": 261.8339792871441,
            "z": 1228.954396549666
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 260.10219753094884,
            "y": 261.8339792871441,
            "z": 1228.954396549666
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 152.0800157249238,
            "y": 252.29443011059735,
            "z": 1121.6507699994654
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 152.08001573160368,
            "y": 261.8339789844987,
            "z": 1121.6507699658348
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 152.08001573160368,
            "y": 261.8339789844987,
            "z": 1121.6507699658348
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 150.01007745391607,
            "y": 252.2944301487856,
            "z": 1132.0720034557903
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 150.01007746059594,
            "y": 261.833979022687,
            "z": 1132.0720034221597
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 150.01007746059594,
            "y": 261.833979022687,
            "z": 1132.0720034221597
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 148.100745952816,
            "y": 252.29443018401082,
            "z": 1141.6846521019702
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 148.10074595949587,
            "y": 261.8339790579122,
            "z": 1141.6846520683396
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 148.10074595949587,
            "y": 261.8339790579122,
            "z": 1141.6846520683396
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 145.67040914536247,
            "y": 252.29443022884814,
            "z": 1153.9203344905286
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 145.67040915204234,
            "y": 261.8339791027495,
            "z": 1153.9203344568982
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 145.67040915204234,
            "y": 261.8339791027495,
            "z": 1153.9203344568982
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 143.22156279719812,
            "y": 252.29443027402687,
            "z": 1166.2492043173881
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 143.22156280387804,
            "y": 261.83397914792823,
            "z": 1166.2492042837573
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 143.22156280387804,
            "y": 261.83397914792823,
            "z": 1166.2492042837573
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 140.70001945358766,
            "y": 252.29443032054687,
            "z": 1178.9440717064592
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 140.70001946026764,
            "y": 261.83397919444826,
            "z": 1178.9440716728284
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 140.70001946026764,
            "y": 261.83397919444826,
            "z": 1178.9440716728284
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 138.17882949369815,
            "y": 252.29443036706027,
            "z": 1191.6371599631248
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 138.1788295003781,
            "y": 261.83397924096164,
            "z": 1191.637159929494
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 138.1788295003781,
            "y": 261.83397924096164,
            "z": 1191.637159929494
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 135.67558369772513,
            "y": 252.2944304132427,
            "z": 1204.2399072062576
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 135.67558370440508,
            "y": 261.8339792871441,
            "z": 1204.2399071726268
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 135.67558370440508,
            "y": 261.8339792871441,
            "z": 1204.2399071726268
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 118.90802084602389,
            "y": 252.29443041324268,
            "z": 1200.9094166070668
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 118.90802085270383,
            "y": 261.8339792871441,
            "z": 1200.9094165734361
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 118.90802085270383,
            "y": 261.8339792871441,
            "z": 1200.9094165734361
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 121.41126664199689,
            "y": 252.2944303670603,
            "z": 1188.3066693639337
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 121.41126664867676,
            "y": 261.83397924096164,
            "z": 1188.3066693303033
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 121.41126664867676,
            "y": 261.83397924096164,
            "z": 1188.3066693303033
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 123.9324566018864,
            "y": 252.2944303205469,
            "z": 1175.6135811072684
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 123.93245660856627,
            "y": 261.83397919444826,
            "z": 1175.613581073638
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 123.93245660856627,
            "y": 261.83397919444826,
            "z": 1175.613581073638
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 126.45399994549686,
            "y": 252.2944302740269,
            "z": 1162.9187137181973
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 126.4539999521768,
            "y": 261.8339791479283,
            "z": 1162.9187136845667
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 126.4539999521768,
            "y": 261.8339791479283,
            "z": 1162.9187136845667
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 128.9028462936612,
            "y": 252.2944302288481,
            "z": 1150.5898438913375
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 128.9028463003411,
            "y": 261.8339791027495,
            "z": 1150.589843857707
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 128.9028463003411,
            "y": 261.8339791027495,
            "z": 1150.589843857707
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 131.33318310111474,
            "y": 252.29443018401085,
            "z": 1138.3541615027793
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 131.3331831077946,
            "y": 261.8339790579122,
            "z": 1138.3541614691487
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 131.3331831077946,
            "y": 261.8339790579122,
            "z": 1138.3541614691487
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 133.2425146022148,
            "y": 252.29443014878558,
            "z": 1128.7415128565992
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 133.2425146088947,
            "y": 261.83397902268695,
            "z": 1128.7415128229686
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 133.2425146088947,
            "y": 261.83397902268695,
            "z": 1128.7415128229686
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 135.31245287322255,
            "y": 252.29443011059732,
            "z": 1118.3202794002743
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 135.31245287990245,
            "y": 261.8339789844987,
            "z": 1118.3202793666437
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 135.31245287990245,
            "y": 261.8339789844987,
            "z": 1118.3202793666437
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 120.34718283515895,
            "y": 252.29443011059735,
            "z": 1115.3477728042387
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 120.34718284183883,
            "y": 261.8339789844987,
            "z": 1115.3477727706081
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 120.34718284183883,
            "y": 261.8339789844987,
            "z": 1115.3477727706081
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 118.27724456415119,
            "y": 252.2944301487856,
            "z": 1125.7690062605636
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 118.27724457083107,
            "y": 261.833979022687,
            "z": 1125.769006226933
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 118.27724457083107,
            "y": 261.833979022687,
            "z": 1125.769006226933
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 116.36791306305113,
            "y": 252.29443018401082,
            "z": 1135.3816549067435
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 116.36791306973107,
            "y": 261.8339790579122,
            "z": 1135.381654873113
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 116.36791306973107,
            "y": 261.8339790579122,
            "z": 1135.381654873113
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 113.9375762555976,
            "y": 252.29443022884814,
            "z": 1147.617337295302
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 113.93757626227757,
            "y": 261.8339791027495,
            "z": 1147.6173372616713
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 113.93757626227757,
            "y": 261.8339791027495,
            "z": 1147.6173372616713
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 111.48872990743324,
            "y": 252.29443027402687,
            "z": 1159.9462071221615
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 111.48872991411322,
            "y": 261.83397914792823,
            "z": 1159.9462070885306
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 111.48872991411322,
            "y": 261.83397914792823,
            "z": 1159.9462070885306
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 108.96718656382278,
            "y": 252.29443032054684,
            "z": 1172.6410745112325
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 108.96718657050269,
            "y": 261.8339791944482,
            "z": 1172.641074477602
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 108.96718657050269,
            "y": 261.8339791944482,
            "z": 1172.641074477602
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 106.44599660393328,
            "y": 252.29443036706027,
            "z": 1185.334162767898
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 106.44599661061322,
            "y": 261.83397924096164,
            "z": 1185.3341627342675
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 106.44599661061322,
            "y": 261.83397924096164,
            "z": 1185.3341627342675
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 103.94275080796027,
            "y": 252.2944304132427,
            "z": 1197.936910011031
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 103.94275081464028,
            "y": 261.8339792871441,
            "z": 1197.9369099774
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 103.94275081464028,
            "y": 261.8339792871441,
            "z": 1197.9369099774
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    }
]
