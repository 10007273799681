  import { RouterView } from 'vue-router'
<template>
<metainfo>
    <template v-slot:title="{ content }">{{ content}}</template>
  </metainfo>
<div class="page-content">

<RouterView />



</div>
</template>

<script>
import { useMeta } from 'vue-meta'
export default {
  name: 'App',
   setup () {
    useMeta({
      title: 'كن جزءًا من قصة يوم التأسيس | تجربة تفاعلية من دارة الملك عبد العزيز',
      og:{
            title: 'كن جزءًا من قصة يوم التأسيس | تجربة تفاعلية من دارة الملك عبد العزيز',
            description:"الفصل الأول: جـذور التأسيس قبل آلاف السنين من هذا اليوم، قبيلة، قادتها العزيمة",
            image:"https://foundingday.antame.org/assets/chapter1-thumbnail.jpg"
        },
      meta: [
        ...( [
          {
            vmid: "description",
            name: "description",
            content:"الفصل الأول: جـذور التأسيس قبل آلاف السنين من هذا اليوم، قبيلة، قادتها العزيمة",
          },
    
          {
            vmid: "image",
            name: "image",
            content:"https://foundingday.antame.org/assets/chapter1-thumbnail.jpg",
          },
        ]
        ),

      ],
    })
  },
}
</script>


