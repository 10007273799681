import emitter from 'tiny-emitter/instance';
export let activeHotspot = 10;
export const setActiveHotspot = (number) => {
    activeHotspot = number;
    emitter.emit('caption', activeHotspot)
}
export const getActiveHotspot = () => {

    return activeHotspot;
}
export const hotspots = [
    {
        position: { x: 335.042, y: 300.113, z: 810.066 },
        rotation: { x: 0, y: 3, z: 0 },
        scale: { x: 5, y: 5, z: 5 },
        info: {
            image: '/assets/chapter4-popup1.jpg',
            title: '',
            description: 'تقــع الدرعية عاصمة الدولة السعودية الأولى في وســط الجزيــرة العربيــة، وتحديــداً فـي إقليـم اليمامـة الـذي يحتـل مسـاحة كبيـرة مـن الجزيـرة العربيـة، حيـث يبلـغ طولـه مـن الشـمال إلـى الجنـوب مـا يزيـد علـى 1000 كـم وعرضه 500 كـم، وتتوسـطه سلسلة جبـال طويـق الشهيرة التـى تغنـى بهــا الشعـراء. '
        }
    },
    {
        position: { x: 183.468, y: 271.000, z: 801.677 },
        rotation: { x: 0, y: 3, z: 0 },
        scale: { x: 0.1, y: 0.1, z: 0.1 },
        info: {
            image: '',
            title: '',
            description: 'كان الإمـام محمـد بـن سـعود حاكـماً حكيـماً وفيـاً، تربى في بيت عـز و إمـارة و تعلـم السياسـة وطـرق التعامـل مـع الإمـارات المجـاورة والعشـائر المتنقلـة، وقـد كان لـه أثـر كبير في استتباب الأوضـاع في الإمـارة قبل توليه الحكم، في الوقـت نفسـه تحـلى الإمـام محمـد بـن سـعود برؤيـة ثاقبـة، فقـد درس الأوضـاع التـي كانـت تعيشها إمارته و الإمـارات التـي حولهـا بشـكل خـاص وسـط الجزيـرة العربيـة بشـكل عـام، و بـدأ منذ توليه الحكم التخطيـط للتغيير عـن النمط السائد خـلال تلـك الأيـام، أســس المســار جديــد في تاريــخ المنطقــة تمثــل في الوحــدة والتعليــم ونشر الثقافــة وتعزيز التواصـل بـين أفـراد المجتمـع والحفاظ عـلى الأمـن. وقـد كان لـه مـن الأبنـاء '
        }
    },
    {
        position: { x: 161.406, y: 271.000, z: 795.938 },
        rotation: { x: 0, y: 3, z: 0 },
        scale: { x: 0.1, y: 0.1, z: 0.1 },
        info: {
            image: '',
            title: 'الحياة العلمية',
            description: 'كان أئمـة الدرعيـة مهتمين بدعم العلـم والتعليـم، وكانوا لا ينقطعون عن مجالس العلم في حلهـم، وكانـت هـذه المجالـس تبـدأ مـن وقـت طلـوع الشـمس حيـث يجلـس النـاس مـن أهـل الدرعيـة وغيرهـم للـدرس في (الباطـن) بالموضـع المعـروف بالموسـم، وهــو مــكان الســوق في بطن وادي حنيفة، فــإن كان في الصيــف فعند الدكاكــين الشرقيـة، وإن كان في الشـتاء فعند الدكاكـين الغربيـة. يجتمـع جمـع عظيـم بحيث لا يتخلف إلا النـادر مـن أهـل الأعـمال.'
        }
    },

    {
        position: { x: 130.065, y: 282.372, z: 882.839 },
        rotation: { x: 0, y: 3, z: 0 },
        scale: { x: 0.5, y: 0.5, z: 0.5 },
        info: {
            image: '',
            title: 'مجتمع الدرعية',
            description: 'وفـد إلى مجتمـع الدرعية خـلال فـترة الدولـة السـعودية الأولى كثـير مـن الذيـن أصبحوا جـزء مـن الدولـة السـعودية الأولى، سـواء كانـوا مـن التجـار أو مـن طلبـة العلـم. وكان هؤلاء الوافدون مـن طبقـات مختلفـة، ومـع مـرور الوقـت ازداد عـدد سـكان الدرعية ازديـاداً كبـيراً، وتوسعت رقعة مساحتها، أصبحـت مقصـد طـلاب العلـم، و قبلـة أربـاب التجـارة، وموئل الباحثين عـن الـرزق. '
        }
    }
]