import * as THREE from 'three';
import { palms, palmsV2, palmsV3 } from './TreesPositions';
import { loadGLTF, loadTexture } from "../../utils/asyncLoaders";
import { isMobile } from '../../utils/device';
class TreesScene {
    constructor() {
        this.bind();

        this.geo;
        this.mat;

        this.mesh = null;
        this.meshVillage2 = null;
        this.meshVillage3 = null;
        this.dummy = new THREE.Object3D();
        this.dummy2 = new THREE.Object3D();
        this.sectionWidth = 200;

        this.palms;
        this.palmsV2;
        this.trees, this.trees2, this.trees3, this.bushes;
        this.geoBush;
        this.matBush;
        this.meshBush;
        this.meshBushVillage2;
        this.meshBushVillage3;

        this.geoTree;
        this.matTree;
        this.meshTree;
        this.meshTreeVillage2;
        this.meshTreeVillage3;

        this.palmMat = new THREE.MeshStandardMaterial({
            //  map: palmTexture,


            metalness: 0.5,
            depthWrite: false,
            transparent: true,
            side: THREE.DoubleSide,
            color: new THREE.Color(0x6F7070),
            alphaTest: 0.01

        })

        this.treeMat = new THREE.MeshStandardMaterial({
            //    map: treeTexture,
            depthTest: true,
            metalness: 0.5,
            depthWrite: false,
            transparent: true,
            side: THREE.DoubleSide,
            color: new THREE.Color(0x6F7070),
            alphaTest: 0.01
        });
        this.bushMat = new THREE.MeshStandardMaterial({
            //    map: bushTexture,
            depthTest: true,
            depthWrite: true,
            transparent: true,
            metalness: 0.9,
            side: THREE.DoubleSide,
            color: new THREE.Color(0x6F7070),
            alphaTest: 0.1
        })


    }

    addInstancedMesh() {
        // An InstancedMesh of 4 cubes
        this.mesh = new THREE.InstancedMesh(this.geo, this.palmMat, 219);
        this.scene.add(this.mesh);
        this.setInstancedMeshPositions(this.mesh, this.palms);
    }
    addInstancedMeshVillage2() {
        // An InstancedMesh of 4 cubes
        this.meshVillage2 = new THREE.InstancedMesh(this.geo, this.palmMat, 200);
        this.scene.add(this.meshVillage2);
        this.setInstancedMeshPositions(this.meshVillage2, this.palmsV2);
    }
    addInstancedMeshVillage3() {
        // An InstancedMesh of 4 cubes
        this.meshVillage3 = new THREE.InstancedMesh(this.geo, this.palmMat, 216);
        this.scene.add(this.meshVillage3);
        this.setInstancedMeshPositions(this.meshVillage3, this.palmsV3);
    }
    addInstancedMeshBush() {
        this.meshBush = new THREE.InstancedMesh(this.geoBush, this.bushMat, this.bushes.length);
        this.scene.add(this.meshBush);
        this.setInstancedMeshBushPositions(this.meshBush);
    }
    addInstancedMeshBushVillage2() {
        this.meshBushVillage2 = new THREE.InstancedMesh(this.geoBush, this.bushMat, this.bushes.length5);
        this.scene.add(this.meshBushVillage2);
        this.setInstancedMeshBushPositions(this.meshBushVillage2);
    }
    addInstancedMeshBushVillage3() {
        this.meshBushVillage3 = new THREE.InstancedMesh(this.geoBush, this.bushMat, this.bushes.length);
        this.scene.add(this.meshBushVillage3);
        this.setInstancedMeshBushPositions(this.meshBushVillage3);
    }
    addInstancedMeshTree() {
        this.meshTree = new THREE.InstancedMesh(this.geoTree, this.treeMat, this.trees.length);
        this.scene.add(this.meshTree);
        this.setInstancedMeshTreesPositions(this.meshTree);
    }
    addInstancedMeshTreeVillage2() {
        this.meshTreeVillage2 = new THREE.InstancedMesh(this.geoTree, this.treeMat, this.trees.length);
        this.scene.add(this.meshTreeVillage2);
        this.setInstancedMeshTreesPositions(this.meshTreeVillage2);
    }
    addInstancedMeshTreeVillage3() {
        this.meshTreeVillage3 = new THREE.InstancedMesh(this.geoTree, this.treeMat, this.trees.length);
        this.scene.add(this.meshTreeVillage3);
        this.setInstancedMeshTreesPositions(this.meshTreeVillage3);
    }

    setInstancedMeshPositions(mesh, palmArray) {
        //   var precision = 5; // 2 decimals
        let j = mesh.count / 2 - 1;
        for (var i = 0; i < mesh.count; i++) {
            this.dummy.position.set(palmArray[i].position.x, palmArray[i].position.y - 2.2, palmArray[i].position.z);
            this.dummy.scale.set(palmArray[i].scale.x, palmArray[i].scale.y, palmArray[i].scale.z);
            this.dummy.quaternion.set(palmArray[i].rotation.x, palmArray[i].rotation.y, palmArray[i].rotation.z, palmArray[i].rotation.w);

            this.dummy.updateMatrix();
            mesh.setMatrixAt(i, this.dummy.matrix);
        }
        mesh.instanceMatrix.needsUpdate = true;
    }

    setInstancedMeshBushPositions(mesh) {
        for (var i = 0; i < mesh.count; i++) {
            this.dummy.position.set(this.bushes[i].position.x, this.bushes[i].position.y, this.bushes[i].position.z);
            //    this.dummy.rotation.set(this.bushes[i].rotation.x, this.bushes[i].rotation.y, this.bushes[i].rotation.z);
            this.dummy.scale.set(this.bushes[i].scale.x, this.bushes[i].scale.y, this.bushes[i].scale.z);
            this.dummy.quaternion.set(this.bushes[i].rotation.x, this.bushes[i].rotation.y, this.bushes[i].rotation.z, this.bushes[i].rotation.w);

            this.dummy.updateMatrix();


            mesh.setMatrixAt(i, this.dummy.matrix);
        }
        mesh.instanceMatrix.needsUpdate = true;
    }
    setInstancedMeshTreesPositions(mesh) {
        let j = mesh.count / 2 - 1;
        for (var i = 0; i < mesh.count; i++) {
            this.dummy.position.set(this.trees[i].position.x, this.trees[i].position.y, this.trees[i].position.z);
            this.dummy.scale.set(this.trees[i].scale.x, this.trees[i].scale.y, this.trees[i].scale.z);
            this.dummy.quaternion.set(this.trees[i].rotation.x, this.trees[i].rotation.y, this.trees[i].rotation.z, this.trees[i].rotation.w);

            this.dummy.updateMatrix();
            mesh.setMatrixAt(i, this.dummy.matrix);
        }
        mesh.instanceMatrix.needsUpdate = true;
    }


    async init(scene, camera, geo, bushes, geoBush, trees, geoTree) {
        this.scene = scene
        this.camera = camera

        this.palms = palms;

        let bushTexture = await loadTexture('../models/chapter2/scene/mats/bush.png')
        this.bushes = bushes;
        this.geoBush = geoBush;
        this.bushMat.map = bushTexture;

        let treeTexture = await loadTexture('../models/chapter2/scene/mats/tree.png')
        this.treeMat.map = treeTexture;
        this.trees = trees;
        this.geoTree = geoTree;

        this.palmTexture = await loadTexture('../models/chapter2/scene/mats/palm.png')
        this.geo = geo;
        this.palmMat.map = this.palmTexture;


        this.addInstancedMesh();



        if (!isMobile()) {
            this.addInstancedMeshBush();
            this.addInstancedMeshTree();
        }
    }

    async init2(scene, camera, geo, bushes, geoBush, trees, geoTree) {
        this.scene = scene
        this.camera = camera

        this.palmsV2 = palmsV2;

        let bushTexture = await loadTexture('../models/chapter2/scene/mats/bush.png')
        this.bushes = bushes;
        this.geoBush = geoBush;
        this.bushMat.map = bushTexture;

        let treeTexture = await loadTexture('../models/chapter2/scene/mats/tree.png')
        this.treeMat.map = treeTexture;
        this.trees = trees;
        this.geoTree = geoTree;

        this.palmTexture = await loadTexture('../models/chapter2/scene/mats/palm.png')
        this.geo = geo;
        this.palmMat.map = this.palmTexture;



        this.treeMat = await loadTexture('../models/chapter2/scene/mats/palm.png')
        this.geo = geo;// gltf.scene.children[0].children[0].geometry;

        this.addInstancedMeshVillage2();

        if (!isMobile()) {
            this.addInstancedMeshBushVillage2();
            this.addInstancedMeshTreeVillage2();
        }

    }

    async init3(scene, camera, geo, bushes, geoBush, trees, geoTree) {
        this.scene = scene
        this.camera = camera

        this.palmsV3 = palmsV3;


        let bushTexture = await loadTexture('../models/chapter2/scene/mats/bush.png')
        this.bushes = bushes;
        this.geoBush = geoBush;
        this.bushMat.map = bushTexture;

        let treeTexture = await loadTexture('../models/chapter2/scene/mats/tree.png')
        this.treeMat.map = treeTexture;
        this.trees = trees;
        this.geoTree = geoTree;

        this.palmTexture = await loadTexture('../models/chapter2/scene/mats/palm.png')
        this.geo = geo;
        this.palmMat.map = this.palmTexture;

        this.addInstancedMeshVillage3();

        if (!isMobile()) {
            this.addInstancedMeshBushVillage3();
        }
    }


    update(camera) {

    }

    bind() {
        this.init = this.init.bind(this)
    }


}

const _instance = new TreesScene()
export default _instance