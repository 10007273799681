<template>
<metainfo>
    <template v-slot:title="{ content }">{{ content}}</template>
  </metainfo>
<Header theme='light' />
<Chapter2Scene />

<div class="screen-orientation" id="screenOrientation">
<div class="screen-orientation-container">
<div class="screen-icon"><img src="../assets/rotate-device.svg" /></div>
<div class="message">الرجاء إعادة الشاشة للوضع العامودي</div>
</div>
</div>
</template>

<script>
import { useMeta } from 'vue-meta'
import Header from '../components/Header.vue'
import Chapter2Scene from "../components/Chapter2Scene.vue";

  export default {
    name: 'Chapter1Component',
    components:{
      Chapter2Scene,
      Header
    },
         setup () {
    useMeta({
      title: 'كن جزءًا من قصة يوم التأسيس | تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز',
       og:{
            title: 'كن جزءًا من قصة يوم التأسيس | تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز',
            description:"الفصل الثاني: مـانـع بـن ربـيـعـة المـريـدي  كلمات، كانت نداءً من ابن درع، لابن عمه مانع يخبره بأن أرضه تنتظره",
            image:"https://foundingday.antame.org/assets/chapter2-thumbnail.jpg"
        },
            twitter:{
          site: "@Darahfoundation",
            creator:"@Darahfoundation",
            card:'summary_large_image',
            site_name:'تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز'
        },
      meta: [
        ...( [
          {
            vmid: "description",
            name: "description",
            content:"الفصل الثاني: مـانـع بـن ربـيـعـة المـريـدي  كلمات، كانت نداءً من ابن درع، لابن عمه مانع يخبره بأن أرضه تنتظره",
          },

          {
            vmid: "image",
            name: "image",
            content:"https://foundingday.antame.org/assets/chapter2-thumbnail.jpg",
          },
        ]
        ),

      ],
    })
  },
  }
</script>



<style lang="scss">

</style>



