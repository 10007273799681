import emitter from 'tiny-emitter/instance';
export let activeHotspot = 10;
export const setActiveHotspot = (number) => {
    activeHotspot = number;
    emitter.emit('caption', activeHotspot)
}
export const getActiveHotspot = () => {

    return activeHotspot;
}

export const hotspots = [
    {
        position: { x: 295.042, y: 250.113, z: 640 },
        scale: { x: 1, y: 1, z: 1 },
        rotation: { x: 0.000, y: 9.000, z: 0.000 },
        info: {
            image: '/assets/chapter3-popup1.jpg',
            title: '',
            description: 'تقــع الدرعية عاصمة الدولة السعودية الأولى في وســط الجزيــرة العربيــة، وتحديــداً فـي إقليـم اليمامـة الـذي يحتـل مسـاحة كبيـرة مـن الجزيـرة العربيـة، حيـث يبلـغ طولـه مـن الشـمال إلـى الجنـوب مـا يزيـد علـى 1000 كـم وعرضه 500 كـم، وتتوسـطه سلسلة جبـال طويـق الشهيرة التـى تغنـى بهــا الشعـراء. '
        }
    },
    {
        position: { x: 270.746, y: 273.000, z: 688.931 },
        rotation: { x: 0.000, y: 9.000, z: 0.000 },
        scale: { x: 1, y: 1, z: 1 },
        info: {
            image: '/assets/chapter3-popup2.png',
            title: '',
            description: 'تولى الإمـام محمــد بـن سـعود الحكــم في أوضاع استثنائية في منتصــف عـام 1139هــ (فبرايـر 1727م)؛ فقـد عانـت الدرعيـة قبيـل توليـه الحكـم من ضعف وانقسـام لأسـبابمتعـددة،منهـاالنـزاعالداخـليعـلىإمـارةالدرعيـةبينعّمـهالأمـيرمقرنبن محمـد والأمـير زيـد بـن مرخـان، وكذلـك حملـة الدرعيـة عـلى العيينـة، ومقتـل الأمـير زيـد بـن مرخـان، ومنهـا كذلـك انتشـار مـرض الطاعـون في جزيـرة العـرب خـلال تلـك الفـترة وتسـببه في وفـاة أعـداد كبـيرة مـن النـاس. ومـع كل هـذه التحديـات اسـتطاع الإمـام محمـد بـن سـعود أن يتغلـب عليهـا وأن يتخطاهـا وي ّوحـد الدرعية، وأن يسهم في نـشر الاستقرار في منطقـة العـارض. '
        }
    },
    {
        position: { x: -485.414, y: 320.715, z: 703.665 },
        scale: { x: 5, y: 5, z: 5 },
        rotation: { x: 0.000, y: 1.7, z: 0.000 },
        info: {
            image: '/assets/chapter3-popup3.jpg',
            title: '',
            description: 'فارس من فرسان الدولة السعودية الأولى من رسم الفنان الفرنسي'
        }
    },

    {
        position: { x: 94.132, y: 288.372, z: 900.870 },
        scale: { x: 5.000, y: 5.000, z: 5.000 },
        rotation: { x: 0.000, y: 4.5, z: 0.000 },
        info: {
            image: '',
            title: '',
            description: 'بتأسيس الإمام محمد بن ســـعود الدولة الســـعودية الأولى أصبح هـــذا الطريق مـــن أبــرز الطرق التـــي تمر بهـا القوافل التجـــارة و الحـــج، نتيجة لسياسة الإمام محمـــد ابن ســـعود بتأمين هذا الطريـــق والارتباط بعلاقات مع القبائـل التي يمر مـــن خـــلال مناطقها، والاتفـــاق معها عـــلى ضبط الأمـــن وتقديم الخدمـــة اللازمة للمستفيدين منه. '
        }
    }
]