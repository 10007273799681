import * as THREE from 'three';
import { loadGLTF, loadTexture, loadAudio } from "../../utils/asyncLoaders";
import gsap from "gsap";
import SandFrag from '../../shaders/Sand/simple.frag';
import SanddVert from '../../shaders/Sand/simple.vert';
import TreesScene from './TreesScene';
import emitter from 'tiny-emitter/instance';
import { clamp } from 'three/src/math/MathUtils';
import { hotspots, setActiveHotspot } from './HotspotsPositions';
import { isMobile } from '../../utils/device';
//import BackgroundScene from './BackgroundScene'
class DesertScene {
    constructor() {
        this.bind();
        this.pause = true;
        this.cameraAnimationMixers = []
        this.obeidAnimationMixer;
        this.sheperdAnimationMixer;
        this.obeidLastAnimationMixer;

        this.stats;
        this.fpsSpeed = 60;
        this.gltfScene;
        this.count = 1;
        this.sceneLineMesh;
        this.sceneLineMesh2;
        this.lineMesh;
        this.lineMesh2;

        this.scene1_obeid;
        this.phase = 0;
        this.phase1Group = [];
        this.phase2Group = [];
        this.phase3Group = [];
        this.phase4Group = [];
        this.phase5Group = [];
        this.phase3AudioPlayed = false;

        this.phase1Mixer;
        this.phase2Mixer;
        this.phase3Mixer;
        this.phase4Mixer;
        this.phase5Mixer;
        this.phase6Mixer;

        this.mixer;
        this.activeAction;
        this.cameraPos;
        this.camera;
        this.renderer;

        this.wheelSpeed = 0.02;
        this.maxDist = 2.0;
        this.minDist = 1.0;
        this.dist = 2;
        this.startDragScroll = 0

        this.modelReady = false
        this.animationActions = []
        this.activeAction;
        this.lastAction;
        this.animationsFolder;

        this.absScroll = 0;
        this.smoothProgress = 0;

        this.lastTime = 0;

        this.SCROLL_SCALAR_MOBILE = 0.01;
        this.MAX_SCROLL_TIME_DELTA = 20;
        this.SCROLL_SCALAR = 0.01;
        this.MAX_SCROLL_DELTA = 5;
        this.transitioning = false;

        this.playPhase1 = false;
        this.playPhase2 = false;
        this.playPhase3 = false;
        this.playPhase4 = false;
        this.playPhase5 = false;
        this.playPhase5 = false;

        this.audioListener = new THREE.AudioListener();
        this.phase1Audio = new THREE.Audio(this.audioListener);
        this.phase2Audio = new THREE.Audio(this.audioListener);
        this.phase3Audio = new THREE.Audio(this.audioListener);
        this.phase4Audio = new THREE.Audio(this.audioListener);
        this.phase4_1Audio = new THREE.Audio(this.audioListener);
        this.phase5Audio = new THREE.Audio(this.audioListener);
        this.phase6Audio = new THREE.Audio(this.audioListener);
        this.bgAudio = new THREE.Audio(this.audioListener);
        this.footstepAudio = new THREE.Audio(this.audioListener);

        this.getOffHorseAudio = new THREE.Audio(this.audioListener);
        this.swordAudio = new THREE.Audio(this.audioListener);
        this.pickDateUp = new THREE.Audio(this.audioListener);
        this.desertWindAudio = new THREE.Audio(this.audioListener);


        this.animationSpeed = 1;

        this.hotspots = []
        this.delta = new THREE.Vector2();
        this.lastInputPosition = new THREE.Vector2(0, 0);

        this.pointer = new THREE.Vector2();
        this.pointerCopy = new THREE.Vector2();
        this.selectedOnDown = null;

        this.textures;

        this.speedPhase1 = 0.0084;
        this.speedPhase2 = 0.006;
        this.speedPhase4 = 0.003;
        this.speedPhase5 = 0.004;
        this.speedPhase6 = 0.003;


        this.voiceVolume = 0;
        this.bgVolume = 0;
        this.footstepVolume = 0;
        this.desertWindVolume = 0;


        this.phase3Visited = false;
        this.sheperd;
        this.sheperd2;
        this.sheperd3;


        //intersected objects
        this.tents
        this.convoy
        this.bush6_1;
        this.village;
        this.raycaster;

        console.log('v2')

        emitter.on('pauseScene', (isPaused) => {
            if (isPaused) {
                this.pauseScene()
            }
            else {
                this.resumeScene()
            }
        })
        emitter.on('startExperience', () => {
            document.getElementById('actionIntro').classList.remove('show');
            this.phase = 1;
            document.getElementById('scrollAction').classList.add('show')

            this.phase1Audio.play();
            this.setUpActions(1)
        })

        emitter.on('updateAudio', (isAudioOn) => {

            if (isAudioOn) {
                this.voiceVolume = 0.5;
                this.bgVolume = 0.1;
                this.footstepVolume = 1;
                this.desertWindVolume = 0.6;

                this.bgAudio.setVolume(0.1);
                this.phase1Audio.setVolume(0.5)
                this.phase2Audio.setVolume(0.5)
                this.phase3Audio.setVolume(0.5)
                this.phase4Audio.setVolume(0.5)
                this.phase4_1Audio.setVolume(0.5)
                this.phase5Audio.setVolume(0.5)
                this.phase6Audio.setVolume(0.5)
                this.footstepAudio.setVolume(1)
                this.desertWindAudio.setVolume(0.6)

            }
            else {
                this.voiceVolume = 0;
                this.bgVolume = 0;
                this.footstepVolume = 0;
                this.desertWindVolume = 0;

                this.bgAudio.setVolume(0);
                this.phase1Audio.setVolume(0)
                this.phase2Audio.setVolume(0)
                this.phase3Audio.setVolume(0)
                this.phase4Audio.setVolume(0)
                this.phase4_1Audio.setVolume(0)
                this.phase5Audio.setVolume(0)
                this.phase6Audio.setVolume(0)
                this.footstepAudio.setVolume(0)
                this.desertWindAudio.setVolume(0)
            }
        });
    }




    async init(scene, camera, renderer, stats) {


        this.stats = stats;
        this.scene = scene
        this.camera = camera
        this.renderer = renderer

        this.raycaster = new THREE.Raycaster()
        this.raycaster.near = 80


        var audiosToBeLoaded = [
            loadAudio('../models/chapter1/audio/bg.mp3'),
            loadAudio('../models/chapter1/audio/phase1.mp3'),
            loadAudio('../models/chapter1/audio/phase2.mp3'),
            loadAudio('../models/chapter1/audio/phase3.mp3'),
            loadAudio('../models/chapter1/audio/phase4.mp3'),
            loadAudio('../models/chapter1/audio/phase5.mp3'),
            loadAudio('../models/chapter1/audio/phase6.mp3'),
            loadAudio('../models/chapter1/audio/getOffHorse.mp3'),
            loadAudio('../models/chapter1/audio/SwordPlaceInSand.mp3'),
            loadAudio('../models/chapter1/audio/PickUpandWalkAway.mp3'),
            loadAudio('../models/chapter1/audio/DesertWind.mp3'),
            loadAudio('../models/chapter1/audio/foosteps.mp3'),
            loadAudio('../models/chapter1/audio/phase4_1.mp3'),


        ]
        let audios = await Promise.all(audiosToBeLoaded);
        this.setUpAudio(audios);

        var texturesToBeLoaded = [
            loadTexture('../models/chapter1/scene/mats/dunes-diffuse.jpg'),
            loadTexture('../models/chapter1/scene/mats/walls-houses4.jpg'),
            loadTexture('../models/chapter1/scene/mats/tent-side3.png'),
            loadTexture('../models/chapter1/scene/mats/tent-perspective2.png'),
            loadTexture('../models/chapter1/scene/mats/tent-front2.png'),
            loadTexture('../models/chapter1/scene/mats/back-rocks.png'),
            loadTexture('../models/chapter1/scene/mats/convoy.png'),
            loadTexture('../models/chapter1/scene/mats/palm.png'),
            loadTexture('../models/chapter1/scene/mats/camel1_1.png'),
            loadTexture('../models/chapter1/scene/mats/camel2_1.png'),
            loadTexture('../models/chapter1/scene/mats/shep1_1.png'),
            loadTexture('../models/chapter1/scene/mats/shep2_2.png'),
            loadTexture('../models/chapter1/scene/mats/bush.png'),
            loadTexture('../models/chapter1/scene/mats/tree.png'),
            // loadTexture('../models/chapter1/scene/mats/Horse_Mount.png'),
            loadTexture('../models/chapter1/scene/mats/sheep1_1.png'),
            loadTexture('../models/chapter1/scene/mats/sheep2_1.png'),
            loadTexture('../models/chapter1/scene/mats/sheep3_1.png'),
            loadTexture('../models/chapter1/scene/mats/desert-land4.jpg'),
            loadTexture('../models/chapter1/scene/mats/rock.jpg'),
            loadTexture('../models/chapter1/scene/mats/Horse_Hair.png'),
            loadTexture('../models/chapter1/scene/mats/Horse_Mount_mod.jpg'),
            loadTexture('../models/chapter1/scene/mats/obeid_last_scene_main_texture.jpg'),
            loadTexture('../models/chapter1/scene/mats/obeid_scene1_main_texture.jpg'),
            loadTexture('../models/chapter1/scene/mats/sheperd_main_texture.jpg'),
            loadTexture('../models/chapter1/scene/mats/spearSurface_Color.jpg'),
            loadTexture('../models/chapter1/scene/mats/dateSurface_Color.jpg'),
            loadTexture('../models/chapter1/scene/mats/dateSurface_Color_1.jpg'),
            loadTexture('../models/chapter1/scene/mats/date.jpg'),
            loadTexture('../models/chapter1/scene/mats/walls-houses2.jpg'),
            loadTexture('../models/chapter1/scene/mats/upwind.png'),
            loadTexture('../models/chapter1/scene/mats/detail.png'),
            loadTexture('../models/chapter1/scene/mats/dash.png'),
            loadTexture('../models/chapter1/scene/mats/line.jpg'),
            loadTexture('../models/chapter1/scene/mats/houses-01.jpg'),
            loadTexture('../models/chapter1/scene/mats/convoy_last.png'),
            loadTexture('../models/chapter1/scene/mats/Horse_fur.jpg'),
        ]
        this.textures = await Promise.all(texturesToBeLoaded);

        let modelUrl = isMobile() ? "../models/chapter1/scene/scene_mobile-baked.glb" : "../models/chapter1/scene/scene2-baked.glb"
        var gltf = await loadGLTF(modelUrl);
        this.gltfScene = gltf.scene;
        this.setUpCamera(gltf)
        //   this.setUpCharactersAnimation(gltf)

        if (isMobile()) {
            this.loadMobileCharacters(this.textures)
        }
        else {
            this.loadCharacters(this.textures)
        }

        await this.setUpTextures(gltf, this.textures);
        await this.addLine(this.textures);
        await this.addLine2(this.textures);
        await TreesScene.init(this.scene, this.camera);
        //   this.addSprite()


        if (isMobile()) {
            window.addEventListener('touchmove', this.handleTouchMove.bind(this), false);
            // window.addEventListener('touchstart', this.handleTouchStart.bind(this), false);
            // window.addEventListener('touchend', this.handleTouchEnd.bind(this), false);
            // window.addEventListener('touchcancel', this.handleTouchEnd.bind(this), false);
        }
        else {


            window.addEventListener("wheel", this.onWheel.bind(this), false);

        }

        // window.addEventListener('mousedown', this.handleMouseDown.bind(this), true);
        // window.addEventListener('mousemove', this.handleMouseMove.bind(this), true);
        // window.addEventListener('mouseup', this.handleMouseUp.bind(this), false);



    }

    onWheel(e) {
        if (this.lastTime) {
            const timeDelta = Date.now() - this.lastTime;
            if (timeDelta < this.MAX_SCROLL_TIME_DELTA) return;
        }
        this.lastTime = Date.now();

        if (this.transitioning) return;
        const d = clamp(e.deltaY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);

        this.handleScroll(d * this.SCROLL_SCALAR)

    }

    setUpAudio(audios) {

        this.bgAudio.setBuffer(audios[0]);
        this.bgAudio.setLoop(true);
        this.bgAudio.setVolume(0.1);
        this.bgAudio.pause();

        this.phase1Audio.setBuffer(audios[1]);
        this.phase1Audio.setLoop(false);
        this.phase1Audio.setPlaybackRate(1.1)
        this.phase1Audio.setVolume(this.voiceVolume);
        this.phase1Audio.pause();

        this.phase2Audio.setBuffer(audios[2]);
        this.phase2Audio.setPlaybackRate(1.1)
        this.phase2Audio.setLoop(false);
        this.phase2Audio.setVolume(this.voiceVolume);
        this.phase2Audio.pause();

        this.phase3Audio.setBuffer(audios[3]);
        this.phase3Audio.setPlaybackRate(1.1)
        this.phase3Audio.setLoop(false);
        this.phase3Audio.setVolume(this.voiceVolume);
        this.phase3Audio.pause();
        this.phase3Audio.onEnded = () => {
            this.phase3AudioPlayed = true;
        }

        this.phase4Audio.setBuffer(audios[4]);
        this.phase4Audio.setPlaybackRate(1.1)
        this.phase4Audio.setLoop(false);
        this.phase4Audio.setVolume(this.voiceVolume);
        this.phase4Audio.pause();

        this.phase4_1Audio.setBuffer(audios[12]);
        this.phase4_1Audio.setPlaybackRate(1.1)
        this.phase4_1Audio.setLoop(false);
        this.phase4_1Audio.setVolume(this.voiceVolume);
        this.phase4_1Audio.pause();

        this.phase5Audio.setBuffer(audios[5]);
        this.phase5Audio.setPlaybackRate(1.1)
        this.phase5Audio.setLoop(false);
        this.phase5Audio.setVolume(this.voiceVolume);
        this.phase5Audio.pause();

        this.phase6Audio.setBuffer(audios[6]);
        this.phase6Audio.setPlaybackRate(1.1)
        this.phase6Audio.setLoop(false);
        this.phase6Audio.setVolume(this.voiceVolume);
        this.phase6Audio.pause();

        this.getOffHorseAudio.setBuffer(audios[7]);
        this.getOffHorseAudio.setLoop(false);
        this.getOffHorseAudio.setVolume(this.voiceVolume);
        this.getOffHorseAudio.pause();

        this.swordAudio.setBuffer(audios[8]);
        this.swordAudio.setLoop(false);
        this.swordAudio.setVolume(this.voiceVolume);
        this.swordAudio.pause();

        this.pickDateUp.setBuffer(audios[9]);
        this.pickDateUp.setLoop(false);
        this.pickDateUp.setVolume(this.voiceVolume);
        this.pickDateUp.pause();

        this.desertWindAudio.setBuffer(audios[10]);
        this.desertWindAudio.setLoop(true);
        this.desertWindAudio.setVolume(this.desertWindVolume);
        this.desertWindAudio.pause();


        this.footstepAudio.setBuffer(audios[11]);
        this.footstepAudio.setLoop(true);
        this.footstepAudio.setVolume(this.footstepVolume);
        this.footstepAudio.pause();
    }


    async loadMobileCharacters(textures) {

        var character1 = await loadGLTF("../models/chapter1/scene/character1-baked.glb");
        this.scene1_obeid = character1.scene;
        this.scene1_obeid.visible = false;

        this.scene1_obeid.traverse(t => {
            if (t.material && t.name.includes('HorseHair')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[19],
                    name: t.material.name,
                    color: new THREE.Color(0x000000)
                })

            }
            if (t.material && t.name.includes('Mount_')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[20],
                    name: t.material.name
                })

            }
            if (t.material && t.name.includes('Horse_LOD1')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[35],
                    name: t.material.name,
                    color: new THREE.Color(0x616263)
                })
            }
            if (t.name.includes('Horse_LOD2')) {
                t.visible = false;
            }
            if (t.material && (t.name.match('scene_1_obeid1') || t.name.match('obeid'))) {

                t.material = new THREE.MeshBasicMaterial({
                    map: textures[22],
                    name: t.material.name
                })
            }
        })

        var character1_1 = await loadGLTF("../models/chapter1/scene/character1-2-baked.glb");
        this.scene1_obeid2 = character1_1.scene;
        this.scene1_obeid2.visible = false;

        this.scene1_obeid2.traverse(t => {
            if (t.material && t.name.includes('HorseHair')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[19],
                    name: t.material.name,
                    color: new THREE.Color(0x000000)
                })

            }
            if (t.material && t.name.includes('Mount_')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[20],
                    name: t.material.name
                })

            }
            if (t.material && t.name.includes('Horse_LOD1')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[35],
                    name: t.material.name,
                    color: new THREE.Color(0x616263)
                })
            }
            if (t.name.includes('Horse_LOD2')) {
                t.visible = false;
            }
            if (t.material && (t.name.match('scene_1_obeid1') || t.name.match('obeid'))) {

                t.material = new THREE.MeshBasicMaterial({
                    map: textures[22],
                    name: t.material.name
                })
            }
        })
        this.obeidAnimationMixer = {
            time: 100
        }


        this.sheperdAnimationMixer = {
            time: 200
        }
        var character2 = await loadGLTF("../models/chapter1/scene/character2-1-baked.glb");
        let sheperd_dates = character2.scene;
        sheperd_dates.visible = false;
        this.phase3Group.push(sheperd_dates)
        this.sheperd = sheperd_dates
        character2.scene.traverse(t => {

            if (t.material && t.name.includes('sheperd')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[23],
                    name: t.material.name
                });

            }

            if (t.material && t.name.includes('date')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[27],
                    name: t.material.name
                });

            }
        })
        var character2_2 = await loadGLTF("../models/chapter1/scene/character2-2-baked.glb");
        let sheperd_dates2 = character2_2.scene;
        sheperd_dates2.visible = false;
        //   this.phase3Group.push(sheperd_dates2)
        this.sheperd2 = sheperd_dates2
        character2_2.scene.traverse(t => {

            if (t.material && t.name.includes('sheperd')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[23],
                    name: t.material.name
                });

            }

            if (t.material && t.name.includes('date')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[27],
                    name: t.material.name
                });

            }
        })
        var character2_3 = await loadGLTF("../models/chapter1/scene/character2-3-baked.glb");
        let sheperd_dates3 = character2_3.scene;
        sheperd_dates3.visible = false;
        // this.phase3Group.push(sheperd_dates3)
        this.sheperd3 = sheperd_dates3
        character2_3.scene.traverse(t => {

            if (t.material && t.name.includes('sheperd')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[23],
                    name: t.material.name
                });

            }

            if (t.material && t.name.includes('date')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[27],
                    name: t.material.name
                });

            }
        })


        var character3 = await loadGLTF("../models/chapter1/scene/character3_mobile-baked.glb");
        const obeidVillage = character3.scene;
        this.phase4Group.push(obeidVillage)

        obeidVillage.visible = false;
        this.obeidLastAnimationMixer = new THREE.AnimationMixer(character3.scene);
        let t3 = this.obeidLastAnimationMixer.clipAction(character3.animations[0]);
        t3.clampWhenFinished = true;
        t3.play();
        this.obeidLastAnimationMixer.update(92)

        obeidVillage.traverse(t => {
            if (t.material && t.name.includes('HorseHair')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[19],
                    name: t.material.name,
                    color: new THREE.Color(0x000000)
                })

            }
            if (t.material && t.name.includes('Mount_')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[20],
                    name: t.material.name
                })

            }
            if (t.material && t.name.includes('Horse_LOD1')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[35],
                    name: t.material.name,
                    color: new THREE.Color(0x616263)
                })
            }
            if (t.name.includes('Horse_LOD2')) {
                t.visible = false;
            }
            if (t.material && (t.name.match('scene_1_obeid1') || t.name.match('obeid'))) {

                t.material = new THREE.MeshBasicMaterial({
                    map: textures[22],
                    name: t.material.name
                })
            }
            if (t.material && t.name.includes('obeid_last_scene')) {

                t.material = new THREE.MeshBasicMaterial({
                    map: textures[21],
                    name: t.material.name
                })

            }
            if (t.material && t.name.includes('spear')) {
                t.material.map = textures[24];

            }
        })

        this.scene.add(character1.scene)
        this.scene.add(character1_1.scene)

        this.scene.add(character2.scene)
        this.scene.add(character2_2.scene)
        this.scene.add(character2_3.scene)
        // this.scene.add(character2.scene)
        this.scene.add(character3.scene)
        this.scene.updateMatrix();
        this.scene.updateMatrixWorld();

    }
    async loadCharacters(textures) {

        var character1 = await loadGLTF("../models/chapter1/scene/character11-baked.glb");
        this.scene1_obeid = character1.scene;
        this.scene1_obeid.visible = false;

        this.scene1_obeid.traverse(t => {
            if (t.material && t.name.includes('HorseHair')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[19],
                    name: t.material.name,
                    color: new THREE.Color(0x000000)
                })

            }
            if (t.material && t.name.includes('Mount_')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[20],
                    name: t.material.name
                })

            }
            if (t.material && t.name.includes('Horse_LOD1')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[35],
                    name: t.material.name,
                    color: new THREE.Color(0x616263)
                })
            }
            if (t.name.includes('Horse_LOD2')) {
                t.visible = false;
            }
            if (t.material && (t.name.match('scene_1_obeid1') || t.name.match('obeid'))) {

                t.material = new THREE.MeshBasicMaterial({
                    map: textures[22],
                    name: t.material.name
                })
            }
        })

        this.obeidAnimationMixer = new THREE.AnimationMixer(character1.scene);
        let t = this.obeidAnimationMixer.clipAction(character1.animations[0]);
        t.clampWhenFinished = true;
        t.play();
        this.obeidAnimationMixer.update(54)


        var character2 = await loadGLTF("../models/chapter1/scene/character2-baked.glb");
        let sheperd_dates = character2.scene;
        sheperd_dates.visible = false;
        this.phase3Group.push(sheperd_dates)

        this.sheperdAnimationMixer = new THREE.AnimationMixer(character2.scene);
        let t2 = this.sheperdAnimationMixer.clipAction(character2.animations[0]);
        t2.clampWhenFinished = true;
        t2.play();
        this.sheperdAnimationMixer.update(120)
        this.sheperd = sheperd_dates
        sheperd_dates.traverse(t => {

            if (t.material && t.name.includes('sheperd')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[23],
                    name: t.material.name
                });

            }

            if (t.material && t.name.includes('date')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[27],
                    name: t.material.name
                });

            }
            // if (t.material && (t.name.match('scene_1_obeid1') || t.name.match('obeid'))) {

            //     t.material = new THREE.MeshBasicMaterial({
            //         map: textures[22],
            //         name: t.material.name
            //     })
            // }
        })

        var character3 = await loadGLTF("../models/chapter1/scene/character3-baked.glb");
        const obeidVillage = character3.scene;
        this.phase4Group.push(obeidVillage)

        obeidVillage.visible = false;
        this.obeidLastAnimationMixer = new THREE.AnimationMixer(character3.scene);
        let t3 = this.obeidLastAnimationMixer.clipAction(character3.animations[0]);
        t3.clampWhenFinished = true;
        t3.play();
        this.obeidLastAnimationMixer.update(90)

        obeidVillage.traverse(t => {
            if (t.material && t.name.includes('HorseHair')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[19],
                    name: t.material.name,
                    color: new THREE.Color(0x000000)
                })

            }
            if (t.material && t.name.includes('Mount_')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[20],
                    name: t.material.name
                })

            }
            if (t.material && t.name.includes('Horse_LOD1')) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[35],
                    name: t.material.name,
                    color: new THREE.Color(0x616263)
                })
            }
            if (t.name.includes('Horse_LOD2')) {
                t.visible = false;
            }
            if (t.material && (t.name.match('scene_1_obeid1') || t.name.match('obeid'))) {

                t.material = new THREE.MeshBasicMaterial({
                    map: textures[22],
                    name: t.material.name
                })
            }
            if (t.material && t.name.includes('obeid_last_scene')) {

                t.material = new THREE.MeshBasicMaterial({
                    map: textures[21],
                    name: t.material.name
                })

            }
            if (t.material && t.name.includes('spear')) {
                t.material.map = textures[24];

            }
        })

        this.scene.add(character1.scene)
        this.scene.add(character2.scene)
        this.scene.add(character3.scene)
        this.scene.updateMatrix();
        this.scene.updateMatrixWorld();

    }
    async setUpTextures(gltf, textures) {
        this.tents = this.gltfScene.getObjectByName('tents');
        this.convoy = this.gltfScene.getObjectByName('convoy');
        this.bush6_1 = this.gltfScene.getObjectByName('bush6_1');



        //      const obeid = this.gltfScene.getObjectByName('scene_1_obeid1');
        const back_rocks = this.gltfScene.getObjectByName('back rocks');

        this.phase1Group.push(this.tents)
        this.phase1Group.push(this.convoy)
        // this.phase1Group.push(obeid)

        // this.scene1_obeid = obeid;
        // this.scene1_obeid.visible = false;

        const sherp = this.gltfScene.getObjectByName('shep');
        this.phase2Group.push(sherp)
        this.bush6_1 = this.gltfScene.getObjectByName('shep');

        const greenery = this.gltfScene.getObjectByName('greenery');
        //    const sheperd_dates = this.gltfScene.getObjectByName('sheperd_date1');
        const rocks = this.gltfScene.getObjectByName('rocks');
        //   const village = this.gltfScene.getObjectByName('village');
        this.village = this.gltfScene.getObjectByName('village');
        const convoy_last = this.gltfScene.getObjectByName('convoy2');

        //   this.phase3Group.push(greenery)
        //   this.phase3Group.push(sheperd_dates)
        this.phase3Group.push(rocks)
        this.phase3Group.push(this.village)
        //    this.phase5Group.push(sheperd_dates);

        // const obeidVillage = this.gltfScene.getObjectByName('obeid_last_scene1');
        // this.phase4Group.push(obeidVillage)

        greenery.visible = false;
        this.village.visible = false;

        this.phase3Group.forEach(t => {
            t.visible = false;
        })
        this.phase4Group.forEach(t => {
            t.visible = false;
        })


        let effectController2 = {

            uColorR: 182,// 176.3,  //20
            uColorG: 122,//137.635,  //0.035  //0.555,
            uColorB: 80//98.942, // 0.044
        };

        this.sceneCamera = gltf.cameras[0];
        this.sceneCamera1 = new THREE.Camera();
        await new Promise((resolve, reject) => {
            gltf.scene.traverse(t => {

                t.castShadow = false;
                t.receiveShadow = false;
                t.matrixAutoUpdate = false;

                if (t.isMesh) {
                    if (t.name == 'convoy_last') {
                        this.convoyLast = t;
                        this.convoyLast.visible = false;
                    }
                }

                // if (t.material && t.material.name.includes('tree')) {
                //     t.material.map = textures[13];
                //     t.side = THREE.DoubleSide
                //     t.material.depthWrite = false;
                //     t.material.transparent = true
                //     t.material.color.r = 0.800000011920929;
                //     t.material.color.g = 0.800000011920929;
                //     t.material.color.b = 0.800000011920929;
                //     t.material.depthWrite = false;

                //     t.updateMatrix();
                // }
                if (t.material && t.name.includes('bush')) {
                    //     t.visible=false;
                    t.castShadow = true
                    t.material.map = textures[12];
                    t.side = THREE.DoubleSide
                    t.material.depthWrite = false;
                    t.material.transparent = true
                    t.material.color.r = 0.800000011920929;
                    t.material.color.g = 0.800000011920929;
                    t.material.color.b = 0.800000011920929;
                    t.material.depthWrite = false;

                    t.updateMatrix();
                }
                if (t.material && (t.material.name.match('rocky desert') || t.material.name.match('desert land'))) {
                    //   t.material.map = textures[17];
                    //   t.material.color = new THREE.Color('#FFFFFF');
                    t.material = new THREE.MeshStandardMaterial({
                        transparent: true,
                        map: textures[17],
                        color: new THREE.Color('#636464')
                    })
                }
                if (t.material && t.material.name.match('desert mat')) {
                    this.duneMaterial = new THREE.ShaderMaterial({
                        uniforms: {
                            sTexture: { value: textures[0] },
                            //  sDust: { value: textures[3] },
                            sDetail1: { value: textures[30] },
                            uDustOpacity: { value: 0.0 },
                            uColor: { value: new THREE.Vector4(effectController2.uColorR / 255, effectController2.uColorG / 255, effectController2.uColorB / 255, 1.) },
                            sandColorIntensity: { value: new THREE.Vector4(1., 1., 1., 1.) },
                            fogDistance: { value: 12000. },
                            fogStartDistance: { value: 1000. },
                            detailDistance: { value: 45000 },
                            detailStartDistance: { value: 0 },
                        },
                        vertexShader: SanddVert,
                        fragmentShader: SandFrag,
                        transparent: false,
                        side: THREE.FrontSide,
                        receiveShadow: false,
                        castShadow: false
                    })
                    t.material = this.duneMaterial;


                }

                if (t.material && t.material.name.includes('tent side')) {
                    t.material.map = textures[2];
                    t.material.transparent = true
                    t.material.alphaTest = 0.5

                }
                if (t.material && t.material.name.includes('tent perspective')) {
                    t.material.map = textures[3];
                    t.material.transparent = true
                    t.material.alphaTest = 0.5
                }
                if (t.material && t.material.name.includes('tent front')) {
                    t.material.map = textures[4];
                    t.material.transparent = true
                    t.material.alphaTest = 0.5

                }
                if (t.material && t.material.name.match('shep')) {
                    t.material.map = textures[11];
                    t.material.transparent = true
                    t.updateMatrix();
                    t.material.metalness = 0.5;
                    t.position.y = t.position.y + 4
                }
                if (t.material && t.material.name.match('shep 2')) {
                    t.material.map = textures[10];
                    t.material.transparent = true
                    t.material.metalness = 0.5;
                    t.position.y = t.position.y + 4

                }

                if (t.material && t.name.match('sheep1')) {
                    t.material.map = textures[14];
                    t.material.transparent = true
                    t.material.metalness = 0.5;
                    t.position.y = t.position.y + 4
                }
                if (t.material && t.name.match('sheep2')) {
                    t.material.map = textures[15];
                    t.material.transparent = true
                    t.material.metalness = 0.5;
                    t.position.y = t.position.y + 4
                }
                if (t.material && t.name.match('sheep3')) {
                    t.material.map = textures[16];
                    t.material.transparent = true
                    t.material.metalness = 0.5;
                    t.position.y = t.position.y + 4
                    // t.material.depthTest = false;
                    // t.material.depthWrite = false;
                }

                if (t.material && t.material.name.match('camel 2')) {
                    t.material.map = textures[8];
                    t.material.transparent = true

                    t.material.metalness = 0.5;
                    t.position.y = t.position.y + 4
                }
                if (t.material && t.material.name.match('camel')) {
                    t.material.map = textures[9];
                    t.material.transparent = true
                    t.material.metalness = 0.5;
                    t.position.y = t.position.y + 4
                }
                if (t.material && t.material.name.match('rocks')) {
                    t.material.map = textures[18];
                    t.material.depthWrite = false;
                    t.material.transparent = true
                    t.receiveShadow = true;
                }

                if (t.material && t.name.includes('back_rocks')) {
                    t.material.map = textures[5];
                    t.material.transparent = true
                }
                if (t.material && t.name.match('convoy')) {
                    t.material.map = textures[6];
                    t.material.transparent = true
                }
                if (t.material && t.name.match('convoy_last')) {
                    t.material.map = textures[34];
                    //   t.material.depthWrite = false;
                    t.material.alphaTest = 0.800;
                }
                if (t.material && t.name.includes('HorseHair')) {
                    t.material = new THREE.MeshBasicMaterial({
                        map: textures[19],
                        name: t.material.name,
                        color: new THREE.Color(0x000000)
                    })
                    //  t.material.map = textures[19];
                    t.matrixAutoUpdate = true;
                    t.matrixWorldNeedsUpdate = true;
                    t.matrixAutoUpdate = true;
                    t.updateMatrixWorld()

                }
                if (t.material && t.name.includes('Mount_')) {
                    t.material = new THREE.MeshBasicMaterial({
                        map: textures[20],
                        name: t.material.name
                    })
                    //  t.material.map = textures[20];
                    t.matrixAutoUpdate = true;
                    t.matrixWorldNeedsUpdate = true;
                    t.matrixAutoUpdate = true;
                    t.updateMatrixWorld()

                }
                if (t.material && t.name.includes('Horse_LOD1')) {
                    t.material = new THREE.MeshBasicMaterial({
                        map: textures[35],
                        name: t.material.name,
                        color: new THREE.Color(0x616263)
                    })
                    //  t.material.map = textures[35];
                    t.matrixAutoUpdate = true;
                }
                if (t.name.includes('Horse_LOD2')) {
                    t.visible = false;
                }

                if (t.material && t.name.includes('sheperd')) {
                    t.material.map = textures[23];

                }

                if (t.material && t.name.includes('date')) {
                    t.visible = false;
                    t.material.map = textures[27];

                }
                if (t.material && t.name.match('date_strap_1')) {
                    t.material.map = textures[25];

                }
                if (t.material && t.name.includes('date_strap')) {
                    t.material.map = textures[26];

                }

                if (t.name.includes('Cube') || t.material && t.material.name.includes('door')) {
                    t.geometry.dispose();
                    t.material.dispose();
                    t.visible = false;
                }

                if (t.name.includes('buildings') && isMobile()) {
                    t.visible = false;
                    t.children.map(b => {
                        if (b.geometry)
                            b.geometry.dispose()
                        if (b.material)
                            b.material.dispose()
                    })
                }
                if (t.name.includes('buildings') || t.name.includes('Null')) {

                    t.children.map(b => {
                        if (b.material && b.material.name.includes('walls n houses')) {
                            b.material.map = textures[1];
                            b.material.metalness = 0;
                        }
                    })
                }
                t.updateMatrix();
            })
            resolve(true)
        })


        this.scene.add(gltf.scene)
        this.scene.updateMatrix();
        this.scene.updateMatrixWorld()
    }

    setUpCharactersAnimation(gltf) {
        let gltfAnimation = gltf.animations[0];
        let tracks = gltf.animations[0].tracks;

        let trackObeid = [tracks[3], tracks[4], tracks[5], tracks[6], tracks[7], tracks[8], tracks[9], tracks[10], tracks[11]];

        let animationCamera = gltfAnimation;
        animationCamera.tracks = trackObeid;
        this.obeidAnimationMixer = new THREE.AnimationMixer(gltf.scene);

        let t = this.obeidAnimationMixer.clipAction(animationCamera);
        t.clampWhenFinished = true;
        t.play();
        this.obeidAnimationMixer.update(16)

    }
    async setUpCamera(gltf) {

        loadGLTF('../models/chapter1/cams/cam2.glb').then(gltf => {
            let cameraMixerPhase2 = new THREE.AnimationMixer(this.camera);
            this.phase2Mixer = cameraMixerPhase2;

            let t2 = this.phase2Mixer.clipAction(gltf.animations[0]);
            t2.clampWhenFinished = true;
            t2.setLoop(THREE.LoopOnce);
            t2.play();
            this.phase2Mixer.update(0);
        })
        loadGLTF('../models/chapter1/cams/cam3.glb').then(gltf => {
            //   console.log('cameta3', gltf)
            this.sceneCamera = gltf.cameras[0]
            let cameraMixerPhase3 = new THREE.AnimationMixer(this.sceneCamera);
            this.phase3Mixer = cameraMixerPhase3;

            let t = this.phase3Mixer.clipAction(gltf.animations[0]);
            // t.clampWhenFinished = true;
            // t.setLoop(THREE.LoopOnce);
            this.phase3Mixer.update(0.2)
            t.play();
        })
        loadGLTF('../models/chapter1/cams/cam4.glb').then(gltf => {
            let cameraMixerPhase4 = new THREE.AnimationMixer(this.camera);
            this.phase4Mixer = cameraMixerPhase4;

            let t = this.phase4Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();
        })
        loadGLTF('../models/chapter1/cams/cam5.glb').then(gltf => {
            let cameraMixerPhase5 = new THREE.AnimationMixer(this.camera);
            this.phase5Mixer = cameraMixerPhase5;

            let t = this.phase5Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();
        })
        loadGLTF('../models/chapter1/cams/cam6.glb').then(gltf => {
            let cameraMixerPhase6 = new THREE.AnimationMixer(this.camera);
            this.phase6Mixer = cameraMixerPhase6;

            let t = this.phase6Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();
        })

        loadGLTF('../models/chapter1/cams/cam1.glb').then(gltf => {
            this.sceneCamera1 = new THREE.Camera()
            let cameraMixerPhase1 = new THREE.AnimationMixer(this.sceneCamera1);
            this.phase1Mixer = cameraMixerPhase1;
            let t = this.phase1Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            //   this.phase1Mixer.update(0.2)

            t.play();


            this.phase1Mixer.update(3)
            gsap.to(this.camera.position, { x: this.sceneCamera1.position.x, y: this.sceneCamera1.position.y, z: this.sceneCamera1.position.z, duration: 0 })
            gsap.to(this.camera.quaternion, { x: this.sceneCamera1.quaternion.x, y: this.sceneCamera1.quaternion.y, z: this.sceneCamera1.quaternion.z, w: this.sceneCamera1.quaternion.w, duration: 0 })

        })
    }


    setUpActions(phaseNumber) {
        switch (phaseNumber) {
            case 1:
                this.lineMesh2.visible = true;
                this.sceneLineMesh2.visible = true;
                break;
            case 2:

                document.getElementById('action1').classList.remove('show')
                this.phase = 2;
                //     BackgroundScene.updateVignette('dark')
                document.getElementsByClassName('story-container')[0].classList.add('border')
                this.playPhase2 = true;
                this.playPhase1 = false;
                this.scene1_obeid.visible = true;
                if (!isMobile())
                    this.playObeidAnimation = true;

                this.getOffHorseAudio.play()
                this.phase1Audio.pause();
                this.textures[6].dispose()
                this.textures[2].dispose()
                this.textures[3].dispose()
                this.textures[4].dispose()
                this.textures[5].dispose()

                if (isMobile()) {
                    this.scene1_obeid2.visible = true;
                    this.scene1_obeid.getObjectByName('Obeid').visible = false;
                }


                //  this.phase3Audio.play();

                setTimeout(() => {
                    this.phase2Audio.play();
                }, 3000)
                break;

            case 3:
                //   BackgroundScene.updateVignette('light')
                document.getElementsByClassName('story-container')[0].classList.remove('border')
                // document.getElementById('action2').classList.remove('show')
                this.phase = 3;
                this.playPhase2 = false;
                this.playPhase3 = false;

                this.lineMesh.visible = true;
                this.sceneLineMesh.visible = true;

                if (isMobile()) {
                    this.scene1_obeid2.visible = false;
                    this.scene1_obeid.visible = false;
                }

                this.textures[8].dispose()
                this.textures[9].dispose()
                this.textures[10].dispose()
                this.textures[11].dispose()
                this.textures[14].dispose()
                this.textures[15].dispose()
                this.textures[16].dispose()

                this.phase3Group.forEach(t => {
                    setTimeout(() => {
                        t.visible = true;
                    }, 200)

                })
                this.phase1Group.forEach(t => {
                    t.visible = false;
                    //this.removeObject3D(t)
                    this.renderer.dispose()
                })
                // setTimeout(() => {
                //     this.cameraAnimationMixers[0].uncacheRoot(this.camera)
                // }, 1000)
                break;
            case 4:
                //   BackgroundScene.updateVignette('dark')
                document.getElementsByClassName('story-container')[0].classList.add('border')
                document.getElementById('action3').classList.remove('show')

                this.phase = 4;
                this.playPhase4 = true;
                this.playPhase3 = false;
                this.playPhase2 = false;
                this.phase3Audio.pause();
                this.phase4Audio.play();

                this.lineMesh.visible = false;
                this.sceneLineMesh.visible = false;

                this.phase2Group.forEach(t => {
                    if (t && t.visible)
                        t.visible = false;

                    this.removeObject3D(t)
                    this.renderer.dispose()

                })
                // setTimeout(() => {
                //     this.phase2Mixer.uncacheRoot(this.camera)
                // }, 1000)

                break;
            case 5:
                this.phase = 5;
                document.getElementById('action4').classList.remove('show')
                if (isMobile()) {
                    this.sheperd.visible = false;
                    this.sheperd2.visible = true;


                    setTimeout(() => {
                        this.sheperd.visible = false;
                        this.sheperd2.visible = false;
                        this.sheperd3.visible = true;

                        this.playPhase5 = true;
                        this.playPhase4 = false;

                    }, 500)
                }
                else {
                    this.playPhase5 = true;
                    this.playPhase4 = false;
                    this.sheperdAnimation = true;
                }

                this.pickDateUp.play()
                this.phase4Audio.pause();
                this.phase5Audio.play();

                this.phase4Group.forEach(t => {
                    t.visible = true;
                })
                this.phase2Group.forEach(t => {
                    t.visible = false;
                    this.removeObject3D(t)
                    this.renderer.dispose()
                })

                break;

            case 6:

                this.sheperd.visible = false;

                if (isMobile()) {
                    this.sheperd2.visible = false;
                    this.sheperd3.visible = false;

                }

                document.getElementById('action5').classList.remove('show')
                this.phase = 6;
                this.playPhase6 = true;
                this.playPhase5 = false;
                this.obeidLastAnimation = true;
                this.sheperd.visible = false;
                this.swordAudio.play()

                this.phase5Audio.pause();
                this.phase6Audio.play();

                this.phase5Group.forEach(t => {
                    t.visible = false;
                })
                // setTimeout(() => {
                //     this.phase4Mixer.uncacheRoot(this.camera)
                // }, 1000)
                this.renderer.dispose()
                break;


        }

    }


    checkFrustum() {

        this.raycaster.setFromCamera(new THREE.Vector2(), this.camera);
        const intersections = this.raycaster.intersectObjects([this.tents, this.convoy, this.bush6_1, this.village]);
        if (intersections[0]) {
            //    console.log(intersections[0].object.name)
            if (intersections[0].object.name.includes('convoy')) {
                //    console.log('show1')
                setActiveHotspot(0);
            }
            else if (intersections[0].object.name.includes('tent')) {
                //   console.log('show2')
                setActiveHotspot(1);
            }
            else if (intersections[0].object.name.includes('bush') && this.phase == 3) {
                setActiveHotspot(2);
            }
            // else if (intersections[0].object.name.includes('Plane_12')) {
            //     setActiveHotspot(2);
            // }
        }
        else {
            setActiveHotspot(-1);
        }

    }


    update(camera) {

        if (!this.pause) {
            //if (this.camera)
            this.checkFrustum()
            //   this.updateInputOverInteractiveObjects();
            // if (this.duneMaterial) {
            //     this.duneMaterial.uniforms.uTime.value = this.clock.getElapsedTime();
            // }
            if (this.playObeidAnimation) {

                if (this.obeidAnimationMixer.time < 59) {
                    this.scene.updateMatrixWorld()
                    this.obeidAnimationMixer.update(0.02)
                }
            }
            else if (this.sheperdAnimation) {
                if (this.sheperdAnimationMixer.time < 130) {
                    this.scene.updateMatrixWorld()
                    this.sheperdAnimationMixer.update(0.06)
                }
            }
            else if (this.obeidLastAnimation) {
                if (this.obeidLastAnimationMixer.time < 98) {
                    this.scene.updateMatrixWorld()
                    this.obeidLastAnimationMixer.update(0.06)
                }
            }

            if (this.playPhase1) {

                if (this.phase == 1) {
                    this.setUpActions(1)
                    // if (this.cameraAnimationMixers[0].time >= 0.1 && this.cameraAnimationMixers[0].time <= 18) {
                    //     this.cameraAnimationMixers[0].update(this.speedPhase1);
                    // }

                    // if (this.cameraAnimationMixers[0].time > 18) {
                    //     document.getElementById('action1').classList.add('show')
                    // }



                    // if (this.cameraAnimationMixers[0].time > 15) {
                    //     if (isMobile()) {
                    //         this.scene1_obeid.visible = true;
                    //     }
                    //     else {
                    //         if (this.obeidAnimationMixer.time <= 57) {
                    //             this.scene1_obeid.visible = true;
                    //             this.obeidAnimationMixer.update(0.01)
                    //             this.scene.updateMatrixWorld()
                    //         }
                    //     }
                    // }

                }
            }
            else if (this.playPhase2) {
                if (this.phase == 2) {
                    if (this.obeidAnimationMixer.time >= 59) {
                        this.playObeidAnimation = false;
                        if (this.phase2Mixer.time < 0) {
                            //    this.phase2Mixer.setTime(0.1)
                            this.phase2Mixer.update(0)
                        }
                        if (this.phase2Mixer.time > 4.35) {
                            this.phase2Mixer.setTime(4.35)
                            //  this.phase2Mixer.setTime(4.4)
                            document.getElementById('action2').classList.add('show')
                            this.setUpActions(3)
                        }
                        if (this.phase2Mixer.time >= 0 && this.phase2Mixer.time <= 4.35) {
                            this.phase2Mixer.update((this.speedPhase2));
                            //   this.phase2Mixer.update((0.006));

                        }



                    }
                }
            }

            else if (this.playPhase4 && this.phase == 4) {
                if (this.phase4Mixer.time >= 0 && this.phase4Mixer.time <= 2.5) {
                    //     this.phase4Mixer.update((0.01));
                    this.phase4Mixer.update((this.speedPhase4));


                }
                if (this.phase4Mixer.time < 0.1) {
                    //     this.phase4Mixer.update(0.1)
                    this.phase4Mixer.update(0.8)
                }
                if (this.phase4Mixer.time > 2.5) {
                    this.phase4Mixer.update(2.5);
                    document.getElementById('action4').classList.add('show')
                    emitter.emit('animateKnob4', true)
                }

            }
            else if (this.playPhase5 && this.phase == 5) {
                if (this.sheperdAnimationMixer.time >= 130) {
                    this.sheperdAnimation = false;

                    if (this.phase5Mixer.time >= 0 && this.phase5Mixer.time <= 2) {
                        this.phase5Mixer.update((this.speedPhase5));
                        //    this.phase5Mixer.update((0.01));

                    }
                    if (this.phase5Mixer.time < 0.1) {
                        this.phase5Mixer.update(0.4)
                    }
                    if (this.phase5Mixer.time > 2) {
                        this.phase5Mixer.setTime(2);
                        document.getElementById('action5').classList.add('show')
                        emitter.emit('animateKnob5', true)
                    }
                }
            }
            else if (this.playPhase6 && this.phase == 6) {
                if (this.obeidLastAnimationMixer.time >= 98) {
                    this.obeidLastAnimation = false;
                    if (this.phase6Mixer.time >= 0 && this.phase6Mixer.time <= 2.5) {
                        this.phase6Mixer.update((this.speedPhase6));
                        //    this.phase6Mixer.update((0.01));
                    }
                    if (this.phase6Mixer.time < 0.1) {
                        this.phase6Mixer.setTime(0.1)
                    }
                    if (this.phase6Mixer.time > 2.5) {
                        this.phase6Mixer.setTime(2.5);

                        emitter.emit('chapterDone', true)
                    }
                    this.convoyLast.visible = true;
                }

            }
        }

    }



    bind() {
        this.init = this.init.bind(this)
    }

    async addLine(textures) {
        var lineGltf = await loadGLTF("../models/chapter1/line3.glb");
        //  this.loader.load('models/chapter1/line3.glb', (gltf) => {

        this.lineMesh = lineGltf.scene.children[0];
        //   console.log('line444', lineGltf.animations[0])

        //    this.lineMesh.material.map = this.texturesList[38]

        let lineGeo = new THREE.BufferGeometry();
        lineGeo.morphTargetsRelative = true;
        lineGeo.morphAttributes = this.lineMesh.geometry.morphAttributes;
        lineGeo.copy(this.lineMesh.geometry)
        lineGeo.setAttribute('position', this.lineMesh.geometry.attributes.position);
        lineGeo.computeBoundingSphere();
        //     this.lineMesh.material.color.setHex(0x563A2D);
        this.lineMesh.material.color.setHex(0x745246);
        //  #745246
        let lineMat = this.lineMesh.material.clone();
        lineMat.map = textures[31]
        //    lineGeo.setDrawRange(0, 0);
        //



        //   this.lineMesh.material.map = this.texturesList[38].texture

        this.sceneLineMesh = new THREE.Mesh(lineGeo, lineMat)

        this.lineMesh.material.map = textures[32]
        this.sceneLineMesh.position.set(this.lineMesh.position.x, this.lineMesh.position.y, this.lineMesh.position.z)
        this.sceneLineMesh.rotation.set(this.lineMesh.rotation.x, this.lineMesh.rotation.y, this.lineMesh.rotation.z)
        this.sceneLineMesh.geometry.attributes.position.needsUpdate = true;

        this.scene.add(this.sceneLineMesh)
        this.scene.add(this.lineMesh)


        this.lineMixer = new THREE.AnimationMixer(this.lineMesh);

        let t2 = this.lineMixer.clipAction(lineGltf.animations[0]);
        //  t2.clampWhenFinished = true;
        //  t2.setLoop(THREE.LoopOnce);
        t2.play();
        this.lineMixer.update(24.5);

        // this.sceneLineMesh.morphTargetInfluences[0] = 0.5;
        this.lineMesh.visible = false;
        this.sceneLineMesh.visible = false;


        //   })
    }
    async addLine2(textures) {
        var lineGltf = await loadGLTF("../models/chapter1/line1_2.glb");
        //  this.loader.load('models/chapter1/line3.glb', (gltf) => {

        this.lineMesh2 = lineGltf.scene.children[0];
        //   console.log('line2', lineGltf.animations[0])

        //    this.lineMesh2.material.map = this.texturesList[38]

        let lineGeo = new THREE.BufferGeometry();
        lineGeo.morphTargetsRelative = true;
        lineGeo.morphAttributes = this.lineMesh2.geometry.morphAttributes;
        lineGeo.copy(this.lineMesh2.geometry)
        lineGeo.setAttribute('position', this.lineMesh2.geometry.attributes.position);
        lineGeo.computeBoundingSphere();
        //     this.lineMesh2.material.color.setHex(0x563A2D);
        this.lineMesh2.material.color.setHex(0x745246);
        //  #745246
        let lineMat = this.lineMesh2.material.clone();
        lineMat.map = textures[31]
        //    lineGeo.setDrawRange(0, 0);
        //



        //   this.lineMesh2.material.map = this.texturesList[38].texture

        this.sceneLineMesh2 = new THREE.Mesh(lineGeo, lineMat)

        this.lineMesh2.material.map = textures[32]
        this.sceneLineMesh2.position.set(this.lineMesh2.position.x, this.lineMesh2.position.y, this.lineMesh2.position.z)
        this.sceneLineMesh2.rotation.set(this.lineMesh2.rotation.x, this.lineMesh2.rotation.y, this.lineMesh2.rotation.z)
        this.sceneLineMesh2.geometry.attributes.position.needsUpdate = true;

        this.scene.add(this.sceneLineMesh2)
        this.scene.add(this.lineMesh2)


        this.lineMixer2 = new THREE.AnimationMixer(this.lineMesh2);

        let t2 = this.lineMixer2.clipAction(lineGltf.animations[0]);
        //  t2.clampWhenFinished = true;
        //  t2.setLoop(THREE.LoopOnce);
        t2.play();
        this.lineMixer2.update(40);

        // this.sceneLineMesh2.morphTargetInfluences[0] = 0.5;
        this.lineMesh2.visible = false;
        this.sceneLineMesh2.visible = false;


        //   })
    }


    pauseAudio() {
        this.phase1Audio.pause()
        this.phase2Audio.pause()
        this.phase3Audio.pause()
        this.phase4Audio.pause()
        this.phase5Audio.pause()
        this.phase6Audio.pause()
    }
    resumeAudio() {
        // if (this.phase == 1) {
        //     this.phase1Audio.play()
        // }
        // if (this.phase == 2) {
        //     this.phase2Audio.play()
        // }
        // if (this.phase == 3 && !this.phase3AudioPlayed) {
        //     this.phase3Audio.play()
        // }
        // if (this.phase == 4) {
        //     this.phase4Audio.play()
        // }
        // if (this.phase == 5) {
        //     this.phase5Audio.play()
        // }
        // if (this.phase == 6) {
        //     this.phase6Audio.play()
        // }
    }


    pauseScene() {
        this.pause = true
        this.pauseAudio()
    }
    resumeScene() {
        this.pause = false
        this.resumeAudio()
    }


    handleMouseDown(e) {
        this.selectedOnDown = this.getInteractiveObjectSelected();
    }

    handleMouseMove(e) {
        this.setPointer(e);

        this.delta.x = this.lastInputPosition.x - e.clientX;
        this.delta.y = this.lastInputPosition.y - e.clientY;

        this.lastInputPosition.set(e.clientX, e.clientY);
    }

    handleMouseUp(e) {
        this.setPointer(e);
        this.testForInteractiveObjects(this.selectedOnDown);
        this.selectedOnDown = null;
    }
    setPointer(e) {
        //     const domElement = this.renderer.domElement;
        const clientWidth = window.innerWidth;
        const clientHeight = window.innerHeight;

        this.pointer.x = (e.clientX / clientWidth) * 2 - 1;
        this.pointer.y = -(e.clientY / clientHeight) * 2 + 1;

        this.pointer.x = clamp(this.pointer.x, -1, 1);
        this.pointer.y = clamp(this.pointer.y, -1, 1);
    }
    testForInteractiveObjects(testAgainst) {
        const intersection = this.getInteractiveObjectSelected();
        if (intersection && testAgainst && intersection.object === testAgainst.object) {
            //     const obj = intersection.object.userData.object;
            //   if (obj.isTransitionedIn()) {
            const { userData } = intersection.object;

            // EventManager.emit(EVENTS.HOTSPOT_SELECTED, userData);

            this.showHotspot(userData);

            //  }
        }
    }


    showHotspot(userData) {
        //    emitter.emit('updateHotspot', userData)

    }


    handleTouchStart(e) {
        const touch = e.changedTouches[0];
        this.lastInputPosition.set(touch.clientX, touch.clientY);

        // test if object was selected on down to test against in up
        //this.pointerCopy.copy(this.pointer);
        this.setPointer(e.changedTouches[0]);
        this.selectedOnDown = this.getInteractiveObjectSelected();
        //   this.pointer.copy(this.pointerCopy);

        // if (this.selectedOnDown) {
        // 	this.selectedOnDown.object.userData.object.mouseOver();
        // }
    }
    handleTouchEnd(e) {
        // copy pointer and set to touch point for picking test
        //    this.pointerCopy.copy(this.pointer);
        this.setPointer(e.changedTouches[0]);

        // test if object was selected
        this.testForInteractiveObjects(this.selectedOnDown);

        // reset pointer to previous state
        //    this.pointer.copy(this.pointerCopy);

        // if (this.selectedOnDown) {
        // 	this.selectedOnDown.object.userData.object.mouseOut(0.1);
        // }
        this.selectedOnDown = null;
    }
    handleTouchMove(e) {
        const touch = e.changedTouches[0];

        this.delta.x = this.lastInputPosition.x - touch.clientX;
        this.delta.y = clamp(this.lastInputPosition.y - touch.clientY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);

        //this.updatePositionTarget(this.delta.y * -SCROLL_SCALAR_MOBILE);


        if (this.phase == 3) {
            this.handleScroll(this.delta.y * -this.SCROLL_SCALAR_MOBILE)
            // if (this.playPhase3) {
            //     this.handleScroll(this.delta.y * -this.SCROLL_SCALAR_MOBILE)

            // }
            // else {
            //  //   this.playPhase3 = true;
            // //    document.getElementById('action2').classList.remove('show')
            // }

        }
        if (this.phase == 1) {
            this.handleScroll(this.delta.y * -this.SCROLL_SCALAR_MOBILE / 2)
            //  this.handleScroll(this.delta.y * -this.SCROLL_SCALAR_MOBILE / 100000)
            // if (this.playPhase3) {
            //     this.handleScroll(this.delta.y * -this.SCROLL_SCALAR_MOBILE)

            // }
            // else {
            //  //   this.playPhase3 = true;
            // //    document.getElementById('action2').classList.remove('show')
            // }

        }

        // update horizonal pan using delta
        // this.pointer.x += -this.delta.x * 0.15;
        // this.pointer.x = clamp(this.pointer.x, -this.lookAt.range.x, this.lookAt.range.x);

        this.lastInputPosition.set(touch.clientX, touch.clientY);

        this.resetScrollTime();

        if (this.selectedOnDown) {
            this.selectedOnDown.object.userData.object.mouseOut();
        }
    }

    MouseWheelHandler(e) {
        // cross-browser wheel delta
        var event = window.event || e; // old IE support
        var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));

        return false;
    }

    handleScroll(deltaY) {

        const d = deltaY// ThreeMath.clamp(deltaY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);
        if (this.phase == 3) {

            if (this.playPhase3 == false && this.phase3Visited == false) {
                document.getElementById('action2').classList.remove('show')
                document.getElementById('scrollAction').classList.add('show')

                this.playPhase3 = true;
                this.phase3Visited = true;


                this.phase2Audio.pause();
                setTimeout(() => {
                    this.phase3Audio.play();
                }, 1000)

            }



            if (this.phase3Mixer.time >= 0.2 && this.phase3Mixer.time <= 10) {
                this.footstepAudio.play();
                this.phase3Mixer.update((d));
                if (this.lineMixer.time >= 24.5) {
                    this.lineMixer.update(d);
                }
                gsap.to(this.camera.position, { x: this.sceneCamera.position.x, y: this.sceneCamera.position.y, z: this.sceneCamera.position.z, duration: 1 })
                gsap.to(this.camera.quaternion, { x: this.sceneCamera.quaternion.x, y: this.sceneCamera.quaternion.y, z: this.sceneCamera.quaternion.z, w: this.sceneCamera.quaternion.w, duration: 1 })

            }
            if (this.phase3Mixer.time < 0.2) {
                this.phase3Mixer.setTime(0.2)
                this.phase3Mixer.update(0.2)
            }
            if (this.lineMixer.time < 24.5) {
                this.lineMixer.setTime(24.5)
            }
            if (this.phase3Mixer.time > 10) {
                this.footstepAudio.pause();
                this.phase3Mixer.setTime(10);
                //  this.phase3Mixer.setTime(10.7);

                this.playPhase3 = false;

                this.phase3Audio.pause();
                //   this.phase4_1Audio.play();
                this.phase = 31;
                document.getElementById('scrollAction').classList.remove('show')
                //    setTimeout(() => {

                if (!document.getElementById('action3').classList.contains('show')) {
                    document.getElementById('action3').classList.add('show')
                    emitter.emit('animateKnob3', true)

                }

                //     }, 5000)
            }


        }
        if (this.phase == 1) {
            if (this.playPhase1 == false) {

                document.getElementById('action1').classList.remove('show')
                document.getElementById('scrollAction').classList.add('show')
                this.playPhase1 = true;
                this.phase1Visited = true;
                //    this.phase1Audio.play();
            }

            if (this.phase1Mixer.time >= 1 && this.phase1Mixer.time <= 17.5) {
                //        this.cameraAnimationMixers[0].update(this.speedPhase1);
                this.phase1Mixer.update((d));
                gsap.to(this.camera.position, { x: this.sceneCamera1.position.x, y: this.sceneCamera1.position.y, z: this.sceneCamera1.position.z, duration: 1 })
                gsap.to(this.camera.quaternion, { x: this.sceneCamera1.quaternion.x, y: this.sceneCamera1.quaternion.y, z: this.sceneCamera1.quaternion.z, w: this.sceneCamera1.quaternion.w, duration: 1 })
                //  this.lineMixer2.update(d);
            }
            if (this.phase1Mixer.time < 1) {
                this.phase1Mixer.setTime(1)
                this.phase1Mixer.update(1)
            }
            if (this.phase1Mixer.time >= 17.5) {
                document.getElementById('action1').classList.add('show')
                document.getElementById('scrollAction').classList.remove('show')
                emitter.emit('animateKnob1', true)
            }



            if (this.phase1Mixer.time > 15) {
                if (isMobile()) {
                    this.scene1_obeid.visible = true;
                }
                else {
                    if (this.obeidAnimationMixer.time <= 57) {
                        this.scene1_obeid.visible = true;
                        this.obeidAnimationMixer.update(0.01)
                        this.scene.updateMatrixWorld()
                    }
                }
            }

        }

        this.resetScrollTime();


    }

    resetScrollTime() {
        this.lastScrollTime = Date.now();
    }
    removeObject3D(object) {
        this.disposeHierarchy(object);

        // this.texturesList[2].texture.dispose()
        // this.texturesList[3].texture.dispose()
        // this.texturesList[4].texture.dispose()
        // this.texturesList[5].texture.dispose()
        // this.texturesList[7].texture.dispose()
        // this.texturesList[23].texture.dispose()
        // this.texturesList[24].texture.dispose()
        // this.texturesList[25].texture.dispose()
        // this.texturesList[26].texture.dispose()
        // this.texturesList[27].texture.dispose()
        if (!(object instanceof THREE.Object3D)) return false;
        // for better memory management and performance
        if (object.geometry) {
            object.geometry.dispose();
        }
        if (object.material) {
            if (object.material instanceof Array) {
                // for better memory management and performance
                object.material.forEach(material => material.dispose());
            } else {
                // for better memory management and performance
                object.material.dispose();
            }
        }
        object.traverse(t => {
            if (t.geometry) {
                t.geometry.dispose();
            }
            if (t.material) {
                if (t.material instanceof Array) {
                    // for better memory management and performance
                    t.material.forEach(material => material.dispose());
                } else {
                    // for better memory management and performance
                    t.material.dispose();
                }
            }
        })
        if (object.parent) {
            object.parent.remove(object);
        }
        this.renderer.clear();
        this.renderer.dispose()
        // the parent might be the scene or another Object3D, but it is sure to be removed this way
        return true;
    }
    disposeHierarchy(node, callback) {
        for (let i = node.children.length - 1; i >= 0; i--) {
            const child = node.children[i];
            this.disposeHierarchy(child, callback);

            if (typeof callback === 'function') {
                callback(child);
            }
        }
    }


    destroy() {

        window.removeEventListener('touchmove', this.handleTouchMove.bind(this), false);
        window.removeEventListener('mousedown', this.handleMouseDown.bind(this), true);
        window.removeEventListener('mousemove', this.handleMouseMove.bind(this), true);
        window.removeEventListener('mouseup', this.handleMouseUp.bind(this), false);
        window.removeEventListener("wheel", this.onWheel.bind(this), false);

        this.cameraAnimationMixers && this.cameraAnimationMixers.length > 0 && this.cameraAnimationMixers[0].uncacheRoot(this.camera);
        this.phase2Mixer && this.phase2Mixer.uncacheRoot(this.camera);
        this.phase3Mixer && this.phase3Mixer.uncacheRoot(this.camera);
        this.phase4Mixer && this.phase4Mixer.uncacheRoot(this.camera);
        this.phase5Mixer && this.phase5Mixer.uncacheRoot(this.camera);
        this.phase6Mixer && this.phase6Mixer.uncacheRoot(this.camera);

        // this.audioListener.clear();
        // this.phase1Audio.clear();
        // this.phase2Audio.clear();
        // this.phase3Audio.clear();
        // this.phase4Audio.clear();
        // this.phase5Audio.clear();
        // this.phase6Audio.clear();
        // this.bgAudio.clear();
        // this.footstepAudio.clear();

        // //  this.getOffHorseAudio.disconnect();
        // this.swordAudio.disconnect();
        // this.pickDateUp.disconnect();
        // this.desertWindAudio.disconnect();

        // this.audioListener.disconnect();
        this.phase1Audio.pause();
        this.phase2Audio.pause();
        this.phase3Audio.pause();
        this.phase4Audio.pause();
        this.phase5Audio.pause();
        this.phase6Audio.pause();
        this.bgAudio.pause();
        this.footstepAudio.pause();

        this.getOffHorseAudio.pause();
        this.swordAudio.pause();
        this.pickDateUp.pause();
        this.desertWindAudio.pause();

        const cleanMaterial = material => {
            //   console.log('dispose material!')
            material.dispose()

            // dispose textures
            for (const key of Object.keys(material)) {
                const value = material[key]
                if (value && typeof value === 'object' && 'minFilter' in value) {
                    //      console.log('dispose texture!')
                    value.dispose()
                }
            }
        }

        //   console.log('dispose renderer!')
        //  this.renderer.dispose()

        this.gltfScene.traverse(object => {
            if (!object.isMesh) return

            //    console.log('dispose geometry!')
            object.geometry.dispose()

            if (object.material.isMaterial) {
                cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) cleanMaterial(material)
            }
        })



        this.pause = true;
        this.cameraAnimationMixers = []
        this.obeidAnimationMixer = null;
        this.sheperdAnimationMixer = null;
        this.obeidLastAnimationMixer = null;

        //  this.gltfScene = null;
        this.count = 1;
        this.sceneLineMesh = null;
        this.lineMesh = null;

        this.scene1_obeid = null;
        this.scene1_obeid2 = null;
        this.phase = 0;
        this.phase1Group = [];
        this.phase2Group = [];
        this.phase3Group = [];
        this.phase4Group = [];
        this.phase5Group = [];

        this.phase2Mixer = null;
        this.phase3Mixer = null;
        this.phase4Mixer = null;
        this.phase5Mixer = null;
        this.phase6Mixer = null;

        this.mixer = null;
        this.activeAction = null;
        this.cameraPos = null;
        this.camera = null;
        this.renderer = null;

        this.wheelSpeed = 0.02;
        this.maxDist = 2.0;
        this.minDist = 1.0;
        this.dist = 2;
        this.startDragScroll = 0

        this.modelReady = false
        this.animationActions = []
        this.activeAction = null;
        this.lastAction = null;
        this.animationsFolder = null;



        this.absScroll = 0;
        this.smoothProgress = 0;


        this.lastTime = 0;

        this.SCROLL_SCALAR_MOBILE = 0.01;
        this.MAX_SCROLL_TIME_DELTA = 20;
        this.SCROLL_SCALAR = 0.01;
        this.MAX_SCROLL_DELTA = 5;
        this.transitioning = false;

        this.playPhase1 = false;
        this.playPhase2 = false;
        this.playPhase3 = false;
        this.playPhase4 = false;
        this.playPhase5 = false;
        this.playPhase5 = false;

        this.audioListener = new THREE.AudioListener();
        this.phase1Audio = new THREE.Audio(this.audioListener);
        this.phase2Audio = new THREE.Audio(this.audioListener);
        this.phase3Audio = new THREE.Audio(this.audioListener);
        this.phase4Audio = new THREE.Audio(this.audioListener);
        this.phase5Audio = new THREE.Audio(this.audioListener);
        this.phase6Audio = new THREE.Audio(this.audioListener);
        this.bgAudio = new THREE.Audio(this.audioListener);
        this.footstepAudio = new THREE.Audio(this.audioListener);

        this.getOffHorseAudio = new THREE.Audio(this.audioListener);
        this.swordAudio = new THREE.Audio(this.audioListener);
        this.pickDateUp = new THREE.Audio(this.audioListener);
        this.desertWindAudio = new THREE.Audio(this.audioListener);


        this.animationSpeed = 1;

        this.raycaster = null;
        this.hotspots = []
        this.delta = new THREE.Vector2();
        this.lastInputPosition = new THREE.Vector2(0, 0);

        this.pointer = new THREE.Vector2();
        this.pointerCopy = new THREE.Vector2();
        this.selectedOnDown = null;
        this.textures.map(texture => {
            texture.dispose();
        })

        this.textures = [];


    }

    start() {


        setTimeout(() => {
            this.pause = false;

            this.bgAudio.play();
            this.desertWindAudio.play()

            if (this.phase == 0) {
                document.getElementById('actionIntro').classList.add('show')
                //    setTimeout(() => {
                //    this.phase1Audio.play();
                //     }, 3000)

            }
            //    this.playPhase1 = true;



            this.fpsSpeed = this.stats.getFPS()
            console.log('fps', this.stats.getFPS())
            if (this.fpsSpeed > 70) {
                this.speedPhase1 = 0.0042;
                this.speedPhase2 = 0.003;
                this.speedPhase4 = 0.0015;
                this.speedPhase5 = 0.002;
                this.speedPhase6 = 0.0015;
            }
        }, 100)



    }
}

const _instance = new DesertScene()
export default _instance