import { createApp } from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag-next";
import router from './router'
//import VueMeta from 'vue-meta'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
const metaManager = createMetaManager()
const app = createApp(App);
app.use(router)
app.use(metaManager)

app.use(VueGtag, {
    property: {
        id: "G-G8FERVY6BG",
    }
});

app.mount("#app");