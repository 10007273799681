import * as THREE from 'three';

import simpleFrag from '../../shaders/FXAAPass/simple.frag';
import simpleVert from '../../shaders/FXAAPass/simple.vert';

import gammaFrag from '../../shaders/GammaCorrection/simple.frag';
import gammaVert from '../../shaders/GammaCorrection/simple.vert';

import VignetteFrag from '../../shaders/VignettePass/simple.frag';
import VignetteVert from '../../shaders/VignettePass/simple.vert';

import VignetteFragMobile from '../../shaders/VignettePassMobile/simple.frag';
import VignetteVertMobile from '../../shaders/VignettePassMobile/simple.vert';

// import FilmNoiseFrag from '../../shaders/FilmNoise/simple.frag';
// import FilmNoiseVert from '../../shaders/FilmNoise/simple.vert';

// import BokehFrag from '../../shaders/bokeh/simple.frag';
// import BokehVert from '../../shaders/bokeh/simple.vert';

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass'

import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';

import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';

import { isMobile } from "../../utils/device";


class BackgroundScene {
    constructor() {
        this.bind();
        this.scene;
        this.renderer;
        this.camera;
        this.passes = [];
        this.effectComposer;
        this.filmNoise;
        this.bokehNoise;
        this.uniform1;
        this.uniform2;
        this.uniform3;
        this.clock;
        this.postprocess = {
            LUTPass: { enabled: !1 },
            BokehPass: { enabled: !1, focus: 20, aperture: .5, maxblur: .009 },
            FilmPass: { enabled: !0, noiseIntensity: .35, scanlinesIntensity: .025, scanlinesCount: 648, grayscale: !1 },
            GammaCorrection: { enabled: !0 },
            Vignette: { enabled: !0, offset: .65, darkness: 2.6 },
            FXAA: { enabled: !0 },
            Sepia: { enabled: !1, amount: .9 },
            Bleach: { enabled: !1, opacity: .95 },
            Tilt: { horizontal: !0, vertical: !0, r: 15.7, h: 13 / 1e3, v: 13 / 1e3 },
            Colorify: { enabled: !1, color: "#f6c409" },
            Bloom: { enabled: !1, strength: .5 }
        }
    }

    init(scene, camera, renderer, effectComposer) {
        this.clock = new THREE.Clock();
        this.scene = scene
        this.camera = camera
        this.renderer = renderer
        this.effectComposer = effectComposer





        const loader = new THREE.TextureLoader();
        loader.load('../../models/background.jpg', (texture) => {
            this.scene.background = texture;
        });

        this.addGammaCorrection();
        //   this.addFXAAPass();
        this.addVignettePass();

        //  this.addVFilmNoise();
        //   this.addBokehPass()

        //  new THREE.TextureLoader().load("/assets/noise.webp", t => {

        // })

        Object.keys(this.passes).forEach(s => this.effectComposer.addPass(this.passes[s].pass))

    }


    bind() {

        this.init = this.init.bind(this)
        //  this.update = this.update.bind(this)
    }

    addFXAAPass() {
        //    const e=new Pe(ca);
        let e = new ShaderPass({
            uniforms: {
                tDiffuse: { value: null },
                resolution: { value: { x: 1 / (window.innerWidth * this.renderer.getPixelRatio()), y: 1 / (window.innerHeight * this.renderer.getPixelRatio()) } }
            },
            vertexShader: simpleVert,
            fragmentShader: simpleFrag
        })
        e.material.uniforms.resolution.value.x = 1 / (window.innerWidth * this.renderer.getPixelRatio());
        e.material.uniforms.resolution.value.y = 1 / (window.innerHeight * this.renderer.getPixelRatio());
        e.enabled = true;
        this.passes.push({ name: "fxaa", pass: e });
    }

    addGammaCorrection() {
        let e = new ShaderPass({
            uniforms: { tDiffuse: { value: null } },

            vertexShader: gammaVert,
            fragmentShader: gammaFrag
        })
        e.enabled = true,
            this.passes.push({ name: "gammaCorrection", pass: e })

        this.uniform1 = e.uniforms;
    }

    addVignettePass() {


        if (!isMobile()) {
            this.filmNoise = new ShaderPass({
                uniforms: {
                    tDiffuse: { value: null },
                    offset: { value: 1 },
                    darkness: { value: 1.25 },
                    uTime: { value: null },
                    resolution: { value: { x: 1 / (window.innerWidth * this.renderer.getPixelRatio()), y: 1 / (window.innerHeight * this.renderer.getPixelRatio()) } }

                },

                vertexShader: VignetteVert,
                fragmentShader: VignetteFrag
            })
            this.filmNoise.enabled = this.postprocess.Vignette.enabled,
                this.filmNoise.uniforms.offset.value = this.postprocess.Vignette.offset,
                this.filmNoise.uniforms.darkness.value = this.postprocess.Vignette.darkness,
                this.passes.push({ name: "vignette", pass: this.filmNoise })

            this.uniform2 = this.filmNoise.uniforms;
        }
        else {
            let filmNoise = new ShaderPass({
                uniforms: {
                    tDiffuse: { value: null },
                    offset: { value: 1 },
                    darkness: { value: 1.25 },
                    resolution: { value: { x: 1 / (window.innerWidth * this.renderer.getPixelRatio()), y: 1 / (window.innerHeight * this.renderer.getPixelRatio()) } }

                },

                vertexShader: VignetteVertMobile,
                fragmentShader: VignetteFragMobile
            })
            filmNoise.enabled = this.postprocess.Vignette.enabled,
                filmNoise.uniforms.offset.value = this.postprocess.Vignette.offset,
                filmNoise.uniforms.darkness.value = this.postprocess.Vignette.darkness,
                this.passes.push({ name: "vignette", pass: filmNoise })

            this.uniform3 = filmNoise.uniforms;
        }



    }
    addVFilmNoise() {

        //  FilmPass: { enabled: !0, noiseIntensity: .35, scanlinesIntensity: .025, scanlinesCount: 648, grayscale: !1 },
        let e = new FilmPass(.13, .02, window.innerHeight, 0);


        // let p = new THREE.TextureLoader().load("/assets/test-animation.png");
        // //    new THREE.TextureLoader().load("/assets/noise.webp", t => {
        // this.filmNoise = new ShaderPass({

        //     uniforms: {
        //         //   tMap: { value: null },
        //         //tNoise: { value: new THREE.TextureLoader().load("/assets/test-animation.png") },
        //         uNoiseIntensity: { value: 0.7 },
        //         uResolution: { value: new THREE.Vector2(3, 3) },
        //         uTime: { value: 1. },
        //     },
        //     vertexShader: FilmNoiseVert,
        //     fragmentShader: FilmNoiseFrag
        // })

        // // new THREE.TextureLoader().load("/assets/noise.webp", (t) => {
        // //     this.filmNoise.uniforms.tNoise = t
        // // })
        // this.filmNoise.enabled = true;
        e.enabled = true;
        // e.uniforms.offset.value = this.postprocess.Vignette.offset,
        // e.uniforms.darkness.value = this.postprocess.Vignette.darkness,
        this.passes.push({ name: "film", pass: e })
        //   });


    }

    addBokehPass() {
        // var postprocessing = {

        //     enabled: true

        // };
        // var pars = { min_filter: THREE.LinearFilter, mag_filter: THREE.LinearFilter };
        // postprocessing.rtTextureDepth = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight, pars);
        // postprocessing.rtTextureColor = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight, pars);

        // this.bokehNoise = new ShaderPass({

        //     uniforms: {
        //         //   tMap: { value: null },
        //         //tNoise: { value: new THREE.TextureLoader().load("/assets/test-animation.png") },
        //         // tColor: { value: postprocessing.rtTextureColor },
        //         // texture: { value: postprocessing.rtTextureDepth },
        //         aspect: { value: window.innerWidth / window.innerHeight },
        //         focus: { value: 1. },
        //         aperture: { value: 0.001 },
        //         maxblur: { value: 10. },
        //         uResolution: { value: new THREE.Vector2(3, 3) },
        //         uTime: { value: 1. },
        //     },
        //     vertexShader: BokehVert,
        //     fragmentShader: BokehFrag
        // })

        // // new THREE.TextureLoader().load("/assets/noise.webp", (t) => {
        // //     this.filmNoise.uniforms.tNoise = t
        // // })
        // this.bokehNoise.enabled = true;
        // // e.uniforms.offset.value = this.postprocess.Vignette.offset,
        // // e.uniforms.darkness.value = this.postprocess.Vignette.darkness,
        // this.passes.push({ name: "bokeh", pass: this.bokehNoise })


        const bokehPass = new BokehPass(this.scene, this.camera, {
            focus: 0.1,
            aperture: 0.01,
            maxblur: .001,
        });

        //        const composer = new EffectComposer( renderer );

        // composer.addPass( renderPass );
        // composer.addPass( bokehPass );

        // postprocessing.composer = composer;
        // postprocessing.bokeh = bokehPass;
        this.passes.push({ name: "bokeh", pass: bokehPass })



        const matChanger = function () {

            bokehPass.uniforms['focus'].value = effectController.focus;
            bokehPass.uniforms['aperture'].value = effectController.aperture * 0.00001;
            bokehPass.uniforms['maxblur'].value = effectController.maxblur;

        };

        const effectController = {

            focus: 500.0,
            aperture: 0.5,
            maxblur: 0.003

        };


        const gui = new GUI();
        gui.add(effectController, 'focus', 10.0, 3000.0, 10).onChange(matChanger);
        gui.add(effectController, 'aperture', 0, 10, 0.1).onChange(matChanger);
        gui.add(effectController, 'maxblur', 0.0, 0.01, 0.001).onChange(matChanger);
        gui.close();

        matChanger();


    }

    update() {
        if (this.filmNoise) {
            this.filmNoise.uniforms.uTime.value = this.clock.getElapsedTime();
        }

        // if (this.bokehNoise) {
        //     this.bokehNoise.uniforms.uTime.value = this.clock.getElapsedTime();
        // }


    }

    dispose() {
        this.scene.background = null

        Object.keys(this.passes).forEach(s => this.effectComposer.removePass(this.passes[s].pass))
        this.passes = []
        //   this.scene = null
        this.camera = null
        this.renderer = null

        this.uniform1 = null;
        this.uniform2 = null;
        this.uniform3 = null;
        this.clock = null;

        // if (this.filmNoise) {
        //     this.filmNoise.uniforms = null;
        // }
        // this.effectComposer = null
    }

}

const _instance = new BackgroundScene()
export default _instance