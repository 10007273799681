import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'
import Chapter1 from '../pages/Chapter1.vue'
import Chapter2 from '../pages/Chapter2.vue'
import Chapter3 from '../pages/Chapter3.vue'
import Chapter4 from '../pages/Chapter4.vue'

const router = createRouter({
    history: createWebHistory(),

    routes: [
        { path: "/", name: 'home', component: Home },
        { path: "/chapter1", name: 'chapter1', component: Chapter1 },
        { path: "/chapter2", name: 'chapter2', component: Chapter2 },
        { path: "/chapter3", name: 'chapter3', component: Chapter3 },
        { path: "/chapter4", name: 'chapter4', component: Chapter4 },
    ],

})

export default router

// import Vue from 'vue'
// import Router from 'vue-router'
// import Login from '@/components/Login'
// import Docxso from '@/components/Docxso'
// Vue.use(Router)
// let router = new Router({
//     routes: [
//         {
//             path: '*',
//             redirect: '/'
//         },
//         {
//             path: '/',
//             name: 'login',
//             component: Login
//         },
//         {
//             path: '/home',
//             name: 'home',
//             component: Docxso,
//             meta: {
//                 requiresAuth: true
//             }
//         }
//     ]
// })
// export default router 