import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader.js";
//import { composeAssetUrl } from "./asyncLoaders";
// import { getPerformanceParameters, getBlobTextures } from "./performance";
// import { state } from "./state";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { isMobile } from "../utils/device";

var dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
dracoLoader.setDecoderConfig({ type: 'js' })

var manager;
var percentLoaded = 0;

class PreloadingManager {
    constructor() {



    }

    static getManager() {
        return manager;
    }

    initialize(onLoad, onProgress) {
        THREE.Cache.enabled = true;
        manager = new THREE.LoadingManager();
        manager.onLoad = onLoad;
        manager.onProgress = onProgress;
        this.textures = [];
        this.rgbes = [];
        this.objs = [];
        this.cubeTextures = [];
        this.gltfs = [];
        this.files = [];
        this.audios = [];
    }

    addTexture(uri) {
        //   var url = composeAssetUrl(uri);
        this.textures.push(uri);
    }
    addAudio(uri) {
        //   var url = composeAssetUrl(uri);
        this.audios.push(uri);
    }

    addRGBE(uri) {
        //     var url = composeAssetUrl(uri);
        this.rgbes.push(uri);
    }

    // addHDRCubeTexture(hdrUris) {
    //     var urls = hdrUris.map((x) => composeAssetUrl(x));
    //     this.cubeTextures.push(urls);
    // }

    // addOBJ(uri) {
    //     var url = composeAssetUrl(uri);
    //     this.objs.push(url);
    // }

    addGLTF(uri) {
        //  var url = composeAssetUrl(uri);
        this.gltfs.push(uri);
    }

    // addFile(uri) {
    //     var url = composeAssetUrl(uri);
    //     this.files.push(url);
    // }

    start() {
        for (let item of this.textures) {
            new THREE.TextureLoader(manager).load(item);
        }
        for (let item of this.rgbes) {
            new RGBELoader(manager).load(item);
        }
        for (let item of this.cubeTextures) {
            new HDRCubeTextureLoader(manager).setDataType(THREE.UnsignedByteType).load(item);
        }
        for (let item of this.objs) {
            new OBJLoader(manager).load(item, () => { });
        }
        for (let item of this.gltfs) {
            new GLTFLoader(manager).setDRACOLoader(dracoLoader).load(item, () => { });
        }
        for (let item of this.files) {
            new THREE.FileLoader(manager).load(item);
        }
        // for (let item of this.audios) {
        //       new THREE.AudioLoader().load(item)
        // }

    }
}

async function preloadAll(chapter) {
    return new Promise((resolve) => {
        var mgr = new PreloadingManager();
        mgr.initialize(
            () => {
                resolve();
            },
            (url, itemsLoaded, itemsTotal) => {
                var percent = (itemsLoaded / itemsTotal) * 100;
                percent = Math.min(percent, 100);
                if (percent > percentLoaded) {
                    percentLoaded = percent;
                    document.getElementById("percent").innerHTML = Math.round(percent) + "%";
                }
            }
        );
        var audiosToBeLoaded;
        var texturesToBeLoaded;
        switch (chapter) {
            case 1:
                audiosToBeLoaded = [
                    '../models/chapter1/audio/bg.mp3',
                    '../models/chapter1/audio/phase1.mp3',
                    '../models/chapter1/audio/phase2.mp3',
                    '../models/chapter1/audio/phase3.mp3',
                    '../models/chapter1/audio/phase4.mp3',
                    '../models/chapter1/audio/phase5.mp3',
                    '../models/chapter1/audio/phase6.mp3',
                    '../models/chapter1/audio/getOffHorse.mp3',
                    '../models/chapter1/audio/SwordPlaceInSand.mp3',
                    '../models/chapter1/audio/PickUpandWalkAway.mp3',
                    '../models/chapter1/audio/DesertWind.mp3',
                    '../models/chapter1/audio/foosteps.mp3',
                    '../models/chapter1/audio/phase4_1.mp3',

                ];
                texturesToBeLoaded = [
                    '../models/chapter1/scene/mats/dunes-diffuse.jpg',
                    '../models/chapter1/scene/mats/walls-houses4.jpg',
                    '../models/chapter1/scene/mats/tent-side3.png',
                    '../models/chapter1/scene/mats/tent-perspective2.png',
                    '../models/chapter1/scene/mats/tent-front2.png',
                    '../models/chapter1/scene/mats/back-rocks.png',
                    '../models/chapter1/scene/mats/convoy.png',
                    '../models/chapter1/scene/mats/palm.png',
                    '../models/chapter1/scene/mats/camel2_1.png',
                    '../models/chapter1/scene/mats/shep1_1.png',
                    '../models/chapter1/scene/mats/shep2_2.png',
                    '../models/chapter1/scene/mats/bush.png',
                    '../models/chapter1/scene/mats/tree.png',
                    '../models/chapter1/scene/mats/sheep1_1.png',
                    // '../models/chapter1/scene/mats/Horse_Mount.png',
                    '../models/chapter1/scene/mats/sheep2_1.png',
                    '../models/chapter1/scene/mats/sheep3_1.png',
                    '../models/chapter1/scene/mats/desert-land4.jpg',
                    '../models/chapter1/scene/mats/rock.jpg',
                    '../models/chapter1/scene/mats/convoy_last.png',
                    '../models/chapter1/scene/mats/Horse_fur.jpg',
                    '../models/chapter1/scene/mats/Horse_Hair.png',
                    '../models/chapter1/scene/mats/Horse_Mount_mod.jpg',
                    '../models/chapter1/scene/mats/obeid_last_scene_main_texture.jpg',
                    '../models/chapter1/scene/mats/obeid_scene1_main_texture.jpg',
                    '../models/chapter1/scene/mats/sheperd_main_texture.jpg',
                    '../models/chapter1/scene/mats/spearSurface_Color.jpg',
                    '../models/chapter1/scene/mats/dateSurface_Color.jpg',
                    '../models/chapter1/scene/mats/dateSurface_Color_1.jpg',
                    '../models/chapter1/scene/mats/date.jpg',
                    '../models/chapter1/scene/mats/detail.png',
                    '../models/chapter1/scene/mats/dash.png',
                    '../models/chapter1/scene/mats/line.jpg',
                ]

                if (isMobile()) {
                    mgr.addGLTF("../models/chapter1/scene/scene_mobile-baked.glb");
                    mgr.addGLTF("../models/chapter1/scene/character1-1-baked.glb");
                    mgr.addGLTF("../models/chapter1/scene/character1-2-baked.glb");

                    mgr.addGLTF("../models/chapter1/scene/character2-1-baked.glb");
                    mgr.addGLTF("../models/chapter1/scene/character2-2-baked.glb");
                    mgr.addGLTF("../models/chapter1/scene/character2-3-baked.glb");

                    mgr.addGLTF("../models/chapter1/scene/character3_mobile-baked.glb");
                }
                else {
                    mgr.addGLTF("../models/chapter1/scene/scene2-baked.glb");
                    mgr.addGLTF("../models/chapter1/scene/character1-baked.glb");
                    mgr.addGLTF("../models/chapter1/scene/character2-baked.glb");
                    mgr.addGLTF("../models/chapter1/scene/character3-baked.glb");
                }
                mgr.addGLTF("../models/chapter1/palmtreex.glb");

                mgr.addGLTF("../models/chapter1/cams/cam1.glb");
                mgr.addGLTF("../models/chapter1/cams/cam2.glb");
                mgr.addGLTF("../models/chapter1/cams/cam3.glb");
                mgr.addGLTF("../models/chapter1/cams/cam4.glb");
                mgr.addGLTF("../models/chapter1/cams/cam5.glb");
                mgr.addGLTF("../models/chapter1/cams/cam6.glb");


                //  if (getPerformanceParameters().envmaps) {
                texturesToBeLoaded.map(texture => {
                    mgr.addTexture(texture);
                })

                audiosToBeLoaded.map(audio => {
                    mgr.addAudio(audio);
                })

                break;

            case 2:
                audiosToBeLoaded = [
                    '../models/chapter2/audio/bg.mp3',
                    '../models/chapter2/audio/crowd.mp3',
                    '../models/chapter2/audio/phase1.mp3',
                    '../models/chapter2/audio/phase2.mp3',
                    '../models/chapter2/audio/phase3.mp3',
                    '../models/chapter2/audio/phase4.mp3',
                    '../models/chapter2/audio/phase5.mp3',
                    '../models/chapter2/audio/DesertWind.mp3',
                    '../models/chapter2/audio/people-disappearing.mp3',
                    '../models/chapter2/audio/growing-plants.mp3'

                ];
                texturesToBeLoaded = [
                    '../models/chapter2/scene/mats/brick.png',
                    '../models/chapter2/scene/mats/bush.png',
                    '../models/chapter2/scene/mats/convoy-b.png',
                    '../models/chapter2/scene/mats/convoy-b.png',
                    '../models/chapter2/scene/mats/convoy-multiple-last.png',
                    '../models/chapter2/scene/mats/desert-land.jpg',
                    '../models/chapter2/scene/mats/door.jpg',
                    '../models/chapter2/scene/mats/garden.png',
                    '../models/chapter2/scene/mats/grass_1.jpg',
                    '../models/chapter2/scene/mats/house1.png',
                    '../models/chapter2/scene/mats/house2.png',
                    '../models/chapter2/scene/mats/main-land.jpeg',
                    '../models/chapter2/scene/mats/mult-palm.png',
                    '../models/chapter2/scene/mats/p1.png',
                    '../models/chapter2/scene/mats/p2.png',
                    '../models/chapter2/scene/mats/p3.png',
                    '../models/chapter2/scene/mats/p4.png',
                    '../models/chapter2/scene/mats/p5.png',
                    '../models/chapter2/scene/mats/p6.png',
                    '../models/chapter2/scene/mats/palm-tree2.png',
                    '../models/chapter2/scene/mats/palm.png',
                    '../models/chapter2/scene/mats/palm2.png',
                    '../models/chapter2/scene/mats/palm3.png',
                    '../models/chapter2/scene/mats/rock.jpg',
                    '../models/chapter2/scene/mats/running-horse.png',
                    '../models/chapter2/scene/mats/tree.png',
                    '../models/chapter2/scene/mats/walls-houses1.jpg',
                    '../models/chapter2/scene/mats/walls-houses2.jpg',
                    '../models/chapter1/scene/mats/dunes-diffuse.jpg',
                    '../models/chapter1/scene/mats/detail.png',
                    '../models/chapter1/scene/mats/dash.png',
                    '../models/chapter1/scene/mats/line.jpg',
                    '../models/chapter2/scene/mats/clothes-shep.png',
                    '../models/chapter2/scene/mats/dressobeidmod.png',
                    '../models/chapter2/scene/mats/face-shep.jpg',
                    '../models/chapter2/scene/mats/face.jpg',
                    '../models/chapter2/scene/mats/ghutra-shep.jpg',
                    '../models/chapter2/scene/mats/ghutra4.jpg',

                ]

                if (isMobile()) {
                    mgr.addGLTF("../models/chapter2/scene/scene_mobile-baked.glb");
                }
                else {
                    mgr.addGLTF("../models/chapter2/scene/scene-baked.glb");
                    mgr.addGLTF("../models/chapter2/scene/horse1-baked.glb");
                }

                mgr.addGLTF("../models/chapter2/cams/cam1.glb");
                mgr.addGLTF("../models/chapter2/cams/cam2.glb");
                mgr.addGLTF("../models/chapter2/cams/cam3.glb");
                mgr.addGLTF("../models/chapter2/cams/cam4.glb");
                mgr.addGLTF("../models/chapter2/cams/cam5.glb");
                mgr.addGLTF("../models/chapter2/cams/cam6.glb");

                mgr.addGLTF("../models/chapter2/scene/character1-baked.glb");
                mgr.addGLTF("../models/chapter2/scene/character2-baked.glb");
                mgr.addGLTF("../models/chapter2/scene/line.glb");

                //  if (getPerformanceParameters().envmaps) {
                texturesToBeLoaded.map(texture => {
                    mgr.addTexture(texture);
                })

                audiosToBeLoaded.map(audio => {
                    mgr.addAudio(audio);
                })

                break;

            case 3:

                audiosToBeLoaded = [
                    '../models/chapter3/audio/phase1.mp3',
                    '../models/chapter3/audio/phase2.mp3',
                    '../models/chapter3/audio/phase3.mp3',
                    '../models/chapter3/audio/phase4.mp3',
                    '../models/chapter3/audio/bg.mp3',
                    '../models/chapter3/audio/DesertWind.mp3',
                    '../models/chapter3/audio/flag.mp3',
                    '../models/chapter3/audio/crowd1.mp3',
                    '../models/chapter3/audio/crowd2.mp3',
                    '../models/chapter3/audio/people-disappearing.mp3',

                ];

                texturesToBeLoaded = [
                    '../models/chapter3/scene/mats/bush.png',
                    '../models/chapter3/scene/mats/cloth-market.jpg',
                    '../models/chapter3/scene/mats/cloth-market2.jpg',
                    '../models/chapter3/scene/mats/desert-land.jpg',
                    '../models/chapter3/scene/mats/door.jpg',
                    '../models/chapter3/scene/mats/grass_1.jpg',
                    '../models/chapter3/scene/mats/market1.png',
                    '../models/chapter3/scene/mats/market2.png',
                    '../models/chapter3/scene/mats/market3.png',
                    '../models/chapter3/scene/mats/market4.png',
                    '../models/chapter3/scene/mats/market5.png',
                    '../models/chapter3/scene/mats/market6.png',
                    '../models/chapter3/scene/mats/market7.png',
                    '../models/chapter3/scene/mats/market8.png',
                    '../models/chapter3/scene/mats/market9.png',
                    '../models/chapter3/scene/mats/market10.png',
                    '../models/chapter3/scene/mats/market11.png',
                    '../models/chapter3/scene/mats/market12.png',
                    '../models/chapter3/scene/mats/market13.png',
                    '../models/chapter3/scene/mats/messenger1.png',
                    '../models/chapter3/scene/mats/messenger2.png',
                    '../models/chapter3/scene/mats/messenger3.png',
                    '../models/chapter3/scene/mats/messenger4.png',
                    '../models/chapter3/scene/mats/p1.png',
                    '../models/chapter3/scene/mats/p2.png',
                    '../models/chapter3/scene/mats/p3.png',
                    '../models/chapter3/scene/mats/p4.png',
                    '../models/chapter3/scene/mats/p6.png',
                    '../models/chapter3/scene/mats/palm.png',
                    '../models/chapter3/scene/mats/rock.jpg',
                    '../models/chapter3/scene/mats/round-artifact1.jpg',
                    '../models/chapter3/scene/mats/round-artifact2.png',
                    '../models/chapter3/scene/mats/round-artifact3.png',
                    '../models/chapter3/scene/mats/shep2.png',
                    '../models/chapter3/scene/mats/sil1-man.png',
                    '../models/chapter3/scene/mats/sil1-woman-market.png',
                    '../models/chapter3/scene/mats/sil1-woman.png',
                    '../models/chapter3/scene/mats/sil2-man.png',
                    '../models/chapter3/scene/mats/sil2-woman.png',
                    '../models/chapter3/scene/mats/sil3-man.png',
                    '../models/chapter3/scene/mats/tree.png',
                    '../models/chapter3/scene/mats/village-distant.png',
                    '../models/chapter3/scene/mats/walls-houses.jpg',
                    '../models/chapter3/scene/mats/flag.jpg',
                    '../models/chapter3/scene/mats/obeid-clothes.jpg',
                    '../models/chapter1/scene/mats/dash.png',
                    '../models/chapter1/scene/mats/line.jpg',


                ]

                if (isMobile()) {
                    mgr.addGLTF("../models/chapter3/scene/scene_mobile-baked.glb");
                }
                else {
                    mgr.addGLTF("../models/chapter3/scene/scene-baked.glb");
                }

                //    mgr.addGLTF("../models/chapter3/scene/scene_mobile.glb");
                mgr.addGLTF("../models/chapter3/cams/cam1.glb");
                mgr.addGLTF("../models/chapter3/cams/cam2.glb");
                mgr.addGLTF("../models/chapter3/cams/cam3.glb");
                mgr.addGLTF("../models/chapter3/cams/cam4.glb");
                mgr.addGLTF("../models/chapter3/scene/character-baked.glb");
                mgr.addGLTF("../models/chapter3/scene/line.glb");
                break;


            case 4:

                audiosToBeLoaded = [
                    '../models/chapter4/audio/phase1.mp3',
                    '../models/chapter4/audio/phase2.mp3',
                    '../models/chapter4/audio/phase3.mp3',
                    '../models/chapter4/audio/phase4.mp3',
                ];

                texturesToBeLoaded = [
                    '../models/chapter4/scene/mats/al-sadu.jpg',
                    '../models/chapter4/scene/mats/bush.png',
                    '../models/chapter4/scene/mats/camels-coming.png',
                    '../models/chapter4/scene/mats/camels-coming2.png',
                    '../models/chapter4/scene/mats/camels-coming3.png',
                    '../models/chapter4/scene/mats/desert-land.jpg',
                    '../models/chapter4/scene/mats/door.jpg',
                    '../models/chapter4/scene/mats/fence.png',
                    '../models/chapter4/scene/mats/field1.png',
                    '../models/chapter4/scene/mats/field2.png',
                    '../models/chapter4/scene/mats/field3.png',
                    '../models/chapter4/scene/mats/field4.png',
                    '../models/chapter4/scene/mats/field5.png',
                    '../models/chapter4/scene/mats/field6.png',
                    '../models/chapter4/scene/mats/grass_1.jpg',
                    '../models/chapter4/scene/mats/market6.png',
                    '../models/chapter4/scene/mats/market7.png',
                    '../models/chapter4/scene/mats/market10.png',
                    '../models/chapter4/scene/mats/greenery-palms.png',
                    '../models/chapter4/scene/mats/p1.png',
                    '../models/chapter4/scene/mats/p2.png',
                    '../models/chapter4/scene/mats/p4.png',
                    '../models/chapter4/scene/mats/p5.png',
                    '../models/chapter4/scene/mats/p6.png',
                    '../models/chapter4/scene/mats/palm-tree2.png',
                    '../models/chapter4/scene/mats/palm.png',
                    '../models/chapter4/scene/mats/pattern1.jpg',
                    '../models/chapter4/scene/mats/reception-ceiling.jpg',
                    '../models/chapter4/scene/mats/rock.jpg',
                    '../models/chapter4/scene/mats/round.jpg',
                    '../models/chapter4/scene/mats/round2.png',
                    '../models/chapter4/scene/mats/round3.png',
                    '../models/chapter4/scene/mats/sil1-man.png',
                    '../models/chapter4/scene/mats/teacher-wall.jpg',
                    '../models/chapter4/scene/mats/tree.png',
                    '../models/chapter4/scene/mats/visitor1.png',
                    '../models/chapter4/scene/mats/visitor2.png',
                    '../models/chapter4/scene/mats/visitor3.png',
                    '../models/chapter4/scene/mats/walls-houses.jpg',
                    '../models/chapter4/scene/mats/walls-houses2.jpg',
                    '../models/chapter4/scene/mats/walls-houses1-1.jpg',
                    '../models/chapter4/scene/mats/walls-houses_1.jpg',
                    '../models/chapter4/scene/mats/reception-floor.jpg',
                    '../models/chapter4/scene/mats/patttern2.jpg',
                    '../models/chapter4/scene/mats/reception-wall.jpg',
                    '../models/chapter4/scene/mats/recepetion-texture.jpg',
                    '../models/chapter4/scene/mats/visitor-texture.jpg',
                    '../models/chapter4/scene/mats/teacher-texture.jpg',


                ]


                if (isMobile()) {
                    mgr.addGLTF("../models/chapter4/scene/scene_mobile-baked.glb");
                }
                else {
                    mgr.addGLTF("../models/chapter4/scene/scene.glb");
                }

                mgr.addGLTF("../models/chapter4/cams/cam1.glb");
                mgr.addGLTF("../models/chapter4/cams/cam2.glb");
                mgr.addGLTF("../models/chapter4/cams/cam3.glb");
                mgr.addGLTF("../models/chapter4/cams/cam4.glb");
                mgr.addGLTF("../models/chapter4/cams/cam5.glb");
                mgr.addGLTF("../models/chapter4/scene/teacher.glb");
                mgr.addGLTF("../models/chapter4/scene/visitors.glb");
                mgr.addGLTF("../models/chapter4/line1_2.glb");
                break;
        }


        // }

        mgr.start();
    });
}

export { PreloadingManager, preloadAll };
