import * as THREE from "three"
import Stats from 'three/examples/jsm/libs/stats.module';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { getPerformanceParameters } from "../../utils/performance";
import RAF from '../../utils/RAF'
import BackgroundScene from './BackgroundScene'
import { preloadAll } from "../../utils/preloadingManager";
import DesertScene from './DesertScene'
//import { OrbitControls } from 'three/addons/controls/OrbitControls.js'

import { gsap, Linear, } from 'gsap';
import { Draggable } from 'gsap/Draggable'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import emitter from 'tiny-emitter/instance';
import { isMobile } from "../../utils/device";

gsap.registerPlugin(Draggable, DrawSVGPlugin, MotionPathPlugin);


class MainScene {
    constructor() {
        this.bind()
        this.camera
        this.scene
        this.renderer
        this.controls
        this.loader
        this.stats
        this.composer
        this.glPerf

        this.light5;
        this.light3;
        this.light;
        this.light2;
        this.light4;

        this.pause = false


        const toneMappingOptions = {
            None: THREE.NoToneMapping,
            Linear: THREE.LinearToneMapping,
            Reinhard: THREE.ReinhardToneMapping,
            // Uncharted2: THREE.Uncharted2ToneMapping,
            Cineon: THREE.CineonToneMapping,
            ACESFilmic: THREE.ACESFilmicToneMapping,
        };

        this.params = {
            toneMapping: "Reinhard",
            toneMappingValue: (toneMapping) => {
                return toneMappingOptions[toneMapping];
            },
            toneMappingWhitePoint: 1.4,
            toneMappingExposure: 1.05,
            lightMapIntensity: 8,
            lightMapIntensityFridges: 1.4,
            bloomStrength: 0.23,
            bloomThreshold: 0.05,
            bloomRadius: 0.17,
            envMapIntensity: 1.7,
            sepiaAmount: 0.15,
            fogNear: 0.6,
            fogFar: 138,
            fogColor: 0xc4c292,
        };

        this.container;

    }

    start() {
        DesertScene.start()
    }

    initDraggableContainer() {
        var D = document.createElement('div');
        var D2 = document.createElement('div');
        var D3 = document.createElement('div');
        var D4 = document.createElement('div');
        var D5 = document.createElement('div');
        gsap.set('svg', { overflow: "visible" })

        var tl = gsap.timeline({
            paused: true,
            reversed: false,
            onComplete: () => { DesertScene.setUpActions(2); }
        })
            .from("#action1 #path2", { drawSVG: "0%", stroke: 'white', duration: 1, ease: Linear.easeNone })
            .to('#action1 .knob', {
                motionPath: {
                    path: "#action1 #path2",
                    align: "#action1 #path2",
                    autoRotate: true,
                    alignOrigin: [0.5, 0.5]
                },
                duration: 1, ease: Linear.easeNone
            },
                0);

        let t = { value: 0 };

        emitter.on('animateKnob1', (isANimate) => {
            if (isANimate) {
                console.log('animate');
                gsap.to(t, {
                    value: 0.15,
                    delay: 0.1,
                    duration: 0.5,
                    onUpdate: (e) => {
                        tl.progress(t.value)
                    }
                })
                gsap.to(t, {
                    value: 0,
                    duration: 0.5,
                    delay: 0.5,
                    onUpdate: (e) => {
                        tl.progress(t.value)
                    }
                })


            }
        })

        // setInterval(()=>{

        // });


        Draggable.create(D, {
            trigger: "#action1",
            type: 'x,y',
            throwProps: true,
            cursor: 'move',
            bounds: { minX: 0, maxX: 500, minY: 0, maxY: 300 },
            onDrag: Update,
            onThrowUpdate: Update,
            force3D: true,
            onDragEnd: () => { }
        });
        function Update() {
            tl.progress(Math.abs(this.x / 200, this.y / 200))
        }

        gsap.to('#action1 #path1', { strokeDashoffset: -10, repeat: -1, duration: 0.5, ease: Linear.easeNone })



        var tl3 = gsap.timeline({
            paused: true,
            reversed: false,
            onComplete: () => { DesertScene.setUpActions(4); }
        })
            .from("#action3 #path2", { drawSVG: "0%", stroke: 'white', duration: 1, ease: Linear.easeNone })
            .to('#action3 .knob', {
                motionPath: {
                    path: "#action3 #path2",
                    align: "#action3 #path2",
                    autoRotate: true,
                    offsetX: 0,
                    offsetY: 0,
                    alignOrigin: [0.5, 0.5]
                },
                duration: 1, ease: Linear.easeNone
            },
                0);
        let t3 = { value: 0 };

        emitter.on('animateKnob3', (isANimate) => {
            if (isANimate) {
                gsap.to(t3, {
                    value: 0.6,
                    delay: 0.3,
                    duration: 0.5,
                    onUpdate: (e) => {
                        tl3.progress(t3.value)
                    }
                })
                gsap.to(t3, {
                    value: 0,
                    duration: 0.5,
                    delay: 0.7,
                    onUpdate: (e) => {
                        tl3.progress(t3.value)
                    }
                })


            }
        })
        Draggable.create(D3, {
            trigger: "#action3",
            type: 'y',
            cursor: 'move',
            throwProps: true,
            bounds: { minY: 0, maxY: 70 },
            onDrag: Update3,
            onThrowUpdate: Update3,
            force3D: true,
            onDragEnd: () => { }
        });
        function Update3() {
            tl3.progress(Math.abs(this.y / 70))
        }

        gsap.to('#action3 #path1', { strokeDashoffset: -10, repeat: -1, duration: 0.5, ease: Linear.easeNone })

        var tl4 = gsap.timeline({
            paused: true,
            reversed: false,
            onComplete: () => { DesertScene.setUpActions(5); }
        })
            .from("#action4 #path2", { drawSVG: "0%", stroke: 'white', duration: 1, ease: Linear.easeNone })
            .to('#action4 .knob', {
                motionPath: {
                    path: "#action4 #path2",
                    align: "#action4 #path2",
                    autoRotate: true,
                    alignOrigin: [0.5, 0.5]
                },
                duration: 1, ease: Linear.easeNone
            },
                0);
        let t4 = { value: 0 };

        emitter.on('animateKnob4', (isANimate) => {
            if (isANimate) {
                gsap.to(t4, {
                    value: 0.25,
                    delay: 0.1,
                    duration: 0.5,
                    onUpdate: (e) => {
                        tl4.progress(t4.value)
                    }
                })
                gsap.to(t4, {
                    value: 0,
                    duration: 0.5,
                    delay: 0.5,
                    onUpdate: (e) => {
                        tl4.progress(t4.value)
                    }
                })


            }
        })
        Draggable.create(D4, {
            trigger: "#action4",


            type: 'y',
            cursor: 'move',
            throwProps: true,
            // bounds: { minY: 0, maxY: 70 },
            bounds: { minY: -140, maxY: 140 },
            onDrag: Update4,
            onThrowUpdate: Update4,
            force3D: true,
            onDragEnd: () => { }


        });
        function Update4() {
            tl4.progress(Math.abs(this.y / 70))
        }

        gsap.to('#action4 #path1', { strokeDashoffset: -10, repeat: -1, duration: 0.5, ease: Linear.easeNone })


        var tl5 = gsap.timeline({
            paused: true,
            reversed: false,
            onComplete: () => { DesertScene.setUpActions(6); }
        })
            .from("#action5 #path2", { drawSVG: "0%", stroke: 'white', duration: 1, ease: Linear.easeNone })
            .to('#action5 .knob', {
                motionPath: {
                    path: "#action5 #path2",
                    align: "#action5 #path2",
                    autoRotate: true,
                    alignOrigin: [0.5, 0.5]
                },
                duration: 1, ease: Linear.easeNone
            },
                0);
        let t5 = { value: 0 };

        emitter.on('animateKnob5', (isANimate) => {
            if (isANimate) {
                console.log('animate');
                gsap.to(t5, {
                    value: 0.15,
                    delay: 0.1,
                    duration: 0.5,
                    onUpdate: (e) => {
                        tl5.progress(t5.value)
                    }
                })
                gsap.to(t5, {
                    value: 0,
                    duration: 0.5,
                    delay: 0.5,
                    onUpdate: (e) => {
                        tl5.progress(t5.value)
                    }
                })


            }
        })
        Draggable.create(D5, {
            trigger: "#action5",
            type: 'y',
            cursor: 'move',
            throwProps: true,
            bounds: { minY: 0, maxY: 70 },
            onDrag: Update5,
            onThrowUpdate: Update5,
            force3D: true,
            onDragEnd: () => { }
        });
        function Update5() {
            tl5.progress(Math.abs(this.y / 70))
        }

        gsap.to('#action5 #path1', { strokeDashoffset: -10, repeat: -1, duration: 0.5, ease: Linear.easeNone })

    }

    async init(container) {
        this.stats = new Stats();
        this.container = container;
        this.initDraggableContainer()

        await preloadAll(1);

        //  setTimeout(() => {
        document.getElementById('preloader').classList.remove('show')
        gsap.to('#preloader', { opacity: 0, display: 'none', duration: 0 })
        gsap.to('#skipIntro', { opacity: 1, display: 'flex', duration: 0 })
        // }, 2000)


        //  container.appendChild(this.stats.dom);

        this.renderer = new THREE.WebGLRenderer({
            alpha: false,
            stencil: false,
            antialias: false, // no way of dynamically changing AA in threejs, so needs to be on
            powerPreference: "high-performance",
            preserveDrawingBuffer: false,

        })
        var pixelRatio = getPerformanceParameters().pixelratio ? window.devicePixelRatio : 1;
        if (pixelRatio >= 2) pixelRatio = 1.45;
        this.renderer.setPixelRatio(pixelRatio);

        this.renderer.setSize(window.innerWidth, window.innerHeight)
        this.renderer.debug.checkShaderErrors = true
        this.renderer.logarithmicDepthBuffer = false;

        this.renderer.shadowMap.autoUpdate = false;
        this.renderer.shadowMap.needsUpdate = true;
        this.renderer.logarithmicDepthBuffer = false;
        this.renderer.physicallyCorrectLights = true;
        this.renderer.toneMapping = this.params.toneMappingValue(this.params.toneMapping);
        this.renderer.toneMappingExposure = this.params.toneMappingExposure;
        this.renderer.toneMappingWhitePoint = this.params.toneMappingWhitePoint;
        this.renderer.outputEncoding = THREE.sRGBEncoding; // same as gamma factor 2.2
        this.renderer.xr.enabled = true;



        this.renderer.domElement.classList.add('chapter-canvas')
        container.appendChild(this.renderer.domElement)

        //MAIN SCENE INSTANCE
        this.scene = new THREE.Scene()
        //  this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000);
        this.camera = new THREE.PerspectiveCamera(31.417275332887456, window.innerWidth / window.innerHeight, 1, 100000000);
        //this.camera.position.set(-3217.8555555227963, 297.18585617352204, -1969.2612389526046)
        // light
        this.light5 = new THREE.PointLight(0xffffff, 1);
        this.camera.add(this.light5);

        this.light = new THREE.DirectionalLight(0xffffff, 1);
        this.light.position.set(54.000, 10.000, -53.000);
        this.scene.add(this.light);


        this.light3 = new THREE.DirectionalLight(0xffffff, 1);
        this.light3.position.set(6790.000, 120.000, 175.000);
        this.scene.add(this.light3);

        // this.light2 = new THREE.DirectionalLight(0xffffff, 1);
        // this.light2.position.set(-200, 10, 7.149);
        // this.scene.add(this.light2);

        //RENDER LOOP AND WINDOW SIZE UPDATER SETUP
        window.addEventListener("resize", this.resizeCanvas)
        RAF.subscribe('threeSceneUpdate', this.update)

        this.light4 = new THREE.AmbientLight(0xffffff); // soft white light
        this.scene.add(this.light4);


        this.composer = new EffectComposer(this.renderer);

        let renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(renderPass);
        this.renderer.autoClear = true;

        DesertScene.init(this.scene, this.camera, this.renderer, this.stats)
        BackgroundScene.init(this.scene, this.camera, this.renderer, this.composer)

        this.scene.autoUpdate = false;
        this.scene.matrixAutoUpdate = false;
        //   this.controls = new OrbitControls(this.camera, this.renderer.domElement)



        emitter.on('chapterDone', () => {
            RAF.unsubscribe('threeSceneUpdate', this.update)
        })


    }

    update() {
        this.composer.render();
        DesertScene.update(this.camera);
        BackgroundScene.update();


        if (this.stats) {
            this.stats.update();
        }
    }

    resizeCanvas() {


        if (isMobile()) {
            if (window.innerWidth > window.innerHeight) {
                this.pause = true;
                DesertScene.pauseScene()
            }
            else {
                this.pause = false;
                DesertScene.resumeScene()
            }
        }
        else {
            this.renderer.setSize(window.innerWidth, window.innerHeight)
            this.camera.aspect = window.innerWidth / window.innerHeight
            this.camera.updateProjectionMatrix()
        }




    }

    bind() {
        this.resizeCanvas = this.resizeCanvas.bind(this)
        this.update = this.update.bind(this)
        this.init = this.init.bind(this)
    }


    destroy() {
        //  this.renderer && this.container.removeChild(this.renderer.domElement)
        RAF.unsubscribe('threeSceneUpdate', this.update)
        DesertScene.destroy()
        BackgroundScene.dispose()

        this.camera = null
        this.renderer && this.renderer.renderLists.dispose()


        this.light.dispose()
        //  this.light2.dispose()
        this.light3.dispose()
        this.light4.dispose()
        this.light5.dispose()

        const cleanMaterial = material => {
            //   console.log('dispose material!')
            material.dispose()

            // dispose textures
            for (const key of Object.keys(material)) {
                const value = material[key]
                if (value && typeof value === 'object' && 'minFilter' in value) {
                    //    console.log('dispose texture!')
                    value.dispose()
                }
            }
        }

        //  console.log('dispose renderer!')
        this.renderer && this.renderer.dispose()
        this.scene.traverse(object => {
            if (!object.isMesh) return

            //  console.log('dispose geometry!')
            object.geometry.dispose()

            if (object.material.isMaterial) {
                cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) cleanMaterial(material)
            }
        })

        this.renderer.forceContextLoss()
        this.renderer.context = null
        this.renderer.domElement = null


        this.scene.clear()
        this.renderer.clear()
        this.camera = null;
        this.composer.dispose();


        this.composer.dispose()
        THREE.Cache.clear()
        this.renderer.forceContextLoss();


    }
}

const _instance = new MainScene()
export default _instance