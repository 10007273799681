export const trees = [
    {
        "x": -149.02300234985353,
        "y": 0.4143097577095034,
        "z": 397.901520324707
    },
    {
        "x": -157.99376651000978,
        "y": 2.4195349392890932,
        "z": 404.64215905761716
    },
    {
        "x": -205.52826281738282,
        "y": 0.06040980195999168,
        "z": 381.526032043457
    },
    {
        "x": -196.41218157958986,
        "y": 2.355938213825226,
        "z": 384.27691705322263
    },
    {
        "x": -304.9841954345703,
        "y": 4.880739468097687,
        "z": 342.67289025878904
    },
    {
        "x": -255.34775134277345,
        "y": 4.880739468097687,
        "z": 360.4039541015625
    },
    {
        "x": -294.41029711914064,
        "y": 4.880739468097687,
        "z": 215.576569152832
    },
    {
        "x": -285.4061314697266,
        "y": 2.355938213825226,
        "z": 242.10854776000974
    },
    {
        "x": -304.9639470214844,
        "y": 4.880739468097687,
        "z": 307.5004773864746
    },
    {
        "x": -272.7605778808594,
        "y": 4.880739468097687,
        "z": 268.54272205924985
    },
    {
        "x": 32.04179028320313,
        "y": 1.445512073993683,
        "z": 212.2071958312988
    },
    {
        "x": 24.663097656250002,
        "y": 10.155384319782257,
        "z": 217.69501550292966
    },
    {
        "x": 33.26299694824219,
        "y": 5.479465740680695,
        "z": 220.79935128784177
    },
    {
        "x": 27.062053955078127,
        "y": 10.155384319782257,
        "z": 304.4522710571289
    },
    {
        "x": 33.78355053710938,
        "y": 7.507295864582062,
        "z": 297.48249681091306
    },
    {
        "x": 38.36022595214844,
        "y": 4.880739468097687,
        "z": 303.48190362548826
    },
    {
        "x": 12.418339050292971,
        "y": 11.606594341754914,
        "z": 361.164330078125
    },
    {
        "x": 0.3367274169921899,
        "y": 6.976385372638703,
        "z": 368.4960561523437
    },
    {
        "x": 4.525257385253909,
        "y": 1.3104870495796206,
        "z": 375.2520757446289
    },
    {
        "x": 10.010830200195315,
        "y": 1.3104870495796206,
        "z": 318.0429921875
    },
    {
        "x": -63.15281649780273,
        "y": 0.466091829299927,
        "z": 163.79959924316404
    },
    {
        "x": -72.03680392456054,
        "y": 2.058946448326111,
        "z": 166.1681447753906
    },
    {
        "x": -68.86839266967773,
        "y": 4.033043163776398,
        "z": 169.82362420654295
    },
    {
        "x": 94.66514233398438,
        "y": 4.033043163776398,
        "z": 327.54812295532224
    },
    {
        "x": 85.50992993164063,
        "y": 0.18728186464309715,
        "z": 334.54780252075193
    },
    {
        "x": 91.27538708496094,
        "y": 4.880739468097687,
        "z": 336.3349385986328
    },
    {
        "x": -14.879481994628904,
        "y": 0.7405750927925112,
        "z": 213.46326882934568
    },
    {
        "x": -9.95129748535156,
        "y": 4.880739468097687,
        "z": 206.5614095458984
    },
    {
        "x": -244.5057255859375,
        "y": 4.880739468097687,
        "z": 285.22251470184324
    },
    {
        "x": -180.01472064208986,
        "y": -1.3136079134941099,
        "z": 367.8117223510742
    },
    {
        "x": -194.10331317138673,
        "y": 2.355938213825226,
        "z": 303.95256097412107
    },
    {
        "x": -229.79308673095704,
        "y": 2.355938213825226,
        "z": 258.47186524963377
    },
    {
        "x": -114.08490654182434,
        "y": 2.355938213825226,
        "z": 300.66704995727537
    },
    {
        "x": -70.87533923339844,
        "y": 2.355938213825226,
        "z": 276.6789723167419
    },
    {
        "x": -182.55625506591798,
        "y": 2.55010058259964,
        "z": 229.32196099853513
    },
    {
        "x": -37.326022827148435,
        "y": 0.1311237988471987,
        "z": 301.87323625183103
    },
    {
        "x": -41.63835498046875,
        "y": 0.5956652340888979,
        "z": 273.21721751785276
    },
    {
        "x": -56.17302294921875,
        "y": 4.50346322631836,
        "z": 339.0933690795898
    },
    {
        "x": -232.30023547363282,
        "y": 4.880739468097687,
        "z": 212.6167188415527
    },
    {
        "x": -201.22394915771486,
        "y": 4.880739468097687,
        "z": 261.159203125
    },
    {
        "x": -152.27459689331056,
        "y": 4.880739468097687,
        "z": 311.1006398925781
    },
    {
        "x": -149.78357669067384,
        "y": 2.355938213825226,
        "z": 274.7386635074615
    },
    {
        "x": -106.9030177230835,
        "y": 2.355938213825226,
        "z": 367.92131097412107
    },
    {
        "x": -125.03684397888183,
        "y": 2.355938213825226,
        "z": 198.52612359619138
    },
    {
        "x": -169.78983660888673,
        "y": 4.880739468097687,
        "z": 184.97744042968748
    },
    {
        "x": -75.81032916259765,
        "y": 5.674630599975586,
        "z": 224.24457604980466
    },
    {
        "x": -191.8920018310547,
        "y": 4.880739468097687,
        "z": 334.11208779907224
    },
    {
        "x": -116.5726258392334,
        "y": 4.880739468097687,
        "z": 222.49106271362302
    },
    {
        "x": -77.55335589599609,
        "y": 4.880739468097687,
        "z": 330.8581853637695
    },
    {
        "x": -10.716670715332029,
        "y": 1.0649197278022768,
        "z": 305.24679620361326
    },
    {
        "x": -41.092632019042966,
        "y": 6.250047999382019,
        "z": 232.08514077758787
    },
    {
        "x": 39.30248669433594,
        "y": 10.155384319782257,
        "z": 418.72477014160154
    },
    {
        "x": 46.02398327636719,
        "y": 7.507295864582062,
        "z": 411.75499780273435
    },
    {
        "x": 50.60065869140625,
        "y": 4.880739468097687,
        "z": 417.7544027099609
    },
    {
        "x": 53.06107739257813,
        "y": 10.155384319782257,
        "z": 423.44493920898435
    },
    {
        "x": 59.78258923339844,
        "y": 7.507295864582062,
        "z": 416.4751516113281
    },
    {
        "x": 64.3592646484375,
        "y": 4.880739468097687,
        "z": 422.4745717773437
    },
    {
        "x": 54.74486950683594,
        "y": 10.155384319782257,
        "z": 436.9537893066406
    },
    {
        "x": 61.46636608886719,
        "y": 7.507295864582062,
        "z": 429.9840169677734
    },
    {
        "x": 66.04304150390625,
        "y": 4.880739468097687,
        "z": 435.983421875
    },
    {
        "x": -112.87460859966278,
        "y": 10.155384319782257,
        "z": 392.280296875
    },
    {
        "x": -106.15310736846924,
        "y": 7.507295864582062,
        "z": 385.31051690673826
    },
    {
        "x": -101.57643099975586,
        "y": 4.880739468097687,
        "z": 391.30992944335935
    },
    {
        "x": -134.0649239654541,
        "y": 10.155384319782257,
        "z": 408.1395345458984
    },
    {
        "x": -127.34342261505127,
        "y": 7.507295864582062,
        "z": 401.16974694824216
    },
    {
        "x": -122.76674624633789,
        "y": 4.880739468097687,
        "z": 407.1691671142578
    },
    {
        "x": -140.69708224487306,
        "y": 10.155384319782257,
        "z": 392.9403844604492
    },
    {
        "x": -129.39890452575685,
        "y": 4.880739468097687,
        "z": 391.97001702880857
    },
    {
        "x": -93.62945719909668,
        "y": 10.155384319782257,
        "z": 194.7082601318359
    },
    {
        "x": -56.890563690185544,
        "y": 10.155384319782257,
        "z": 218.4093442687988
    },
    {
        "x": -210.21735736083986,
        "y": 10.155384319782257,
        "z": 193.43952233886716
    },
    {
        "x": -203.49585314941407,
        "y": 7.507295864582062,
        "z": 186.46974999999998
    },
    {
        "x": -198.919177734375,
        "y": 4.880739468097687,
        "z": 192.46915490722654
    },
    {
        "x": -240.08111926269532,
        "y": 10.155384319782257,
        "z": 188.75990350341795
    },
    {
        "x": -233.359607421875,
        "y": 7.507295864582062,
        "z": 181.79012353515623
    },
    {
        "x": -228.78293200683595,
        "y": 4.880739468097687,
        "z": 187.78953607177732
    },
    {
        "x": -211.8092152709961,
        "y": 10.155384319782257,
        "z": 175.9712682495117
    },
    {
        "x": -205.08771105957032,
        "y": 7.507295864582062,
        "z": 169.00148828124998
    },
    {
        "x": -200.51103564453126,
        "y": 4.880739468097687,
        "z": 175.00090081787107
    },
    {
        "x": -242.02113696289064,
        "y": 10.155384319782257,
        "z": 168.64332635498045
    },
    {
        "x": -235.2996403808594,
        "y": 7.507295864582062,
        "z": 161.67354638671873
    },
    {
        "x": -230.72296496582032,
        "y": 4.880739468097687,
        "z": 167.67295892333982
    },
    {
        "x": -130.04594584655763,
        "y": 10.155384319782257,
        "z": 111.66979272460935
    },
    {
        "x": -123.3244454498291,
        "y": 7.507295864582062,
        "z": 104.70002038574216
    },
    {
        "x": -118.74776908111572,
        "y": 4.880739468097687,
        "z": 110.69942529296873
    },
    {
        "x": -101.5419690246582,
        "y": 10.155384319782257,
        "z": 106.62717492675779
    },
    {
        "x": -94.82046862792969,
        "y": 7.507295864582062,
        "z": 99.6574025878906
    },
    {
        "x": -90.24379130554199,
        "y": 4.880739468097687,
        "z": 105.65680749511716
    },
    {
        "x": -96.18484183502197,
        "y": 10.155384319782257,
        "z": 121.68817956542966
    },
    {
        "x": -89.46334048461914,
        "y": 7.507295864582062,
        "z": 114.71840722656248
    },
    {
        "x": -84.88666506958008,
        "y": 4.880739468097687,
        "z": 120.71781213378904
    },
    {
        "x": -47.277751647949216,
        "y": 4.880739468097687,
        "z": 401.48900659179685
    },
    {
        "x": -54.70188876342773,
        "y": 7.729885357379914,
        "z": 396.91476495361326
    },
    {
        "x": -45.20801135253906,
        "y": 7.729885357379914,
        "z": 393.3743444213867
    },
    {
        "x": -55.88903399658203,
        "y": 7.729885357379914,
        "z": 410.8301320800781
    },
    {
        "x": -44.8030888671875,
        "y": 7.729885357379914,
        "z": 409.6128774414062
    },
    {
        "x": 2.377773559570315,
        "y": 9.50274141407013,
        "z": 158.5772023925781
    },
    {
        "x": -2.151546203613279,
        "y": 7.729885357379914,
        "z": 152.85702569580076
    },
    {
        "x": -9.244060241699216,
        "y": 12.704042392730713,
        "z": 157.04400689697263
    },
    {
        "x": -13.363704406738279,
        "y": 9.926988857746124,
        "z": 148.17711694335935
    },
    {
        "x": -2.22034045410156,
        "y": 10.323795157432556,
        "z": 146.4421926269531
    },
    {
        "x": 8.030826843261721,
        "y": 5.869739490509033,
        "z": 148.82587487792966
    },
    {
        "x": 11.405834472656252,
        "y": 9.769030529022217,
        "z": 155.7887960205078
    },
    {
        "x": 62.41361645507813,
        "y": 4.880739468097687,
        "z": 207.84750421142576
    },
    {
        "x": 56.31133679199219,
        "y": 7.500934856891632,
        "z": 203.60778863525388
    },
    {
        "x": 69.76711682128906,
        "y": 4.325605648517609,
        "z": 200.37728173828123
    },
    {
        "x": 58.095424926757815,
        "y": 7.507295864582062,
        "z": 331.8121000061035
    },
    {
        "x": 101.22610119628906,
        "y": 3.3976919827461245,
        "z": 363.02369744873045
    },
    {
        "x": 43.47457531738284,
        "y": 4.880739468097687,
        "z": 136.92662866210935
    },
    {
        "x": 44.96087292480469,
        "y": 7.500934856891632,
        "z": 129.6462484130859
    },
    {
        "x": 53.24105480957037,
        "y": 4.325605648517609,
        "z": 140.73375756835935
    }
]