import * as THREE from 'three';
import { palms } from './TreesPositions';
import { loadTexture } from "../../utils/asyncLoaders";
import { isMobile } from '../../utils/device';

class TreesScene {
    constructor() {
        this.bind();

        this.geo;
        this.mat;

        this.mesh = null;
        this.dummy = new THREE.Object3D();
        this.sectionWidth = 200;

        this.palms;
        this.trees, this.bushes;
        this.geoBush;
        this.matBush;
        this.meshBush;

        this.geoTree;
        this.matTree;
        this.meshTree;

        this.bushTexture;
        this.treeTexture;

    }

    addInstancedMesh() {
        this.mesh = new THREE.InstancedMesh(this.geo, this.mat, this.palms.length);
        this.scene.add(this.mesh);
        this.setInstancedMeshPositions(this.mesh, this.palms);
    }

    addInstancedMeshBush() {
        this.meshBush = new THREE.InstancedMesh(this.geoBush, this.matBush, 305);
        this.scene.add(this.meshBush);
        this.setInstancedMeshBushPositions(this.meshBush);
    }

    addInstancedMeshTree() {
        this.meshTree = new THREE.InstancedMesh(this.geoTree, this.matTree, 45);
        this.scene.add(this.meshTree);
        this.setInstancedMeshTreesPositions(this.meshTree);
    }

    setInstancedMeshPositions(mesh, palmArray) {
        for (var i = 0; i < mesh.count; i++) {
            this.dummy.position.set(palmArray[i].position.x, palmArray[i].position.y, palmArray[i].position.z);
            this.dummy.scale.set(palmArray[i].scale.x, palmArray[i].scale.y, palmArray[i].scale.z);
            this.dummy.quaternion.set(palmArray[i].rotation.x, palmArray[i].rotation.y, palmArray[i].rotation.z, palmArray[i].rotation.w);

            this.dummy.updateMatrix();
            mesh.setMatrixAt(i, this.dummy.matrix);
        }
        mesh.instanceMatrix.needsUpdate = true;
    }

    setInstancedMeshBushPositions(mesh) {
        for (var i = 0; i < mesh.count; i++) {
            this.dummy.position.set(this.bushes[i].position.x, this.bushes[i].position.y, this.bushes[i].position.z);
            //    this.dummy.rotation.set(this.bushes[i].rotation.x, this.bushes[i].rotation.y, this.bushes[i].rotation.z);
            this.dummy.scale.set(this.bushes[i].scale.x, this.bushes[i].scale.y, this.bushes[i].scale.z);
            this.dummy.quaternion.set(this.bushes[i].rotation.x, this.bushes[i].rotation.y, this.bushes[i].rotation.z, this.bushes[i].rotation.w);

            this.dummy.updateMatrix();


            mesh.setMatrixAt(i, this.dummy.matrix);
        }
        mesh.instanceMatrix.needsUpdate = true;
    }
    setInstancedMeshTreesPositions(mesh) {
        for (var i = 0; i < mesh.count; i++) {
            this.dummy.position.set(this.trees[i].position.x, this.trees[i].position.y, this.trees[i].position.z);
            this.dummy.scale.set(this.trees[i].scale.x, this.trees[i].scale.y, this.trees[i].scale.z);
            this.dummy.quaternion.set(this.trees[i].rotation.x, this.trees[i].rotation.y, this.trees[i].rotation.z, this.trees[i].rotation.w);
            //  this.dummy.quaternion.set(1.571, 0, -1.571);
            this.dummy.updateMatrix();
            mesh.setMatrixAt(i, this.dummy.matrix);
        }
        mesh.instanceMatrix.needsUpdate = true;
    }


    async init(scene, camera, geo, bushes, geoBush, trees, geoTree) {
        this.scene = scene
        this.camera = camera

        this.palms = palms;

        this.bushTexture = await loadTexture('../models/chapter2/scene/mats/bush.png')
        this.bushes = bushes;
        this.geoBush = geoBush;
        this.matBush = new THREE.MeshStandardMaterial({
            map: this.bushTexture,
            depthTest: true,
            depthWrite: false,
            transparent: true,
            metalness: 0.5,
            side: THREE.DoubleSide,
            color: new THREE.Color(0x6F7070),
            alphaTest: 0.01
        })

        this.treeTexture = await loadTexture('../models/chapter2/scene/mats/tree.png')
        this.trees = trees;
        this.geoTree = geoTree;
        this.matTree = new THREE.MeshStandardMaterial({
            map: this.treeTexture,
            depthTest: true,
            metalness: 0.5,
            depthWrite: false,
            transparent: true,
            side: THREE.DoubleSide,
            color: new THREE.Color(0x6F7070),
            alphaTest: 0.01
        })




        this.treeMat = await loadTexture('../models/chapter2/scene/mats/palm.png')
        this.geo = geo;// gltf.scene.children[0].children[0].geometry;

        this.palms = palms
        this.mat = new THREE.MeshStandardMaterial({
            map: this.treeMat,
            depthTest: true,
            metalness: 0.5,
            depthWrite: false,
            transparent: true,
            side: THREE.DoubleSide,
            color: new THREE.Color(0x6F7070),
            alphaTest: 0.01
        })


        this.addInstancedMesh();

        if (!isMobile()) {
            this.addInstancedMeshBush();
            this.addInstancedMeshTree();

        }
    }


    update(camera) {

    }

    bind() {
        this.init = this.init.bind(this)
    }

    destroy() {
        this.palms = []
        this.trees = []
        this.bushes = []
        this.disposeNode(this.matBush);
        this.disposeNode(this.geo);
        this.disposeNode(this.mat);
        this.disposeNode(this.mesh);
        this.disposeNode(this.dummy);
        this.disposeNode(this.geoBush);
        this.disposeNode(this.matBush);
        this.disposeNode(this.geoTree);
        this.disposeNode(this.matTree);
        this.disposeNode(this.meshTree);
        this.disposeNode(this.meshBush);
        this.disposeNode(this.bushTexture);
        this.disposeNode(this.treeTexture);

    }

    disposeNode(node) {
        if (node instanceof THREE.Mesh) {
            if (node.geometry) {
                node.geometry.dispose();
            }

            if (node.material) {

                if (node.material.map) node.material.map.dispose();
                if (node.material.lightMap) node.material.lightMap.dispose();
                if (node.material.bumpMap) node.material.bumpMap.dispose();
                if (node.material.normalMap) node.material.normalMap.dispose();
                if (node.material.specularMap) node.material.specularMap.dispose();
                if (node.material.envMap) node.material.envMap.dispose();
                if (node.material.alphaMap) node.material.alphaMap.dispose();
                if (node.material.aoMap) node.material.aoMap.dispose();
                if (node.material.displacementMap) node.material.displacementMap.dispose();
                if (node.material.emissiveMap) node.material.emissiveMap.dispose();
                if (node.material.gradientMap) node.material.gradientMap.dispose();
                if (node.material.metalnessMap) node.material.metalnessMap.dispose();
                if (node.material.roughnessMap) node.material.roughnessMap.dispose();

                node.material.dispose();   // disposes any programs associated with the material

            }
        }
    }   // disposeNode

    disposeHierarchy(node, callback) {
        for (var i = node.children.length - 1; i >= 0; i--) {
            var child = node.children[i];
            this.disposeHierarchy(child, callback);
            // callback(child);
        }
    }


}

const _instance = new TreesScene()
export default _instance