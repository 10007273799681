import * as THREE from 'three';
import { loadGLTF, loadTexture, loadAudio } from "../../utils/asyncLoaders";
import gsap from "gsap";
import TreesScene from './TreesScene';
import { Plane } from 'three'
import emitter from 'tiny-emitter/instance';
import { clamp } from 'three/src/math/MathUtils';
import { hotspots, setActiveHotspot } from './HotspotsPositions';
import { isMobile } from '../../utils/device';

class DesertScene {
    constructor() {
        this.bind();
        this.pause = true;
        this.cameraAnimationMixers = []
        this.obeidAnimationMixer;
        this.sheperdAnimationMixer;
        this.obeidLastAnimationMixer;

        this.stats;
        this.fpsSpeed = 60;
        this.gltfScene;
        this.count = 1;
        this.sceneLineMesh;
        this.sceneLineMesh2;
        this.lineMesh;
        this.lineMesh2;

        this.scene1_obeid;
        this.phase = 0;
        this.phase1Group = [];
        this.phase2Group = [];
        this.phase3Group = [];
        this.phase4Group = [];
        this.phase5Group = [];

        this.phase4Visited = false;

        this.phase2Mixer;
        this.phase3Mixer;
        this.phase4Mixer;
        this.phase5Mixer;
        this.phase6Mixer;
        this.lineMixer;

        this.mixer;
        this.activeAction;
        this.cameraPos;
        this.camera;
        this.renderer;

        this.wheelSpeed = 0.02;
        this.maxDist = 2.0;
        this.minDist = 1.0;
        this.dist = 2;
        this.startDragScroll = 0

        this.mixer;
        this.modelReady = false
        this.animationActions = []
        this.activeAction;
        this.lastAction;
        this.animationsFolder;
        this.absScroll = 0;
        this.smoothProgress = 0;


        this.lastTime = 0;

        this.MAX_SCROLL_TIME_DELTA = 20;
        this.SCROLL_SCALAR = 0.01;
        this.MAX_SCROLL_DELTA = 5;
        this.transitioning = false;

        this.playPhase1 = true;
        this.playPhase2 = false;
        this.playPhase3 = false;
        this.playPhase4 = false;
        this.playPhase5 = false;
        this.playPhase5 = false;
        this.SCROLL_SCALAR_MOBILE = 0.01;

        this.audioListener = new THREE.AudioListener();
        this.phase1Audio = new THREE.Audio(this.audioListener);
        this.phase2Audio = new THREE.Audio(this.audioListener);
        this.phase3Audio = new THREE.Audio(this.audioListener);
        this.phase4Audio = new THREE.Audio(this.audioListener);
        this.phase5Audio = new THREE.Audio(this.audioListener);
        this.phase6Audio = new THREE.Audio(this.audioListener);
        this.bgAudio = new THREE.Audio(this.audioListener);
        this.desertWindAudio = new THREE.Audio(this.audioListener);
        this.flagAudio = new THREE.Audio(this.audioListener);
        this.crowd1Audio = new THREE.Audio(this.audioListener);
        this.crowd2Audio = new THREE.Audio(this.audioListener);
        this.peopleDisappearing = new THREE.Audio(this.audioListener);
        this.phase4AudioPlayed = false;

        this.animationSpeed = 1;

        this.raycaster;
        this.hotspots = []
        this.delta = new THREE.Vector2();
        this.lastInputPosition = new THREE.Vector2(0, 0);
        this.pointer = new THREE.Vector2();
        this.pointerCopy = new THREE.Vector2();
        this.selectedOnDown = null;

        this.leaving_convoy;
        this.textures;
        this.trees = [];
        this.bushes = [];
        this.palms = [];

        this.buildings = []

        this.village;
        this.village1;
        this.village1_population;
        this.village2;
        this.village2_population;
        this.base_stone;
        this.landscape_build;
        this.farming;
        this.cliff_rocks;
        this.palms_last;


        this.horseAnimation;
        this.character1Animation;
        this.character2Animation;
        this.flag = [];

        this.speedPhase1 = 0.011;
        this.speedPhase2 = 0.016;
        this.speedPhase3 = 0.01;
        this.speedPhase4 = 0.08;
        this.speedPhase5 = 0.01;
        this.speedPhase6 = 0.016;

        this.voiceVolume = 0.5;
        this.bgVolume = 0.1;
        this.desertWindVolume = 0.6;
        this.sceneCamera;
        this.sceneCamera1 = new THREE.Camera();
        //intersected objects
        this.raycaster;

        emitter.on('pauseScene', (isPaused) => {
            if (isPaused) {
                this.pauseScene()
            }
            else {
                this.resumeScene()
            }
        })
        emitter.on('startExperience3', () => {
            document.getElementById('scrollAction').classList.add('show')

            document.getElementById('actionIntro').classList.remove('show');
            this.phase = 1;
            this.setUpActions(1)
        })
        emitter.on('updateAudio', (isAudioOn) => {

            if (isAudioOn) {

                this.bgAudio.setVolume(0.1);
                this.phase1Audio.setVolume(0.5)
                this.phase2Audio.setVolume(0.5)
                this.phase3Audio.setVolume(0.5)
                this.phase4Audio.setVolume(0.5)
                this.phase5Audio.setVolume(0.5)
                this.desertWindAudio.setVolume(0.6)
                this.flagAudio.setVolume(1)
                this.crowd1Audio.setVolume(0.2)
                this.crowd2Audio.setVolume(0.2)
                this.peopleDisappearing.setVolume(0.2)

            }
            else {
                this.bgAudio.setVolume(0);
                this.phase1Audio.setVolume(0)
                this.phase2Audio.setVolume(0)
                this.phase3Audio.setVolume(0)
                this.phase4Audio.setVolume(0)
                this.phase5Audio.setVolume(0)
                this.desertWindAudio.setVolume(0)
                this.flagAudio.setVolume(0)
                this.crowd1Audio.setVolume(0)
                this.crowd2Audio.setVolume(0)
                this.peopleDisappearing.setVolume(0)
            }
        });

    }




    async init(scene, camera, renderer, stats) {

        this.scene = scene
        this.camera = camera
        this.renderer = renderer
        this.stats = stats
        this.raycaster = new THREE.Raycaster()
        //  this.raycaster.near = 80


        var audiosToBeLoaded = [
            loadAudio('../models/chapter3/audio/phase1.mp3'),
            loadAudio('../models/chapter3/audio/phase2.mp3'),
            loadAudio('../models/chapter3/audio/phase3.mp3'),
            loadAudio('../models/chapter3/audio/phase4.mp3'),
            loadAudio('../models/chapter3/audio/bg.mp3'),
            loadAudio('../models/chapter3/audio/DesertWind.mp3'),
            loadAudio('../models/chapter3/audio/flag.mp3'),
            loadAudio('../models/chapter3/audio/crowd1.mp3'),
            loadAudio('../models/chapter3/audio/crowd2.mp3'),
            loadAudio('../models/chapter3/audio/people-disappearing.mp3'),

        ]
        let audios = await Promise.all(audiosToBeLoaded);
        this.setUpAudio(audios);

        var texturesToBeLoaded = [
            loadTexture('../models/chapter3/scene/mats/bush.png'),
            loadTexture('../models/chapter3/scene/mats/cloth-market.jpg'),
            loadTexture('../models/chapter3/scene/mats/cloth-market2.jpg'),
            loadTexture('../models/chapter3/scene/mats/desert-land.jpg'),
            loadTexture('../models/chapter3/scene/mats/door.jpg'),
            loadTexture('../models/chapter3/scene/mats/grass_1.jpg'),
            loadTexture('../models/chapter3/scene/mats/market1.png'),
            loadTexture('../models/chapter3/scene/mats/market2.png'),
            loadTexture('../models/chapter3/scene/mats/market3.png'),
            loadTexture('../models/chapter3/scene/mats/market4.png'),
            loadTexture('../models/chapter3/scene/mats/market5.png'),
            loadTexture('../models/chapter3/scene/mats/market6.png'),
            loadTexture('../models/chapter3/scene/mats/market7.png'),
            loadTexture('../models/chapter3/scene/mats/market8.png'),
            loadTexture('../models/chapter3/scene/mats/market9.png'),
            loadTexture('../models/chapter3/scene/mats/market10.png'),
            loadTexture('../models/chapter3/scene/mats/market11.png'),
            loadTexture('../models/chapter3/scene/mats/market12.png'),
            loadTexture('../models/chapter3/scene/mats/market13.png'),
            loadTexture('../models/chapter3/scene/mats/messenger1.png'),
            loadTexture('../models/chapter3/scene/mats/messenger2.png'),
            loadTexture('../models/chapter3/scene/mats/messenger3.png'),
            loadTexture('../models/chapter3/scene/mats/messenger4.png'),
            loadTexture('../models/chapter3/scene/mats/p1.png'),
            loadTexture('../models/chapter3/scene/mats/p2.png'),
            loadTexture('../models/chapter3/scene/mats/p3.png'),
            loadTexture('../models/chapter3/scene/mats/p4.png'),
            loadTexture('../models/chapter3/scene/mats/p6.png'),
            loadTexture('../models/chapter3/scene/mats/palm.png'),
            loadTexture('../models/chapter3/scene/mats/rock.jpg'),
            loadTexture('../models/chapter3/scene/mats/round-artifact1.jpg'),
            loadTexture('../models/chapter3/scene/mats/round-artifact2.png'),
            loadTexture('../models/chapter3/scene/mats/round-artifact3.png'),
            loadTexture('../models/chapter3/scene/mats/shep2.png'),
            loadTexture('../models/chapter3/scene/mats/sil1-man.png'),
            loadTexture('../models/chapter3/scene/mats/sil1-woman-market.png'),
            loadTexture('../models/chapter3/scene/mats/sil1-woman.png'),
            loadTexture('../models/chapter3/scene/mats/sil2-man.png'),
            loadTexture('../models/chapter3/scene/mats/sil2-woman.png'),
            loadTexture('../models/chapter3/scene/mats/sil3-man.png'),
            loadTexture('../models/chapter3/scene/mats/tree.png'),
            loadTexture('../models/chapter3/scene/mats/village-distant.png'),
            loadTexture('../models/chapter3/scene/mats/walls-houses.jpg'),
            loadTexture('../models/chapter3/scene/mats/walls-houses.jpg'),
            loadTexture('../models/chapter3/scene/mats/flag.jpg'),
            loadTexture('../models/chapter3/scene/mats/obeid-clothes.jpg'),
            loadTexture('../models/chapter1/scene/mats/dash.png'),
            loadTexture('../models/chapter1/scene/mats/line.jpg'),

        ]
        this.textures = await Promise.all(texturesToBeLoaded);

        let modelUrl = isMobile() ? "../models/chapter3/scene/scene_mobile-baked.glb" : "../models/chapter3/scene/scene-baked.glb"
        var gltf = await loadGLTF(modelUrl);
        this.gltfScene = gltf.scene;
        this.setUpCamera(gltf)
        await this.addLine2(this.textures);

        await this.setUpTextures(gltf, this.textures);
        this.scene.add(gltf.scene)
        await this.addLine(this.textures);

        await this.loadCharacter(this.textures)

        this.addSprite()

        if (isMobile()) {
            window.addEventListener('touchmove', this.handleTouchMove.bind(this), false);
            // window.addEventListener('touchstart', this.handleTouchStart.bind(this), false);
            // window.addEventListener('touchend', this.handleTouchEnd.bind(this), false);
            // window.addEventListener('touchcancel', this.handleTouchEnd.bind(this), false);
        }
        else {

            window.addEventListener("wheel", (e) => {
                if (this.lastTime) {
                    const timeDelta = Date.now() - this.lastTime;
                    if (timeDelta < this.MAX_SCROLL_TIME_DELTA) return;
                }
                this.lastTime = Date.now();

                if (this.transitioning) return;
                const d = clamp(e.deltaY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);

                if (this.phase == 4) {
                    this.handleScroll(d * this.SCROLL_SCALAR)
                }
                else if (this.phase == 1) {
                    this.handleScroll(d * this.SCROLL_SCALAR)
                }




            }, { passive: false });
        }


        // window.addEventListener('mousedown', this.handleMouseDown.bind(this), true);
        // window.addEventListener('mousemove', this.handleMouseMove.bind(this), true);
        // window.addEventListener('mouseup', this.handleMouseUp.bind(this), false);


        // document.getElementById('close_hotspot').addEventListener('click', () => {
        //     document.getElementById('hotspot').classList.remove('show')
        //     gsap.to('#hotspot', { opacity: 0, display: 'none', duration: 0.2 })
        //     this.pause = false;
        //     this.resumeAudio()
        // })



    }

    resumeAudio() {
        if (this.phase == 1) {
            this.phase1Audio.play()
        }
        if (this.phase == 2) {
            this.phase2Audio.play()
        }
        if (this.phase == 3) {
            this.phase3Audio.play()
        }
        if (this.phase == 4 && !this.phase4AudioPlayed) {
            this.phase4Audio.play()
        }
        if (this.phase == 5) {
            this.phase5Audio.play()
        }
        if (this.phase == 6) {
            this.phase6Audio.play()
        }
    }

    pauseScene() {
        this.pause = true
        this.pauseAudio()
    }
    resumeScene() {
        this.pause = false
        this.resumeAudio()
    }
    pauseAudio() {
        if (this.phase == 1) {
            this.phase1Audio.pause()
            this.phase2Audio.pause()
        }
        if (this.phase == 2) {
            this.phase2Audio.pause()
            this.phase1Audio.pause()
        }
        if (this.phase == 3) {
            this.phase3Audio.pause()
        }
        if (this.phase == 4) {
            this.phase4Audio.pause()
        }
        if (this.phase == 5) {
            this.phase5Audio.pause()
        }
        if (this.phase == 6) {
            this.phase6Audio.pause()
        }
    }
    setUpAudio(audios) {




        this.phase1Audio.setBuffer(audios[0]);
        this.phase1Audio.setPlaybackRate(1.07)
        this.phase1Audio.setLoop(false);
        this.phase1Audio.setVolume(0.5);
        this.phase1Audio.pause();

        this.phase2Audio.setBuffer(audios[1]);
        this.phase2Audio.setPlaybackRate(1.08)
        this.phase2Audio.setLoop(false);
        this.phase2Audio.setVolume(0.5);
        this.phase2Audio.pause();

        this.phase3Audio.setBuffer(audios[2]);
        this.phase3Audio.setPlaybackRate(1.1)
        this.phase3Audio.setLoop(false);
        this.phase3Audio.setVolume(0.5);
        this.phase3Audio.pause();

        this.phase4Audio.setBuffer(audios[3]);
        this.phase4Audio.setPlaybackRate(1.1)
        this.phase4Audio.setLoop(false);
        this.phase4Audio.setVolume(0.5);
        this.phase4Audio.pause();
        this.phase4Audio.onEnded = () => {
            this.phase4AudioPlayed = true;
        }



        this.bgAudio.setBuffer(audios[4]);
        this.bgAudio.setLoop(true);
        this.bgAudio.setVolume(0.1);
        this.bgAudio.pause();

        this.desertWindAudio.setBuffer(audios[5]);
        this.desertWindAudio.setLoop(true);
        this.desertWindAudio.setVolume(0.6);
        this.desertWindAudio.pause();

        this.flagAudio.setBuffer(audios[6]);
        this.flagAudio.setLoop(false);
        this.flagAudio.setVolume(1);
        this.flagAudio.pause();

        this.crowd1Audio.setBuffer(audios[7]);
        this.crowd1Audio.setLoop(false);
        this.crowd1Audio.setVolume(0.2);
        this.crowd1Audio.pause();

        this.crowd2Audio.setBuffer(audios[8]);
        this.crowd2Audio.setLoop(true);
        this.crowd2Audio.setVolume(0.2);
        this.crowd2Audio.pause();

        this.peopleDisappearing.setBuffer(audios[9]);
        this.peopleDisappearing.setLoop(false);
        this.peopleDisappearing.setVolume(0.2);
        this.peopleDisappearing.pause();




    }


    async setUpTextures(gltf, textures) {
        this.village = this.gltfScene.getObjectByName('village');
        this.greenery = this.gltfScene.getObjectByName('greenery');
        this.crowd = this.gltfScene.getObjectByName('crowd_1');
        this.rocks = this.gltfScene.getObjectByName('rocks');
        this.additionalCrowd = this.gltfScene.getObjectByName('additional_village_crowd');
        this.market = this.gltfScene.getObjectByName('market');
        this.messengers = this.gltfScene.getObjectByName('messengers');
        this.distantVillage = this.gltfScene.getObjectByName('distant_cities');


        this.flagCharacter = this.gltfScene.getObjectByName('flag_character');
        this.flagPole = this.gltfScene.getObjectByName('FlagPole');

        if (this.flagCharacter) {
            this.flagCharacter.visible = false;
        }
        if (this.flagPole) {
            this.flagPole.visible = false;
        }

        this.phase1Group.push(this.crowd)
        this.phase1Group.push(this.rocks)

        this.additionalCrowd.visible = false;
        this.market.visible = false;
        this.messengers.visible = false;
        this.distantVillage.visible = false;

        this.phase4Group.push(this.additionalCrowd)
        this.phase4Group.push(this.market)

        this.phase3Group.push(this.messengers)
        this.phase3Group.push(this.distantVillage);





        await new Promise((resolve, reject) => {
            gltf.scene.traverse(t => {

                if (t.material && (t.material.name.includes('palm') || t.material.name.includes('tree') || t.material.name.includes('bush'))) {
                    t.visible = false;
                }

                if (t.material) {
                    if (t.material.name.includes('p4')) {
                        t.material.map = this.textures[26];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('p6')) {
                        t.material.map = this.textures[27];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('p1')) {
                        t.material.map = this.textures[23];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('p3')) {
                        t.material.map = this.textures[25];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 7-01')) {
                        t.material.map = this.textures[12];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 11-01')) {
                        t.material.map = this.textures[16];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 10-01')) {
                        t.material.map = this.textures[15];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 9-01')) {
                        t.material.map = this.textures[14];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 8-01')) {
                        t.material.map = this.textures[13];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 12-01')) {
                        t.material.map = this.textures[17];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 6-01')) {
                        t.material.map = this.textures[11];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 5-01')) {
                        t.material.map = this.textures[10];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 4-01')) {
                        t.material.map = this.textures[9];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 3-01')) {
                        t.material.map = this.textures[8];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 2-01')) {
                        t.material.map = this.textures[7];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 1-01')) {
                        t.material.map = this.textures[6];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 10-01')) {
                        t.material.map = this.textures[6];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('market 13-01')) {
                        t.material.map = this.textures[18];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('sil1 woman market')) {
                        t.material.map = this.textures[35];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;


                    }
                    if (t.material.name.includes('sil2 woman')) {
                        t.material.map = this.textures[38];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;

                    }
                    if (t.material.name.includes('sil1 woman')) {
                        t.material.map = this.textures[36];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;

                    }
                    if (t.material.name.includes('shep 2')) {
                        t.material.map = this.textures[33];
                        t.material.alphaTest = 0.3

                    }
                    if (t.material.name.includes('sil1 man')) {
                        t.material.map = this.textures[34];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;

                    }
                    if (t.material.name.includes('sil2 man')) {
                        t.material.map = this.textures[37];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;

                    }
                    if (t.material.name.includes('sil3 man')) {
                        t.material.map = this.textures[39];
                        t.material.alphaTest = 0.3
                        t.material.metalness = 0.5;

                    }
                    if (t.material.name.includes('round artifact 1')) {
                        t.material.map = this.textures[30];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('round artifact 2')) {
                        t.material.map = this.textures[31];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('round artifact 3')) {
                        t.material.map = this.textures[32];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('cloth market')) {
                        t.material.map = this.textures[1];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('cloth market2')) {
                        t.material.map = this.textures[2];
                        t.material.transparent = true;
                    }
                    if (t.parent.name.includes('distant_cities')) {
                        t.material.map = this.textures[41];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('messenger1')) {
                        t.material.map = this.textures[19];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('messenger2')) {
                        t.material.map = this.textures[20];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('messenger 3')) {
                        t.material.map = this.textures[21];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('messenger 4')) {
                        t.material.map = this.textures[22];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('flag')) {
                        t.material.map = this.textures[44];
                        t.material.transparent = true;
                    }
                    if (t.material.name.includes('Grass')) {
                        t.material.map = this.textures[5];
                    }
                }

                if (t.material && t.name.includes('main_desert_platform3')) {
                    t.material.map = this.textures[3];
                    t.material.metalness = 0
                    t.material.color = new THREE.Color('#ffffff')
                    t.material.toneMapped = false;
                }

                if (t.material && t.material.name.includes('walls n houses')) {
                    t.material.map = this.textures[42];
                    t.material.metalness = 0.3;
                }
                if (t.name.includes('Cube') || t.material && t.material.name.includes('door')) {
                    t.geometry.dispose();
                    t.material.dispose();
                    t.visible = false;
                }



                t.updateMatrix();
            })
            resolve(true)
        })

        this.additionalCrowd.visible = false;


        let geo;
        let geoBush = null;
        let geoTree = null;
        this.greenery.traverse(t => {
            if (t.name.includes('tree')) {
                t.traverse(b => {
                    if (geoTree == null)
                        geoTree = b.geometry;

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);
                    this.trees.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
            if (t.name.includes('bush')) {
                t.traverse(b => {
                    if (geoBush == null)
                        geoBush = b.geometry;

                    let vec = new THREE.Vector3();
                    b.getWorldPosition(vec);

                    let vec2 = new THREE.Vector3();
                    b.getWorldScale(vec2);

                    let vec3 = new THREE.Quaternion();
                    b.getWorldQuaternion(vec3);
                    this.bushes.push({
                        position: { x: vec.x, y: vec.y, z: vec.z },
                        scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                    })
                })

            }
            if (t.name.includes('palm')) {


                t.traverse(b => {
                    geo = b.geometry;
                    if (b.visible) {



                        // var position = new THREE.Vector3();

                        // let vec = new THREE.Vector3();
                        // b.getWorldPosition(vec);

                        // let vec2 = new THREE.Vector3();
                        // b.getWorldScale(vec2);

                        // let vec3 = new THREE.Quaternion();
                        // b.getWorldQuaternion(vec3);
                        // this.palms.push({
                        //     position: { x: vec.x, y: vec.y, z: vec.z },
                        //     scale: { x: vec2.x, y: vec2.y, z: vec2.z },
                        //     rotation: { x: vec3._x, y: vec3._y, z: vec3._z, w: vec3._w },
                        // })
                    }
                    // else {
                    // }
                })

            }
        })
        await TreesScene.init(this.scene, this.camera, geo, this.bushes, geoBush, this.trees, geoTree)


        this.scene.add(gltf.scene)
        this.scene.updateMatrix();
        this.scene.updateMatrixWorld()
    }

    setUpCharactersAnimation(gltf) {
        let gltfAnimation = gltf.animations[0];
        let tracks = gltf.animations[0].tracks;


        let trackObeid = [tracks[3], tracks[4], tracks[5], tracks[6], tracks[7], tracks[8], tracks[9], tracks[10], tracks[11]];

        let animationCamera = gltfAnimation;
        animationCamera.tracks = trackObeid;
        this.obeidAnimationMixer = new THREE.AnimationMixer(gltf.scene);

        let t = this.obeidAnimationMixer.clipAction(animationCamera);
        t.clampWhenFinished = true;
        t.play();
        this.obeidAnimationMixer.update(16)



        let trackSheperd = [tracks[21], tracks[22], tracks[23], tracks[24]];
        let animationSheperd = gltfAnimation;
        animationCamera.tracks = trackSheperd;
        this.sheperdAnimationMixer = new THREE.AnimationMixer(gltf.scene);

        let t2 = this.sheperdAnimationMixer.clipAction(animationSheperd);
        t2.clampWhenFinished = true;
        t2.play();
        this.sheperdAnimationMixer.update(90)

        let trackObeidLast = [tracks[12], tracks[13], tracks[14], tracks[15], tracks[16], tracks[17], tracks[18], tracks[19], tracks[20]];
        let animationObeidLast = gltfAnimation;
        animationCamera.tracks = trackObeidLast;
        this.obeidLastAnimationMixer = new THREE.AnimationMixer(gltf.scene);

        let t3 = this.obeidLastAnimationMixer.clipAction(animationObeidLast);
        t3.clampWhenFinished = true;
        t3.play();
        this.obeidLastAnimationMixer.update(96)

    }
    async setUpCamera(gltf) {
        loadGLTF('../models/chapter3/cams/cam2.glb').then(gltf => {

            let cameraMixerPhase2 = new THREE.AnimationMixer(this.camera);
            this.phase2Mixer = cameraMixerPhase2;

            let t = this.phase2Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();


        })
        loadGLTF('../models/chapter3/cams/cam3.glb').then(gltf => {

            let cameraMixerPhase3 = new THREE.AnimationMixer(this.camera);
            this.phase3Mixer = cameraMixerPhase3;

            let t = this.phase3Mixer.clipAction(gltf.animations[0]);
            t.clampWhenFinished = true;
            t.setLoop(THREE.LoopOnce);
            t.play();


        })


        loadGLTF('../models/chapter3/cams/cam1.glb').then(gltf => {
            this.sceneCamera1 = new THREE.Camera()
            let cameraMixerPhase1 = new THREE.AnimationMixer(this.sceneCamera1);
            this.cameraAnimationMixers[0] = cameraMixerPhase1;

            let t2 = this.cameraAnimationMixers[0].clipAction(gltf.animations[0]);
            t2.clampWhenFinished = true;
            t2.setLoop(THREE.LoopOnce);
            t2.play();
            this.cameraAnimationMixers[0].update(0.1);

            gsap.to(this.camera.position, { x: this.sceneCamera1.position.x, y: this.sceneCamera1.position.y, z: this.sceneCamera1.position.z, duration: 0 })
            gsap.to(this.camera.quaternion, { x: this.sceneCamera1.quaternion.x, y: this.sceneCamera1.quaternion.y, z: this.sceneCamera1.quaternion.z, w: this.sceneCamera1.quaternion.w, duration: 0 })

            // gsap.to(this.camera.position, { x: this.sceneCamera.position.x, y: this.sceneCamera.position.y, z: this.sceneCamera.position.z, duration: 0 })
            // gsap.to(this.camera.rotation, { x: this.sceneCamera.rotation.x, y: this.sceneCamera.rotation.y, z: this.sceneCamera.rotation.z, duration: 0 })
            // gsap.to(this.camera.quaternion, { x: this.sceneCamera.quaternion.x, y: this.sceneCamera.quaternion.y, z: this.sceneCamera.quaternion.z, w: this.sceneCamera.quaternion.w, duration: 0 })
            // gsap.to(this.camera.scale, { x: this.sceneCamera.scale.x, y: this.sceneCamera.scale.y, z: this.sceneCamera.scale.z, duration: 0 })

        })
        loadGLTF('../models/chapter3/cams/cam4.glb').then(gltf => {
            // this.sceneCamera = gltf.cameras[0]
            //    this.camera = gltf.cameras[0].clone()
            this.sceneCamera = gltf.cameras[0].clone()

            // gltf.cameras[0].copy()
            let cameraMixerPhase4 = new THREE.AnimationMixer(this.sceneCamera);
            this.phase4Mixer = cameraMixerPhase4;

            let t = this.phase4Mixer.clipAction(gltf.animations[0]);
            //  t.clampWhenFinished = true;
            //  t.setLoop(THREE.LoopOnce);
            t.play();
            this.phase4Mixer.update(0.1);

        })


    }


    setUpActions(phaseNumber) {
        switch (phaseNumber) {
            case 1:
                this.lineMesh2.visible = true;
                this.sceneLineMesh2.visible = true;
                break;
            case 2:
                document.getElementById('action1').classList.remove('show')
                this.phase = 2;
                this.playPhase2 = true;
                this.playPhase1 = false;




                this.phase1Audio.pause()
                this.phase2Audio.play();

                this.crowd1Audio.play()
                document.getElementsByClassName('story-container')[0].classList.add('border')


                break;

            case 3:

                document.getElementById('action2').classList.remove('show')
                this.phase = 3;
                this.playPhase2 = false;
                //   this.leaving_convoy.visible = true;
                this.playPhase3 = true;
                this.phase2Audio.pause();
                this.phase3Audio.play();
                document.getElementsByClassName('story-container')[0].classList.add('border')
                this.phase1Group.forEach(t => {
                    t.visible = false;
                    this.removeObject3D(t)
                    this.renderer.dispose()

                })
                this.phase3Group.map(t => {
                    t.visible = true
                })
                setTimeout(() => {
                    this.peopleDisappearing.play()
                }, 4000)

                break;
            case 4:
                //  document.getElementById('action3').classList.remove('show')
                this.phase = 4;
                this.playPhase4 = true;
                this.playPhase2 = false;
                this.phase3Audio.pause();
                setTimeout(() => {
                    this.crowd2Audio.play()
                }, 4000)
                document.getElementsByClassName('story-container')[0].classList.remove('border')
                this.phase1Group.forEach(t => {
                    if (t)
                        t.visible = false;
                    this.removeObject3D(t)
                    this.renderer.dispose()

                })
                this.phase4Group.map(t => {
                    t.visible = true
                })


                break;
            case 5:
                document.getElementById('action4').classList.remove('show')
                this.phase = 5;
                this.playPhase5 = true;
                this.playPhase4 = false;
                this.sheperdAnimation = true;
                document.getElementsByClassName('story-container')[0].classList.add('border')
                this.phase4Audio.pause();
                this.phase5Audio.play();

                this.phase5Group.forEach(t => {
                    t.visible = true;
                })
                this.phase2Group.forEach(t => {
                    t.visible = false;
                    this.removeObject3D(t)
                    this.renderer.dispose()
                })

                break;

            case 6:
                document.getElementById('action5').classList.remove('show')
                this.phase = 6;
                this.playPhase6 = true;
                this.playPhase5 = false;
                this.obeidLastAnimation = true;
                document.getElementsByClassName('story-container')[0].classList.add('border')
                this.phase5Audio.pause();
                this.phase6Audio.play();

                // this.phase5Group.forEach(t => {
                //     t.visible = false;
                // })
                this.phase4Group.forEach(t => {
                    t.visible = false;
                })
                this.landscape_build.visible = true

                var delay = 0;

                setTimeout(() => {
                    this.base_stone.traverse(t => {
                        if (t.material) {
                            gsap.to(t.material, { opacity: 0, duration: 0.5, delay: 0.2 });
                        }
                    })
                }, 1000)

                setTimeout(() => {
                    this.landscape_build.traverse(t => {
                        if (t.material) {
                            delay += 0.1;
                            gsap.to(t.material, { opacity: 1, duration: 0.5, delay: delay })
                        }
                    })
                }, 2000)

                this.renderer.dispose()
                break;
        }
    }



    async loadCharacter(textures) {
        var character = await loadGLTF("../models/chapter3/scene/character-baked.glb");

        character.scene.traverse(t => {
            t.matrixAutoUpdate = true;
            if (t.material && (t.name.includes('Default_'))) {
                t.material = new THREE.MeshBasicMaterial({
                    map: textures[45],
                    name: t.material.name
                })
            }
            else {
                if (t.isMesh)
                    t.visible = false;
                this.flag.push(t)
            }
            if (t.material && t.material.name.includes('flag')) {
                t.material.map = this.textures[44];
                t.material.transparent = true;
                t.autoUpdate = true
                t.matrixAutoUpdate = true;
            }
        })
        this.character2Animation = new THREE.AnimationMixer(character.scene);
        let t2 = this.character2Animation.clipAction(character.animations[0]);
        t2.clampWhenFinished = true;
        t2.play();
        this.character2Animation.update(28)

        this.scene.add(character.scene)
        this.scene.updateMatrix();
        this.scene.updateMatrixWorld();

    }


    checkFrustum() {

        this.raycaster.setFromCamera(new THREE.Vector2(), this.camera);
        this.raycaster.far = 1000
        const intersections = this.raycaster.intersectObjects([this.hotspots[0], this.hotspots[1], this.hotspots[2], this.hotspots[3]]);
        if (intersections[0]) {
            console.log(intersections[0].object.name, this.phase)
            if (this.phase == 1 && intersections[0].object.name.includes('hotspot0')) {
                //    console.log('show1')
                setActiveHotspot(0);
            }
            else if (this.phase == 2 && intersections[0].object.name.includes('hotspot1')) {
                //    console.log('show1')
                setActiveHotspot(1);
            }
            else if (this.phase == 3 && intersections[0].object.name.includes('hotspot2')) {
                //    console.log('show1')
                setActiveHotspot(2);
            }
            else if (this.phase == 4 && intersections[0].object.name.includes('hotspot3')) {
                //    console.log('show1')
                setActiveHotspot(3);
            }
            else {
                setActiveHotspot(-1);
            }
            // else if (intersections[0].object.name.includes('tent')) {
            //     //   console.log('show2')
            //     setActiveHotspot(1);
            // }
            // else if (intersections[0].object.name.includes('bush') && this.phase == 3) {
            //     setActiveHotspot(2);
            // }
            // else if (intersections[0].object.name.includes('Plane_12')) {
            //     setActiveHotspot(2);
            // }
        }
        else {
            setActiveHotspot(-1);
        }

    }
    update(camera) {
        if (!this.pause) {
            //         this.checkFrustum()
            // this.updateInputOverInteractiveObjects();

            // if (this.playPhase1 && this.cameraAnimationMixers.length > 0) {
            //     if (this.phase == 1) {
            //         if (this.cameraAnimationMixers[0].time >= 0.1 && this.cameraAnimationMixers[0].time <= 11) {
            //             this.cameraAnimationMixers[0].update(this.speedPhase1);
            //         }
            //         if (this.cameraAnimationMixers[0].time > 11) {
            //             document.getElementById('action1').classList.add('show')
            //         }
            //     }
            // }
            if (this.playPhase2) {
                if (this.phase == 2 && this.phase2Mixer) {
                    if (this.phase2Mixer.time > 14) {
                        document.getElementById('action2').classList.add('show')
                        emitter.emit('animateKnob2_3', true)

                    }
                    if (this.phase2Mixer.time >= 0 && this.phase2Mixer.time <= 14) {
                        this.phase2Mixer.update((this.speedPhase2));
                        if (this.camera.position.x > -2081) {
                            this.phase2Group.map(t => {
                                t.visible = true
                            })
                        }
                    }
                }
            }
            else if (this.playPhase3 && this.phase == 3) {



                if (this.character2Animation.time < 32) {
                    this.scene.updateMatrixWorld()
                    this.flag.map(t => { t.visible = true })
                    this.character2Animation.update(0.25);
                    this.flagAudio.play()
                }
                else if (this.character2Animation.time >= 32) {
                    if (this.phase3Mixer.time >= 0 && this.phase3Mixer.time <= 10) {
                        this.phase3Mixer.update(this.speedPhase3);
                    }
                    if (this.phase3Mixer.time > 10) {
                        document.getElementById('action3').classList.add('show')
                        emitter.emit('animateKnob3_3', true)
                        this.setUpActions(4)

                    }
                }
            }
        }
    }



    bind() {
        this.init = this.init.bind(this)
    }

    async addLine(textures) {
        var lineGltf = await loadGLTF("../models/chapter3/scene/line.glb");
        //  this.loader.load('models/chapter1/line3.glb', (gltf) => {

        this.lineMesh = lineGltf.scene.children[0];

        //    this.lineMesh.material.map = this.texturesList[38]

        let lineGeo = new THREE.BufferGeometry();
        lineGeo.morphTargetsRelative = true;
        lineGeo.morphAttributes = this.lineMesh.geometry.morphAttributes;
        lineGeo.copy(this.lineMesh.geometry)
        lineGeo.setAttribute('position', this.lineMesh.geometry.attributes.position);
        lineGeo.computeBoundingSphere();
        //     this.lineMesh.material.color.setHex(0x563A2D);
        this.lineMesh.material.color.setHex(0x745246);
        //  #745246
        let lineMat = this.lineMesh.material.clone();
        lineMat.map = textures[46]


        this.sceneLineMesh = new THREE.Mesh(lineGeo, lineMat)

        this.lineMesh.material.map = textures[47]
        this.sceneLineMesh.position.set(this.lineMesh.position.x, this.lineMesh.position.y, this.lineMesh.position.z)
        this.sceneLineMesh.rotation.set(this.lineMesh.rotation.x, this.lineMesh.rotation.y, this.lineMesh.rotation.z)
        this.sceneLineMesh.geometry.attributes.position.needsUpdate = true;

        this.scene.add(this.sceneLineMesh)
        this.scene.add(this.lineMesh)


        this.lineMixer = new THREE.AnimationMixer(this.lineMesh);

        let t2 = this.lineMixer.clipAction(lineGltf.animations[0]);
        t2.clampWhenFinished = true;
        t2.setLoop(THREE.LoopOnce);
        t2.play();
        this.lineMixer.update(50);

        // this.sceneLineMesh.morphTargetInfluences[0] = 0.5;
        this.lineMesh.visible = true;
        this.sceneLineMesh.visible = true;


        //   })
    }
    async addLine2(textures) {
        var lineGltf = await loadGLTF("../models/chapter3/line1_2.glb");
        //  this.loader.load('models/chapter1/line3.glb', (gltf) => {

        this.lineMesh2 = lineGltf.scene.children[0];
        //   console.log('line2', lineGltf.animations[0])

        //    this.lineMesh2.material.map = this.texturesList[38]

        let lineGeo = new THREE.BufferGeometry();
        lineGeo.morphTargetsRelative = true;
        lineGeo.morphAttributes = this.lineMesh2.geometry.morphAttributes;
        lineGeo.copy(this.lineMesh2.geometry)
        lineGeo.setAttribute('position', this.lineMesh2.geometry.attributes.position);
        lineGeo.computeBoundingSphere();
        //     this.lineMesh2.material.color.setHex(0x563A2D);
        this.lineMesh2.material.color.setHex(0x745246);
        //  #745246
        let lineMat = this.lineMesh2.material.clone();
        lineMat.map = textures[46]
        //    lineGeo.setDrawRange(0, 0);
        //



        //   this.lineMesh2.material.map = this.texturesList[38].texture

        this.sceneLineMesh2 = new THREE.Mesh(lineGeo, lineMat)

        this.lineMesh2.material.map = textures[47]
        this.sceneLineMesh2.position.set(this.lineMesh2.position.x, this.lineMesh2.position.y, this.lineMesh2.position.z)
        this.sceneLineMesh2.rotation.set(this.lineMesh2.rotation.x, this.lineMesh2.rotation.y, this.lineMesh2.rotation.z)
        this.sceneLineMesh2.geometry.attributes.position.needsUpdate = true;

        this.scene.add(this.sceneLineMesh2)
        this.scene.add(this.lineMesh2)


        this.lineMixer2 = new THREE.AnimationMixer(this.lineMesh2);

        let t2 = this.lineMixer2.clipAction(lineGltf.animations[0]);
        //  t2.clampWhenFinished = true;
        //  t2.setLoop(THREE.LoopOnce);
        t2.play();
        this.lineMixer2.update(0);

        // this.sceneLineMesh2.morphTargetInfluences[0] = 0.5;
        this.lineMesh2.visible = false;
        this.sceneLineMesh2.visible = false;


        //   })
    }

    handleMouseDown(e) {
        this.selectedOnDown = this.getInteractiveObjectSelected();
    }

    handleMouseMove(e) {
        this.setPointer(e);

        this.delta.x = this.lastInputPosition.x - e.clientX;
        this.delta.y = this.lastInputPosition.y - e.clientY;

        this.lastInputPosition.set(e.clientX, e.clientY);
    }

    handleMouseUp(e) {
        this.setPointer(e);
        this.testForInteractiveObjects(this.selectedOnDown);
        this.selectedOnDown = null;
    }
    setPointer(e) {
        //     const domElement = this.renderer.domElement;
        const clientWidth = window.innerWidth;
        const clientHeight = window.innerHeight;

        this.pointer.x = (e.clientX / clientWidth) * 2 - 1;
        this.pointer.y = -(e.clientY / clientHeight) * 2 + 1;

        this.pointer.x = clamp(this.pointer.x, -1, 1);
        this.pointer.y = clamp(this.pointer.y, -1, 1);
    }
    testForInteractiveObjects(testAgainst) {
        const intersection = this.getInteractiveObjectSelected();
        if (intersection && testAgainst && intersection.object === testAgainst.object) {
            //     const obj = intersection.object.userData.object;
            //   if (obj.isTransitionedIn()) {
            const { userData } = intersection.object;
            // EventManager.emit(EVENTS.HOTSPOT_SELECTED, userData);

            this.showHotspot(userData);

            //  }
        }
    }

    showHotspot(userData) {
        emitter.emit('updateHotspot', userData)

        this.pause = true;
        this.pauseAudio()
    }


    handleTouchStart(e) {
        const touch = e.changedTouches[0];
        this.lastInputPosition.set(touch.clientX, touch.clientY);

        // test if object was selected on down to test against in up
        this.pointerCopy.copy(this.pointer);
        this.setPointer(e.changedTouches[0]);
        this.selectedOnDown = this.getInteractiveObjectSelected();
        this.pointer.copy(this.pointerCopy);

        // if (this.selectedOnDown) {
        // 	this.selectedOnDown.object.userData.object.mouseOver();
        // }
    }
    handleTouchEnd(e) {
        // copy pointer and set to touch point for picking test
        this.pointerCopy.copy(this.pointer);
        this.setPointer(e.changedTouches[0]);

        // test if object was selected
        this.testForInteractiveObjects(this.selectedOnDown);

        // reset pointer to previous state
        this.pointer.copy(this.pointerCopy);

        // if (this.selectedOnDown) {
        // 	this.selectedOnDown.object.userData.object.mouseOut(0.1);
        // }
        this.selectedOnDown = null;
    }
    handleTouchMove(e) {
        const touch = e.changedTouches[0];

        this.delta.x = this.lastInputPosition.x - touch.clientX;
        this.delta.y = clamp(this.lastInputPosition.y - touch.clientY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);

        //this.updatePositionTarget(this.delta.y * -SCROLL_SCALAR_MOBILE);

        if (this.phase == 4) {
            this.handleScroll(this.delta.y * -this.SCROLL_SCALAR_MOBILE)
        }
        else if (this.phase == 1) {
            this.handleScroll(this.delta.y * -this.SCROLL_SCALAR_MOBILE / 2)
        }

        // gsap.to(this.camera.position, { x: this.sceneCamera.position.x, y: this.sceneCamera.position.y, z: this.sceneCamera.position.z, duration: 1 })
        // gsap.to(this.camera.quaternion, { x: this.sceneCamera.quaternion.x, y: this.sceneCamera.quaternion.y, z: this.sceneCamera.quaternion.z, w: this.sceneCamera.quaternion.w, duration: 1 })



        // update horizonal pan using delta
        // this.pointer.x += -this.delta.x * 0.15;
        // this.pointer.x = clamp(this.pointer.x, -this.lookAt.range.x, this.lookAt.range.x);

        this.lastInputPosition.set(touch.clientX, touch.clientY);

        this.resetScrollTime();

        if (this.selectedOnDown) {
            this.selectedOnDown.object.userData.object.mouseOut();
        }
    }

    MouseWheelHandler(e) {
        // cross-browser wheel delta
        var event = window.event || e; // old IE support
        var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));

        return false;
    }

    handleScroll(deltaY) {

        const d = deltaY// ThreeMath.clamp(deltaY, -this.MAX_SCROLL_DELTA, this.MAX_SCROLL_DELTA);


        if (this.phase == 4) {

            document.getElementById('action3').classList.remove('show')
            document.getElementById('scrollAction').classList.add('show')

            if (!this.phase4Visited) {
                this.phase4Audio.play();
                this.phase4Visited = true
            }



            if (this.lineMixer.time >= 21) {
                this.lineMixer.update(d * 1.1);
            }
            if (this.lineMixer.time < 21) {
                this.lineMixer.setTime(21)
            }

            if (this.phase4Mixer.time >= 0.1 && this.phase4Mixer.time <= 30) {
                this.phase4Mixer.update((d * 1.5));
                document.getElementById('scrollAction').classList.add('show')

                // console.log(this.sceneCamera.position)
                // console.log(this.sceneCamera.quaternion)
                gsap.to(this.camera.position, { x: this.sceneCamera.position.x, y: this.sceneCamera.position.y, z: this.sceneCamera.position.z, duration: 1 })
                //   gsap.to(this.camera.quaternion, { x: this.sceneCamera.quaternion.x, y: this.sceneCamera.quaternion.y, z: this.sceneCamera.quaternion.z, w: this.sceneCamera.quaternion.w, duration: 0.5 })
                this.camera.quaternion.slerp(this.sceneCamera.quaternion, 1)
            }
            if (this.phase4Mixer.time < 0.1) {
                //     this.phase4Mixer.update(0.1)
                this.phase4Mixer.update(0.1)
                this.phase4Mixer.setTime(0.1)
            }
            if (this.phase4Mixer.time > 30) {
                this.phase4Mixer.update(30);
                document.getElementById('scrollAction').classList.remove('show')

                emitter.emit('chapterDone', true)
                document.getElementById('scrollAction').classList.remove('show')

            }

        }
        if (this.playPhase1 && this.cameraAnimationMixers.length > 0) {
            if (this.phase == 1) {
                if (this.cameraAnimationMixers[0].time >= 0.1 && this.cameraAnimationMixers[0].time <= 11) {
                    document.getElementById('scrollAction').classList.add('show')
                    this.cameraAnimationMixers[0].update(d);
                    gsap.to(this.camera.position, { x: this.sceneCamera1.position.x, y: this.sceneCamera1.position.y, z: this.sceneCamera1.position.z, duration: 1 })
                    gsap.to(this.camera.quaternion, { x: this.sceneCamera1.quaternion.x, y: this.sceneCamera1.quaternion.y, z: this.sceneCamera1.quaternion.z, w: this.sceneCamera1.quaternion.w, duration: 1 })

                }
                if (this.cameraAnimationMixers[0].time > 11) {
                    document.getElementById('scrollAction').classList.remove('show')
                    document.getElementById('action1').classList.add('show')
                    emitter.emit('animateKnob1_3', true)
                }
                if (this.cameraAnimationMixers[0].time < 0.1) {
                    this.cameraAnimationMixers[0].setTime(0.1)
                    this.cameraAnimationMixers[0].update(0.1)


                }
            }
        }

        this.resetScrollTime();


    }

    resetScrollTime() {
        this.lastScrollTime = Date.now();
    }
    removeObject3D(object) {
        this.disposeHierarchy(object);

        // this.texturesList[2].texture.dispose()
        // this.texturesList[3].texture.dispose()
        // this.texturesList[4].texture.dispose()
        // this.texturesList[5].texture.dispose()
        // this.texturesList[7].texture.dispose()
        // this.texturesList[23].texture.dispose()
        // this.texturesList[24].texture.dispose()
        // this.texturesList[25].texture.dispose()
        // this.texturesList[26].texture.dispose()
        // this.texturesList[27].texture.dispose()
        if (!(object instanceof THREE.Object3D)) return false;
        // for better memory management and performance
        if (object.geometry) {
            object.geometry.dispose();
        }
        if (object.material) {
            if (object.material instanceof Array) {
                // for better memory management and performance
                object.material.forEach(material => material.dispose());
            } else {
                // for better memory management and performance
                object.material.dispose();
            }
        }
        object.traverse(t => {
            if (t.geometry) {
                t.geometry.dispose();
            }
            if (t.material) {
                if (t.material instanceof Array) {
                    // for better memory management and performance
                    t.material.forEach(material => material.dispose());
                } else {
                    // for better memory management and performance
                    t.material.dispose();
                }
            }
        })
        if (object.parent) {
            object.parent.remove(object);
        }
        this.renderer.clear();
        this.renderer.dispose()
        // the parent might be the scene or another Object3D, but it is sure to be removed this way
        return true;
    }
    disposeHierarchy(node, callback) {
        for (let i = node.children.length - 1; i >= 0; i--) {
            const child = node.children[i];
            this.disposeHierarchy(child, callback);

            if (typeof callback === 'function') {
                callback(child);
            }
        }
    }


    addSprite() {
        const map = new THREE.TextureLoader().load('../assets/more-info-icon.png');
        const material = new THREE.SpriteMaterial({ map: map, alphaTest: 0.5 });

        material.color.set(0x745246);

        hotspots.map((hotspot, index) => {
            const sprite = new THREE.Sprite(material);
            sprite.position.set(hotspot.position.x, hotspot.position.y, hotspot.position.z)
            sprite.scale.set(hotspot.scale.x, hotspot.scale.y, hotspot.scale.z)

            sprite.userData = hotspot.info

            const mesh = new THREE.Mesh(new THREE.PlaneGeometry(50, 50))
            mesh.name = 'hotspot' + index
            mesh.visible = false
            mesh.position.set(hotspot.position.x, hotspot.position.y, hotspot.position.z)
            mesh.rotation.set(hotspot.rotation.x, hotspot.rotation.y, hotspot.rotation.z)
            mesh.scale.set(hotspot.scale.x, hotspot.scale.y, hotspot.scale.z)
            //    mesh.rotation.set(0, 9, 0)
            this.scene.add(mesh)
            this.hotspots.push(mesh)
            this.scene.updateMatrix();
            this.scene.updateMatrixWorld()

        })
    }
    // getMouseIntersection(mouse, camera, objects, raycaster) {
    //     this.raycaster = raycaster || new THREE.Raycaster();

    //     this.raycaster.setFromCamera(mouse, camera);
    //     const intersections = this.raycaster.intersectObjects(objects);
    //     return intersections.length > 0 ? intersections[0] : null;
    // }
    // screenToWorldAtZ(position, z, camera) {
    //     const x = (position.x / window.innerWidth) * 2 - 1;
    //     const y = -(position.y / window.innerHeight) * 2 + 1;
    //     const planeZ = new Plane(new THREE.Vector3(0, 0, 1), z);
    //     const vector = new THREE.Vector3(x, y, 0.5);
    //     const raycaster = new THREE.Raycaster();

    //     raycaster.setFromCamera(vector, camera);
    //     const pos = raycaster.ray.intersectPlane(planeZ);
    //     return pos;
    // }
    // getMouseWorldPosAtPlane(mouseScreenPos, camera, raycaster, plane, vector) {
    //     raycaster.setFromCamera(mouseScreenPos, camera);
    //     raycaster.ray.intersectPlane(plane, vector);
    //     return vector;
    // }
    // getInteractiveObjectSelected() {
    //     //	const { hotspots, camera, raycaster } = AppProps.state;

    //     if (this.hotspots.length) {
    //         return this.getMouseIntersection(this.pointer, this.camera, this.hotspots, this.raycaster);
    //     }

    //     return null;
    // }
    // updateInputOverInteractiveObjects() {
    //     //		const { hotspots, camera, raycaster } = AppProps.state;

    //     if (this.hotspots.length) {
    //         const intersection = this.getMouseIntersection(this.pointer, this.camera, this.hotspots, this.raycaster);
    //         if (intersection) {
    //             const obj = intersection.object;
    //             if (this.currentInputOver !== obj) {
    //                 this.currentInputOver = obj;
    //                 //  if (this.currentInputOver) {
    //                 //     console.log('mouseover()')
    //                 //	this.currentInputOver.mouseOver();
    //                 //  }
    //                 //    console.log('mouseover')
    //                 this.setCursor('pointer');
    //             }
    //         } else {
    //             if (this.currentInputOver) {
    //                 //  console.log('mouseOut()')
    //                 //this.currentInputOver.mouseOut();
    //                 this.currentInputOver = null;
    //             }
    //             this.setCursor('auto');
    //         }
    //     }
    // }
    // setCursor(style = 'auto') {
    //     const canvas = this.renderer.domElement;
    //     if (canvas.style.cursor !== style) canvas.style.cursor = style;
    // }

    start() {
        setTimeout(() => {
            this.pause = false;

            this.bgAudio.play();
            this.desertWindAudio.play()
            setTimeout(() => {
                this.phase1Audio.play();
            }, 3000)
            if (this.phase == 0) {
                document.getElementById('actionIntro').classList.add('show')

            }
            this.playPhase1 = true;

            //    this.addSprite()

            this.fpsSpeed = this.stats.getFPS()
            console.log('fps', this.stats.getFPS())
            // console.log('new fps2', this.stats.getFPS())
            // console.log(123, 'fps', this.fpsSpeed)
            if (this.fpsSpeed > 60) {
                this.speedPhase1 = 0.0055;
                this.speedPhase2 = 0.006// 0.0016;
                this.speedPhase3 = 0.005;
                this.speedPhase4 = 0.04;
                this.speedPhase5 = 0.005;
                this.speedPhase6 = 0.008;
            }
        }, 200)

    }

    destroy() {

        this.cameraAnimationMixers && this.cameraAnimationMixers.length > 0 && this.cameraAnimationMixers[0].uncacheRoot(this.camera);
        this.phase2Mixer && this.phase2Mixer.uncacheRoot(this.camera);
        this.phase3Mixer && this.phase3Mixer.uncacheRoot(this.camera);
        this.phase4Mixer && this.phase4Mixer.uncacheRoot(this.camera);
        this.phase5Mixer && this.phase5Mixer.uncacheRoot(this.camera);
        this.phase6Mixer && this.phase6Mixer.uncacheRoot(this.camera);

        // this.audioListener.clear();
        // this.phase1Audio.clear();
        // this.phase2Audio.clear();
        // this.phase3Audio.clear();
        // this.phase4Audio.clear();
        // this.phase5Audio.clear();
        // this.phase6Audio.clear();
        // this.bgAudio.clear();
        // this.footstepAudio.clear();

        // //  this.getOffHorseAudio.disconnect();
        // this.swordAudio.disconnect();
        // this.pickDateUp.disconnect();
        // this.desertWindAudio.disconnect();

        // this.audioListener.disconnect();
        this.phase1Audio.pause();
        this.phase2Audio.pause();
        this.phase3Audio.pause();
        this.phase4Audio.pause();
        this.phase5Audio.pause();
        this.phase6Audio.pause();
        this.bgAudio.pause();

        this.desertWindAudio.pause();
        this.flagAudio.pause();
        this.crowd1Audio.pause();
        this.crowd2Audio.pause();
        this.peopleDisappearing.pause();


        const cleanMaterial = material => {
            //   console.log('dispose material!')
            material.dispose()

            // dispose textures
            for (const key of Object.keys(material)) {
                const value = material[key]
                if (value && typeof value === 'object' && 'minFilter' in value) {
                    //      console.log('dispose texture!')
                    value.dispose()
                }
            }
        }

        //   console.log('dispose renderer!')
        //  this.renderer.dispose()

        this.gltfScene.traverse(object => {
            if (!object.isMesh) return

            //    console.log('dispose geometry!')
            object.geometry.dispose()

            if (object.material.isMaterial) {
                cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) cleanMaterial(material)
            }
        })



        this.pause = true;
        this.cameraAnimationMixers = []
        this.obeidAnimationMixer = null;
        this.sheperdAnimationMixer = null;
        this.obeidLastAnimationMixer = null;

        //  this.gltfScene = null;
        this.count = 1;
        this.sceneLineMesh = null;
        this.lineMesh = null;

        this.scene1_obeid = null;
        this.phase = 1;
        this.phase1Group = [];
        this.phase2Group = [];
        this.phase3Group = [];
        this.phase4Group = [];
        this.phase5Group = [];

        this.phase2Mixer = null;
        this.phase3Mixer = null;
        this.phase4Mixer = null;
        this.phase5Mixer = null;
        this.phase6Mixer = null;
        this.lineMixer = null;

        this.mixer = null;
        this.activeAction = null;
        this.cameraPos = null;
        this.camera = null;
        this.renderer = null;

        this.wheelSpeed = 0.02;
        this.maxDist = 2.0;
        this.minDist = 1.0;
        this.dist = 2;
        this.startDragScroll = 0

        this.modelReady = false
        this.animationActions = []
        this.activeAction = null;
        this.lastAction = null;
        this.animationsFolder = null;



        this.absScroll = 0;
        this.smoothProgress = 0;


        this.lastTime = 0;

        this.SCROLL_SCALAR_MOBILE = 0.01;
        this.MAX_SCROLL_TIME_DELTA = 20;
        this.SCROLL_SCALAR = 0.01;
        this.MAX_SCROLL_DELTA = 5;
        this.transitioning = false;

        this.playPhase1 = false;
        this.playPhase2 = false;
        this.playPhase3 = false;
        this.playPhase4 = false;
        this.playPhase5 = false;
        this.playPhase5 = false;

        this.audioListener = new THREE.AudioListener();
        this.phase1Audio = new THREE.Audio(this.audioListener);
        this.phase2Audio = new THREE.Audio(this.audioListener);
        this.phase3Audio = new THREE.Audio(this.audioListener);
        this.phase4Audio = new THREE.Audio(this.audioListener);
        this.phase5Audio = new THREE.Audio(this.audioListener);
        this.phase6Audio = new THREE.Audio(this.audioListener);
        this.bgAudio = new THREE.Audio(this.audioListener);
        this.footstepAudio = new THREE.Audio(this.audioListener);

        this.getOffHorseAudio = new THREE.Audio(this.audioListener);
        this.swordAudio = new THREE.Audio(this.audioListener);
        this.pickDateUp = new THREE.Audio(this.audioListener);
        this.desertWindAudio = new THREE.Audio(this.audioListener);

        this.flagAudio = new THREE.Audio(this.audioListener);
        this.crowd1Audio = new THREE.Audio(this.audioListener);
        this.crowd2Audio = new THREE.Audio(this.audioListener);
        this.peopleDisappearing = new THREE.Audio(this.audioListener);


        this.animationSpeed = 1;

        this.raycaster = null;
        this.hotspots = []
        this.delta = new THREE.Vector2();
        this.lastInputPosition = new THREE.Vector2(0, 0);

        this.pointer = new THREE.Vector2();
        this.pointerCopy = new THREE.Vector2();
        this.selectedOnDown = null;

        this.textures.map(texture => {
            texture.dispose();
        })
        this.textures = [];


    }
}

const _instance = new DesertScene()
export default _instance