<template>
    <div class="more-container" id="knowMore" ref="moreIconRef" v-on:click="showDetails()" v-if="chapter == 1">
        <img src="../assets/timer.png" class="scroll-down" />
        <span class="label" :class="{ active: captionId == 0 }">
            <span>اعــرف المـــزيد</span>
            <span>عن ترحال بني حنيفة</span>
        </span>
        <span class="label label2" :class="{ active: captionId == 1 }">
            <span>اعــرف المـــزيد</span>
            <span>عن اليمامة</span>
        </span>
        <span class="label label3" :class="{ active: captionId == 2 }">
            <span>تــعــرف علــى</span>
            <span>وادي حنيفة</span>
        </span>
    </div>
    <div class="more-container" id="knowMore" ref="moreIconRef" v-on:click="showDetails()" v-if="chapter == 2">
        <img src="../assets/timer.png" class="scroll-down" />
        <span class="label" :class="{ active: captionId == 0 }">
            <span>انـتقـال بني حنـيفـة </span>
            <span>إلـى اليمامـة</span>
        </span>
        <span class="label label2" :class="{ active: captionId == 1 }">
            <span>شـاهد خريـطة </span>
            <span>اليمامـة</span>
        </span>

    </div>

    <div class="hotspot" id="hotspot" ref="captionRef" :class="{ show: openCaptionDetail == true }">
        <div class="hotspot-container" :class="{ hasMedia: imageSrc != '' }">
            <div class="close" id="close_hotspot" ref="captionClose" v-on:click="hideDetails()">
                <img src="../assets/close.png" />
            </div>
            <div ref="captionTitle" class="title"><span v-if="title != ''">{{ title }}</span></div>
            <div ref="captionImage" class="media" v-if="imageSrc != ''">
                <img :src="imageSrc" />
            </div>
            <div ref="captionDescription" class="information" v-if="information != ''">
                {{ information }}
            </div>
        </div>

    </div>
</template>


<script>
import gsap from "gsap";
import emitter from 'tiny-emitter/instance';

import { hotspots } from '@/classes/chapter1/HotspotsPositions';



export default {
    name: 'CaptionComponent',
    props: ['chapter'],
    methods: {
        showCaptionIcon() {
            gsap.to(this.$refs.moreIconRef, { opacity: 1, display: 'flex', duration: 0.5 })
        },
        hideCaptionIcon() {
            gsap.to(this.$refs.moreIconRef, { opacity: 0, display: 'none', duration: 0 })
        },
        showDetails() {
            this.hideCaptionIcon()
            //  emitter.emit('pauseScene', true)
            this.openCaptionDetail = true
            if (this.image != '' && (this.description != '' && this.title != '')) {
                gsap.timeline()
                    .fromTo(this.$refs.captionRef, { opacity: 0, display: 'none' }, { opacity: 1, display: 'flex', duration: 0.2 })
            }
            if (this.imageSrc != '' && (this.information != '' && this.title == '')) {
                gsap.timeline()
                    .fromTo(this.$refs.captionRef, { opacity: 0, display: 'none' }, { opacity: 1, display: 'flex', duration: 0.2 })
            }
            if (this.imageSrc == '' && (this.information != '' && this.title != '')) {
                gsap.timeline()
                    .fromTo(this.$refs.captionRef, { opacity: 0, display: 'none' }, { opacity: 1, display: 'flex', duration: 0.2 })
            }
            if (this.imageSrc == '' && (this.information != '' && this.title == '')) {
                gsap.timeline()
                    .fromTo(this.$refs.captionRef, { opacity: 0, display: 'none' }, { opacity: 1, display: 'flex', duration: 0.2 })
            }
        },
        hideDetails() {
            this.showCaptionIcon()
            this.openCaptionDetail = false
            emitter.emit('pauseScene', false)

            gsap.to(this.$refs.captionRef, { opacity: 0, display: 'none', duration: 0.2 })
            /// gsap.to('.hotspot .hotspot-container > div', { opacity: 0, duration: 1, startAt: 0.01 })
        }
    },
    data: () => {
        return {
            showCaption: false,
            captionId: -1,
            imageSrc: '',
            title: '',
            information: '',
            openCaptionDetail: false
        }
    },
    watch: {
        captionId(id) {
            if (id == null || id < 0) {
                this.showCaption = false;
                this.imageSrc = null;
                this.title = null;
                this.information = null;
                this.hideCaptionIcon()

            }
            else {
                this.showCaption = true;
                this.captionId = id
                this.imageSrc = hotspots[id].info.image;
                this.title = hotspots[id].info.title;
                this.information = hotspots[id].info.description;


                this.showCaptionIcon()
            }
        }
    },

    mounted() {

        emitter.on('caption', (id) => {
            this.captionId = id
        })
    },
    beforeUnmount() {
    },
    unmounted() {
    }
}
</script>