import emitter from 'tiny-emitter/instance';
export let activeHotspot = 10;
export const setActiveHotspot = (number) => {
    activeHotspot = number;
    emitter.emit('caption', activeHotspot)
}
export const getActiveHotspot = () => {

    return activeHotspot;
}

export const hotspots = [
    {
        position: { x: -1600.793, y: 180.000, z: -5288.461 },

        scale: { x: 20.000, y: 20.000, z: 20.000 },
        info: {
            image: '',
            title: '',
            description: 'أسسـت عشيرة "المـردة" مـن "الـدروع" مـن بنـي حنيفـة مدينـة شرقـي الجزيـرة العربيـة علـى ســاحل الخليــج العــربي، أطلقوا عليهــا اســم "الدرعيــة" نســبة إلى العشــيرة، وذلــك بعــد أن انتقلــوا مــن وســط الجزيــرة العربيــة في القــرن الرابــع الهجــري لظــروف عــدم الإســتقرار آنــذاك، نتيجــة لعــودة عشــائر بنــي حنيفـة إلى حجـر اليمامة بعـد عـودة الاسـتقرار إليهـا تلقـى مانـع بـن ربيعة المريدي الحنفي وهـو في بلدته الدرعية شرق الجزيـرة العربيـة دعـوة ابـن عمـه حاكـم مدينـة حجـر في اليمامة وهـو ابـن درع للقـدوم بالعشـيرة والاستقرار في منطقـة أجداده وأسلافه.'
        }
    },
    {
        position: { x: -2865.410, y: 379.918, z: -3812.590 },
        scale: { x: 20.000, y: 20.000, z: 20.000 },
        info: {
            image: '/assets/chapter2-popup2.jpg',
            title: '',
            description: 'انتقـل "مانـع بـن ربيعـة المريـدي الحنفـي" وأفـراد عشـيرته مـن الدرعيـة في شرق الجزيـرة العربيـة إلى وسـطها لتأسـيس الدرعية الجديـدة عام 850هــ (1446م)، وقد عبر خـلال رحلتـه من شرق الجزيـرة العربية رمال الدهناء القاحلة مؤمناً بشـخصيته المسـتقلة الراغبـة في تأسـيس دولة واسـعة تحقق الأمن والاسـتقرار، وهو ما أورثـه ذريته من بعده.<br/>طريق الرحلة من الدرعية شرق شبه الجزيرة العربية إلى الدرعية في وادي حنيفة'
        }
    },
    {
        position: { x: 205.042, y: 299.113, z: 1010.066 },
        scale: { x: 20.000, y: 20.000, z: 20.000 },
        info: {
            image: '/assets/chapter2-popup3.jpg',
            title: '',
            description: 'اســتقبل ابن درع ابــَن عمــه وعشــيرته في وادي حنيفــة، وأقطعــه موضعــي "غصيبــة" و"المليبيــد" اللذيــن يقعــان شــمال غــرب مدينــة حجــر، فجعــل مانــع "غصيبــة" مقــراً لــه ولحكمــه وبنــى لهــا ســوراً، وجعــل "المليبيــد" مقــراً للزراعــة. ويعـد هـذا الحـدث أبـرز أحـداث الجزيـرة العربيـة في العـصر الوسـيط، فقـد كان قـدوم مانـع اللبنـة الأولى لتأسـيس أعظـم دولـة قامـت في المنطقـة في تاريـخ الجزيـرة العربيـة بعـد دولـة النبـوة والخلافـة الراشـدة.'
        }
    },
    {
        position: { x: 126.549, y: 269.113, z: 1371.812 },
        scale: { x: 10.000, y: 10.000, z: 10.000 },
        info: {
            image: '/assets/chapter2-popup4.jpg',
            title: '',
            description: 'كان حلــم بنــاء دولــة في جزيــرة العــرب يــراود بعــض العقــلاء، وذلــك لمــا اعـترى هـذه المنطقـة الجغرافيـة مـن الإهـمال الـذي اسـتمر عـدة قـرون، ولسـيطرة أعــراق أخــرى عــلى بعــض أجــزاء الأراضي العربيــة، وعــلى شــعوبها ومقدراتهــا. '
        }
    }
]