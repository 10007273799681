import * as THREE from 'three';
import { trees } from './TreesPositions';
import { loadGLTF, loadTexture } from "../../utils/asyncLoaders";

class TreesScene {
    constructor() {
        this.bind();

        this.geo;
        this.mat;

        this.mesh = null;
        this.dummy = new THREE.Object3D();
        this.dummy2 = new THREE.Object3D();
        this.sectionWidth = 200;

        this.trees, this.trees2;

    }

    addInstancedMesh() {
        // An InstancedMesh of 4 cubes
        this.mesh = new THREE.InstancedMesh(this.geo, this.mat, 220);
        this.scene.add(this.mesh);
        this.setInstancedMeshPositions(this.mesh);
    }

    setInstancedMeshPositions(mesh) {
        let j = mesh.count / 2 - 1;
        for (var i = 0; i < mesh.count / 2; i++) {
            // we add 200 units of distance (the width of the section) between each.
            this.dummy.position.set(this.trees[i].x, 10, this.trees[i].z);
            this.dummy.rotation.set(1.571, 0, -1.571);
            this.dummy.scale.set(0.3, 0.3, 0.3)

            this.dummy.updateMatrix();

            this.dummy2.position.set(this.trees[i].x, 10, this.trees[i].z);
            this.dummy2.rotation.set(1.571, 0, 1.571);
            this.dummy2.scale.set(0.3, 0.3, 0.3)
            this.dummy2.updateMatrix();

            j++;
            mesh.setMatrixAt(i, this.dummy.matrix);
            mesh.setMatrixAt(j, this.dummy2.matrix);
        }
        mesh.instanceMatrix.needsUpdate = true;
    }


    async init(scene, camera) {
        this.scene = scene
        this.camera = camera

        this.trees = trees;

        this.treeMat = await loadTexture('../models/chapter1/scene/mats/palm.png')

        var gltf = await loadGLTF("../models/chapter1/palmtreex.glb");
        gltf.scene.children[0].children[0].material.side = THREE.DoubleSide
        this.geo = gltf.scene.children[0].children[0].geometry;


        this.mat = gltf.scene.children[0].children[0].material;
        this.mat.map = this.treeMat;
        this.mat.color = new THREE.Color('#636365');
        this.mat.metalness = 1



        this.addInstancedMesh();



    }


    update(camera) {

    }

    bind() {
        this.init = this.init.bind(this)
    }


}

const _instance = new TreesScene()
export default _instance