export const palms = [
    {
        "position": {
            "x": 208.90053421857615,
            "y": 252.2944305874345,
            "z": 1250.2064760236353
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 208.90053422525602,
            "y": 261.83397946133584,
            "z": 1250.2064759900047
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 208.90053422525602,
            "y": 261.83397946133584,
            "z": 1250.2064759900047
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 206.8305959475684,
            "y": 252.29443062562277,
            "z": 1260.6277094799602
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 206.8305959542483,
            "y": 261.83397949952416,
            "z": 1260.6277094463296
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 206.8305959542483,
            "y": 261.83397949952416,
            "z": 1260.6277094463296
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 204.92126444646834,
            "y": 252.29443066084798,
            "z": 1270.24035812614
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 204.9212644531482,
            "y": 261.83397953474935,
            "z": 1270.2403580925095
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 204.9212644531482,
            "y": 261.83397953474935,
            "z": 1270.2403580925095
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 202.4909276390148,
            "y": 252.2944307056853,
            "z": 1282.4760405146985
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 202.49092764569477,
            "y": 261.83397957958664,
            "z": 1282.476040481068
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 202.49092764569477,
            "y": 261.83397957958664,
            "z": 1282.476040481068
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 200.04208129085046,
            "y": 252.29443075086402,
            "z": 1294.804910341558
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 200.0420812975303,
            "y": 261.8339796247654,
            "z": 1294.8049103079277
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 200.0420812975303,
            "y": 261.8339796247654,
            "z": 1294.8049103079277
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 197.52053794724,
            "y": 252.29443079738402,
            "z": 1307.4997777306291
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 197.52053795391993,
            "y": 261.8339796712854,
            "z": 1307.4997776969985
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 197.52053795391993,
            "y": 261.8339796712854,
            "z": 1307.4997776969985
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 194.9993479873505,
            "y": 252.29443084389743,
            "z": 1320.1928659872947
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 194.99934799403042,
            "y": 261.8339797177988,
            "z": 1320.192865953664
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 194.99934799403042,
            "y": 261.8339797177988,
            "z": 1320.192865953664
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 192.49610219137747,
            "y": 252.29443089007987,
            "z": 1332.7956132304275
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 192.4961021980574,
            "y": 261.83397976398123,
            "z": 1332.795613196797
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 192.4961021980574,
            "y": 261.83397976398123,
            "z": 1332.795613196797
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 175.72853933967625,
            "y": 252.29443089007984,
            "z": 1329.4651226312367
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 175.72853934635629,
            "y": 261.83397976398123,
            "z": 1329.4651225976056
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 175.72853934635629,
            "y": 261.83397976398123,
            "z": 1329.4651225976056
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 178.23178513564923,
            "y": 252.29443084389746,
            "z": 1316.8623753881036
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 178.23178514232916,
            "y": 261.8339797177988,
            "z": 1316.862375354473
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 178.23178514232916,
            "y": 261.8339797177988,
            "z": 1316.862375354473
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 180.75297509553874,
            "y": 252.29443079738405,
            "z": 1304.1692871314383
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 180.7529751022186,
            "y": 261.8339796712854,
            "z": 1304.1692870978077
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 180.7529751022186,
            "y": 261.8339796712854,
            "z": 1304.1692870978077
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 183.2745184391492,
            "y": 252.29443075086405,
            "z": 1291.4744197423672
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 183.2745184458291,
            "y": 261.83397962476545,
            "z": 1291.4744197087366
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 183.2745184458291,
            "y": 261.83397962476545,
            "z": 1291.4744197087366
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 185.72336478731356,
            "y": 252.29443070568527,
            "z": 1279.1455499155074
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 185.7233647939935,
            "y": 261.83397957958664,
            "z": 1279.1455498818766
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 185.7233647939935,
            "y": 261.83397957958664,
            "z": 1279.1455498818766
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 188.15370159476709,
            "y": 252.294430660848,
            "z": 1266.9098675269493
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 188.15370160144704,
            "y": 261.83397953474935,
            "z": 1266.9098674933184
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 188.15370160144704,
            "y": 261.83397953474935,
            "z": 1266.9098674933184
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 190.06303309586715,
            "y": 252.29443062562274,
            "z": 1257.2972188807692
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 190.0630331025471,
            "y": 261.8339794995241,
            "z": 1257.2972188471385
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 190.0630331025471,
            "y": 261.8339794995241,
            "z": 1257.2972188471385
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 192.1329713668749,
            "y": 252.29443058743448,
            "z": 1246.8759854244443
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 192.13297137355474,
            "y": 261.83397946133584,
            "z": 1246.8759853908139
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 192.13297137355474,
            "y": 261.83397946133584,
            "z": 1246.8759853908139
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 177.1677013288113,
            "y": 252.2944305874345,
            "z": 1243.9034788284087
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 177.16770133549116,
            "y": 261.83397946133584,
            "z": 1243.903478794778
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 177.16770133549116,
            "y": 261.83397946133584,
            "z": 1243.903478794778
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 175.09776305780355,
            "y": 252.29443062562277,
            "z": 1254.3247122847336
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 175.09776306448353,
            "y": 261.83397949952416,
            "z": 1254.3247122511027
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 175.09776306448353,
            "y": 261.83397949952416,
            "z": 1254.3247122511027
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 173.18843155670348,
            "y": 252.29443066084798,
            "z": 1263.9373609309134
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 173.18843156338335,
            "y": 261.83397953474935,
            "z": 1263.9373608972828
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 173.18843156338335,
            "y": 261.83397953474935,
            "z": 1263.9373608972828
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 170.75809474924995,
            "y": 252.2944307056853,
            "z": 1276.1730433194718
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 170.75809475592985,
            "y": 261.83397957958664,
            "z": 1276.173043285841
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 170.75809475592985,
            "y": 261.83397957958664,
            "z": 1276.173043285841
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 168.3092484010856,
            "y": 252.29443075086402,
            "z": 1288.5019131463314
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 168.30924840776555,
            "y": 261.8339796247654,
            "z": 1288.5019131127005
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 168.30924840776555,
            "y": 261.8339796247654,
            "z": 1288.5019131127005
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 165.78770505747514,
            "y": 252.294430797384,
            "z": 1301.1967805354025
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 165.7877050641551,
            "y": 261.83397967128536,
            "z": 1301.1967805017716
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 165.7877050641551,
            "y": 261.83397967128536,
            "z": 1301.1967805017716
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 163.26651509758562,
            "y": 252.29443084389743,
            "z": 1313.889868792068
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 163.26651510426558,
            "y": 261.8339797177988,
            "z": 1313.8898687584372
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 163.26651510426558,
            "y": 261.8339797177988,
            "z": 1313.8898687584372
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 160.7632693016126,
            "y": 252.29443089007987,
            "z": 1326.4926160352009
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 160.76326930829248,
            "y": 261.83397976398123,
            "z": 1326.4926160015702
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 160.76326930829248,
            "y": 261.83397976398123,
            "z": 1326.4926160015702
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 308.2394624412324,
            "y": 252.29443011059735,
            "z": 1152.668256571731
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 308.23946244791233,
            "y": 261.8339789844987,
            "z": 1152.6682565381004
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 308.23946244791233,
            "y": 261.8339789844987,
            "z": 1152.6682565381004
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 306.16952417022463,
            "y": 252.2944301487856,
            "z": 1163.089490028056
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 306.16952417690464,
            "y": 261.833979022687,
            "z": 1163.0894899944253
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 306.16952417690464,
            "y": 261.833979022687,
            "z": 1163.0894899944253
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 304.2601926691246,
            "y": 252.29443018401082,
            "z": 1172.7021386742358
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 304.2601926758045,
            "y": 261.8339790579122,
            "z": 1172.7021386406052
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 304.2601926758045,
            "y": 261.8339790579122,
            "z": 1172.7021386406052
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 301.8298558616711,
            "y": 252.29443022884814,
            "z": 1184.9378210627942
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 301.82985586835105,
            "y": 261.8339791027495,
            "z": 1184.9378210291636
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 301.82985586835105,
            "y": 261.8339791027495,
            "z": 1184.9378210291636
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 299.3810095135067,
            "y": 252.29443027402687,
            "z": 1197.2666908896538
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 299.3810095201866,
            "y": 261.83397914792823,
            "z": 1197.266690856023
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 299.3810095201866,
            "y": 261.83397914792823,
            "z": 1197.266690856023
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 296.8594661698962,
            "y": 252.29443032054687,
            "z": 1209.9615582787249
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 296.8594661765761,
            "y": 261.83397919444826,
            "z": 1209.9615582450942
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 296.8594661765761,
            "y": 261.83397919444826,
            "z": 1209.9615582450942
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 294.3382762100067,
            "y": 252.29443036706027,
            "z": 1222.6546465353904
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 294.3382762166867,
            "y": 261.83397924096164,
            "z": 1222.6546465017598
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 294.3382762166867,
            "y": 261.83397924096164,
            "z": 1222.6546465017598
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 291.8350304140337,
            "y": 252.2944304132427,
            "z": 1235.2573937785232
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 291.8350304207137,
            "y": 261.8339792871441,
            "z": 1235.2573937448926
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 291.8350304207137,
            "y": 261.8339792871441,
            "z": 1235.2573937448926
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 275.06746756233247,
            "y": 252.29443041324268,
            "z": 1231.9269031793324
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 275.0674675690124,
            "y": 261.8339792871441,
            "z": 1231.9269031457018
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 275.0674675690124,
            "y": 261.8339792871441,
            "z": 1231.9269031457018
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 277.5707133583055,
            "y": 252.2944303670603,
            "z": 1219.3241559361993
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 277.5707133649854,
            "y": 261.83397924096164,
            "z": 1219.3241559025687
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 277.5707133649854,
            "y": 261.83397924096164,
            "z": 1219.3241559025687
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 280.091903318195,
            "y": 252.2944303205469,
            "z": 1206.631067679534
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 280.091903324875,
            "y": 261.83397919444826,
            "z": 1206.6310676459034
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 280.091903324875,
            "y": 261.83397919444826,
            "z": 1206.6310676459034
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 282.6134466618054,
            "y": 252.2944302740269,
            "z": 1193.936200290463
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 282.61344666848527,
            "y": 261.8339791479283,
            "z": 1193.9362002568325
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 282.61344666848527,
            "y": 261.8339791479283,
            "z": 1193.9362002568325
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 285.06229300996984,
            "y": 252.2944302288481,
            "z": 1181.6073304636031
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 285.0622930166498,
            "y": 261.8339791027495,
            "z": 1181.6073304299725
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 285.0622930166498,
            "y": 261.8339791027495,
            "z": 1181.6073304299725
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 287.49262981742334,
            "y": 252.29443018401085,
            "z": 1169.371648075045
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 287.49262982410323,
            "y": 261.8339790579122,
            "z": 1169.3716480414143
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 287.49262982410323,
            "y": 261.8339790579122,
            "z": 1169.3716480414143
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 289.4019613185234,
            "y": 252.29443014878558,
            "z": 1159.7589994288649
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 289.40196132520333,
            "y": 261.83397902268695,
            "z": 1159.7589993952342
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 289.40196132520333,
            "y": 261.83397902268695,
            "z": 1159.7589993952342
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 291.4718995895312,
            "y": 252.29443011059732,
            "z": 1149.33776597254
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 291.4718995962111,
            "y": 261.8339789844987,
            "z": 1149.3377659389093
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 291.4718995962111,
            "y": 261.8339789844987,
            "z": 1149.3377659389093
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 276.50662955146754,
            "y": 252.29443011059735,
            "z": 1146.3652593765044
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 276.5066295581475,
            "y": 261.8339789844987,
            "z": 1146.3652593428737
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 276.5066295581475,
            "y": 261.8339789844987,
            "z": 1146.3652593428737
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 274.4366912804598,
            "y": 252.2944301487856,
            "z": 1156.7864928328293
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 274.4366912871397,
            "y": 261.833979022687,
            "z": 1156.7864927991986
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 274.4366912871397,
            "y": 261.833979022687,
            "z": 1156.7864927991986
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 272.5273597793597,
            "y": 252.29443018401082,
            "z": 1166.3991414790091
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 272.5273597860396,
            "y": 261.8339790579122,
            "z": 1166.3991414453785
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 272.5273597860396,
            "y": 261.8339790579122,
            "z": 1166.3991414453785
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 270.0970229719062,
            "y": 252.29443022884814,
            "z": 1178.6348238675675
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 270.0970229785862,
            "y": 261.8339791027495,
            "z": 1178.634823833937
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 270.0970229785862,
            "y": 261.8339791027495,
            "z": 1178.634823833937
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 267.64817662374185,
            "y": 252.29443027402687,
            "z": 1190.963693694427
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 267.6481766304217,
            "y": 261.83397914792823,
            "z": 1190.9636936607967
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 267.6481766304217,
            "y": 261.83397914792823,
            "z": 1190.9636936607967
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 265.1266332801314,
            "y": 252.29443032054684,
            "z": 1203.6585610834982
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 265.12663328681145,
            "y": 261.8339791944482,
            "z": 1203.6585610498673
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 265.12663328681145,
            "y": 261.8339791944482,
            "z": 1203.6585610498673
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 262.6054433202419,
            "y": 252.29443036706027,
            "z": 1216.3516493401637
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 262.60544332692183,
            "y": 261.83397924096164,
            "z": 1216.3516493065329
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 262.60544332692183,
            "y": 261.83397924096164,
            "z": 1216.3516493065329
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 260.1021975242689,
            "y": 252.2944304132427,
            "z": 1228.9543965832966
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 260.10219753094884,
            "y": 261.8339792871441,
            "z": 1228.954396549666
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 260.10219753094884,
            "y": 261.8339792871441,
            "z": 1228.954396549666
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 152.0800157249238,
            "y": 252.29443011059735,
            "z": 1121.6507699994654
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 152.08001573160368,
            "y": 261.8339789844987,
            "z": 1121.6507699658348
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 152.08001573160368,
            "y": 261.8339789844987,
            "z": 1121.6507699658348
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 150.01007745391607,
            "y": 252.2944301487856,
            "z": 1132.0720034557903
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 150.01007746059594,
            "y": 261.833979022687,
            "z": 1132.0720034221597
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 150.01007746059594,
            "y": 261.833979022687,
            "z": 1132.0720034221597
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 148.100745952816,
            "y": 252.29443018401082,
            "z": 1141.6846521019702
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 148.10074595949587,
            "y": 261.8339790579122,
            "z": 1141.6846520683396
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 148.10074595949587,
            "y": 261.8339790579122,
            "z": 1141.6846520683396
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 145.67040914536247,
            "y": 252.29443022884814,
            "z": 1153.9203344905286
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 145.67040915204234,
            "y": 261.8339791027495,
            "z": 1153.9203344568982
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 145.67040915204234,
            "y": 261.8339791027495,
            "z": 1153.9203344568982
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 143.22156279719812,
            "y": 252.29443027402687,
            "z": 1166.2492043173881
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 143.22156280387804,
            "y": 261.83397914792823,
            "z": 1166.2492042837573
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 143.22156280387804,
            "y": 261.83397914792823,
            "z": 1166.2492042837573
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 140.70001945358766,
            "y": 252.29443032054687,
            "z": 1178.9440717064592
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 140.70001946026764,
            "y": 261.83397919444826,
            "z": 1178.9440716728284
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 140.70001946026764,
            "y": 261.83397919444826,
            "z": 1178.9440716728284
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 138.17882949369815,
            "y": 252.29443036706027,
            "z": 1191.6371599631248
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 138.1788295003781,
            "y": 261.83397924096164,
            "z": 1191.637159929494
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 138.1788295003781,
            "y": 261.83397924096164,
            "z": 1191.637159929494
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 135.67558369772513,
            "y": 252.2944304132427,
            "z": 1204.2399072062576
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 135.67558370440508,
            "y": 261.8339792871441,
            "z": 1204.2399071726268
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 135.67558370440508,
            "y": 261.8339792871441,
            "z": 1204.2399071726268
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 118.90802084602389,
            "y": 252.29443041324268,
            "z": 1200.9094166070668
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 118.90802085270383,
            "y": 261.8339792871441,
            "z": 1200.9094165734361
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 118.90802085270383,
            "y": 261.8339792871441,
            "z": 1200.9094165734361
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 121.41126664199689,
            "y": 252.2944303670603,
            "z": 1188.3066693639337
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 121.41126664867676,
            "y": 261.83397924096164,
            "z": 1188.3066693303033
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 121.41126664867676,
            "y": 261.83397924096164,
            "z": 1188.3066693303033
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 123.9324566018864,
            "y": 252.2944303205469,
            "z": 1175.6135811072684
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 123.93245660856627,
            "y": 261.83397919444826,
            "z": 1175.613581073638
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 123.93245660856627,
            "y": 261.83397919444826,
            "z": 1175.613581073638
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 126.45399994549686,
            "y": 252.2944302740269,
            "z": 1162.9187137181973
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 126.4539999521768,
            "y": 261.8339791479283,
            "z": 1162.9187136845667
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 126.4539999521768,
            "y": 261.8339791479283,
            "z": 1162.9187136845667
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 128.9028462936612,
            "y": 252.2944302288481,
            "z": 1150.5898438913375
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 128.9028463003411,
            "y": 261.8339791027495,
            "z": 1150.589843857707
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 128.9028463003411,
            "y": 261.8339791027495,
            "z": 1150.589843857707
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 131.33318310111474,
            "y": 252.29443018401085,
            "z": 1138.3541615027793
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 131.3331831077946,
            "y": 261.8339790579122,
            "z": 1138.3541614691487
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 131.3331831077946,
            "y": 261.8339790579122,
            "z": 1138.3541614691487
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 133.2425146022148,
            "y": 252.29443014878558,
            "z": 1128.7415128565992
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 133.2425146088947,
            "y": 261.83397902268695,
            "z": 1128.7415128229686
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 133.2425146088947,
            "y": 261.83397902268695,
            "z": 1128.7415128229686
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 135.31245287322255,
            "y": 252.29443011059732,
            "z": 1118.3202794002743
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 135.31245287990245,
            "y": 261.8339789844987,
            "z": 1118.3202793666437
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 135.31245287990245,
            "y": 261.8339789844987,
            "z": 1118.3202793666437
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 120.34718283515895,
            "y": 252.29443011059735,
            "z": 1115.3477728042387
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 120.34718284183883,
            "y": 261.8339789844987,
            "z": 1115.3477727706081
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 120.34718284183883,
            "y": 261.8339789844987,
            "z": 1115.3477727706081
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 118.27724456415119,
            "y": 252.2944301487856,
            "z": 1125.7690062605636
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 118.27724457083107,
            "y": 261.833979022687,
            "z": 1125.769006226933
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 118.27724457083107,
            "y": 261.833979022687,
            "z": 1125.769006226933
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 116.36791306305113,
            "y": 252.29443018401082,
            "z": 1135.3816549067435
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 116.36791306973107,
            "y": 261.8339790579122,
            "z": 1135.381654873113
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 116.36791306973107,
            "y": 261.8339790579122,
            "z": 1135.381654873113
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 113.9375762555976,
            "y": 252.29443022884814,
            "z": 1147.617337295302
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 113.93757626227757,
            "y": 261.8339791027495,
            "z": 1147.6173372616713
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 113.93757626227757,
            "y": 261.8339791027495,
            "z": 1147.6173372616713
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 111.48872990743324,
            "y": 252.29443027402687,
            "z": 1159.9462071221615
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 111.48872991411322,
            "y": 261.83397914792823,
            "z": 1159.9462070885306
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 111.48872991411322,
            "y": 261.83397914792823,
            "z": 1159.9462070885306
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 108.96718656382278,
            "y": 252.29443032054684,
            "z": 1172.6410745112325
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 108.96718657050269,
            "y": 261.8339791944482,
            "z": 1172.641074477602
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 108.96718657050269,
            "y": 261.8339791944482,
            "z": 1172.641074477602
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 106.44599660393328,
            "y": 252.29443036706027,
            "z": 1185.334162767898
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 106.44599661061322,
            "y": 261.83397924096164,
            "z": 1185.3341627342675
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 106.44599661061322,
            "y": 261.83397924096164,
            "z": 1185.3341627342675
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 103.94275080796027,
            "y": 252.2944304132427,
            "z": 1197.936910011031
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5,
            "z": 0.5000000379909325
        },
        "rotation": {
            "x": 1.759037532084492e-10,
            "y": 0.9951981714908626,
            "z": -1.7884973152598936e-9,
            "w": 0.0978805366824452
        }
    },
    {
        "position": {
            "x": 103.94275081464028,
            "y": 261.8339792871441,
            "z": 1197.9369099774
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000379909325,
            "z": 0.5
        },
        "rotation": {
            "x": 0.44865881642200817,
            "y": -0.5465393532803571,
            "z": 0.5465393548929508,
            "w": 0.44865881838640914
        }
    },
    {
        "position": {
            "x": 103.94275081464028,
            "y": 261.8339792871441,
            "z": 1197.9369099774
        },
        "scale": {
            "x": 0.5000000379909325,
            "y": 0.5000000208766607,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.06921199017420551,
            "y": 0.7037113864644905,
            "z": -0.7037113649067925,
            "w": 0.06921199229446585
        }
    },
    {
        "position": {
            "x": 74.34571838378906,
            "y": 252.75780391693115,
            "z": 1347.2318115234375
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 128.36506949484553,
            "y": 252.05686420202255,
            "z": 1469.6543861307623
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 128.36506949484553,
            "y": 261.5964130759239,
            "z": 1469.6543861307623
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 128.36506949484553,
            "y": 261.5964130759239,
            "z": 1469.6543861307623
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 130.53800669995482,
            "y": 252.05686420202255,
            "z": 1463.9690317687928
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 130.53800669995482,
            "y": 260.3276533484459,
            "z": 1463.9690317687928
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 130.53800669995482,
            "y": 260.3276533484459,
            "z": 1463.9690317687928
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 202.53678261707,
            "y": 250.42357921600342,
            "z": 1505.2102652744636
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 202.53678261707,
            "y": 259.9631280899048,
            "z": 1505.2102652744636
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 202.53678261707,
            "y": 259.9631280899048,
            "z": 1505.2102652744636
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 204.70972929791088,
            "y": 250.42357921600342,
            "z": 1499.5249230236113
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 204.70972929791088,
            "y": 258.69436836242676,
            "z": 1499.5249230236113
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 204.70972929791088,
            "y": 258.69436836242676,
            "z": 1499.5249230236113
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 87.61736720386342,
            "y": 252.05686420202255,
            "z": 1433.2152197329617
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 87.61736720386342,
            "y": 261.5964130759239,
            "z": 1433.2152197329617
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 87.61736720386342,
            "y": 261.5964130759239,
            "z": 1433.2152197329617
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 160.58698023633764,
            "y": 252.05686420202255,
            "z": 1455.6339864869387
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 160.58698023633764,
            "y": 261.5964130759239,
            "z": 1455.6339864869387
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 160.58698023633764,
            "y": 261.5964130759239,
            "z": 1455.6339864869387
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 89.72202772735233,
            "y": 252.40308314561844,
            "z": 1380.0980994980814
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 89.72202772735233,
            "y": 261.9426320195198,
            "z": 1380.0980994980814
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 89.72202772735233,
            "y": 261.9426320195198,
            "z": 1380.0980994980814
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 89.01233927619049,
            "y": 252.40308314561844,
            "z": 1374.0531677103354
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 89.01233927619049,
            "y": 260.6738722920418,
            "z": 1374.0531677103354
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 89.01233927619049,
            "y": 260.6738722920418,
            "z": 1374.0531677103354
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 96.48054515779927,
            "y": 252.40308314561844,
            "z": 1378.851817868526
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 96.48054515779921,
            "y": 259.17134553194046,
            "z": 1378.851817868526
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 96.48054515779921,
            "y": 259.17134553194046,
            "z": 1378.851817868526
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 71.26375466151684,
            "y": 252.75780391693115,
            "z": 1347.9200961456736
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 71.26375466151684,
            "y": 262.2973527908325,
            "z": 1347.9200961456736
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 71.26375466151684,
            "y": 262.2973527908325,
            "z": 1347.9200961456736
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 74.4017505412446,
            "y": 252.75780391693115,
            "z": 1342.7049421722195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 74.4017505412446,
            "y": 261.0285930633545,
            "z": 1342.7049421722195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 74.4017505412446,
            "y": 261.0285930633545,
            "z": 1342.7049421722195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 77.3716501017181,
            "y": 252.75780391693115,
            "z": 1351.0703965081036
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 77.3716501017181,
            "y": 259.5260663032532,
            "z": 1351.0703965081036
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 77.3716501017181,
            "y": 259.5260663032532,
            "z": 1351.0703965081036
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 239.48332157902652,
            "y": 249.77328324317932,
            "z": 1514.2009618904924
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5,
            "z": 0.5000000429150953
        },
        "rotation": {
            "x": 0,
            "y": -0.06016457538483551,
            "z": 0,
            "w": 0.9981884711159323
        }
    },
    {
        "position": {
            "x": 239.48332157902658,
            "y": 258.04407238960266,
            "z": 1514.2009618904922
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000429150953,
            "z": 0.5
        },
        "rotation": {
            "x": 0.529176523250384,
            "y": 0.46901194786554845,
            "z": -0.46901194786554845,
            "w": 0.5291765232503839
        }
    },
    {
        "position": {
            "x": 239.48332157902658,
            "y": 258.04407238960266,
            "z": 1514.2009618904922
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000258008233,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.705825824748613,
            "y": -0.04254277996991522,
            "z": 0.04254277851373775,
            "w": 0.7058258489080028
        }
    },
    {
        "position": {
            "x": 231.05368188393268,
            "y": 249.77328324317932,
            "z": 1511.4184718296667
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5,
            "z": 0.5000000429150953
        },
        "rotation": {
            "x": 0,
            "y": -0.06016457538483551,
            "z": 0,
            "w": 0.9981884711159323
        }
    },
    {
        "position": {
            "x": 231.0536818839327,
            "y": 256.54154562950134,
            "z": 1511.4184718296667
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000429150953,
            "z": 0.5
        },
        "rotation": {
            "x": 0.529176523250384,
            "y": 0.46901194786554845,
            "z": -0.46901194786554845,
            "w": 0.5291765232503839
        }
    },
    {
        "position": {
            "x": 231.0536818839327,
            "y": 256.54154562950134,
            "z": 1511.4184718296667
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000258008233,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.705825824748613,
            "y": -0.04254277996991522,
            "z": 0.04254277851373775,
            "w": 0.7058258489080028
        }
    },
    {
        "position": {
            "x": 114.6770520704425,
            "y": 252.75780391693115,
            "z": 1477.5164185111098
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5,
            "z": 0.5000000429150953
        },
        "rotation": {
            "x": 0,
            "y": -0.06016457538483551,
            "z": 0,
            "w": 0.9981884711159323
        }
    },
    {
        "position": {
            "x": 114.6770520704425,
            "y": 261.0285930633545,
            "z": 1477.5164185111098
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000429150953,
            "z": 0.5
        },
        "rotation": {
            "x": 0.529176523250384,
            "y": 0.46901194786554845,
            "z": -0.46901194786554845,
            "w": 0.5291765232503839
        }
    },
    {
        "position": {
            "x": 114.6770520704425,
            "y": 261.0285930633545,
            "z": 1477.5164185111098
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000258008233,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.705825824748613,
            "y": -0.04254277996991522,
            "z": 0.04254277851373775,
            "w": 0.7058258489080028
        }
    },
    {
        "position": {
            "x": 88.93249265369734,
            "y": 252.75780391693115,
            "z": 1509.6067323138254
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5,
            "z": 0.5000000429150953
        },
        "rotation": {
            "x": 0,
            "y": -0.06016457538483551,
            "z": 0,
            "w": 0.9981884711159323
        }
    },
    {
        "position": {
            "x": 88.93249265369731,
            "y": 261.0285930633545,
            "z": 1509.6067323138254
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000429150953,
            "z": 0.5
        },
        "rotation": {
            "x": 0.529176523250384,
            "y": 0.46901194786554845,
            "z": -0.46901194786554845,
            "w": 0.5291765232503839
        }
    },
    {
        "position": {
            "x": 88.93249265369731,
            "y": 261.0285930633545,
            "z": 1509.6067323138254
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000258008233,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.705825824748613,
            "y": -0.04254277996991522,
            "z": 0.04254277851373775,
            "w": 0.7058258489080028
        }
    },
    {
        "position": {
            "x": 167.0278310949118,
            "y": 252.75780391693115,
            "z": 1487.14771301271
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5,
            "z": 0.5000000429150953
        },
        "rotation": {
            "x": 0,
            "y": -0.06016457538483551,
            "z": 0,
            "w": 0.9981884711159323
        }
    },
    {
        "position": {
            "x": 167.0278310949118,
            "y": 261.0285930633545,
            "z": 1487.14771301271
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000429150953,
            "z": 0.5
        },
        "rotation": {
            "x": 0.529176523250384,
            "y": 0.46901194786554845,
            "z": -0.46901194786554845,
            "w": 0.5291765232503839
        }
    },
    {
        "position": {
            "x": 167.0278310949118,
            "y": 261.0285930633545,
            "z": 1487.14771301271
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000258008233,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.705825824748613,
            "y": -0.04254277996991522,
            "z": 0.04254277851373775,
            "w": 0.7058258489080028
        }
    },
    {
        "position": {
            "x": 64.18637350778314,
            "y": 251.2587970495224,
            "z": 1459.0514356262895
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 64.18637350778314,
            "y": 260.79834592342377,
            "z": 1459.0514356262895
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 64.18637350778314,
            "y": 260.79834592342377,
            "z": 1459.0514356262895
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 63.476683743700285,
            "y": 251.2587970495224,
            "z": 1453.0065090711405
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 63.476683743700285,
            "y": 259.52958619594574,
            "z": 1453.0065090711405
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 63.476683743700285,
            "y": 259.52958619594574,
            "z": 1453.0065090711405
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 70.94489003440395,
            "y": 251.2587970495224,
            "z": 1457.8051589843515
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 70.94489003440395,
            "y": 258.0270594358444,
            "z": 1457.8051589843515
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 70.94489003440395,
            "y": 258.0270594358444,
            "z": 1457.8051589843515
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 166.52440978356216,
            "y": 249.97431302070618,
            "z": 1407.13333106744
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 166.52440978356216,
            "y": 259.51386189460754,
            "z": 1407.13333106744
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 166.52440978356216,
            "y": 259.51386189460754,
            "z": 1407.13333106744
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 165.81472329223834,
            "y": 249.97431302070618,
            "z": 1401.088402552453
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 165.8147232922384,
            "y": 258.2451021671295,
            "z": 1401.088402552453
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 165.8147232922384,
            "y": 258.2451021671295,
            "z": 1401.088402552453
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 173.28292631018297,
            "y": 249.97431302070618,
            "z": 1405.8870544255021
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 173.28292631018292,
            "y": 256.7425754070282,
            "z": 1405.8870544255021
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 173.28292631018292,
            "y": 256.7425754070282,
            "z": 1405.8870544255021
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 128.36506949484553,
            "y": 252.05686420202255,
            "z": 1469.6543861307623
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 128.36506949484553,
            "y": 261.5964130759239,
            "z": 1469.6543861307623
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 128.36506949484553,
            "y": 261.5964130759239,
            "z": 1469.6543861307623
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 130.53800669995482,
            "y": 252.05686420202255,
            "z": 1463.9690317687928
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 130.53800669995482,
            "y": 260.3276533484459,
            "z": 1463.9690317687928
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 130.53800669995482,
            "y": 260.3276533484459,
            "z": 1463.9690317687928
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 202.53678261707,
            "y": 250.42357921600342,
            "z": 1505.2102652744636
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 202.53678261707,
            "y": 259.9631280899048,
            "z": 1505.2102652744636
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 202.53678261707,
            "y": 259.9631280899048,
            "z": 1505.2102652744636
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 204.70972929791088,
            "y": 250.42357921600342,
            "z": 1499.5249230236113
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 204.70972929791088,
            "y": 258.69436836242676,
            "z": 1499.5249230236113
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 204.70972929791088,
            "y": 258.69436836242676,
            "z": 1499.5249230236113
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 87.61736720386342,
            "y": 252.05686420202255,
            "z": 1433.2152197329617
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 87.61736720386342,
            "y": 261.5964130759239,
            "z": 1433.2152197329617
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 87.61736720386342,
            "y": 261.5964130759239,
            "z": 1433.2152197329617
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 160.58698023633764,
            "y": 252.05686420202255,
            "z": 1455.6339864869387
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5,
            "z": 0.5000000264595826
        },
        "rotation": {
            "x": 0,
            "y": 0.9100818645151308,
            "z": 0,
            "w": 0.41442852204048763
        }
    },
    {
        "position": {
            "x": 160.58698023633764,
            "y": 261.5964130759239,
            "z": 1455.6339864869387
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000264595826,
            "z": 0.5
        },
        "rotation": {
            "x": 0.2478266712373217,
            "y": -0.6622551932778094,
            "z": 0.6622551932778092,
            "w": 0.2478266712373217
        }
    },
    {
        "position": {
            "x": 160.58698023633764,
            "y": 261.5964130759239,
            "z": 1455.6339864869387
        },
        "scale": {
            "x": 0.5000000264595826,
            "y": 0.5000000093453112,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.29304521323669186,
            "y": 0.6435250688470082,
            "z": -0.6435250468200833,
            "w": 0.2930452232672033
        }
    },
    {
        "position": {
            "x": 89.72202772735233,
            "y": 252.40308314561844,
            "z": 1380.0980994980814
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 89.72202772735233,
            "y": 261.9426320195198,
            "z": 1380.0980994980814
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 89.72202772735233,
            "y": 261.9426320195198,
            "z": 1380.0980994980814
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 89.01233927619049,
            "y": 252.40308314561844,
            "z": 1374.0531677103354
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 89.01233927619049,
            "y": 260.6738722920418,
            "z": 1374.0531677103354
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 89.01233927619049,
            "y": 260.6738722920418,
            "z": 1374.0531677103354
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 96.48054515779927,
            "y": 252.40308314561844,
            "z": 1378.851817868526
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 96.48054515779921,
            "y": 259.17134553194046,
            "z": 1378.851817868526
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 96.48054515779921,
            "y": 259.17134553194046,
            "z": 1378.851817868526
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 71.26375466151684,
            "y": 252.75780391693115,
            "z": 1347.9200961456736
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 71.26375466151684,
            "y": 262.2973527908325,
            "z": 1347.9200961456736
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 71.26375466151684,
            "y": 262.2973527908325,
            "z": 1347.9200961456736
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 74.4017505412446,
            "y": 252.75780391693115,
            "z": 1342.7049421722195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 74.4017505412446,
            "y": 261.0285930633545,
            "z": 1342.7049421722195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 74.4017505412446,
            "y": 261.0285930633545,
            "z": 1342.7049421722195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 77.3716501017181,
            "y": 252.75780391693115,
            "z": 1351.0703965081036
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 77.3716501017181,
            "y": 259.5260663032532,
            "z": 1351.0703965081036
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 77.3716501017181,
            "y": 259.5260663032532,
            "z": 1351.0703965081036
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 239.48332157902652,
            "y": 249.77328324317932,
            "z": 1514.2009618904924
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5,
            "z": 0.5000000429150953
        },
        "rotation": {
            "x": 0,
            "y": -0.06016457538483551,
            "z": 0,
            "w": 0.9981884711159323
        }
    },
    {
        "position": {
            "x": 239.48332157902658,
            "y": 258.04407238960266,
            "z": 1514.2009618904922
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000429150953,
            "z": 0.5
        },
        "rotation": {
            "x": 0.529176523250384,
            "y": 0.46901194786554845,
            "z": -0.46901194786554845,
            "w": 0.5291765232503839
        }
    },
    {
        "position": {
            "x": 239.48332157902658,
            "y": 258.04407238960266,
            "z": 1514.2009618904922
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000258008233,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.705825824748613,
            "y": -0.04254277996991522,
            "z": 0.04254277851373775,
            "w": 0.7058258489080028
        }
    },
    {
        "position": {
            "x": 231.05368188393268,
            "y": 249.77328324317932,
            "z": 1511.4184718296667
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5,
            "z": 0.5000000429150953
        },
        "rotation": {
            "x": 0,
            "y": -0.06016457538483551,
            "z": 0,
            "w": 0.9981884711159323
        }
    },
    {
        "position": {
            "x": 231.0536818839327,
            "y": 256.54154562950134,
            "z": 1511.4184718296667
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000429150953,
            "z": 0.5
        },
        "rotation": {
            "x": 0.529176523250384,
            "y": 0.46901194786554845,
            "z": -0.46901194786554845,
            "w": 0.5291765232503839
        }
    },
    {
        "position": {
            "x": 231.0536818839327,
            "y": 256.54154562950134,
            "z": 1511.4184718296667
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000258008233,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.705825824748613,
            "y": -0.04254277996991522,
            "z": 0.04254277851373775,
            "w": 0.7058258489080028
        }
    },
    {
        "position": {
            "x": 114.6770520704425,
            "y": 252.75780391693115,
            "z": 1477.5164185111098
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5,
            "z": 0.5000000429150953
        },
        "rotation": {
            "x": 0,
            "y": -0.06016457538483551,
            "z": 0,
            "w": 0.9981884711159323
        }
    },
    {
        "position": {
            "x": 114.6770520704425,
            "y": 261.0285930633545,
            "z": 1477.5164185111098
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000429150953,
            "z": 0.5
        },
        "rotation": {
            "x": 0.529176523250384,
            "y": 0.46901194786554845,
            "z": -0.46901194786554845,
            "w": 0.5291765232503839
        }
    },
    {
        "position": {
            "x": 114.6770520704425,
            "y": 261.0285930633545,
            "z": 1477.5164185111098
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000258008233,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.705825824748613,
            "y": -0.04254277996991522,
            "z": 0.04254277851373775,
            "w": 0.7058258489080028
        }
    },
    {
        "position": {
            "x": 88.93249265369734,
            "y": 252.75780391693115,
            "z": 1509.6067323138254
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5,
            "z": 0.5000000429150953
        },
        "rotation": {
            "x": 0,
            "y": -0.06016457538483551,
            "z": 0,
            "w": 0.9981884711159323
        }
    },
    {
        "position": {
            "x": 88.93249265369731,
            "y": 261.0285930633545,
            "z": 1509.6067323138254
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000429150953,
            "z": 0.5
        },
        "rotation": {
            "x": 0.529176523250384,
            "y": 0.46901194786554845,
            "z": -0.46901194786554845,
            "w": 0.5291765232503839
        }
    },
    {
        "position": {
            "x": 88.93249265369731,
            "y": 261.0285930633545,
            "z": 1509.6067323138254
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000258008233,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.705825824748613,
            "y": -0.04254277996991522,
            "z": 0.04254277851373775,
            "w": 0.7058258489080028
        }
    },
    {
        "position": {
            "x": 167.0278310949118,
            "y": 252.75780391693115,
            "z": 1487.14771301271
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5,
            "z": 0.5000000429150953
        },
        "rotation": {
            "x": 0,
            "y": -0.06016457538483551,
            "z": 0,
            "w": 0.9981884711159323
        }
    },
    {
        "position": {
            "x": 167.0278310949118,
            "y": 261.0285930633545,
            "z": 1487.14771301271
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000429150953,
            "z": 0.5
        },
        "rotation": {
            "x": 0.529176523250384,
            "y": 0.46901194786554845,
            "z": -0.46901194786554845,
            "w": 0.5291765232503839
        }
    },
    {
        "position": {
            "x": 167.0278310949118,
            "y": 261.0285930633545,
            "z": 1487.14771301271
        },
        "scale": {
            "x": 0.5000000429150953,
            "y": 0.5000000258008233,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.705825824748613,
            "y": -0.04254277996991522,
            "z": 0.04254277851373775,
            "w": 0.7058258489080028
        }
    },
    {
        "position": {
            "x": 64.18637350778314,
            "y": 251.2587970495224,
            "z": 1459.0514356262895
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 64.18637350778314,
            "y": 260.79834592342377,
            "z": 1459.0514356262895
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 64.18637350778314,
            "y": 260.79834592342377,
            "z": 1459.0514356262895
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 63.476683743700285,
            "y": 251.2587970495224,
            "z": 1453.0065090711405
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 63.476683743700285,
            "y": 259.52958619594574,
            "z": 1453.0065090711405
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 63.476683743700285,
            "y": 259.52958619594574,
            "z": 1453.0065090711405
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 70.94489003440395,
            "y": 251.2587970495224,
            "z": 1457.8051589843515
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 70.94489003440395,
            "y": 258.0270594358444,
            "z": 1457.8051589843515
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 70.94489003440395,
            "y": 258.0270594358444,
            "z": 1457.8051589843515
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 166.52440978356216,
            "y": 249.97431302070618,
            "z": 1407.13333106744
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 166.52440978356216,
            "y": 259.51386189460754,
            "z": 1407.13333106744
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 166.52440978356216,
            "y": 259.51386189460754,
            "z": 1407.13333106744
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 165.81472329223834,
            "y": 249.97431302070618,
            "z": 1401.088402552453
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 165.8147232922384,
            "y": 258.2451021671295,
            "z": 1401.088402552453
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 165.8147232922384,
            "y": 258.2451021671295,
            "z": 1401.088402552453
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 173.28292631018297,
            "y": 249.97431302070618,
            "z": 1405.8870544255021
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5,
            "z": 0.5000000219044898
        },
        "rotation": {
            "x": 0,
            "y": 0.9826875901891232,
            "z": 0,
            "w": 0.1852703432508663
        }
    },
    {
        "position": {
            "x": 173.28292631018292,
            "y": 256.7425754070282,
            "z": 1405.8870544255021
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000219044898,
            "z": 0.5
        },
        "rotation": {
            "x": 0.39870862346912844,
            "y": -0.5839789667199948,
            "z": 0.5839789667199947,
            "w": 0.39870862346912844
        }
    },
    {
        "position": {
            "x": 173.28292631018292,
            "y": 256.7425754070282,
            "z": 1405.8870544255021
        },
        "scale": {
            "x": 0.5000000219044898,
            "y": 0.5000000047902184,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.13100591382337604,
            "y": 0.6948650707027052,
            "z": -0.6948650469184866,
            "w": 0.13100591830751787
        }
    },
    {
        "position": {
            "x": 405.30977171823895,
            "y": 264.9514179825783,
            "z": 905.8715419882363
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 405.30977171823895,
            "y": 272.25775200128555,
            "z": 905.8715419882363
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 405.30977171823895,
            "y": 272.25775200128555,
            "z": 905.8715419882363
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 420.29081115180566,
            "y": 264.9514179825783,
            "z": 962.7624015917348
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 420.29081115180566,
            "y": 272.0808020234108,
            "z": 962.7624015917348
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 420.29081115180566,
            "y": 272.0808020234108,
            "z": 962.7624015917348
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 417.96741347438126,
            "y": 264.9514179825783,
            "z": 953.5281101490403
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 417.9674134743812,
            "y": 273.2285662293434,
            "z": 953.5281101490402
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 417.9674134743812,
            "y": 273.2285662293434,
            "z": 953.5281101490402
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 438.05383184250866,
            "y": 264.9514179825783,
            "z": 1068.050223682424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 438.0538318425086,
            "y": 274.49096685647964,
            "z": 1068.050223682424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 438.0538318425086,
            "y": 274.49096685647964,
            "z": 1068.050223682424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 427.7647085376346,
            "y": 264.9514179825783,
            "z": 1016.3559159949789
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 427.76470853763465,
            "y": 274.49096685647964,
            "z": 1016.355915994979
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 427.76470853763465,
            "y": 274.49096685647964,
            "z": 1016.355915994979
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 323.58111445566186,
            "y": 264.9514179825783,
            "z": 1124.2754927923972
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 323.5811144556618,
            "y": 274.49096685647964,
            "z": 1124.2754927923972
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 323.5811144556618,
            "y": 274.49096685647964,
            "z": 1124.2754927923972
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 341.71782674040963,
            "y": 264.9514179825783,
            "z": 1102.9194482212042
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 341.7178267404096,
            "y": 273.2285662293434,
            "z": 1102.9194482212042
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 341.7178267404096,
            "y": 273.2285662293434,
            "z": 1102.9194482212042
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 407.8678150100515,
            "y": 264.9514179825783,
            "z": 1086.1030228562545
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 407.8678150100515,
            "y": 274.49096685647964,
            "z": 1086.1030228562545
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 407.8678150100515,
            "y": 274.49096685647964,
            "z": 1086.1030228562545
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 357.8998307155284,
            "y": 264.9514179825783,
            "z": 1078.4895805160832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 357.8998307155284,
            "y": 274.49096685647964,
            "z": 1078.4895805160832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 357.8998307155284,
            "y": 274.49096685647964,
            "z": 1078.4895805160832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 19.125425454759693,
            "y": 264.9514179825783,
            "z": 838.2735189752873
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 19.125425454759693,
            "y": 272.77335315942764,
            "z": 838.2735189752873
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 19.125425454759693,
            "y": 272.77335315942764,
            "z": 838.2735189752873
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 27.624492088185775,
            "y": 264.9514179825783,
            "z": 841.7845283670765
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 27.624492088185775,
            "y": 277.12828928232193,
            "z": 841.7845283670765
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 27.624492088185775,
            "y": 277.12828928232193,
            "z": 841.7845283670765
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 25.869507763326,
            "y": 264.9514179825783,
            "z": 832.811491038617
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 25.869507763326,
            "y": 274.79032999277115,
            "z": 832.811491038617
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 25.869507763326,
            "y": 274.79032999277115,
            "z": 832.811491038617
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 234.67105063675854,
            "y": 264.9514179825783,
            "z": 802.8126249489576
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 234.67105063675854,
            "y": 277.12828928232193,
            "z": 802.8126249489576
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 234.67105063675854,
            "y": 277.12828928232193,
            "z": 802.8126249489576
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 225.23820873643643,
            "y": 264.9514179825783,
            "z": 800.6268131762381
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 225.23820873643643,
            "y": 275.80424505472183,
            "z": 800.6268131762381
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 225.23820873643643,
            "y": 275.80424505472183,
            "z": 800.6268131762381
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 228.0339929617571,
            "y": 264.9514179825783,
            "z": 793.6180731563394
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 228.0339929617571,
            "y": 274.49096685647964,
            "z": 793.6180731563394
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 228.0339929617571,
            "y": 274.49096685647964,
            "z": 793.6180731563394
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 290.84961427772134,
            "y": 264.9514179825783,
            "z": 786.239593223377
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 290.84961427772134,
            "y": 277.85389429330826,
            "z": 786.239593223377
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 290.84961427772134,
            "y": 277.85389429330826,
            "z": 786.239593223377
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 303.3467985217645,
            "y": 264.9514179825783,
            "z": 792.8380744365985
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 303.3467985217646,
            "y": 275.53878980875015,
            "z": 792.8380744365986
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 303.3467985217646,
            "y": 275.53878980875015,
            "z": 792.8380744365986
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 306.9911202026529,
            "y": 264.9514179825783,
            "z": 785.7736204376071
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 306.9911202026529,
            "y": 272.7058406472206,
            "z": 785.7736204376071
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 306.9911202026529,
            "y": 272.7058406472206,
            "z": 785.7736204376071
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 255.0912272744788,
            "y": 264.9514179825783,
            "z": 810.4590861937195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 255.0912272744788,
            "y": 272.7058406472206,
            "z": 810.4590861937195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 255.0912272744788,
            "y": 272.7058406472206,
            "z": 810.4590861937195
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 160.34909240128223,
            "y": 266.7614178657532,
            "z": 952.4727280635752
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 160.34909240128223,
            "y": 273.1886429786682,
            "z": 952.4727280635752
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 160.34909240128223,
            "y": 273.1886429786682,
            "z": 952.4727280635752
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 166.94538388598846,
            "y": 266.732990860939,
            "z": 958.8777408091921
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 166.94538388598846,
            "y": 273.97085678577423,
            "z": 958.8777408091921
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 166.94538388598846,
            "y": 273.97085678577423,
            "z": 958.8777408091921
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 168.45374225281097,
            "y": 264.9514179825783,
            "z": 954.2814143081677
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 168.45374225281086,
            "y": 274.067118704319,
            "z": 954.2814143081675
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 168.45374225281086,
            "y": 274.067118704319,
            "z": 954.2814143081675
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 219.7540230377799,
            "y": 264.9514179825783,
            "z": 732.9479073197679
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 219.7540230377799,
            "y": 274.067118704319,
            "z": 732.9479073197679
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 219.7540230377799,
            "y": 274.067118704319,
            "z": 732.9479073197679
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 230.46286702869952,
            "y": 264.9514179825783,
            "z": 737.2063228471001
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 230.46286702869952,
            "y": 272.14423805475235,
            "z": 737.2063228471001
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 230.46286702869952,
            "y": 272.14423805475235,
            "z": 737.2063228471001
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 229.03405211513387,
            "y": 264.9514179825783,
            "z": 731.3417816539368
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 229.03405211513387,
            "y": 274.49096685647964,
            "z": 731.3417816539368
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 229.03405211513387,
            "y": 274.49096685647964,
            "z": 731.3417816539368
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 9.922966532307214,
            "y": 264.9514179825783,
            "z": 910.3352665489407
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 9.922966532307214,
            "y": 272.42088466882706,
            "z": 910.3352665489407
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 9.922966532307214,
            "y": 272.42088466882706,
            "z": 910.3352665489407
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 1.4697299998509266,
            "y": 264.9514179825783,
            "z": 909.6531110480598
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 1.4697299998509266,
            "y": 274.49096685647964,
            "z": 909.6531110480598
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 1.4697299998509266,
            "y": 274.49096685647964,
            "z": 909.6531110480598
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 357.6937877753027,
            "y": 264.9514179825783,
            "z": 1045.6793713911707
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 357.6937877753028,
            "y": 274.49096685647964,
            "z": 1045.6793713911707
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 357.6937877753028,
            "y": 274.49096685647964,
            "z": 1045.6793713911707
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 395.41700484567616,
            "y": 264.9514179825783,
            "z": 947.91932324892
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 395.4170048456761,
            "y": 271.39379316568375,
            "z": 947.9193232489199
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 395.4170048456761,
            "y": 271.39379316568375,
            "z": 947.9193232489199
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 347.86820189393234,
            "y": 264.9514179825783,
            "z": 992.8146763348383
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 347.86820189393234,
            "y": 273.2285662293434,
            "z": 992.8146763348383
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 347.86820189393234,
            "y": 273.2285662293434,
            "z": 992.8146763348383
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 303.93921109264954,
            "y": 264.9514179825783,
            "z": 925.8521163576821
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 303.93921109264954,
            "y": 273.2285662293434,
            "z": 925.8521163576821
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 303.93921109264954,
            "y": 273.2285662293434,
            "z": 925.8521163576821
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 261.1596825336089,
            "y": 264.9514179825783,
            "z": 901.1052625242968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 261.1596825336089,
            "y": 273.2285662293434,
            "z": 901.1052625242968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 261.1596825336089,
            "y": 273.2285662293434,
            "z": 901.1052625242968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 277.9076478208033,
            "y": 264.9514179825783,
            "z": 1021.2502612653942
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 277.9076478208033,
            "y": 273.3256474137306,
            "z": 1021.2502612653942
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 277.9076478208033,
            "y": 273.3256474137306,
            "z": 1021.2502612653942
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 243.16893838792697,
            "y": 264.9514179825783,
            "z": 877.8003657697766
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 243.16893838792697,
            "y": 272.34842973947525,
            "z": 877.8003657697766
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 243.16893838792697,
            "y": 272.34842973947525,
            "z": 877.8003657697766
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 307.15366601030394,
            "y": 267.03534626960754,
            "z": 856.4256368179892
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 307.153666010304,
            "y": 275.3442928791046,
            "z": 856.4256368179894
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 307.153666010304,
            "y": 275.3442928791046,
            "z": 856.4256368179894
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 289.1321064116115,
            "y": 264.9514179825783,
            "z": 1072.5098021101408
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 289.1321064116115,
            "y": 274.49096685647964,
            "z": 1072.5098021101408
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 289.1321064116115,
            "y": 274.49096685647964,
            "z": 1072.5098021101408
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 314.8126153120851,
            "y": 264.9514179825783,
            "z": 1020.9092190585438
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 314.8126153120851,
            "y": 274.49096685647964,
            "z": 1020.9092190585438
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 314.8126153120851,
            "y": 274.49096685647964,
            "z": 1020.9092190585438
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 332.51087094942443,
            "y": 264.9514179825783,
            "z": 953.2559969142968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 332.51087094942443,
            "y": 274.49096685647964,
            "z": 953.2559969142968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 332.51087094942443,
            "y": 274.49096685647964,
            "z": 953.2559969142968
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 300.03490379753265,
            "y": 264.9514179825783,
            "z": 969.8001864866187
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 300.03490379753265,
            "y": 273.2285662293434,
            "z": 969.8001864866187
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 300.03490379753265,
            "y": 273.2285662293434,
            "z": 969.8001864866187
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 357.94917251087236,
            "y": 264.9514179825783,
            "z": 885.1379945912572
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 357.9491725108724,
            "y": 273.2285662293434,
            "z": 885.1379945912573
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 357.9491725108724,
            "y": 273.2285662293434,
            "z": 885.1379945912573
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 221.93568755953802,
            "y": 264.9514179825783,
            "z": 987.7240569597624
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 221.93568755953802,
            "y": 273.2285662293434,
            "z": 987.7240569597624
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 221.93568755953802,
            "y": 273.2285662293434,
            "z": 987.7240569597624
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 233.30409712765294,
            "y": 264.9514179825783,
            "z": 1033.0799459243647
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 233.30409712765294,
            "y": 274.49096685647964,
            "z": 1033.0799459243647
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 233.30409712765294,
            "y": 274.49096685647964,
            "z": 1033.0799459243647
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 218.70982285697693,
            "y": 267.8185703754425,
            "z": 932.2778426072537
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 218.70982285697698,
            "y": 276.3214886188507,
            "z": 932.2778426072537
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 218.70982285697698,
            "y": 276.3214886188507,
            "z": 932.2778426072537
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 238.1474523230118,
            "y": 264.9514179825783,
            "z": 968.1500934291346
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 238.1474523230118,
            "y": 274.49096685647964,
            "z": 968.1500934291346
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 238.1474523230118,
            "y": 274.49096685647964,
            "z": 968.1500934291346
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 254.76189003594817,
            "y": 264.9514179825783,
            "z": 834.8160877870832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 254.76189003594817,
            "y": 272.58305698633194,
            "z": 834.8160877870832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 254.76189003594817,
            "y": 272.58305698633194,
            "z": 834.8160877870832
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 326.4206875994796,
            "y": 264.9514179825783,
            "z": 733.6025261378716
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 326.4206875994796,
            "y": 277.12828928232193,
            "z": 733.6025261378716
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 326.4206875994796,
            "y": 277.12828928232193,
            "z": 733.6025261378716
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 316.987847335537,
            "y": 264.9514179825783,
            "z": 731.4167133852329
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 316.987847335537,
            "y": 275.80424505472183,
            "z": 731.4167133852329
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 316.987847335537,
            "y": 275.80424505472183,
            "z": 731.4167133852329
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 319.7836299244782,
            "y": 264.9514179825783,
            "z": 724.4079743452533
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 319.7836299244782,
            "y": 274.49096685647964,
            "z": 724.4079743452533
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 319.7836299244782,
            "y": 274.49096685647964,
            "z": 724.4079743452533
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 323.40167133578495,
            "y": 264.9514179825783,
            "z": 719.3735283669073
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 323.40167133578495,
            "y": 277.12828928232193,
            "z": 719.3735283669073
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 323.40167133578495,
            "y": 277.12828928232193,
            "z": 719.3735283669073
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 313.9688101414541,
            "y": 264.9514179825783,
            "z": 717.1877103625847
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 313.9688101414541,
            "y": 275.80424505472183,
            "z": 717.1877103625847
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 313.9688101414541,
            "y": 275.80424505472183,
            "z": 717.1877103625847
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 316.7646058214315,
            "y": 264.9514179825783,
            "z": 710.1789634832529
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 316.7646058214315,
            "y": 274.49096685647964,
            "z": 710.1789634832529
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 316.7646058214315,
            "y": 274.49096685647964,
            "z": 710.1789634832529
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 334.1263104519153,
            "y": 264.9514179825783,
            "z": 710.9886418088153
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 334.1263104519153,
            "y": 277.12828928232193,
            "z": 710.9886418088153
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 334.1263104519153,
            "y": 277.12828928232193,
            "z": 710.9886418088153
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 324.6934701879727,
            "y": 264.9514179825783,
            "z": 708.8028290561767
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 324.69347018797276,
            "y": 275.80424505472183,
            "z": 708.8028290561768
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 324.69347018797276,
            "y": 275.80424505472183,
            "z": 708.8028290561768
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 327.4892527769139,
            "y": 264.9514179825783,
            "z": 701.7940900161971
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 327.4892527769139,
            "y": 274.49096685647964,
            "z": 701.7940900161971
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 327.4892527769139,
            "y": 274.49096685647964,
            "z": 701.7940900161971
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 381.91554147995316,
            "y": 264.9514179825783,
            "z": 877.746559158249
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 381.9155414799531,
            "y": 277.12828928232193,
            "z": 877.7465591582489
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 381.9155414799531,
            "y": 277.12828928232193,
            "z": 877.7465591582489
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 372.4826922819399,
            "y": 264.9514179825783,
            "z": 875.5607463366115
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 372.4826922819399,
            "y": 275.80424505472183,
            "z": 875.5607463366115
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 372.4826922819399,
            "y": 275.80424505472183,
            "z": 875.5607463366115
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 375.2784809264396,
            "y": 264.9514179825783,
            "z": 868.5520025587659
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 375.27848092643967,
            "y": 274.49096685647964,
            "z": 868.552002558766
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 375.27848092643967,
            "y": 274.49096685647964,
            "z": 868.552002558766
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 406.40845470056246,
            "y": 264.9514179825783,
            "z": 887.7786158798183
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 406.40845470056246,
            "y": 277.12828928232193,
            "z": 887.7786158798183
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 406.40845470056246,
            "y": 277.12828928232193,
            "z": 887.7786158798183
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 396.9755988957862,
            "y": 264.9514179825783,
            "z": 885.5928068755829
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 396.9755988957862,
            "y": 275.80424505472183,
            "z": 885.5928068755829
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 396.9755988957862,
            "y": 275.80424505472183,
            "z": 885.5928068755829
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 399.77139408580405,
            "y": 264.9514179825783,
            "z": 878.5840591780615
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 399.77139408580405,
            "y": 274.49096685647964,
            "z": 878.5840591780615
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 399.77139408580405,
            "y": 274.49096685647964,
            "z": 878.5840591780615
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 396.77592100276536,
            "y": 264.9514179825783,
            "z": 901.2772828836585
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 396.77592100276536,
            "y": 277.12828928232193,
            "z": 901.2772828836585
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 396.77592100276536,
            "y": 277.12828928232193,
            "z": 901.2772828836585
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 390.1388603880069,
            "y": 264.9514179825783,
            "z": 892.0827261819018
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 390.13886038800695,
            "y": 274.49096685647964,
            "z": 892.0827261819019
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 390.13886038800695,
            "y": 274.49096685647964,
            "z": 892.0827261819019
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 202.52436011267403,
            "y": 264.9514179825783,
            "z": 962.7400520745593
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 202.52436011267403,
            "y": 277.12828928232193,
            "z": 962.7400520745593
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 202.52436011267403,
            "y": 277.12828928232193,
            "z": 962.7400520745593
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 203.98336377832575,
            "y": 264.9514179825783,
            "z": 919.0438371438186
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 203.9833637783258,
            "y": 277.12828928232193,
            "z": 919.0438371438186
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 203.9833637783258,
            "y": 277.12828928232193,
            "z": 919.0438371438186
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 261.3340401755894,
            "y": 264.9514179825783,
            "z": 1063.4166191182678
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 261.33404017558934,
            "y": 277.12828928232193,
            "z": 1063.4166191182678
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 261.33404017558934,
            "y": 277.12828928232193,
            "z": 1063.4166191182678
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 251.90119599197078,
            "y": 264.9514179825783,
            "z": 1061.230799820111
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 251.90119599197078,
            "y": 275.80424505472183,
            "z": 1061.230799820111
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 251.90119599197078,
            "y": 275.80424505472183,
            "z": 1061.230799820111
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 254.69697858091195,
            "y": 264.9514179825783,
            "z": 1054.2220607801314
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 254.69697858091195,
            "y": 274.49096685647964,
            "z": 1054.2220607801314
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 254.69697858091195,
            "y": 274.49096685647964,
            "z": 1054.2220607801314
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 272.66203502714575,
            "y": 264.9514179825783,
            "z": 1091.441960119341
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 272.66203502714575,
            "y": 277.12828928232193,
            "z": 1091.441960119341
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 272.66203502714575,
            "y": 277.12828928232193,
            "z": 1091.441960119341
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 263.22918037833307,
            "y": 264.9514179825783,
            "z": 1089.2561381953424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 263.2291803783331,
            "y": 275.80424505472183,
            "z": 1089.2561381953424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 263.2291803783331,
            "y": 275.80424505472183,
            "z": 1089.2561381953424
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 266.02496951279227,
            "y": 264.9514179825783,
            "z": 1082.2473952356866
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 266.0249695127922,
            "y": 274.49096685647964,
            "z": 1082.2473952356866
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 266.0249695127922,
            "y": 274.49096685647964,
            "z": 1082.2473952356866
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 247.16526107967272,
            "y": 264.9514179825783,
            "z": 1073.7568146935837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 247.16526107967272,
            "y": 277.12828928232193,
            "z": 1073.7568146935837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 247.16526107967272,
            "y": 277.12828928232193,
            "z": 1073.7568146935837
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 237.73241035053601,
            "y": 264.9514179825783,
            "z": 1071.570999315103
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 237.73241035053607,
            "y": 275.80424505472183,
            "z": 1071.570999315103
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 237.73241035053607,
            "y": 275.80424505472183,
            "z": 1071.570999315103
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 240.52819948499527,
            "y": 264.9514179825783,
            "z": 1064.5622563554473
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 240.52819948499527,
            "y": 274.49096685647964,
            "z": 1064.5622563554473
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 240.52819948499527,
            "y": 274.49096685647964,
            "z": 1064.5622563554473
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 256.4000396442493,
            "y": 264.9514179825783,
            "z": 1103.4414557300415
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 256.4000396442493,
            "y": 277.12828928232193,
            "z": 1103.4414557300415
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 256.4000396442493,
            "y": 277.12828928232193,
            "z": 1103.4414557300415
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 246.96719283478862,
            "y": 264.9514179825783,
            "z": 1101.2556468970788
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 246.96719283478868,
            "y": 275.80424505472183,
            "z": 1101.2556468970788
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 246.96719283478868,
            "y": 275.80424505472183,
            "z": 1101.2556468970788
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 249.76298196924787,
            "y": 264.9514179825783,
            "z": 1094.2469039374232
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 249.76298196924787,
            "y": 274.49096685647964,
            "z": 1094.2469039374232
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 249.76298196924787,
            "y": 274.49096685647964,
            "z": 1094.2469039374232
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 149.9921505355038,
            "y": 264.9514179825783,
            "z": 1036.6448256370945
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 149.9921505355038,
            "y": 277.12828928232193,
            "z": 1036.6448256370945
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 149.9921505355038,
            "y": 277.12828928232193,
            "z": 1036.6448256370945
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 140.5593083117232,
            "y": 264.9514179825783,
            "z": 1034.4590096116967
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 140.5593083117232,
            "y": 275.80424505472183,
            "z": 1034.4590096116967
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 140.5593083117232,
            "y": 275.80424505472183,
            "z": 1034.4590096116967
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 143.35509041070486,
            "y": 264.9514179825783,
            "z": 1027.4502697535274
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 143.3550904107048,
            "y": 274.49096685647964,
            "z": 1027.4502697535274
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 143.3550904107048,
            "y": 274.49096685647964,
            "z": 1027.4502697535274
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 131.0217197763217,
            "y": 264.9514179825783,
            "z": 1014.780984808315
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 131.0217197763217,
            "y": 277.12828928232193,
            "z": 1014.780984808315
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 131.0217197763217,
            "y": 277.12828928232193,
            "z": 1014.780984808315
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 121.5888775525411,
            "y": 264.9514179825783,
            "z": 1012.5951687829174
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 121.5888775525411,
            "y": 275.80424505472183,
            "z": 1012.5951687829174
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 121.5888775525411,
            "y": 275.80424505472183,
            "z": 1012.5951687829174
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 124.38465916156326,
            "y": 264.9514179825783,
            "z": 1005.5864281065582
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 124.38465916156326,
            "y": 274.49096685647964,
            "z": 1005.5864281065582
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 124.38465916156326,
            "y": 274.49096685647964,
            "z": 1005.5864281065582
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 141.1907934659782,
            "y": 264.9514179825783,
            "z": 1002.4471840879135
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 141.1907934659782,
            "y": 277.12828928232193,
            "z": 1002.4471840879135
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 141.1907934659782,
            "y": 277.12828928232193,
            "z": 1002.4471840879135
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 131.75795075223812,
            "y": 264.9514179825783,
            "z": 1000.261367244326
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 131.75795075223812,
            "y": 275.80424505472183,
            "z": 1000.261367244326
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 131.75795075223812,
            "y": 275.80424505472183,
            "z": 1000.261367244326
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 134.55373334117928,
            "y": 264.9514179825783,
            "z": 993.2526282043464
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 134.55373334117928,
            "y": 274.49096685647964,
            "z": 993.2526282043464
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 134.55373334117928,
            "y": 274.49096685647964,
            "z": 993.2526282043464
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 356.11498141828577,
            "y": 264.9514179825783,
            "z": 816.737711040262
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 356.11498141828577,
            "y": 274.49096685647964,
            "z": 816.737711040262
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 356.11498141828577,
            "y": 274.49096685647964,
            "z": 816.737711040262
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 356.0048063489829,
            "y": 264.9514179825783,
            "z": 825.4571931407556
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 356.0048063489829,
            "y": 275.91553980112076,
            "z": 825.4571931407556
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 356.0048063489829,
            "y": 275.91553980112076,
            "z": 825.4571931407556
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 348.0897863445549,
            "y": 264.9514179825783,
            "z": 819.1309971904151
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 348.08978634455485,
            "y": 275.91553980112076,
            "z": 819.130997190415
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 348.08978634455485,
            "y": 275.91553980112076,
            "z": 819.130997190415
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 368.55318243535874,
            "y": 264.9514179825783,
            "z": 819.326529667113
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 368.55318243535874,
            "y": 275.91553980112076,
            "z": 819.326529667113
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 368.55318243535874,
            "y": 275.91553980112076,
            "z": 819.326529667113
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 361.813345423271,
            "y": 264.9514179825783,
            "z": 810.4408952673675
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 361.813345423271,
            "y": 275.91553980112076,
            "z": 810.4408952673675
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 361.813345423271,
            "y": 275.91553980112076,
            "z": 810.4408952673675
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": -46.0317405873584,
            "y": 266.7242740392685,
            "z": 923.7279355521274
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": -46.03174058735835,
            "y": 277.688395857811,
            "z": 923.7279355521274
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": -46.03174058735835,
            "y": 277.688395857811,
            "z": 923.7279355521274
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": -48.61230365424501,
            "y": 264.9514179825783,
            "z": 930.5525877125028
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": -48.61230365424506,
            "y": 275.91553980112076,
            "z": 930.5525877125028
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": -48.61230365424506,
            "y": 275.91553980112076,
            "z": 930.5525877125028
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": -41.37630108413191,
            "y": 266.3366525173187,
            "z": 934.4863951627309
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": -41.37630108413191,
            "y": 279.0952355861664,
            "z": 934.4863951627309
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": -41.37630108413191,
            "y": 279.0952355861664,
            "z": 934.4863951627309
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": -46.866994735852245,
            "y": 266.2787018418312,
            "z": 942.5762260444749
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": -46.866994735852295,
            "y": 277.6777334809303,
            "z": 942.5762260444749
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": -46.866994735852295,
            "y": 277.6777334809303,
            "z": 942.5762260444749
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": -54.08045380311631,
            "y": 266.5904060602188,
            "z": 933.907294479117
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": -54.080453803116264,
            "y": 278.03198873996735,
            "z": 933.907294479117
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": -54.080453803116264,
            "y": 278.03198873996735,
            "z": 933.907294479117
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": -57.302039654931775,
            "y": 266.94477820396423,
            "z": 923.8878135693269
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": -57.302039654931775,
            "y": 275.9821469783783,
            "z": 923.8878135693269
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": -57.302039654931775,
            "y": 275.9821469783783,
            "z": 923.8878135693269
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": -53.06225109438685,
            "y": 266.9905631542206,
            "z": 917.4150170149256
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": -53.0622510943868,
            "y": 277.95468497276306,
            "z": 917.4150170149256
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": -53.0622510943868,
            "y": 277.95468497276306,
            "z": 917.4150170149256
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 51.06853295837638,
            "y": 264.9514179825783,
            "z": 799.906530120978
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 51.06853295837638,
            "y": 274.49096685647964,
            "z": 799.906530120978
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 51.06853295837638,
            "y": 274.49096685647964,
            "z": 799.906530120978
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 50.566242031110505,
            "y": 264.9514179825783,
            "z": 807.320079541595
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 50.566242031110455,
            "y": 275.8010645508766,
            "z": 807.320079541595
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 50.566242031110455,
            "y": 275.8010645508766,
            "z": 807.320079541595
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 40.881633473391844,
            "y": 264.9514179825783,
            "z": 797.4355977994128
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 40.881633473391844,
            "y": 274.2133999466896,
            "z": 797.4355977994128
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 40.881633473391844,
            "y": 274.2133999466896,
            "z": 797.4355977994128
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 242.20028439333618,
            "y": 264.9514179825783,
            "z": 762.1316567084534
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 242.20028439333618,
            "y": 275.80424505472183,
            "z": 762.1316567084534
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 242.20028439333618,
            "y": 275.80424505472183,
            "z": 762.1316567084534
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 246.8189729974451,
            "y": 264.9514179825783,
            "z": 709.0931134535299
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5,
            "z": 0.5000000268695642
        },
        "rotation": {
            "x": 0,
            "y": 0.8699884335335587,
            "z": 0,
            "w": 0.49307213013698586
        }
    },
    {
        "position": {
            "x": 246.8189729974451,
            "y": 273.74944311380386,
            "z": 709.0931134535299
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000268695642,
            "z": 0.5
        },
        "rotation": {
            "x": 0.1884581516982864,
            "y": -0.6815302818352723,
            "z": 0.6815302818352723,
            "w": 0.1884581516982864
        }
    },
    {
        "position": {
            "x": 246.8189729974451,
            "y": 273.74944311380386,
            "z": 709.0931134535299
        },
        "scale": {
            "x": 0.5000000268695642,
            "y": 0.5000000097552928,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.3486546408669883,
            "y": 0.6151747314337083,
            "z": -0.6151747103771742,
            "w": 0.3486546528009295
        }
    },
    {
        "position": {
            "x": 71.7947233026949,
            "y": 264.9514179825783,
            "z": 877.5496292089513
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 71.79472330269506,
            "y": 274.49096685647964,
            "z": 877.5496292089513
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 71.79472330269506,
            "y": 274.49096685647964,
            "z": 877.5496292089513
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 64.78502347098262,
            "y": 264.9514179825783,
            "z": 880.0148581093822
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 64.7850234709826,
            "y": 275.8010645508766,
            "z": 880.0148581093821
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 64.7850234709826,
            "y": 275.8010645508766,
            "z": 880.0148581093821
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 70.04336486906465,
            "y": 264.9514179825783,
            "z": 867.2146832668279
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 70.04336486906476,
            "y": 274.2133999466896,
            "z": 867.2146832668278
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 70.04336486906476,
            "y": 274.2133999466896,
            "z": 867.2146832668278
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 24.239855696828755,
            "y": 264.9514179825783,
            "z": 984.2968395946065
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 24.239855696828755,
            "y": 274.49096685647964,
            "z": 984.2968395946065
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 24.239855696828755,
            "y": 274.49096685647964,
            "z": 984.2968395946065
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 17.230159784792477,
            "y": 264.9514179825783,
            "z": 986.7620750405555
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 17.230159784792477,
            "y": 275.8010645508766,
            "z": 986.7620750405555
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 17.230159784792477,
            "y": 275.8010645508766,
            "z": 986.7620750405555
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 22.488497263198468,
            "y": 264.9514179825783,
            "z": 973.9618936524832
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 22.488497263198468,
            "y": 274.2133999466896,
            "z": 973.9618936524832
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 22.488497263198468,
            "y": 274.2133999466896,
            "z": 973.9618936524832
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 79.41329545308187,
            "y": 264.9514179825783,
            "z": 956.9378031341191
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 79.41329545308203,
            "y": 274.49096685647964,
            "z": 956.9378031341191
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 79.41329545308203,
            "y": 274.49096685647964,
            "z": 956.9378031341191
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 72.4035995410456,
            "y": 264.9514179825783,
            "z": 959.4030385800683
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 72.40359954104552,
            "y": 275.8010645508766,
            "z": 959.4030385800684
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 72.40359954104552,
            "y": 275.8010645508766,
            "z": 959.4030385800684
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 77.66193701945159,
            "y": 264.9514179825783,
            "z": 946.6028571919959
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 77.6619370194515,
            "y": 274.2133999466896,
            "z": 946.6028571919958
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 77.6619370194515,
            "y": 274.2133999466896,
            "z": 946.6028571919958
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 159.60169007202654,
            "y": 264.9514179825783,
            "z": 983.091092130069
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 159.6016900720266,
            "y": 274.49096685647964,
            "z": 983.0910921300689
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 159.6016900720266,
            "y": 274.49096685647964,
            "z": 983.0910921300689
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 152.59200725102647,
            "y": 264.9514179825783,
            "z": 985.556319736666
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 152.59200725102653,
            "y": 275.8010645508766,
            "z": 985.5563197366658
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 152.59200725102653,
            "y": 275.8010645508766,
            "z": 985.5563197366658
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 157.85034014375236,
            "y": 264.9514179825783,
            "z": 972.7561455410288
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 157.85034014375242,
            "y": 274.2133999466896,
            "z": 972.7561455410287
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 157.85034014375242,
            "y": 274.2133999466896,
            "z": 972.7561455410287
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 163.73154576338092,
            "y": 264.9514179825783,
            "z": 847.3620011198519
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 163.7315457633809,
            "y": 274.49096685647964,
            "z": 847.3620011198518
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 163.7315457633809,
            "y": 274.49096685647964,
            "z": 847.3620011198518
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 156.7218635892978,
            "y": 264.9514179825783,
            "z": 849.8272372318049
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 156.7218635892978,
            "y": 275.8010645508766,
            "z": 849.8272372318049
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 156.7218635892978,
            "y": 275.8010645508766,
            "z": 849.8272372318049
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 161.98019844186175,
            "y": 264.9514179825783,
            "z": 837.0270663089268
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 161.9801984418618,
            "y": 274.2133999466896,
            "z": 837.0270663089267
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 161.9801984418618,
            "y": 274.2133999466896,
            "z": 837.0270663089267
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 99.42131642266793,
            "y": 264.9514179825783,
            "z": 828.0998171547877
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5,
            "z": 0.5000000221087613
        },
        "rotation": {
            "x": 0,
            "y": 0.4552871541839099,
            "z": 0,
            "w": 0.8903446564309334
        }
    },
    {
        "position": {
            "x": 99.42131642266804,
            "y": 275.8010645508766,
            "z": 828.0998171547876
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000221087613,
            "z": 0.5
        },
        "rotation": {
            "x": -0.21752875112351167,
            "y": -0.6728159053074216,
            "z": 0.6728159053074216,
            "w": -0.21752875112351167
        }
    },
    {
        "position": {
            "x": 99.42131642266804,
            "y": 275.8010645508766,
            "z": 828.0998171547876
        },
        "scale": {
            "x": 0.5000000221087613,
            "y": 0.5000000049944899,
            "z": 0.49999998288572944
        },
        "rotation": {
            "x": 0.6295687333809092,
            "y": 0.32193663962027935,
            "z": -0.321936628600857,
            "w": 0.62956875493013
        }
    },
    {
        "position": {
            "x": 132.4811911472046,
            "y": 264.9514179825783,
            "z": 799.8065680154155
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5,
            "z": 0.5000000271886207
        },
        "rotation": {
            "x": 0,
            "y": 0.9088730581404234,
            "z": 0,
            "w": 0.41707285237290914
        }
    },
    {
        "position": {
            "x": 132.4811911472046,
            "y": 274.49096685647964,
            "z": 799.8065680154155
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000271886207,
            "z": 0.5
        },
        "rotation": {
            "x": 0.24590010288375722,
            "y": -0.6629729552566662,
            "z": 0.6629729552566663,
            "w": 0.24590010288375722
        }
    },
    {
        "position": {
            "x": 132.4811911472046,
            "y": 274.49096685647964,
            "z": 799.8065680154155
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000100743492,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.2949150371144437,
            "y": 0.6426703136476826,
            "z": -0.6426702916500147,
            "w": 0.2949150472089565
        }
    },
    {
        "position": {
            "x": 133.24622776443553,
            "y": 264.9514179825783,
            "z": 807.1976257000862
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5,
            "z": 0.5000000271886207
        },
        "rotation": {
            "x": 0,
            "y": 0.9088730581404234,
            "z": 0,
            "w": 0.41707285237290914
        }
    },
    {
        "position": {
            "x": 133.24622776443553,
            "y": 275.8010645508766,
            "z": 807.1976257000862
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000271886207,
            "z": 0.5
        },
        "rotation": {
            "x": 0.24590010288375722,
            "y": -0.6629729552566662,
            "z": 0.6629729552566663,
            "w": 0.24590010288375722
        }
    },
    {
        "position": {
            "x": 133.24622776443553,
            "y": 275.8010645508766,
            "z": 807.1976257000862
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000100743492,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.2949150371144437,
            "y": 0.6426703136476826,
            "z": -0.6426702916500147,
            "w": 0.2949150472089565
        }
    },
    {
        "position": {
            "x": 122.022548137929,
            "y": 264.9514179825783,
            "z": 799.1029729609058
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5,
            "z": 0.5000000271886207
        },
        "rotation": {
            "x": 0,
            "y": 0.9088730581404234,
            "z": 0,
            "w": 0.41707285237290914
        }
    },
    {
        "position": {
            "x": 122.022548137929,
            "y": 274.2133999466896,
            "z": 799.1029729609058
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000271886207,
            "z": 0.5
        },
        "rotation": {
            "x": 0.24590010288375722,
            "y": -0.6629729552566662,
            "z": 0.6629729552566663,
            "w": 0.24590010288375722
        }
    },
    {
        "position": {
            "x": 122.022548137929,
            "y": 274.2133999466896,
            "z": 799.1029729609058
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000100743492,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.2949150371144437,
            "y": 0.6426703136476826,
            "z": -0.6426702916500147,
            "w": 0.2949150472089565
        }
    },
    {
        "position": {
            "x": 120.5737274211786,
            "y": 264.4468485713005,
            "z": 955.2828043002877
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5,
            "z": 0.5000000271886207
        },
        "rotation": {
            "x": 0,
            "y": 0.9088730581404234,
            "z": 0,
            "w": 0.41707285237290914
        }
    },
    {
        "position": {
            "x": 120.5737274211786,
            "y": 273.9863974452019,
            "z": 955.2828043002877
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000271886207,
            "z": 0.5
        },
        "rotation": {
            "x": 0.24590010288375722,
            "y": -0.6629729552566662,
            "z": 0.6629729552566663,
            "w": 0.24590010288375722
        }
    },
    {
        "position": {
            "x": 120.5737274211786,
            "y": 273.9863974452019,
            "z": 955.2828043002877
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000100743492,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.2949150371144437,
            "y": 0.6426703136476826,
            "z": -0.6426702916500147,
            "w": 0.2949150472089565
        }
    },
    {
        "position": {
            "x": 121.33875749289143,
            "y": 264.4468485713005,
            "z": 962.6738659046345
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5,
            "z": 0.5000000271886207
        },
        "rotation": {
            "x": 0,
            "y": 0.9088730581404234,
            "z": 0,
            "w": 0.41707285237290914
        }
    },
    {
        "position": {
            "x": 121.33875749289143,
            "y": 275.29649513959885,
            "z": 962.6738659046345
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000271886207,
            "z": 0.5
        },
        "rotation": {
            "x": 0.24590010288375722,
            "y": -0.6629729552566662,
            "z": 0.6629729552566663,
            "w": 0.24590010288375722
        }
    },
    {
        "position": {
            "x": 121.33875749289143,
            "y": 275.29649513959885,
            "z": 962.6738659046345
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000100743492,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.2949150371144437,
            "y": 0.6426703136476826,
            "z": -0.6426702916500147,
            "w": 0.2949150472089565
        }
    },
    {
        "position": {
            "x": 110.115083764986,
            "y": 264.4468485713005,
            "z": 954.5792007404219
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5,
            "z": 0.5000000271886207
        },
        "rotation": {
            "x": 0,
            "y": 0.9088730581404234,
            "z": 0,
            "w": 0.41707285237290914
        }
    },
    {
        "position": {
            "x": 110.11508376498591,
            "y": 273.70883053541183,
            "z": 954.579200740422
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000271886207,
            "z": 0.5
        },
        "rotation": {
            "x": 0.24590010288375722,
            "y": -0.6629729552566662,
            "z": 0.6629729552566663,
            "w": 0.24590010288375722
        }
    },
    {
        "position": {
            "x": 110.11508376498591,
            "y": 273.70883053541183,
            "z": 954.579200740422
        },
        "scale": {
            "x": 0.5000000271886207,
            "y": 0.5000000100743492,
            "z": 0.4999999828857294
        },
        "rotation": {
            "x": 0.2949150371144437,
            "y": 0.6426703136476826,
            "z": -0.6426702916500147,
            "w": 0.2949150472089565
        }
    }
]
