<template>
  <div class="story-container">
    <div class="frames">
      <img class="frame-top" src="../assets/border-top.png" />
      <img class="frame-bottom" src="../assets/border-top.png" />
    </div>
    <div class="chapter-scene" ref="container">
      <div class="information">
        <div class="chapter-summary">
          <div class="chapter-title">4. الـثـقـافـة والازدهـار</div>
          <div class="chapter-year">1728 م - 1140 هـ</div>
        </div>

      </div>
    </div>

    <div class="introduction" ref="intro">
      <div class="introduction-container">
        <div class="video">
          <div class="video-info" ref="videoInfo">
            <div class="intro-bg"><img src="/assets/chapter4-thumbnail.jpg" /></div>
            <div class="video-inner-info">
              <div class="chapter-number p1-font-size ">الفصل الرابع</div>
              <div class="chapter-title h4-font-size">الـثـقـافـة والازدهـار</div>
              <div class="chapter-year h6-font-size">1728 م - 1140 هـ</div>

              <div class="btn">
                <a @click="startVideo()">
                  ابــدأ الــرحــلــة
                  <img src="../assets/ic-arrow.svg" />
                </a>
              </div>
            </div>
          </div>
          <video width="100vw" height="100vh" playsinline @ended="onEnd()" ref="video">
            <source src="/models/chapter4/intro.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <div class="vignette">
            <div class="frames">
              <img class="frame-top" src="../assets/border-top.png" />
              <img class="frame-bottom" src="../assets/border-top.png" />
            </div>

          </div>
        </div>
        <div class="introduction-footer">
          <div class="preloader show" id="preloader">
            <div class="">جــار الــتــحــمــيــل</div>
            <div class="preloader-container">
              <div id="percent"></div>
              <div class="preloader-bg"></div>

            </div>
          </div>
          <div class="skip-intro" id="skipIntro" @click="onEnd()">
            <svg width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_417_2864)">
                <path
                  d="M0.835234 21.3441C0.855275 21.5979 3.26022 21.5003 3.48067 21.3441C6.82754 18.904 10.0943 16.0538 12.4591 12.6766C12.7397 12.2862 13.1405 11.8177 13.3008 11.3687C13.4612 10.9197 13.2407 10.8806 12.88 10.5097C12.6395 10.256 12.419 10.0022 12.1785 9.76793C11.2366 8.77233 10.2947 7.77674 9.35273 6.78114C7.42878 4.73139 5.48478 2.70116 3.56083 0.651409C3.56083 0.631887 3.52075 0.612366 3.44059 0.592844C3.20009 0.475716 2.77923 0.495237 2.45857 0.514759C1.71704 0.514759 0.875315 0.553802 0.875315 0.690453C0.574698 7.54248 0.173875 14.5116 0.835234 21.3441Z"
                  fill="#FFF4E5" />
                <path
                  d="M18.0062 0.636332C17.3435 7.46884 17.7451 14.438 18.0263 21.29C18.0263 21.4267 18.8698 21.4852 19.6128 21.4852C19.9342 21.5048 20.3559 21.5243 20.5969 21.4072C20.6773 21.4072 20.7174 21.3681 20.7174 21.3486C22.6454 19.2988 24.5934 17.2491 26.5214 15.2189C27.4653 14.2233 28.4092 13.2277 29.3531 12.2321C29.5941 11.9783 29.8351 11.744 30.056 11.4903C30.4175 11.1194 30.6184 11.0803 30.4778 10.6313C30.3372 10.1823 29.9155 9.71381 29.6343 9.32338C27.2645 5.94617 23.991 3.09603 20.6371 0.655853C20.4162 0.499681 18.0062 0.402073 17.966 0.655853"
                  fill="#FFF4E5" />
              </g>
              <defs>
                <clipPath id="clip0_417_2864">
                  <rect width="21" height="31" fill="white" transform="translate(0 21.5) rotate(-90)" />
                </clipPath>
              </defs>
            </svg>
            <span>تجاوز هذه المقدمة</span>

          </div>


        </div>
      </div>
    </div>

    <!-- <div class="preloader show" id="preloader">
      <div class="preloader-container">
        <div id="percent"></div>
        <div class="preloader-bg"></div>
        <div class="p1-font-size">جــار الــتــحــمــيــل</div>
      </div>
    </div> -->
    <div class="outro chapter4-outro" id="outro" ref="outro" :class="{ show: showOutro }">
      <div class="outro-container">
        <div class="outro-info">


          <div class="chapter-title h4-font-size">شـارك قـصـتـنـا مـع الـعـالـم</div>

          <div class="btn">
            <a target="_blank"
              href="http://twitter.com/share?text=كُن جزءًا من قصة التأسيس، تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز&url=https://foundingday.antame.org&via=Darahfoundation">
              شـارك عـلـى تـويـتـر

              <img src="../assets/twitter-icon-light.svg" />
              <span class="bg"></span>
            </a>
            <a class="whatsapp_share" target="_blank"
              href="whatsapp://send?text=كُن جزءًا من قصة التأسيس، تجربة تفاعلية من أنتمي أحد مبادرات دارة الملك عبدالعزيز https://foundingday.antame.org">
              شـارك عـلـى واتـسـاب

              <img src="../assets/whatsapp-icon-light.svg" />
              <span class="bg"></span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="action1 chapter1-actions " id="actionIntro">
      <div class="take-action">
        <div class="take-action-container">
          <span class="label">عندما ترى هذة العلامة</span>
          <div class="drag-container">

            <img src="../assets/scroll-down.gif" class="knob scroll-down desktop-view" />
            <!-- <img src="../assets/scroll-mobile.gif" class="knob scroll-down mobile-view" /> -->
          </div>
          <span class="label">
            قــم بــالــتــمــريــر للأســفــل
            <br /> لمتابعة أحداث القصة
          </span>
          <div class="btn"><a @click="startExperience()"> تـأكــيـد</a></div>
        </div>
        <div class="bg"></div>
      </div>
    </div>
    <!--    <div class="action1 chapter4-actions show" id="action0"><span>Start</span></div>-->
    <div class="action1 chapter4-actions" id="action1">

      <div class="take-action">
        <div class="take-action-container">
          <span class="title">نجح الإمام برؤيته للاستقرار</span>
          <div class="drag-container">
            <svg width="1049.305" height="1" viewBox="0 0 1049.305 1" fill="none" version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <path id="path1" d="M-24238,4807.433h1049.3" transform="translate(24238 -4806.933)" stroke="gray"
                stroke-dasharray="5,5" fill="transparent" />
              <path id="path2" d="M-24238,4807.433h1049.3" transform="translate(24238 -4806.933)" stroke-width="7"
                stroke="#7CFC00" fill="transparent" stroke-linecap="round" />
            </svg>
            <img src="../assets/drag.svg" class="knob" />
          </div>
          <span class="label">رحب بالضيوف</span>
        </div>
        <div class="bg"></div>

      </div>
    </div>
    <div class="action1 chapter4-actions" id="action2">
      <div class="take-action">
        <div class="take-action-container">
          <span class="title">أصبحت الدرعية مركزا للعلم والثقافة</span>
          <div class="drag-container">
            <svg width="1049.305" height="1" viewBox="0 0 1049.305 1" fill="none" version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <path id="path1" d="M-24238,4807.433h1049.3" transform="translate(24238 -4806.933)" stroke="gray"
                stroke-dasharray="5,5" fill="transparent" />
              <path id="path2" d="M-24238,4807.433h1049.3" transform="translate(24238 -4806.933)" stroke-width="7"
                stroke="#7CFC00" fill="transparent" stroke-linecap="round" />
            </svg>
            <img src="../assets/drag.svg" class="knob" />
          </div>
          <span class="label">شارك بنشر المعرفة</span>
        </div>
        <div class="bg"></div>

      </div>
    </div>
    <div class="action1 chapter4-actions" id="action3">
      <div class="take-action">
        <div class="take-action-container">
          <span class="title">
            الدرعية عاصمة.. الامان والكرم و الازدهار
          </span>
          <div class="drag-container">

            <svg width="1" height="200.075" viewBox="0 0 1 200.075" fill="none" version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <path id="path1" d="M-23421.68,8543.969V8343.894" transform="translate(23422.18 -8343.894)" stroke="gray"
                stroke-dasharray="5,5" fill="transparent" />
              <path id="path2" d="M-23421.68,8543.969V8343.894" transform="translate(23422.18 -8343.894)" stroke-width="7"
                stroke="#7CFC00" fill="transparent" stroke-linecap="round" />
            </svg>
            <img src="../assets/drag.svg" class="knob" />
          </div>
          <span class="label">افتخر بموطنك</span>
        </div>
        <div class="bg"></div>

      </div>
    </div>
    <div class="action1 chapter4-actions" id="action4">
      <div class="take-action">
        <div class="take-action-container">
          <div class="drag-container">
            <svg width="544.496" height="218.879" viewBox="0 0 544.496 218.879" fill="none" version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <path id="path1" d="M-15073.732-3582.848s66.184,218.234,281.068,218.234,262.461-218.234,262.461-218.234"
                transform="translate(15074.211 3582.993)" stroke="gray" stroke-dasharray="5,5" fill="transparent" />
              <path id="path2" d="M-15073.732-3582.848s66.184,218.234,281.068,218.234,262.461-218.234,262.461-218.234"
                transform="translate(15074.211 3582.993)" stroke-width="7" stroke="#7CFC00" fill="transparent"
                stroke-linecap="round" />
            </svg>
            <img src="../assets/drag.svg" class="knob" />
          </div>
          <span class="label">قــم بــالــتــدويــر</span>
        </div>
        <div class="bg"></div>

      </div>
    </div>
    <div class="action1 chapter4-actions" id="action5">
      <div class="take-action">
        <div class="take-action-container">
          <div class="drag-container">
            <svg width="544.496" height="218.879" viewBox="0 0 544.496 218.879" fill="none" version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <path id="path1" d="M-15073.732-3582.848s66.184,218.234,281.068,218.234,262.461-218.234,262.461-218.234"
                transform="translate(15074.211 3582.993)" stroke="gray" stroke-dasharray="5,5" fill="transparent" />
              <path id="path2" d="M-15073.732-3582.848s66.184,218.234,281.068,218.234,262.461-218.234,262.461-218.234"
                transform="translate(15074.211 3582.993)" stroke-width="7" stroke="#7CFC00" fill="transparent"
                stroke-linecap="round" />
            </svg>
            <img src="../assets/drag.svg" class="knob" />
          </div>
          <span class="label">قــم بــالــتــدويــر</span>
        </div>
        <div class="bg"></div>

      </div>
    </div>

    <div class="scroll-container" id="scrollAction">

      <img src="../assets/scroll-down.gif" class="knob scroll-down desktop-view" />
      <img src="../assets/scroll-mobile.gif" class="knob scroll-down mobile-view" />
    </div>
    <CaptionComponent />


  </div>
</template>


<script>
import MainScene from "@/classes/chapter4/MainScene";
import gsap from "gsap";
import emitter from 'tiny-emitter/instance';
import CaptionComponent from "../components/Caption.vue";
export default {
  name: 'Chapter4SceneComponent',
  components: {
    CaptionComponent
  },
  methods: {
    onEnd: function () {

      gsap.to(this.$refs.intro, { opacity: 0, display: 'none', duration: 1 })
      emitter.emit('updateAudio', true)
      MainScene.start();
      this.$refs.video.pause();
    },
    startVideo: function () {
      emitter.emit('updateAudio', true);
      gsap.to(this.$refs.videoInfo, { opacity: 0, display: 'none', duration: 1 });
      this.$refs.video.play();
    },
    startExperience: function () {
      emitter.emit('startExperience4', true);
    },

  },
  data: () => {
    return {
      imageSrc: '',
      title: '',
      information: '',
      showOutro: false,
    }
  },
  mounted() {


    MainScene.init(this.$refs.container);
    this.$refs.video.pause();

    emitter.on('chapterDone', (isDone) => {
      this.showOutro = true;

    });
    emitter.on('updateAudio', (isMuted) => {
      this.$refs.video.muted = !isMuted
    })

  },
  beforeUnmount() {
    MainScene.destroy();
  },
  unmounted() {
    MainScene.destroy();
  }
}
</script> 